import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!

  return (_openBlock(), _createBlock(_component_MkBadge, {
    rounded: false,
    color: _ctx.color,
    block: "",
    size: _ctx.size,
    filled: "",
    label: _ctx.label
  }, null, 8, ["color", "size", "label"]))
}

import { Vue, Options } from 'vue-class-component';
import DataTable from '@/lib/components/DataTable/DataTable.vue';
import { ExternalPatientSearchResult } from '@/models';
import { IColumn } from '@/lib';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import { getDobFromISOString } from '@/helpers/patient.helper';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { genderOptions } from '@/constants';

@Options({
  components: {
    BaseIcon,
    BaseButton,
    DataTable
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    selectedPatient: {
      type: Object,
      default: null
    },
    searchResult: {
      type: Array,
      default: null
    },
    searchTypeIndex: {
      type: Number,
      required: true
    },
    showPatientResultsTable: {
      type: Boolean,
      default: true
    }
  }
})
export default class PatientResults extends Vue {
  searchResult!: Array<ExternalPatientSearchResult>;
  showResult = false;
  loading!: boolean;
  columns: Array<IColumn> = [
    { name: 'patient', label: 'Patient', sortable: false },
    { name: 'ids' },
    { name: 'postcode', label: 'Postcode' },
    { name: 'buttons' }
  ];

  searchTypeIndex!: number;
  showPatientResultsTable!: boolean;

  customHeadingStyle =
    'background-color: rgba(61, 71, 107, 0.03);padding-top: 1rem;padding-bottom: 1rem;border-radius: 0.5rem;';

  get isPDSSearch(): boolean {
    return this.searchResult[this.searchTypeIndex].type === 'SPINE';
  }

  get tableRows() {
    return this.searchResult[this.searchTypeIndex].rows.map((patient) => ({
      ...patient,
      ...(patient.deceased_at
        ? {
          deceased_at_pretty: this.$d(getDobFromISOString(patient.deceased_at), 'shortMonth')
        }
        : {}),
      ...(patient.date_of_birth
        ? {
          date_of_birth_pretty_short_month: this.$d(getDobFromISOString(patient.date_of_birth), 'shortMonth')
        }
        : {}),
      isMuted: !!patient.deceased_at
    }));
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'shortMonth');
  }

  getGender(gender: string) {
    return genderOptions().find((option) => option.value === gender)?.label;
  }

  select(index: number) {
    this.$emit('update-search-type', index);
  }

  get selectedStyle() {
    return {
      label: 'text-gray-900',
      total: 'text-white bg-primary-500 rounded'
    };
  }

  get unselectedStyle() {
    return {
      label: 'text-gray-500',
      total: 'text-gray-500 bg-gray-200 rounded-md'
    };
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "text-sm font-medium text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(
      _ctx.dark
        ? 'background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)'
        : 'background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%)'
    ),
    class: "flex w-full justify-center items-end fixed py-8 bottom-0 right-0 left-0 h-48 z-10 pointer-events-none"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row items-center py-3 px-4 rounded text-white space-x-4 pointer-events-auto", _ctx.bgColorClass])
    }, [
      _createVNode(_component_BaseIcon, {
        name: _ctx.icon,
        height: "16px"
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1),
      (_ctx.action || !_ctx.autoDismiss)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["h-6 border", _ctx.separatorColorClass])
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.action)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["text-sm font-medium hover:text-white", _ctx.actionColorClass])
          }, [
            _createVNode(_component_router_link, {
              to: _ctx.action.to
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.action.label), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ], 2))
        : _createCommentVNode("", true),
      (!_ctx.action && !_ctx.autoDismiss)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            type: "button",
            class: _normalizeClass(["focus:outline-none", _ctx.dismissColorClass]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss')))
          }, [
            _createVNode(_component_BaseIcon, {
              name: "close",
              height: "16px"
            })
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 4))
}
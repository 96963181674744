import router from '@/router';
import { useSessionStore } from '@/stores/session.store';
// @ts-ignore
import { watch } from 'vue';
import { storeToRefs } from 'pinia';

const directive = {
  created: (el, binding) => {
    const sessionStore = useSessionStore();
    const { permissions } = storeToRefs(sessionStore);
    const removeChild = document.createComment(' ');
    // TODO: store.watch with Pinia??
    el.unwatch = watch(
      permissions,
      (permissionList: string[]) => {
        const { currentUser } = sessionStore;
        if (!(router.currentRoute.value.meta?.isAdmin && currentUser?.is_admin)) {
          if (binding.value && binding.value.length && !permissionList.includes(binding.value)) {
            if (el.parentNode) {
              el.parentNode.replaceChild(removeChild, el);
            }
          } else if (removeChild.parentNode) {
            removeChild.parentNode.replaceChild(el, removeChild);
          }
        }
      },
      { deep: true }
    );
  },
  mounted: () => {
    const sessionStore = useSessionStore();
    const currentOrgId = sessionStore.currentOrganisationId;
    sessionStore.currentOrganisationId = '';
    sessionStore.currentOrganisationId = currentOrgId;
  },
  unmounted: (el) => {
    el.unwatch();
  }
};

export default directive;

export const lensParametersUnits = {
  Addition: ' D',
  BackCylinder: ' D',
  BackCylinderAxis: 'º',
  Diameter: ' mm',
  EdgeLift: ' mm',
  FrontCylinder: ' D',
  FrontCylinderAxis: 'º',
  Height: ' mm',
  HeightFlat: ' mm',
  HeightSteep: ' mm',
  LensTotalHeight: ' mm',
  Power: ' D',
  Prism: ' pD',
  Radius: ' mm',
  RadiusFlat: ' mm',
  RadiusSteep: ' mm',
  SkirtRadius: ' mm',
  Tangent: 'º',
  TangentFlat: 'º',
  TangentSteep: 'º',
  Vault: ' µm'
};


import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    level: {
      type: Number,
      default: 2
    },
    loading: {
      type: Boolean,
      default: false
    },
    card: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  }
})
export default class Question extends Vue {}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_2 = { class: "grid grid-cols-4 gap-y-8 gap-x-4 mt-10 md:grid-cols-4" }
const _hoisted_3 = { class: "grid grid-cols-4 gap-y-8 gap-x-4 mt-8 md:grid-cols-4" }
const _hoisted_4 = { class: "grid grid-cols-4 mt-8" }
const _hoisted_5 = { class: "text-gray-500 text-sm" }
const _hoisted_6 = { class: "flex space-between mt-2" }
const _hoisted_7 = {
  key: 2,
  class: "pr-2 text-gray-500"
}
const _hoisted_8 = { class: "col-span-1 flex justify-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseDatePicker = _resolveComponent("BaseDatePicker")!
  const _component_BaseSearchInput = _resolveComponent("BaseSearchInput")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", null, [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('custom.uhb.patient.search')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseTextInput, {
          "model-value": _ctx.modelValue.nhsNumber,
          class: "col-span-3",
          label: _ctx.$t('custom.uhb.patient.nhs-number'),
          placeholder: _ctx.$t('custom.uhb.patient.enter-nhs-number'),
          error: _ctx.nhsNumberError,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.validateNHSNumber($event))),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateNHSNumber($event.target.value, $event.type))),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (!_ctx.disableSearch && _ctx.search()), ["enter"]))
        }, null, 8, ["model-value", "label", "placeholder", "error"]),
        _createVNode(_component_BaseDatePicker, {
          modelValue: _ctx.modelValue.dob,
          "onUpdate:modelValue": [
            _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.dob) = $event)),
            _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateDob($event)))
          ],
          class: "col-span-1 leading-tight",
          "max-date": _ctx.today,
          label: _ctx.$t('platform.patient.date-of-birth'),
          error: _ctx.dobError,
          onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (!_ctx.disableSearch && _ctx.search()), ["enter"]))
        }, null, 8, ["modelValue", "max-date", "label", "error"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseTextInput, {
          "model-value": _ctx.modelValue.givenName,
          label: _ctx.$t('platform.patient.given-names'),
          placeholder: _ctx.$t('platform.patient.enter-given-names'),
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:modelValue', { ..._ctx.modelValue, givenName: $event }))),
          onKeypress: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateName($event))),
          onKeyup: _cache[8] || (_cache[8] = _withKeys(($event: any) => (!_ctx.disableSearch && _ctx.search()), ["enter"]))
        }, null, 8, ["model-value", "label", "placeholder"]),
        _createVNode(_component_BaseTextInput, {
          "model-value": _ctx.modelValue.familyName,
          label: _ctx.$t('platform.patient.family-name'),
          placeholder: _ctx.$t('platform.patient.enter-family-name'),
          class: "leading-tight",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('update:modelValue', { ..._ctx.modelValue, familyName: $event }))),
          onKeypress: _cache[10] || (_cache[10] = ($event: any) => (_ctx.validateName($event))),
          onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (!_ctx.disableSearch && _ctx.search()), ["enter"]))
        }, null, 8, ["model-value", "label", "placeholder"]),
        _createVNode(_component_BaseSearchInput, {
          "model-value": _ctx.modelValue.gender,
          label: _ctx.$t('platform.patient.gender'),
          placeholder: _ctx.$t('platform.patient.enter-gender'),
          suggestions: _ctx.filteredGenderOptions,
          "max-width": false,
          class: "leading-tight",
          clear: "",
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('update:modelValue', { ..._ctx.modelValue, gender: $event })))
        }, null, 8, ["model-value", "label", "placeholder", "suggestions"]),
        _createVNode(_component_BaseTextInput, {
          "model-value": _ctx.modelValue.postcode,
          label: _ctx.$t('platform.patient.postcode'),
          placeholder: _ctx.$t('platform.patient.enter-postcode'),
          "is-optional": "",
          error: _ctx.postcodeError,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.validatePostcode($event))),
          onKeyup: _cache[14] || (_cache[14] = _withKeys(($event: any) => (!_ctx.disableSearch && _ctx.search()), ["enter"]))
        }, null, 8, ["model-value", "label", "placeholder", "error"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mpiAndPdsRequirementFields, (requirementSection, sectionIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: sectionIndex,
            class: "col-span-3"
          }, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(requirementSection.label) + ": ", 1),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(requirementSection.requirements, (requirement, requirementIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: requirementIndex,
                  class: _normalizeClass(["flex space-x-2 text-xs items-center", {
                'text-success-500': requirement.isRequirementMet,
                'text-danger-500': requirement.isError,
                'text-gray-500': !requirement.isRequirementMet && !requirement.isError
              }])
                }, [
                  (!requirement.isError)
                    ? (_openBlock(), _createBlock(_component_BaseIcon, {
                        key: 0,
                        name: "tick"
                      }))
                    : (_openBlock(), _createBlock(_component_BaseIcon, {
                        key: 1,
                        name: "close"
                      })),
                  _createElementVNode("div", null, _toDisplayString(requirement.label), 1),
                  (requirementIndex !== requirementSection.requirements.length - 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, " or "))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_BaseButton, {
            color: "ghost",
            onClick: _ctx.clear
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.clear')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_BaseButton, {
            class: "ml-2",
            disabled: !_ctx.areMinimumSearchRequirementsMet || _ctx.disableSearch || _ctx.loading || _ctx.isAnyError,
            onClick: _ctx.search
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.search')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ])
    ])
  ]))
}
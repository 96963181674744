import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  "data-cy": "nav-account-initials",
  class: "flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-gray-900 rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.url)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "flex items-center justify-center w-6 h-6 rounded bg-cover bg-no-repeat bg-center",
          style: _normalizeStyle(`background-image: url(${_ctx.url})`)
        }, null, 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.initials), 1))
  ]))
}
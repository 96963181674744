
import { Vue, Options } from 'vue-class-component';
import { BaseTextInput } from '@/lib/components/Input';
import { BaseButton } from '@/lib/components/Button';
import PatientSupportingDocumentModal from '@/views/patient/PatientSupportingDocumentModal.vue';

@Options({
  components: { BaseButton, BaseTextInput, PatientSupportingDocumentModal },

  props: {
    checkboxClass: {
      type: String,
      default: 'text-primary-500 h-4 w-4'
    },
    actionMessage: {
      type: String,
      default: 'If you are unable to complete this step please include your reason below to continue.'
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Array,
      default: () => [false, '']
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validating: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    media: {
      type: Object,
      default: () => ({})
    }
  }
})
export default class ChecklistItem extends Vue {
  modelValue!: Array<boolean | string>; // [checked, reason] if valid; [checked, reason, invalid] if not
  reason = '';
  error?: string;
  validatedReason = '';
  displayPdfDocument = false;
  media?: { label: string; documentId?: string };

  get computedModel() {
    return this.modelValue?.length ? this.modelValue : [false, ''];
  }

  get checked() {
    return this.computedModel[0];
  }

  mounted() {
    if (this.computedModel && this.computedModel[1] && (this.computedModel[1] as string).length) {
      this.reason = this.computedModel[1] as string;
    }
    this.validatedReason = this.reason;
    this.onChange();
    this.$watch('validating', (current, old) => {
      if (old && !current) {
        this.validatedReason = this.reason;
      }
    });
  }

  onKeyUp(event: KeyboardEvent) {
    if (this.error || !(event.target as HTMLInputElement).value.length) {
      this.onChange();
    }
  }

  onChange() {
    const checked = (this.$refs.checkbox as HTMLInputElement).checked;
    const value = [checked, checked ? '' : this.reason];
    if (checked) {
      this.reason = '';
    } else if (!this.reason.length) {
      value.push(false);
    }
    this.$emit('update:modelValue', value);
    this.$emit('change');
  }

  viewMedia() {
    this.displayPdfDocument = true;
  }

  closeModal() {
    this.displayPdfDocument = false;
  }
}


import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: { BaseIcon },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
})
export default class NavItem extends Vue {}

import { EncounterType } from '@/models/encounter-type.model';

export interface JourneyType {
  type: JourneyTypeName;
  encounter_types: EncounterType[];
}

export enum JourneyTypeName {
  MENICON_BLOOM_NIGHT = 'Menicon Bloom Night',
  MENICON_BLOOM_DAY = 'Menicon Bloom Day',
  ACUVUE_ABILITI = 'ACUVUE Abiliti'
}

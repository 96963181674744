import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "mt-8"
}
const _hoisted_2 = { class: "space-y-16" }
const _hoisted_3 = { class: "p-10 flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmartFormSection = _resolveComponent("SmartFormSection", true)!
  const _component_SmartFormItem = _resolveComponent("SmartFormItem")!
  const _component_PageLoading = _resolveComponent("PageLoading")!

  return (_ctx.conditionsMet)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(["space-y-16", { 'mt-28': _ctx.level > 0 }]),
        "data-dusk": "smart-form-section"
      }, [
        _createElementVNode("div", null, [
          (_ctx.section.heading && _ctx.section.heading.length)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent('h' + (_ctx.level + 1)), {
                key: 0,
                class: _normalizeClass(["font-bold text-gray-900", _ctx.level === 0 ? 'text-2xl' : 'text-xl'])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.section.heading), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.section.instructions && _ctx.section.instructions.length)
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                class: _normalizeClass(["mt-2 text-gray-500", _ctx.level === 0 ? 'text-lg' : 'text-base'])
              }, _toDisplayString(_ctx.section.instructions), 3))
            : _createCommentVNode("", true),
          ((_ctx.section.heading && _ctx.section.heading.length) || (_ctx.section.instructions && _ctx.section.instructions.length))
            ? (_openBlock(), _createElementBlock("hr", _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.section.items, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.id
            }, [
              (item.type === 'section')
                ? (_openBlock(), _createBlock(_component_SmartFormSection, {
                    key: 0,
                    section: item,
                    "patient-id": _ctx.patientId,
                    "participant-id": _ctx.participantId,
                    "form-submission-id": _ctx.formSubmissionId,
                    next: _ctx.next,
                    back: _ctx.back,
                    level: _ctx.level + 1
                  }, null, 8, ["section", "patient-id", "participant-id", "form-submission-id", "next", "back", "level"]))
                : (_openBlock(), _createBlock(_component_SmartFormItem, {
                    key: item.id,
                    item: item,
                    "patient-id": _ctx.patientId,
                    "participant-id": _ctx.participantId,
                    "form-submission-id": _ctx.formSubmissionId,
                    loading: _ctx.loadings.length !== 0,
                    next: _ctx.next,
                    back: _ctx.back,
                    onLoading: _ctx.checkLoading
                  }, null, 8, ["item", "patient-id", "participant-id", "form-submission-id", "loading", "next", "back", "onLoading"]))
            ], 64))
          }), 128))
        ], 512), [
          [_vShow, _ctx.loadings.length === 0]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(_component_PageLoading)
        ], 512), [
          [_vShow, _ctx.loadings.length !== 0]
        ])
      ], 2))
    : _createCommentVNode("", true)
}
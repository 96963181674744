
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';
import { LoadingIcon } from '@/lib/components/Loading';
import { ButtonColors } from '@/lib';

@Options({
  props: {
    rightIcon: {
      type: String,
      default: ''
    },
    leftIcon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary',
      validator: (value: ButtonColors) =>
        [
          'primary',
          'primary-600',
          'secondary',
          'success',
          'alert',
          'danger',
          'ghost',
          'muted',
          'gray',
          'gray-200',
          'gray-900',
          'dark',
          'clear'
        ].includes(value)
    },
    size: {
      type: String,
      default: 'regular',
      validator: (value) => ['sm', 'small', 'regular', 'large', 'xl'].includes(value)
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseIcon,
    LoadingIcon
  }
})
export default class BaseButton extends Vue {}


import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    title: {
      type: String,
      default: null
    }
  }
})
export default class SidebarNavigationGroup extends Vue {}

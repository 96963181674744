import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center xl:space-x-6 xl:text-xl space-x-4 text-base" }
const _hoisted_2 = ["data-dusk", "onClick"]
const _hoisted_3 = { class: "flex items-center space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["select-none", _ctx.optionStyle]),
        "data-dusk": `consult-status-selector-${option.label}`,
        onClick: ($event: any) => (_ctx.select(index))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["font-semibold", _ctx.modelValue === index ? _ctx.selectedStyle.label : _ctx.unselectedStyle.label])
          }, _toDisplayString(_ctx.windowWidth < 1280 && option.shorthand ? option.shorthand : option.label), 3),
          _createElementVNode("div", {
            class: _normalizeClass(["flex items-center justify-center p-1 text-2xs font-semibold", _ctx.modelValue === index ? _ctx.selectedStyle.total : _ctx.unselectedStyle.total])
          }, _toDisplayString(option.total), 3)
        ])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}
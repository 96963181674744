import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "cursor-pointer z-10",
  type: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_BaseIcon, {
      class: _normalizeClass([_ctx.dark ? 'bg-gray-700 hover:bg-gray-500' : 'bg-gray-300 hover:bg-gray-900', "text-white rounded-full p-px stroke-1.5"]),
      name: "close"
    }, null, 8, ["class"])
  ]))
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-1 inline-flex border border-gray-300 rounded bg-white text-base font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["py-3 px-4 focus:outline-none cursor-pointer", _ctx.modelValue ? 'bg-primary-500 text-white rounded' : 'text-gray-900 bg-white']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setValue(true)))
    }, _toDisplayString(_ctx.$t('platform.common.yes')), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["py-3 px-4 focus:outline-none cursor-pointer", [
        _ctx.modelValue === false ? 'bg-primary-500 text-white rounded' : 'text-gray-900 bg-white',
        typeof _ctx.modelValue === 'undefined' && 'border-l border-gray-300'
      ]]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setValue(false)))
    }, _toDisplayString(_ctx.$t('platform.common.no')), 3)
  ]))
}
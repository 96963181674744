import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full max-w-xl p-8 flex flex-col space-y-4" }
const _hoisted_2 = { class: "flex flex-row items-start space-x-2" }
const _hoisted_3 = { class: "flex -ml-3" }
const _hoisted_4 = { class: "text-xl font-semibold text-gray-900 self-center" }
const _hoisted_5 = { class: "text-base font-regular leading-normal text-gray-600 pb-2" }
const _hoisted_6 = { class: "w-full justify-start flex flex-col space-y-4" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-row w-full justify-between space-x-4"
}
const _hoisted_8 = {
  key: 4,
  class: "flex flex-end justify-end space-x-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseDatePicker = _resolveComponent("BaseDatePicker")!
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseIcon, {
          name: "outcome",
          stroke: false,
          height: "2.5rem"
        })
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.copd.complete-review')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('custom.uhb.copd.complete-review-desc')), 1),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_BaseSelect, {
        "model-value": (_ctx.reviewParams && _ctx.reviewParams.outcome) || '',
        error: _ctx.errors && _ctx.errors.errors && _ctx.errors.errors.outcome && _ctx.errors.errors.outcome[0],
        label: _ctx.$t('custom.uhb.copd.outcome'),
        options: _ctx.outcomeList,
        placeholder: _ctx.$t('custom.uhb.copd.select-outcome'),
        "data-dusk": "review-measurements-select-outcome",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateReviewParams({ outcome: $event, reason: null })))
      }, null, 8, ["model-value", "error", "label", "options", "placeholder"]),
      (_ctx.reviewParams && _ctx.reviewParams.outcome && (_ctx.reviewParams.outcome === 'change' || _ctx.isDischargeReview))
        ? (_openBlock(), _createBlock(_component_BaseSelect, {
            key: 0,
            "model-value": _ctx.reviewParams.reason || '',
            error: _ctx.errors && _ctx.errors.errors.reason && _ctx.errors.errors.reason[0],
            label: _ctx.$t('custom.uhb.copd.outcome-reason'),
            options: _ctx.reasonOptions,
            placeholder: _ctx.$t('custom.uhb.copd.select-reason'),
            "data-dusk": "review-measurements-select-outcome-reason",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateReviewParams({ reason: $event })))
          }, null, 8, ["model-value", "error", "label", "options", "placeholder"]))
        : _createCommentVNode("", true),
      (_ctx.reviewParams && _ctx.reviewParams.outcome && !_ctx.isDischargeReview)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_BaseDatePicker, {
              modelValue: _ctx.reviewParams.next_consult_at,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reviewParams.next_consult_at) = $event)),
                _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateReviewParams({ next_consult_at: $event })))
              ],
              class: "flex flex-col w-1/2 items-start",
              error: _ctx.errors && _ctx.errors.errors.next_consult_at && _ctx.errors.errors.next_consult_at[0],
              label: _ctx.$t('custom.uhb.copd.next-consult-at'),
              "min-date": _ctx.today,
              placeholder: _ctx.$t('custom.uhb.copd.select-next-consult-at'),
              "data-dusk": "review-measurements-next-consult-date-picker"
            }, null, 8, ["modelValue", "error", "label", "min-date", "placeholder"]),
            _createVNode(_component_BaseSelect, {
              "model-value": _ctx.reviewParams.next_consult_type,
              class: "flex w-1/2 h-full",
              error: _ctx.errors && _ctx.errors.errors.next_consult_type && _ctx.errors.errors.next_consult_type[0],
              label: _ctx.$t('custom.uhb.copd.next-consult-type'),
              options: _ctx.consultTypeList,
              placeholder: _ctx.$t('custom.uhb.copd.select-next-consult-type'),
              "data-dusk": "review-measurements-select-consult-type",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateReviewParams({ next_consult_type: $event })))
            }, null, 8, ["model-value", "error", "label", "options", "placeholder"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.reviewParams && _ctx.reviewParams.outcome && !_ctx.isDischargeReview)
        ? (_openBlock(), _createBlock(_component_BaseCheckbox, {
            key: 2,
            modelValue: _ctx.patientScheduledExternally,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.patientScheduledExternally) = $event)),
            label: _ctx.$t('custom.uhb.copd.patient-scheduled-externally'),
            shadow: true,
            border: true,
            "data-dusk": "review-measurements-patient-scheduled-externally"
          }, null, 8, ["modelValue", "label"]))
        : _createCommentVNode("", true),
      (_ctx.alreadyReviewed)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 3,
            size: "small",
            color: "ghost",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close', true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.close')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.alreadyReviewed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_BaseButton, {
              class: "mt-8",
              size: "large",
              color: "ghost",
              onClick: _ctx.closeReviewModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_BaseButton, {
              class: "mt-8",
              size: "large",
              color: _ctx.isDischargeReview ? 'alert' : 'primary',
              disabled: _ctx.submitDisabled,
              "data-dusk": "update-review-button",
              onClick: _ctx.reviewMeasurements
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isDischargeReview ? _ctx.$t('custom.uhb.copd.discharge-patient') : _ctx.$t('platform.common.update')), 1)
              ]),
              _: 1
            }, 8, ["color", "disabled", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}

import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  props: {
    showSortButton: {
      type: Boolean,
      default: false
    },

    columnName: {
      type: String,
      default: ''
    },

    isActive: {
      type: Boolean,
      default: false
    },

    currentDirection: {
      type: String,
      default: 'asc'
    },

    customStyle: {
      type: String,
      default: null
    }
  },
  components: {
    BaseIcon
  }
})
export default class DataTableHeading extends Vue {
  isActive!: boolean;
  currentDirection!: string;
  columnName!: string;

  sortClicked() {
    this.$emit('sort', (this.isActive && this.currentDirection === 'asc' ? '-' : '') + this.columnName);
  }
}

import { defineStore } from 'pinia';

export type RootState = {
    from: string;
    to: string;
};

export const useHistoryStore = defineStore('history', {
  state: () => ({
    from: 'patient-list',
    to: 'patient-list'
  } as RootState)
});

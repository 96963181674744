import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "font-bold text-2xl" }
const _hoisted_2 = { class: "mt-6 mb-8 text-lg" }
const _hoisted_3 = { class: "flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.color === 'white' || _ctx.color === 'ghost' ? 'bg-white text-primary-500' : 'text-white bg-' + _ctx.color, "p-14 flex flex-col rounded-lg shadow-lg select-none"])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
        return (_openBlock(), _createBlock(_component_BaseButton, {
          key: action.label,
          class: "mr-4",
          color: action.color,
          size: "large",
          onClick: ($event: any) => (_ctx.onActionClick(action, $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(action.label), 1)
          ]),
          _: 2
        }, 1032, ["color", "onClick"]))
      }), 128))
    ])
  ], 2))
}
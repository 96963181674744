import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-row items-center text-sm font-medium" }
const _hoisted_3 = { class: "text-white mr-2" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-500"
}
const _hoisted_5 = {
  key: 1,
  class: "flex flex-row flex-wrap space-x-2 max-w-full items-center -mb-2 ml-2"
}
const _hoisted_6 = {
  key: 0,
  class: "text-white text-sm font-medium ml-4 mb-2"
}
const _hoisted_7 = { class: "rounded-full flex items-center bg-gray-800 text-white p-2 hover:bg-gray-600 focus:bg-gray-600 focus:outline-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseChip = _resolveComponent("BaseChip")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_SearchSelect = _resolveComponent("SearchSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex flex-row pl-4 pr-2 py-1 items-center justify-between rounded mb-2 cursor-pointer",
      style: {"background":"rgba(28, 28, 28, 0.9)"},
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.add = !_ctx.add), ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.scans.sign.signs')), 1),
        (!_ctx.modelValue.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.scans.sign.no-signs')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (sign, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: sign.indicator.id
                }, [
                  (_ctx.add || index < 2)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "mb-2",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                      }, [
                        _createVNode(_component_BaseChip, {
                          removable: "",
                          dark: "",
                          onRemoved: ($event: any) => (_ctx.removeSign(sign.id))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(sign.indicator.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["onRemoved"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              (!_ctx.add && _ctx.modelValue.length > 2)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('custom.uhb.scans.sign.more', { value: _ctx.modelValue.length - 2 })), 1))
                : _createCommentVNode("", true)
            ]))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_BaseIcon, {
          name: _ctx.add ? 'chevron-up' : 'add'
        }, null, 8, ["name"])
      ])
    ]),
    (_ctx.add)
      ? (_openBlock(), _createBlock(_component_SearchSelect, {
          key: 0,
          "model-value": _ctx.indicators,
          list: _ctx.list,
          placeholder: "Search by name",
          dark: "",
          filter: false,
          onSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('search', $event))),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
          onAdd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('add', $event))),
          onRemove: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeSignFromSearchSelect($event))),
          onScrolled: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('loadNext')))
        }, null, 8, ["model-value", "list"]))
      : _createCommentVNode("", true)
  ]))
}
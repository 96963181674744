import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-4 text-sm font-semibold" }
const _hoisted_2 = {
  key: 0,
  class: "border bg-white rounded mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementPlanStep = _resolveComponent("ManagementPlanStep")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    (_ctx.steps.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, stepIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: stepIndex,
              class: _normalizeClass({
          'border-t': stepIndex
        })
            }, [
              _createVNode(_component_ManagementPlanStep, {
                value: _ctx.steps[stepIndex],
                destinations: _ctx.destinations,
                timeframes: _ctx.timeframes,
                "selected-combinations": _ctx.selectedCombinations,
                "step-index": stepIndex,
                loading: _ctx.loading,
                onAddStep: _ctx.addStep,
                onRemoveStep: _ctx.removeStep,
                onUpdateStep: _ctx.updateStep
              }, null, 8, ["value", "destinations", "timeframes", "selected-combinations", "step-index", "loading", "onAddStep", "onRemoveStep", "onUpdateStep"])
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_BaseButton, {
      class: "text-sm font-medium",
      "left-icon": "add",
      color: "ghost",
      disabled: _ctx.selectedCombinations.length !== _ctx.steps.length,
      onClick: _ctx.appendStep
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.add-', [_ctx.singularItemName])), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}
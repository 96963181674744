
import { Vue, Options } from 'vue-class-component';
import { useSessionStore } from '@/stores/session.store';
import { useUiStore } from '@/stores/ui.store';

@Options({
  props: {
    useDefault: {
      type: Boolean,
      default: false
    },
    useHeaderBranding: {
      type: Boolean,
      default: false
    },
    logoSize: {
      type: String,
      default: 'regular',
      validator: (value: string) => ['regular', 'mark'].includes(value)
    }
  }
})
export default class BrandLogo extends Vue {
  useDefault!: boolean;
  useHeaderBranding!: boolean;
  logoSize!: string;
  sessionStore = useSessionStore();
  uiStore = useUiStore();

  get branding() {
    return this.useHeaderBranding ? this.uiStore.headerBranding :
      this.sessionStore.customerBranding;
  }

  get brandLogo() {
    if (!this.useDefault && this.branding) {
      return require(`@/assets/branding/logos/logo-${this.branding?.toLowerCase()}-${this.logoSize}.svg`);
    }
    return require(`@/assets/branding/logos/logo-big-picture-medical-${this.logoSize}.svg`);
  }
}

import { GenericService } from '../generic.service';
import apiClient from '@/services/api/client/apiClient';
import { AxiosResponse } from 'axios';

export class ConsultRejectService extends GenericService<string> {

  async getConsultRejectOptions(): Promise<AxiosResponse<string[]>> {
    return await apiClient.get('v1/consult-reject-reasons');
  }
}

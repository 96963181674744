
import { Vue, Options } from 'vue-class-component';
import { IGalleryImage } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import { ImageWrapper } from '@/lib/components/Image';
import ImagesGallery from './BaseImagesGallery.vue';

@Options({
  components: {
    ImageWrapper,
    ImagesGallery,
    BaseIcon
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    startIndex: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class Lightbox extends Vue {
  selectedIndex = 0;
  startIndex!: number;
  images!: Array<IGalleryImage>;

  get image(): string {
    const image = this.images[this.selectedIndex];
    if (image && image.imageUri && image.imageUri.length) {
      return image.imageUri;
    }
    if (image && image.image && image.image.length) {
      return image.image;
    }
    return '';
  }

  created() {
    this.selectedIndex = this.startIndex >= 0 && this.startIndex <= this.images.length ? this.startIndex : 0;
    this.$watch(
      'startIndex',
      () => (this.selectedIndex = this.startIndex >= 0 && this.startIndex <= this.images.length ? this.startIndex : 0)
    );
  }

  mounted() {
    if (this.images[this.selectedIndex]) {
      this.$emit('load', this.selectedIndex);
    }
    window.addEventListener('keydown', this.onKeyDown);
    this.$watch('selectedIndex', () => this.$emit('load', this.selectedIndex));
  }

  unmounted() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(ev: KeyboardEvent) {
    if (ev.key === 'ArrowLeft') {
      this.previousImage();
    }
    if (ev.key === 'ArrowRight') {
      this.nextImage();
    }
    if (ev.key === 'Escape') {
      this.close();
    }
  }

  nextImage() {
    this.selectedIndex = this.selectedIndex + 1 < this.images.length ? this.selectedIndex + 1 : 0;
  }

  previousImage() {
    this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.images.length - 1;
  }

  close() {
    this.$emit('close');
  }
}

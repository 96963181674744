
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';

dayjs.extend(relativeTime);

@Options({
  components: { BaseIcon, BaseButton },
  props: {
    id: {
      type: String
    },
    label: {
      type: String
    },
    logoUri: {
      type: String
    },
    lastConnection: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showEditButton: {
      type: Boolean,
      default: true
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    }
  }
})
export default class AccountListItem extends Vue {
  nhsNumberLabel = 'uhb-nhs-number';
  readonly!: boolean;
  showEditButton!: boolean;
  showDeleteButton!: boolean;
  label!: string;
  id!: string;

  get formattedLabel(): string {
    if (this.label === this.nhsNumberLabel) {
      return this.$t('custom.uhb.patient.nhs-number') as string;
    }
    return this.label;
  }

  get formattedId(): string {
    if (this.id && this.label === this.nhsNumberLabel) {
      return formattedNHSNumber(this.id);
    }
    return this.id;
  }

  formatDate(date: string) {
    return dayjs(date).fromNow();
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("button", _mergeProps({
    type: "button",
    class: [[
      _ctx.dark
        ? 'bg-gray-900 hover:bg-gray-500 hover:text-white focus:bg-gray-500 focus:text-white'
        : 'bg-white hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900',
      _ctx.active ? (_ctx.dark ? 'bg-gray-500' : 'bg-gray-100') : '',
      _ctx.disabled && 'btn-disabled'
    ], "flex items-center w-full px-4 py-3 font-medium text-left rounded outline-none text-gray-500"],
    disabled: _ctx.disabled
  }, _ctx.$attrs), [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 0,
          name: _ctx.icon,
          position: "left"
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}
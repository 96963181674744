import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImagePreview = _resolveComponent("ImagePreview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
      return (_openBlock(), _createBlock(_component_ImagePreview, {
        key: index,
        class: "mr-4",
        "image-url": image.thumbnailUri ? image.thumbnailUri : image.image,
        text: _ctx.modelValue === index ? null : image.placeholder,
        onClick: ($event: any) => (_ctx.$emit('update:modelValue', index))
      }, null, 8, ["image-url", "text", "onClick"]))
    }), 128))
  ]))
}
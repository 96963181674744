
import { Vue, Options } from 'vue-class-component';
import { ColorName, IModalAction } from '@/lib';
import { BaseButton } from '@/lib/components/Button';

@Options({
  components: {
    BaseButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: 'primary-500'
    }
  }
})
export default class BaseJammer extends Vue {
  actions!: Array<IModalAction>;
  color!: ColorName;

  onActionClick(action: IModalAction, event: MouseEvent) {
    action.onClick(event);
    event.stopPropagation();
  }
}

import { ExternalPatientReferenceType } from '@/models';
import { defineStore } from 'pinia';
import { ExternalPatientReferenceTypeService } from '@/services/api';
import { UHB_NHS_NUMBER_KEY } from '@/constants';

const externalPatientReferenceTypeService = new ExternalPatientReferenceTypeService();

interface State {
  showNhsNumber: boolean;
  externalPatientReferenceTypes: ExternalPatientReferenceType[];
}

export const usePatientStore = defineStore('patient', {
  state: (): State => ({
    showNhsNumber: false,
    externalPatientReferenceTypes: []
  }),
  actions: {
    async fetchExternalPatientReferenceTypes() {
      const response = (await externalPatientReferenceTypeService.index()).data;
      this.externalPatientReferenceTypes = response;
      this.showNhsNumber = !!response.find((type) => type.key === UHB_NHS_NUMBER_KEY);
    }
  }
});


import { Vue, Options } from 'vue-class-component';
import { BaseCard } from '@/lib/components/Card';
import { BaseSelect } from '@/lib/components/Select';
import { BaseNumberInput } from '@/lib/components/Input';
import { IErrors, IOption } from '@/lib';
import { FilterTag, Organisation, TagGroupName } from '@/models';
import { FilterTagService } from '@/services/api/filter-tag.service';
import { AxiosError } from 'axios';
import capitalize from 'lodash-es/capitalize';

@Options({
  components: { BaseCard, BaseSelect, BaseNumberInput },
  props: {
    organisationalUnit: {
      type: String,
      default: ''
    },
    communityTeam: {
      type: String,
      default: ''
    },
    errors: {
      type: String,
      default: ''
    }
  }
})
export default class CommunityTeamSelect extends Vue {
  organisationalUnit!: Organisation['id'];
  communityTeam!: FilterTag['id'];
  communityTeams: Array<FilterTag> = [];

  errors!: string;
  fetchErrors: IErrors | null = null;

  tagService = new FilterTagService();

  get validationErrors(): string | null {
    if (this.errors) {
      return this.errors;
    }
    if (this.fetchErrors && this.fetchErrors.message) {
      return this.fetchErrors.message[0];
    }
    return null;
  }

  get communityTeamLabel(): string {
    return capitalize(this.$t('custom.uhb.copd.community-team'));
  }

  get communityTeamPlaceholder(): string {
    return capitalize(
      this.$t(
        'platform.common.select-entity',
        [this.$t('custom.uhb.copd.community-team')]
      )
    );
  }

  get communityTeamOptions(): Array<IOption> {
    return this.communityTeams.map((team: FilterTag) => ({
      value: team.id,
      label: team.name
    }));
  }

  mounted() {
    if (this.organisationalUnit) {
      this.fetchCommunityTeams();
    }
    this.$watch('organisationalUnit', this.fetchCommunityTeams);
  }

  updateCommunityTeam(tagId: string) {
    const tag = this.communityTeams.find((tag) => tag.id === tagId);
    if (tag) {
      this.$emit('update:community-team', {
        id: tag.id,
        title: tag.name,
        attributes: {
          name: tag.name
        }
      });
    }
  }

  async fetchCommunityTeams() {
    try {
      this.communityTeams = await this.tagService.getTagsForGroupName(TagGroupName.CommunityTeam, {
        params: {
          organisational_unit: this.organisationalUnit
        }
      });
    } catch (e: unknown) {
      if ((e as AxiosError).response?.status === 422) {
        this.fetchErrors = (e as AxiosError).response?.data?.errors;
      } else {
        this.fetchErrors = {};
      }
    }
  }
}

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withKeys as _withKeys, mergeProps as _mergeProps, withDirectives as _withDirectives, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center justify-between mb-3"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_4 = ["id", "disabled", "readonly", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ClearButton = _resolveComponent("ClearButton")!
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!

  return (_openBlock(), _createElementBlock("div", null, [
    ((_ctx.label && _ctx.label.length) || _ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: _normalizeClass(["inline-block text-base font-medium leading-tight", { 'text-transparent bg-gray-200 rounded-lg': _ctx.loading }])
          }, _toDisplayString(_ctx.label), 11, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.$slots.action)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([_ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-primary-500 cursor-pointer', "flex items-center text-sm font-medium text-right"])
                }, [
                  _renderSlot(_ctx.$slots, "action")
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.isOptional)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass([_ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-xs font-medium cursor-default capitalize"])
                }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([{
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          _ctx.error && _ctx.error.length,
        'h-full': _ctx.fullHeight,
        'bg-gray-50 border-transparent': _ctx.disabled || _ctx.readonly,
        'text-gray-600': _ctx.disabled,
        'border-primary-500': _ctx.focused && !_ctx.disabled,
        'h-10': _ctx.size === 'small',
        inputClass: _ctx.inputClass
      }, "flex flex-row bg-white text-gray-900 h-12 shadow hover:border-gray-500 items-center w-full overflow-hidden transition-all duration-100 ease-in-out border rounded focus:shadow-md"])
    }, [
      _renderSlot(_ctx.$slots, "left"),
      (_ctx.icon && _ctx.icon.length && !_ctx.loading)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            class: _normalizeClass(['text-' + _ctx.iconColor, _ctx.iconPosition === 'right' ? 'order-1' : '']),
            position: _ctx.iconPosition === 'left' ? 'right' : 'left',
            name: _ctx.icon,
            height: _ctx.iconHeight,
            stroke: _ctx.iconStroke,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('icon-click')))
          }, null, 8, ["class", "position", "name", "height", "stroke"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", _mergeProps({
        id: _ctx.id,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        autocomplete: "chrome-off",
        class: [[
          _ctx.disabled || _ctx.readonly ? 'bg-gray-50' : 'bg-white',
          _ctx.disabled ? 'text-gray-600' : 'text-gray-900',
          _ctx.size === 'small' ? 'text-sm h-10' : 'h-12',
          _ctx.icon ? 'px-3' : 'px-4'
        ], "w-full border-none focus:outline-none"]
      }, _ctx.$attrs, {
        value: _ctx.modelValue,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('keydownEvent')), ["enter"])),
        onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
        onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.blur($event)))
      }), null, 16, _hoisted_4), [
        [_vShow, !_ctx.loading]
      ]),
      (_ctx.count && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["flex mr-2 h-full items-center text-sm font-medium", _ctx.modelValue.length > _ctx.count ? 'text-danger-600' : 'text-gray-500'])
          }, _toDisplayString(_ctx.modelValue.length) + "/" + _toDisplayString(_ctx.count), 3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "use-default"),
      (_ctx.clear && !_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ClearButton, {
            key: 2,
            class: "mr-4",
            onClick: _withModifiers(_ctx.onClear, ["prevent"])
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.symbol && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["inline-flex h-full justify-center items-center bg-white text-base font-small text-center border-gray-200 border-l rounded rounded-l-none px-4", { 'bg-gray-50': _ctx.disabled || _ctx.readonly, 'text-gray-600': _ctx.disabled }])
          }, _toDisplayString(_ctx.symbol), 3))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_InputTextHelper, {
      error: _ctx.error,
      loading: _ctx.loading,
      description: _ctx.successMessage
    }, null, 8, ["error", "loading", "description"])
  ]))
}
import { AsYouType, CountryCode, getExampleNumber, PhoneNumber } from 'libphonenumber-js';
import { countries, ICountry, phoneNumberExamples } from '@/assets/data/country_dial_info';
import { NationalNumber } from 'libphonenumber-js/types';

export const getCountryCodeFromInternationalPhoneNumber = (value: string): CountryCode | null => {
  const formatter = new AsYouType();
  formatter.input(value);
  const number = formatter.getNumber();
  return number ? ((number as PhoneNumber).country as CountryCode) : null;
};

export const getCountry = (countryCode: CountryCode) => countries.find((c: ICountry) => c.code === countryCode);

export const getNumber = (value: string, countryCode: CountryCode): PhoneNumber | undefined => {
  const formatter = new AsYouType(countryCode);
  formatter.input(value);
  return formatter.getNumber();
};

export const getPlaceholder = (countryCode: CountryCode): string =>
  (
    getExampleNumber(
      countryCode,
      phoneNumberExamples as unknown as {
        [country in CountryCode]: NationalNumber;
      }
    ) as PhoneNumber
  ).formatNational();

import 'reflect-metadata';
import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { createPinia } from 'pinia';
import { i18n } from '@/i18n/i18n';
import VueClipboard from 'vue-clipboard3';
import scrollLock from '@/directives/scroll-lock.directive';
import VueClickAway from 'vue3-click-away';
import InlineSvg from 'vue-inline-svg';
import VuePortal from 'portal-vue';
// Assets
import './assets/tailwind.css';
import './assets/flags.css';
// Sentry error tracking
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { beforeEach } from '@/router/middleware';
import interceptors from '@/services/api/client/interceptors';
import allow from '@/directives/allow.directive';
import feature from '@/directives/feature.directive';
import AWS from 'aws-sdk/global';

// Medkit components
import { MkBadge } from '@bigpicturemedical/medkit-vue3';
import medKit from '@bigpicturemedical/medkit';

const app = createApp(App);
const pinia = createPinia();

// Add middleware
router.beforeResolve(
  (routeTo: RouteLocationNormalized, routeFrom: RouteLocationNormalized, next: NavigationGuardNext) =>
    beforeEach(routeTo, routeFrom, next)
);
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(VueClipboard);
app.use(VueClickAway);
app.use(VuePortal);
app.use(medKit, { dateFormat: 'dd/mm/yyyy', timeFormat: 'HH:mm' });
app.component('InlineSvg', InlineSvg);
app.component('MkBadge', MkBadge);
interceptors;

app.directive('allow', allow);
app.directive('feature-flagged', feature);
app.directive('scroll-lock', scrollLock);

// Temporary solution to tweak behaviour per-customer
// app.config.globalProperties.$t = i18n.global.t;
// app.config.globalProperties.$d = i18n.global.d;
app.config.globalProperties.$te = i18n.global.te;
// app.config.globalProperties.$router = router;
app.config.globalProperties.$customer = process.env.VUE_APP_CUSTOMER;
app.config.globalProperties.$meniconEducationalPortalUrl = process.env.VUE_APP_MENICON_EDUCATION_PORTAL_URL;

router.isReady().then(() => app.mount('#app'));

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new VueIntegration({ attachProps: true, logErrors: true })],
  environment: process.env.VUE_APP_LOG_ENV ? process.env.VUE_APP_LOG_ENV : 'unspecified'
});

AWS.config = new AWS.Config({
  region: process.env.VUE_APP_AWS_KINESIS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_ID ?? ''
  })
});


import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    modelValue: {
      type: [Boolean, Array],
      required: true
    },

    checkboxClass: {
      type: String,
      default: 'ml-4'
    },

    // eslint-disable-next-line
    value: {
      required: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    shadow: {
      type: Boolean,
      default: true
    },

    border: {
      type: Boolean,
      default: true
    },

    dark: {
      type: Boolean,
      default: false
    },

    error: {
      type: String,
      default: null
    },

    hoverBgClass: {
      type: String,
      default: ''
    },

    textClass: {
      type: String,
      default: 'text-red'
    }
  }
})
export default class BaseCheckbox extends Vue {
  modelValue!: boolean | Array<string> | null;
  value!: string;
  isHovering = false;

  get computedModel() {
    if (this.modelValue === null) {
      return [];
    }
    return this.modelValue;
  }

  get checked(): boolean {
    if (Array.isArray(this.computedModel)) {
      return this.computedModel.includes(this.value);
    }
    return Boolean(this.computedModel);
  }

  update() {
    const checked = (this.$refs.checkbox as HTMLInputElement).checked;

    if (!Array.isArray(this.computedModel)) {
      this.$emit('update', checked);
      this.$emit('update:modelValue', checked);
      return;
    }

    if (checked) {
      this.$emit('update', [...this.computedModel, this.value]);
      this.$emit('update:modelValue', [...this.computedModel, this.value]);
    } else {
      this.$emit(
        'update',
        this.computedModel.filter((value) => value !== this.value)
      );
      this.$emit(
        'update:modelValue',
        this.computedModel.filter((value) => value !== this.value)
      );
    }

    this.$emit('update:listObject', {
      id: this.value,
      checked,
      label: this.label
    });
  }

  uncheck() {
    (this.$refs.checkbox as HTMLInputElement).checked = false;
    this.$emit('update:listObject', {
      id: this.value,
      checked: false,
      label: this.label
    });
  }
}

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["text-base font-medium mb-3 inline-block", { 'text-white': _ctx.dark }])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex gap-4", [_ctx.fullWidth ? 'flex-col w-full' : 'flex-wrap']])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_component_BaseCheckbox, {
          key: option.value ? option.value : option,
          "model-value": _ctx.modelValue,
          label: option.label ? option.label : option,
          value: option.label ? option.value : option,
          disabled: _ctx.disabled,
          error: _ctx.error,
          loading: _ctx.loading,
          dark: _ctx.dark,
          "checkbox-class": _ctx.checkboxClass,
          class: "pl-4",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUpdate($event)))
        }, null, 8, ["model-value", "label", "value", "disabled", "error", "loading", "dark", "checkbox-class"]))
      }), 128))
    ], 2),
    _createVNode(_component_InputTextHelper, {
      error: _ctx.displayErrorMessage ? _ctx.error : '',
      loading: _ctx.loading,
      description: _ctx.description
    }, null, 8, ["error", "loading", "description"])
  ]))
}

import { Vue, Options } from 'vue-class-component';
import draggable from 'vuedraggable';
import { Diagnosis } from '@/models';
import Condition from './Condition.vue';

@Options({
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  components: { draggable, Condition }
})
export default class ConditionList extends Vue {
  modelValue!: Array<Diagnosis>;
}

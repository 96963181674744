import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = {
  key: 0,
  "data-dusk": "patient-details-initials",
  class: "flex items-center justify-center w-10 h-10 mr-4 text-base font-semibold rounded-full bg-primary-200 text-primary-500"
}
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "mt-5 -mb-4 text-sm font-medium"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["data-dusk"]
const _hoisted_7 = ["data-dusk"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-5 rounded-lg", _ctx.dark ? 'bg-gray-900' : 'bg-white border'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showInitials)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.initials), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.name.length)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              "data-dusk": "patient-details-name",
              class: _normalizeClass(["text-base font-bold", _ctx.dark ? 'text-white' : 'text-gray-900'])
            }, _toDisplayString(_ctx.name), 3))
          : _createCommentVNode("", true),
        (_ctx.id && _ctx.id.length)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              "data-dusk": "patient-details-mrn",
              class: _normalizeClass(["text-sm uppercase font-medium", _ctx.dark ? 'text-gray-500' : 'text-gray-400'])
            }, "MRN: " + _toDisplayString(_ctx.id), 3))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.infos && Object.keys(_ctx.infos).length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infos, (info, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: _normalizeClass([_ctx.dark ? 'border-gray-800' : 'border-gray-100', "grid grid-cols-2 py-3 border-t"])
            }, [
              (key === 'nhs')
                ? (_openBlock(), _createBlock(_component_BaseIcon, {
                    key: 0,
                    name: "nhs-logo"
                  }))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(["break-words", _ctx.dark ? 'text-gray-500' : 'text-gray-400'])
                  }, _toDisplayString(_ctx.formatInfoKey(key)), 3)),
              (Array.isArray(info))
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(info, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        "data-dusk": `patient-details-${key}-${index}`,
                        class: _normalizeClass(["break-words w-full", _ctx.dark ? 'text-white' : 'text-gray-900'])
                      }, _toDisplayString(item), 11, _hoisted_6))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("span", {
                    key: 3,
                    "data-dusk": `patient-details-${key}`,
                    class: _normalizeClass(["break-words w-full", _ctx.dark ? 'text-white' : 'text-gray-900'])
                  }, _toDisplayString(info), 11, _hoisted_7))
            ], 2))
          }), 128)),
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}

import { Vue, Options } from 'vue-class-component';
// File pond
import vueFilePond from 'vue-filepond';
import { FilePondErrorDescription, FilePondFile, FilePondInitialFile, FileStatus } from 'filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import { getPercent } from '@/lib/helpers/slider.helpers';
import LoadingBar from '@/lib/components/Loading/LoadingBar.vue';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export interface FileAnswer {
  error?: string;
  isNew?: boolean;
  created_at?: string;
  progress?: number;
  status?: FileStatus;
  extension?: string;
  type: string;
  name: string;
  size: number;
  originalId: string;
  id: string;
}

export interface FileUploaded {
  source: string;
  options: {
    type: string;
    file: {
      name: string;
      size: number;
      type: string;
    };
  };
}

@Options({
  props: {
    fileSizeLimit: {
      type: String,
      default: '400MB'
    },
    extension: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    error: {
      type: String,
      default: null
    }
  },
  components: {
    LoadingBar,
    BaseButton,
    BaseIcon,
    FilePond
  }
})
export default class FileUploader extends Vue {
  fileList: Array<FileAnswer | FilePondFile> = [];
  fileSizeLimit?: string;
  files: Array<FilePondFile | FilePondInitialFile | FileUploaded> = [];
  modelValue!: Array<FileAnswer>;
  extension!: string;
  uploadingFiles: Array<FileAnswer> = [];

  mounted() {
    if (this.modelValue) {
      this.fileList = [...this.modelValue];
      this.uploadingFiles = [...this.modelValue];
      this.updateFiles();
    }
  }

  get sizeLimitLabel(): string {
    return this.$t('platform.common.file-upload.max-size-note', [this.fileSizeLimit]);
  }

  get labelIdle(): string {
    return `
<div class="flex w-full justify-between items-center">
  <div class="text-gray-900 text-sm font-medium">
    ${this.$t('platform.common.file-upload.drag-drop')}
    <span class="btn btn-small btn-ghost px-3 py-2 rounded shadow cursor-pointer text-gray-800">
      ${this.$t('platform.common.file-upload.browse')}
    </span>
  </div>
  <div>${this.sizeLimitLabel}</div>
</div>`;
  }

  get allowFileTypeValidation(): boolean {
    return this.extension !== '';
  }

  get answer(): Array<FileAnswer> {
    return this.fileList.map((file: FileAnswer | FilePondFile) => {
      if ('serverId' in file) {
        const fileFromServer = JSON.parse(file.serverId).data;
        return {
          id: fileFromServer.id,
          originalId: file.id,
          name: fileFromServer.filename,
          size: file.fileSize,
          type: fileFromServer.mime_type,
          extension: file.fileExtension,
          status: file.status,
          created_at: fileFromServer.created_at
        };
      }
      return file;
    });
  }

  get server() {
    return {
      url: `${process.env.VUE_APP_LARAVEL_API_URI}/v1/files`,
      process: {
        url: '/',
        ondata: (formData: FormData) => {
          formData.append('options[allow_all_types]', '1');
          return formData;
        }
      },
      revert: {
        url: '/',
        headers: {
          'Content-type': 'application/json'
        }
      }
    };
  }

  handleAddFile(error: { main: string; sub: string } | null, file: FilePondFile) {
    if (error) {
      this.uploadingFiles.push({
        id: file.id,
        originalId: file.id,
        name: file.filename,
        size: file.fileSize,
        type: file.fileType,
        extension: file.fileExtension,
        status: file.status,
        progress: 0,
        error: error.main
      });
    }
  }

  handleProcessFileStart(file: FilePondFile) {
    this.uploadingFiles.push({
      id: file.id,
      originalId: file.id,
      name: file.filename,
      size: file.fileSize,
      type: file.fileType,
      extension: file.fileExtension,
      status: file.status,
      progress: 0,
      isNew: true
    });
  }

  handleProcessFileProgress(processingFile: FilePondFile, progress: number) {
    this.uploadingFiles.find((file) => file.originalId === processingFile.id && ((file.progress = progress), true));
  }

  handleProcessFile(error: FilePondErrorDescription | null, processFile: FilePondFile) {
    if (error) {
      this.uploadingFiles = this.uploadingFiles.map((file) =>
        file.originalId === processFile.id
          ? {
            ...file,
            status: processFile.status,
            error: this.$t('platform.common.file-upload.general-error-message') as string
          }
          : file
      );
    } else {
      this.fileList.push(processFile);

      this.uploadingFiles = this.uploadingFiles.map((file) =>
        file.originalId === processFile.id
          ? {
            ...file,
            status: processFile.status,
            id: JSON.parse(processFile.serverId).data.id,
            name: JSON.parse(processFile.serverId).data.filename
          }
          : file
      );
    }
  }

  handleRemoveFile(error: FilePondErrorDescription | null, file: FilePondFile) {
    if (!error) {
      this.fileList = this.fileList.filter((data: FileAnswer | FilePondFile) => {
        const fileName = 'serverId' in data ? data.filename : data.name;
        return fileName !== file.filename;
      });

      this.save();
    }
  }

  handleAbort(targetFile: FilePondFile) {
    this.uploadingFiles = this.uploadingFiles.filter((file: FileAnswer) => targetFile.id !== file.id);
  }

  updateFiles() {
    this.files = this.fileList.map((file: FileAnswer | FilePondFile) => {
      if ('serverId' in file) {
        return file; // when file is uploaded, display the default layout
      }
      // when file has uploaded, display with idle layout(mock file)
      return {
        source: file.id,
        options: {
          type: 'local',
          file: {
            name: file.name,
            size: file.size,
            type: file.type
          }
        }
      };
    });
  }

  save() {
    this.updateFiles();
    this.$emit('update:modelValue', this.answer);
    this.$emit('change', this.answer);
  }

  getPercent(progress: string) {
    return getPercent(parseFloat(progress), 0, 1).toFixed(2);
  }

  findFileIndexFromInstance(targetFile: FileAnswer) {
    /*
     * This plugin is really tricky:
     * 1. The serverId of previous uploaded file will be the id in our DB, we can use serverId to remove the file.
     * 2. For new uploaded file, the serverId will be null and we need the originalId to find and remove the file.
     */
    if (this.$refs.filePond) {
      const allFiles = this.$refs.filePond.getFiles();
      if ('isNew' in targetFile) {
        return allFiles.findIndex(
          (file: FilePondFile) => targetFile.isNew === true && targetFile.originalId === file.id
        );
      }
      return allFiles.findIndex((file: FilePondFile) => targetFile.id === file.serverId);
    }
    return;
  }

  // Remove file after file is uploaded.
  removeFile(targetFile: FileAnswer) {
    // Tricky plugin: the order is different everytime in getFiles(), need to ensure we remove the correct one.
    if (this.$refs.filePond) {
      const allFiles = this.$refs.filePond.getFiles();
      const fileIndex = this.findFileIndexFromInstance(targetFile);
      if (typeof fileIndex === 'number') {
        const uploadingFileIndex = this.uploadingFiles.findIndex((file: FileAnswer) => targetFile.id === file.id);
        this.$refs.filePond.removeFile(allFiles[fileIndex], { revert: true });
        this.uploadingFiles.splice(uploadingFileIndex, 1);
      }
    }
  }

  // Dismiss file when adding file got error.
  dismissFile(targetFile: FileAnswer) {
    this.uploadingFiles = this.uploadingFiles.filter((file: FileAnswer) => file.id !== targetFile.id);
  }

  // Cancel/abort processing uploading file.
  cancelFile(targetFile: FileAnswer) {
    if (this.$refs.filePond) {
      const allFiles = this.$refs.filePond.getFiles();
      const fileIndex = this.findFileIndexFromInstance(targetFile);
      if (allFiles && typeof fileIndex === 'number') {
        allFiles[fileIndex].abortProcessing();
      }
    }
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}

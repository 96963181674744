import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "relative",
    onKeyup: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"]))
  }, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["h-full focus:outline-none group", [_ctx.highlight ? 'bg-primary-500' : (_ctx.dark ? 'bg-gray-900' : ''), _ctx.buttonClass]]),
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center h-full", {
          'border-b-2 border-transparent': !_ctx.dark,
          'group-hover:border-gray-400 group-focus:border-gray-400': !_ctx.disabled
        }])
      }, [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "button")
        ]),
        _createVNode(_component_BaseIcon, {
          name: _ctx.isOpen ? 'chevron-up' : 'chevron-down',
          class: _normalizeClass(["stroke-1.5", _ctx.dark ? 'text-white' : 'text-gray-900']),
          position: "right"
        }, null, 8, ["name", "class"])
      ], 2)
    ], 10, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.isOpen ? 'block' : 'hidden', _ctx.dark ? 'scrollbar-dark' : '']),
      onWheel: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([[
          _ctx.dark ? '' : 'bg-white border border-gray-300',
          _ctx.menuClass,
          _ctx.scrollable ? 'overflow-hidden overflow-y-auto max-h-125' : ''
        ], "absolute right-0 z-40 mt-4 rounded-lg shadow-lg"]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _renderSlot(_ctx.$slots, "menu")
      ], 2),
      _createElementVNode("button", {
        type: "button",
        tabindex: "-1",
        class: "fixed inset-0 z-30 block w-full h-full cursor-default",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      })
    ], 34)
  ], 32))
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["space-y-6", { 'bg-white border border-gray-200 rounded-lg p-6 shadow': _ctx.card }])
  }, [
    (_ctx.label && _ctx.label.length)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([
        _ctx.level === 1 ? 'text-lg font-semibold' : 'font-semibold',
        _ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-900'
      ])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvitePatientModal = _resolveComponent("InvitePatientModal")!
  const _component_AssignToOrgModal = _resolveComponent("AssignToOrgModal")!
  const _component_AssignToTeamModal = _resolveComponent("AssignToTeamModal")!
  const _component_ReviewMeasurementsModal = _resolveComponent("ReviewMeasurementsModal")!
  const _component_PatientSettingsModal = _resolveComponent("PatientSettingsModal")!
  const _component_DischargeProgramModal = _resolveComponent("DischargeProgramModal")!
  const _component_SetNextConsultModal = _resolveComponent("SetNextConsultModal")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      (_ctx.status === 'invite')
        ? (_openBlock(), _createBlock(_component_InvitePatientModal, _mergeProps({
            key: 0,
            patient: _ctx.patient
          }, _ctx.$attrs), null, 16, ["patient"]))
        : (_ctx.status === 'assign' && !_ctx.tagsFeatureFlag)
          ? (_openBlock(), _createBlock(_component_AssignToOrgModal, _mergeProps({
              key: 1,
              "program-id": _ctx.programId,
              "assigned-org": _ctx.assignedOrg
            }, _ctx.$attrs), null, 16, ["program-id", "assigned-org"]))
          : (_ctx.status === 'assign' && _ctx.tagsFeatureFlag)
            ? (_openBlock(), _createBlock(_component_AssignToTeamModal, _mergeProps({
                key: 2,
                "program-id": _ctx.programId,
                "assigned-org": _ctx.assignedOrg,
                "assigned-community-team": _ctx.assignedCommunityTeam,
                "assigned-hospital-team": _ctx.assignedHospitalTeam
              }, _ctx.$attrs), null, 16, ["program-id", "assigned-org", "assigned-community-team", "assigned-hospital-team"]))
            : (_ctx.status === 'review')
              ? (_openBlock(), _createBlock(_component_ReviewMeasurementsModal, _mergeProps({
                  key: 3,
                  status: _ctx.status,
                  "to-be-reviewed-list": _ctx.toBeReviewedList,
                  "program-id": _ctx.programId,
                  "next-consult": _ctx.nextConsult
                }, _ctx.$attrs), null, 16, ["status", "to-be-reviewed-list", "program-id", "next-consult"]))
              : (_ctx.status === 'settings')
                ? (_openBlock(), _createBlock(_component_PatientSettingsModal, _mergeProps({
                    key: 4,
                    "current-settings": _ctx.settings,
                    "program-id": _ctx.programId,
                    "on-home-monitoring": _ctx.onHomeMonitoring
                  }, _ctx.$attrs), null, 16, ["current-settings", "program-id", "on-home-monitoring"]))
                : (_ctx.status === 'discharge')
                  ? (_openBlock(), _createBlock(_component_DischargeProgramModal, _mergeProps({
                      key: 5,
                      status: _ctx.status,
                      "program-id": _ctx.programId
                    }, _ctx.$attrs), null, 16, ["status", "program-id"]))
                  : (_ctx.status === 'consult')
                    ? (_openBlock(), _createBlock(_component_SetNextConsultModal, _mergeProps({
                        key: 6,
                        "program-id": _ctx.programId,
                        "next-consult": _ctx.nextConsult
                      }, _ctx.$attrs), null, 16, ["program-id", "next-consult"]))
                    : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}

import { Vue, Options } from 'vue-class-component';
import { BaseCard } from '@/lib/components/Card';
import { BaseSelect } from '@/lib/components/Select';
import { BaseNumberInput } from '@/lib/components/Input';
import { IErrors, IOption } from '@/lib';
import { FilterTag, TagGroupName } from '@/models';
import { FilterTagService } from '@/services/api/filter-tag.service';
import { AxiosError } from 'axios';
import capitalize from 'lodash-es/capitalize';

@Options({
  components: { BaseCard, BaseSelect, BaseNumberInput },
  props: {
    hospitalTeam: {
      type: String,
      default: ''
    },
    errors: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class HospitalTeamSelect extends Vue {
  hospitalTeam!: FilterTag['id'];
  hospitalTeams: Array<FilterTag> = [];

  errors!: string;
  fetchErrors: IErrors | null = null;

  tagService = new FilterTagService();

  get validationErrors(): string | null {
    if (this.errors) {
      return this.errors;
    }
    if (this.fetchErrors && this.fetchErrors.message) {
      return this.fetchErrors.message[0];
    }
    return null;
  }

  get hospitalTeamLabel(): string {
    return capitalize(this.$t('custom.uhb.copd.hospital-team'));
  }

  get hospitalTeamPlaceholder(): string {
    return capitalize(
      this.$t(
        'platform.common.select-entity',
        [this.$t('custom.uhb.copd.hospital-team')]
      )
    );
  }

  get hospitalTeamOptions(): Array<IOption> {
    return this.hospitalTeams.map((team: FilterTag) => ({
      value: team.id,
      label: team.name
    }));
  }

  mounted() {
    this.fetchHospitalTeams();
  }

  updateHospitalTeam(tagId: string) {
    const tag = this.hospitalTeams.find((tag) => tag.id === tagId);
    if (tag) {
      this.$emit('update:hospital-team', {
        id: tag.id,
        title: tag.name,
        attributes: {
          name: tag.name
        }
      });
    }
  }

  async fetchHospitalTeams() {
    try {
      this.hospitalTeams = await this.tagService.getTagsForGroupName(TagGroupName.HospitalTeam);
    } catch (e: unknown) {
      if ((e as AxiosError).response?.status === 422) {
        this.fetchErrors = (e as AxiosError).response?.data?.errors;
      } else {
        this.fetchErrors = {};
      }
    }
  }
}

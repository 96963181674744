
import { Vue, Options } from 'vue-class-component';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import InputTextHelper from '@/lib/components/Helper/InputTextHelper.vue';
import { getClosestStepMultiple } from '@/lib/helpers/number.helper';

@Options({
  components: { BaseIcon, InputTextHelper },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    units: {
      type: String,
      default: null
    },
    min: {
      type: Number,
      default: 0.0
    },
    max: {
      type: Number,
      default: 0.0
    },
    step: {
      type: Number,
      default: 1
    },
    precision: {
      type: Number,
      default: null
    },
    modelValue: {
      // I made this a string because:
      // we want intermediate states where a user is typing '-' followed by a number, without showing NaN
      type: String,
      default: '0'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String,
      default: 'h-12'
    },
    error: {
      type: String,
      default: null
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    autoCorrect: {
      type: Boolean,
      default: false
    }
  }
})
export default class UnitsNumberInput extends Vue {
  modelValue!: string;
  min!: number;
  max!: number;
  step!: number;
  autoCorrect!: boolean;
  precision!: number | null;
  focused = false;

  get formattedValue(): string {
    return this.precision && this.modelValue?.length ? (+this.modelValue).toFixed(this.precision) : this.modelValue;
  }

  validateInput(ev: KeyboardEvent): boolean {
    const target = ev.target as HTMLInputElement;

    const targetValue = target.value;

    if (ev.ctrlKey || ev.key === 'Delete' || ev.key === 'Backspace') {
      return true;
    }

    const isArrow = ['ArrowLeft', 'ArrowRight'].includes(ev.key);
    const isNumber = /[0-9]|\.|\b/.test(ev.key);

    const caretPosition = target.selectionStart;

    // check for valid negative number input
    const isHyphen = ev.key === '-';
    const isHyphenAlreadyPresent = targetValue.includes('-');
    const isValidHyphen = !isHyphen || (!isHyphenAlreadyPresent && caretPosition === 0);

    // check for valid . input
    const isFullStop = ev.key === '.';
    const isFullStopAlreadyPresent = targetValue.includes('.');
    const isValidFullStop = !isFullStop || !isFullStopAlreadyPresent;

    // check for alphabetical characters
    const isAlphabeticalChar = /[a-zA-Z]/.test(ev.key) && ev.key.length === 1;

    // general check
    const isValid = isNumber || isArrow || isHyphen;

    if (isAlphabeticalChar || !isValidHyphen || !isValidFullStop || !isValid) {
      ev.preventDefault();
    }

    return isValid;
  }

  validateChange(value: string) {
    const numberValue = Number(value);
    if (!numberValue && numberValue !== 0) {
      // maybe set an error for this input
    } else if (this.autoCorrect && this.step) {
      let validatedInput = '';
      if (value.length) {
        validatedInput = getClosestStepMultiple(numberValue, {
          step: this.step,
          min: this.min,
          max: this.max
        }).toString();
      }
      this.$emit('format', validatedInput);
    } else {
      this.$emit('format', numberValue.toString());
    }
  }
}


import { Vue, Options } from 'vue-class-component';
import { ConsultStatusSelectorOption } from '@/lib';

@Options({
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class ConsultStatusSelector extends Vue {
  options!: Array<ConsultStatusSelectorOption>;
  disabled?: boolean;

  windowWidth = 0;

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.windowWidth = window.innerWidth;
  }

  get optionStyle() {
    return this.disabled ? 'cursor-not-allowed' : 'cursor-pointer';
  }

  get selectedStyle() {
    return {
      label: 'text-gray-900',
      total: 'text-white bg-primary-500 rounded'
    };
  }

  get unselectedStyle() {
    return {
      label: 'text-gray-500',
      total: 'text-gray-500 bg-gray-200 rounded-md'
    };
  }

  select(index: number) {
    if (!this.disabled) {
      this.$emit('update:modelValue', index);
    }
  }
}

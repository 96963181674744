
// TODO: the code has been copied from vue-focus-lock and updated to Vue 3
// This needs some improvement
// https://github.com/theKashey/vue-focus-lock
import moveFocusInside, { focusInside, focusIsHidden, constants } from 'focus-lock';
import { Options, Vue } from 'vue-class-component';

function deferAction(action) {
  const setImmediate = window.setImmediate;
  if (typeof setImmediate === 'undefined') {
    setTimeout(action, 1);
  } else {
    setImmediate(action);
  }
}

let lastActiveTrap: any = 0;
let lastActiveFocus: any = null;

let focusWasOutsideWindow = false;

const isFreeFocus = () => document?.activeElement === document.body || focusIsHidden();

const activateTrap = () => {
  let result: any = false;
  if (lastActiveTrap) {
    const { observed, onActivation } = lastActiveTrap;
    if (focusWasOutsideWindow || !isFreeFocus() || !lastActiveFocus) {
      if (observed && !focusInside(observed)) {
        onActivation();
        result = moveFocusInside(observed, lastActiveFocus);
      }
      focusWasOutsideWindow = false;
      lastActiveFocus = document && document.activeElement;
    }
  }
  return result;
};

const reducePropsToState = (propsList) => propsList.filter(({ disabled }) => !disabled).slice(-1)[0];

const handleStateChangeOnClient = (trap) => {
  if (lastActiveTrap !== trap) {
    lastActiveTrap = null;
  }
  lastActiveTrap = trap;
  if (trap) {
    activateTrap();
    deferAction(activateTrap);
  }
};

let instances: any[] = [];

const emitChange = () => {
  handleStateChangeOnClient(reducePropsToState(instances));
};

const onTrap = (event) => {
  if (activateTrap() && event) {
    // prevent scroll jump
    event.stopPropagation();
    event.preventDefault();
  }
};

const onBlur = () => {
  deferAction(activateTrap);
};

const onWindowBlur = () => {
  focusWasOutsideWindow = true;
};

@Options({
  props: {
    returnFocus: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noFocusGuards: {
      type: [Boolean, String],
      default: false
    },
    group: {
      type: String
    }
  }
})
export default class Lock extends Vue {
  data: any = {};
  hidden = '';
  noFocusGuards!: boolean | string;
  disabled!: boolean;

  get groupAttr() {
    return { [constants.FOCUS_GROUP]: this.group };
  }

  get hasLeadingGuards() {
    return this.noFocusGuards !== true;
  }

  get hasTailingGuards() {
    return this.hasLeadingGuards && this.noFocusGuards !== 'tail';
  }

  created() {
    this.$watch('disabled', () => {
      this.data.disabled = this.disabled;
      emitChange();
    });
  }

  mounted() {
    this.data.vue = this;
    this.data.observed = this.$el.querySelector('[data-lock]');

    this.data.disabled = this.disabled;
    this.data.onActivation = () => {
      this.originalFocusedElement = this.originalFocusedElement || (document && document.activeElement);
    };

    if (!instances.length) {
      document.addEventListener('focusin', onTrap, true);
      document.addEventListener('focusout', onBlur);
      window.addEventListener('blur', onWindowBlur);
    }
    instances.push(this.data);
    emitChange();
  }

  unmounted() {
    instances = instances.filter(({ vue }) => vue !== this);
    if (!instances.length) {
      document.removeEventListener('focusin', onTrap, true);
      document.removeEventListener('focusout', onBlur);
      window.removeEventListener('blur', onWindowBlur);
    }
    if (this.returnFocus && this.originalFocusedElement && this.originalFocusedElement.focus) {
      this.originalFocusedElement.focus();
    }
    emitChange();
  }

  onBlur() {
    deferAction(emitChange);
  }
}

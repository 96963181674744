import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSelect, {
      "model-value": _ctx.modelValue.selectedReason,
      label: _ctx.$t('custom.uhb.consult.primary-rejected-reason'),
      options: _ctx.rejectReasonsOptions,
      "data-dusk": "reject-reason-type-select",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateSelect($event)))
    }, null, 8, ["model-value", "label", "options"]),
    (_ctx.showAdditional || _ctx.isReasonMandatory)
      ? (_openBlock(), _createBlock(_component_BaseTextarea, {
          key: 0,
          "model-value": _ctx.modelValue.additionalReason,
          label: _ctx.$t('custom.uhb.consult.additional-information'),
          "is-optional": !_ctx.isReasonMandatory,
          "data-dusk": "reject-reason-text",
          rows: 3,
          "max-length": 500,
          instructions: 
        _ctx.$t('platform.instructions.maximum-characters', [
          _ctx.modelValue.additionalReason.length,
          _ctx.reasonMaxLength,
        ])
      ,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.setReason({ additionalReason: $event })))
        }, null, 8, ["model-value", "label", "is-optional", "instructions"]))
      : _createCommentVNode("", true)
  ], 64))
}
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center space-x-4" }
const _hoisted_2 = {
  key: 0,
  class: "text-base font-medium"
}
const _hoisted_3 = { class: "flex flex-row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-row justify-between bg-white border border-solid border-gray-300 rounded-lg shadow p-4",
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hover = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.name && _ctx.name.length)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.name), 1))
        : _createCommentVNode("", true),
      (_ctx.label && _ctx.label.name && _ctx.label.name.length)
        ? (_openBlock(), _createBlock(_component_MkBadge, {
            key: 1,
            class: "capitalize",
            color: _ctx.label.color,
            stroked: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label.name), 1)
            ]),
            _: 1
          }, 8, ["color"]))
        : _createCommentVNode("", true),
      (_ctx.description && _ctx.description.length)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(["text-gray-500 text-sm transition-opacity truncate ml-10", _ctx.hover ? 'opacity-1 ease-in duration-200' : 'opacity-0 ease-out duration-500'])
          }, _toDisplayString(_ctx.description), 3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "left-actions")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "right-actions"),
      (_ctx.enableAdd)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            "data-dusk": "org-new-child-btn",
            color: "ghost",
            "left-icon": "add",
            size: "small",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('createChild')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.org-unit.new-child')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.actions && _ctx.actions.length)
        ? (_openBlock(), _createBlock(_component_BasePopover, {
            key: 1,
            position: "left"
          }, {
            button: _withCtx(() => [
              _createVNode(_component_SpecialityButton, { class: "ml-4" })
            ]),
            menu: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (item) => {
                return _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                  key: item.name,
                  onClick: ($event: any) => (item.onClick($event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])), [
                  [_directive_allow, item.permission]
                ])
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 32))
}
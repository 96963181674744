
import { Vue, Options } from 'vue-class-component';
import { BaseModal } from '@/lib/components/Modals';
import { BaseIcon } from '@/lib/components/Icon';
import IconGlyph from '@/lib/components/Icon/IconGlyph.vue';

@Options({
  components: {
    IconGlyph,
    BaseModal,
    BaseIcon
  },

  props: {
    heading: {
      type: String,
      required: true
    },

    body: {
      type: String,
      required: true
    },

    isGlyph: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: 'check-glyph'
    },

    iconColor: {
      type: String,
      default: 'primary-500'
    },

    stroke: {
      type: Boolean,
      default: false
    }
  }
})
export default class SmartFormComplete extends Vue {}


import { Vue, Options } from 'vue-class-component';
import { IOption, ISuggestion } from '@/lib';
import { Anatomy, Condition, Diagnosis, DiagnosisRequest, DiagnosisStatus } from '@/models';
import { BaseButton } from '@/lib/components/Button';
import { SearchInput } from '@/lib/components/Search';
import { BaseSelect } from '@/lib/components/Select';
import { BaseSwitch } from '@/lib/components/Switch';

export enum AnatomyLaterality {
  RIGHTLATERALITY = 'R',
  LEFTLATERALITY = 'L',
  BOTHLATERALITY = 'BIL'
}

@Options({
  props: {
    conditionList: {
      type: Array,
      default: () => []
    },
    conditions: {
      type: Array,
      default: () => []
    },
    anatomy: {
      type: Array,
      default: () => []
    },
    statuses: {
      type: Array,
      default: () => []
    },
    isPresent: {
      type: Boolean,
      default: true
    },
    diagnosis: {
      type: Object,
      default: null
    }
  },
  components: { BaseButton, BaseSelect, BaseSwitch, SearchInput }
})
export default class NewConditionForm extends Vue {
  isPresent!: boolean;
  diagnosis?: Diagnosis | null;
  conditionList!: Array<Condition>;
  anatomy!: Array<Anatomy>;
  statuses!: Array<DiagnosisStatus>;
  conditions!: Array<Diagnosis>;

  conditionId = '';
  stageId = '';
  locationId = '';
  statusId = '';
  search = '';
  differential = false;

  get title() {
    return this.diagnosis
      ? this.$t('custom.uhb.diagnosis.edit-condition')
      : this.isPresent
        ? this.$t('custom.uhb.diagnosis.add-condition')
        : this.$t('custom.uhb.diagnosis.add-absence');
  }

  get saveButtonText() {
    return this.diagnosis
      ? this.$t('platform.common.save-changes')
      : this.isPresent
        ? this.$t('custom.uhb.diagnosis.add-condition')
        : this.$t('custom.uhb.diagnosis.add-absence');
  }

  get statusOptions(): Array<IOption> {
    return this.statuses.map((status) => ({
      label: status.name,
      value: status.id
    }));
  }

  get locationOptions(): Array<IOption> {
    return this.anatomy
      .filter((anatomy) => {
        if (this.condition) {
          const savedConditions = this.getSavedConditions();
          if (savedConditions.length > 0 && anatomy.laterality === AnatomyLaterality.BOTHLATERALITY) {
            return false;
          }

          const doesConditionAnatomieExist = savedConditions.find(
            (condition) => condition.anatomy && condition.anatomy.id === anatomy.id
          );

          return (
            !doesConditionAnatomieExist ||
            (this.diagnosis &&
              this.condition.id === this.diagnosis.condition.id &&
              this.diagnosis.anatomy.id === anatomy.id)
          );
        }
        return true;
      })
      .map((anatomy) => ({
        label: anatomy.name,
        value: anatomy.id
      }));
  }

  get stageOptions(): Array<IOption> {
    return this.stages.map((stage) => ({
      label: stage.name,
      value: stage.id
    }));
  }

  get condition() {
    return this.conditionList.find((condition) => condition.id === this.conditionId);
  }

  get location() {
    return this.anatomy.find((anatomy) => anatomy.id === this.locationId);
  }

  get status() {
    return this.statuses.find((status) => status.id === this.statusId);
  }

  get stage() {
    return this.stages.find((stage) => stage.id === this.stageId);
  }

  get suggestions(): Array<ISuggestion> {
    return this.conditionList.find((condition) => condition.name === this.search)
      ? []
      : this.conditionList.map((condition) => ({
        id: condition.id,
        label: condition.name
      }));
  }

  // Disable save changes button if the required fields are not filled
  get disabled() {
    return !this.condition || !this.location;
  }

  // get selected condition stages
  get stages() {
    return this.condition ? this.condition.stages : [];
  }

  mounted() {
    if (this.diagnosis) {
      const diagnosis = this.diagnosis as Diagnosis;
      this.conditionId = diagnosis.condition.id;
      this.search = diagnosis.condition.name;
      this.stageId = diagnosis.stage ? diagnosis.stage.id : '';
      this.locationId = diagnosis.anatomy ? diagnosis.anatomy.id : '';
      this.statusId = diagnosis.status ? diagnosis.status.id : '';
      this.differential = diagnosis.is_differential;
    }
  }

  onSearch(value: ISuggestion) {
    this.stageId = '';
    this.search = value.label;
    this.conditionId = value.id;
    this.$emit('search', this.search);
  }

  resetValues() {
    if (this.search.length) {
      this.$emit('search', '');
    }
    this.conditionId = '';
    this.search = '';
    this.differential = false;
    this.statusId = '';
    this.stageId = '';
    this.locationId = '';
  }

  cancel() {
    this.resetValues();
    this.$emit('cancel');
  }

  save() {
    if (this.condition) {
      const genericDiagnosis: DiagnosisRequest = {
        ...(this.isPresent ? { is_differential: this.differential } : {}),
        ...(this.stage ? { stage_id: this.stage.id } : {}),
        ...(this.location ? { anatomy_id: this.location.id } : {}),
        ...(this.status ? { status_id: this.status.id } : {}),
        condition_id: this.condition.id,
        is_present: this.isPresent
      };

      // This logic only applies to the "Both Eyes" anatomy
      if (this.location?.laterality === AnatomyLaterality.BOTHLATERALITY) {
        const rightAnatomy = this.anatomy.find((location) => location.laterality === AnatomyLaterality.RIGHTLATERALITY);
        const leftAnatomy = this.anatomy.find((location) => location.laterality === AnatomyLaterality.LEFTLATERALITY);

        const rightDiagnosis = { ...genericDiagnosis };
        if (rightAnatomy) {
          rightDiagnosis.anatomy_id = rightAnatomy.id;
        }
        this.$emit('create', rightDiagnosis);

        const leftDiagnosis = { ...genericDiagnosis };
        if (leftAnatomy) {
          leftDiagnosis.anatomy_id = leftAnatomy.id;
        }
        this.$emit('create', leftDiagnosis);
      } else {
        this.$emit('create', genericDiagnosis);
      }

      this.resetValues();
    }
  }

  getSavedConditions() {
    return this.conditions.filter((diagnosis) => diagnosis.condition.id === (this.condition as Condition).id);
  }
}

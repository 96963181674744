import { i18n } from '@/i18n/i18n';
import { IOption } from '@/lib';
import { Laterality } from '@/custom/menicon/models';
import { KUnit, VaUnit } from '@/models';

export const UHB_NHS_NUMBER_KEY = 'UHB_NHS_NUMBER_KEY';
export const ORGANISATION_NAME_MAX_LENGTH = 50;
export const ORGANISATION_DESCRIPTION_MAX_LENGTH = 30;
export const FEATURES = {
  ORGANISATION_LOCATIONS: 'organisation-locations',
  ORGANISATION_NAME_LIMIT: 'organisation-name-limit',
  EXTERNAL_LOOKUP_PATIENT: 'external-lookup-patient',
  PATIENT_EMAIL_MANDATORY: 'patient-email-mandatory',
  PATIENT_PHONE_MANDATORY: 'patient-phone-mandatory',
  PATIENT_ADDRESS_OPTIONAL: 'patient-address-optional',
  PATIENT_MRN_LIMIT: 'patient-mrn-limit',
  FAMILY_HISTORY_CLINICAL_BLOCK: 'family-history-clinical-block',
  SMOKING_SUMMARY_CLINICAL_BLOCK: 'smoking-summary-clinical-block',
  LANGUAGE_PREFERENCES: 'language-preferences',
  VIRTUAL_DOCTOR: 'virtual-doctor',
  MYOPIA_PROGNOSIS: 'myopia-prognosis',
  UNIT_PREFERENCES: 'unit-preferences',
  BLOOM_DAY: 'menicon-bloom-day',
  SIX_MONTH_OPTIONAL_ORDERING: '6-month-optional-ordering',
  DIRECT_ORDER: 'direct-order',
  COLOUR_TRANSFORMATIONS: 'colour-transformations',
  CLINICAL_BLOCKS: 'clinical-blocks',
  TAGS: 'tags'
};

export const sexOptions = () => [
  { value: 'Female', label: i18n.global.t('platform.patient.female') as string },
  { value: 'Male', label: i18n.global.t('platform.patient.male') as string }
];

export const genderOptions = () => [
  { value: 'female', label: i18n.global.t('platform.patient.female') as string },
  { value: 'male', label: i18n.global.t('platform.patient.male') as string },
  { value: 'unknown', label: i18n.global.t('platform.patient.not-known') as string },
  { value: 'other', label: i18n.global.t('platform.patient.not-specified') as string }
];

export const genderOptionsExternalLookup = () => [
  { value: 'female', label: i18n.global.t('platform.patient.female') as string },
  { value: 'male', label: i18n.global.t('platform.patient.male') as string }
];

export const participantGenderOptions = () => [
  emptyOption(),
  ...sexOptions(),
  {
    value: 'Prefer not to say',
    label: i18n.global.t('platform.common.prefer-not-to-say') as string
  }
];

export const emptyOption = () => ({ value: '', label: i18n.global.t('platform.common.select-option') });

export const requiredEyesOptions = (): IOption[] => [
  {
    label: i18n.global.t('platform.eyes.both-eyes') as string,
    value: ''
  },
  {
    label: i18n.global.t('platform.eyes.right-only') as string,
    value: Laterality.right
  },
  {
    label: i18n.global.t('platform.eyes.left-only') as string,
    value: Laterality.left
  }
];

export const kUnits: string[] = [KUnit.MM, KUnit.DIOPTRES];
export const vaUnits: string[] = [VaUnit.SIX_SIX, VaUnit.TWENTY_TWENTY, VaUnit.DECIMAL, VaUnit.LOG_MAR, VaUnit.MONOYER];
export const vaOptions: { [unit: string]: string[] } = {
  [VaUnit.SIX_SIX]: [
    '6/3',
    '6/3.8',
    '6/4',
    '6/4.5',
    '6/4.8',
    '6/5',
    '6/5.5',
    '6/6',
    '6/6.6',
    '6/7.5',
    '6/9',
    '6/12',
    '6/15',
    '6/18',
    '6/21',
    '6/24',
    '6/30',
    '6/36',
    '6/38',
    '6/40',
    '6/45',
    '6/48',
    '6/50',
    '6/60',
    '5/60',
    '4/60',
    '3/60',
    '2/60',
    '1/60'
  ],
  [VaUnit.TWENTY_TWENTY]: [
    '20/10',
    '20/12.5',
    '20/16',
    '20/20',
    '20/25',
    '20/30',
    '20/32',
    '20/40',
    '20/50',
    '20/60',
    '20/63',
    '20/80',
    '20/100',
    '20/125',
    '20/160',
    '20/200',
    '16/200',
    '14/200',
    '10/200',
    '6/200',
    '4/200'
  ],
  [VaUnit.DECIMAL]: [
    '2',
    '1.5',
    '1.2',
    '1',
    '0.8',
    '0.63',
    '0.6',
    '0.5',
    '0.4',
    '0.32',
    '0.25',
    '0.2',
    '0.16',
    '0.13',
    '0.1',
    '0.08',
    '0.07',
    '0.05',
    '0.03',
    '0.02'
  ],
  [VaUnit.LOG_MAR]: [
    '-0.3',
    '-0.2',
    '-0.1',
    '0',
    '0.1',
    '0.2',
    '0.3',
    '0.4',
    '0.5',
    '0.6',
    '0.7',
    '0.8',
    '0.9',
    '1',
    '1.08',
    '1.18',
    '1.3',
    '1.47',
    '1.78'
  ],
  [VaUnit.MONOYER]: [
    '20/10',
    '15/10',
    '13.3/10',
    '12/10',
    '10.9/10',
    '10/10',
    '9/10',
    '8/10',
    '7.5/10',
    '6/10',
    '5/10',
    '4/10',
    '3/10',
    '2/10',
    '1/10',
    '0.8/10',
    '0.7/10',
    '0.5/10',
    '0.3/10',
    '0.2/10'
  ]
};

export const scansViewerColourFilters = () => [
  { value: '', label: i18n.global.t('platform.scans.no-colour-filter') as string },
  { value: 'redFree', label: i18n.global.t('platform.scans.red-free') as string },
  { value: 'falseColour', label: i18n.global.t('platform.scans.false-colour') as string }
];


import { Vue, Options } from 'vue-class-component';
import { getPages } from '@/lib/helpers/pagination.helper';
import { BaseIcon } from '@/lib/components/Icon';
import PaginationButton from './PaginationButton.vue';

@Options({
  props: {
    modelValue: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    length: {
      type: Number,
      default: 0
    },
    mdPagesLimit: {
      type: Number,
      default: 8
    },
    smPagesLimit: {
      type: Number,
      default: 7
    },
    itemsName: {
      type: String,
      default: 'items'
    }
  },
  components: { PaginationButton, BaseIcon }
})
export default class BasePagination extends Vue {
  length!: number;
  pageSize!: number;
  modelValue!: number;
  smPagesLimit!: number;
  mdPagesLimit!: number;
  pagesLimit = 0;

  get pagesList() {
    // FIXME: it is not updated when length and pageSize are updated
    // return [...Array(Math.ceil(this.length / this.pageSize)).keys()].map((i: number) => i + 1);
    const list: number[] = [];
    for (let i = 1; i <= Math.ceil(this.length / this.pageSize); i++) {
      list.push(i);
    }
    return list;
  }

  get displayedList() {
    return getPages(this.pagesList, this.pagesLimit, this.modelValue);
  }

  created() {
    this.setPageLimit();
    // On window resize, update pages limit
    window.addEventListener('resize', this.setPageLimit);
    this.$watch('mdPagesLimit', () => {
      this.setPageLimit();
    });
    this.$watch('smPagesLimit', () => {
      this.setPageLimit();
    });
  }

  unmounted() {
    window.removeEventListener('resize', this.setPageLimit);
  }

  setPageLimit() {
    const mdScreen = 768;
    const limit = window.innerWidth >= mdScreen ? this.mdPagesLimit : this.smPagesLimit;
    if (limit !== this.pagesLimit) {
      this.pagesLimit = limit;
    }
  }

  updatePage(page: number) {
    if (this.modelValue !== page && page) {
      this.$emit('update:modelValue', page);
      this.$emit('change', page);
    }
  }

  previousPage() {
    if (this.modelValue > 1) {
      this.updatePage(this.modelValue - 1);
    }
  }

  nextPage() {
    if (this.modelValue < this.pagesList.length) {
      this.updatePage(this.modelValue + 1);
    }
  }
}

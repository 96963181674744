import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!

  return (_openBlock(), _createBlock(_component_BaseSelect, {
    "data-dusk": "copd-program-community-team",
    "model-value": _ctx.communityTeam,
    label: _ctx.communityTeamLabel,
    placeholder: _ctx.communityTeamPlaceholder,
    options: _ctx.communityTeamOptions,
    disabled: !_ctx.organisationalUnit,
    error: _ctx.validationErrors,
    class: "w-full",
    "onUpdate:modelValue": _ctx.updateCommunityTeam
  }, null, 8, ["model-value", "label", "placeholder", "options", "disabled", "error", "onUpdate:modelValue"]))
}
import { defineStore } from 'pinia';
import { INotification } from '@/lib';
import { v4 } from 'uuid';

interface State {
  notifications: INotification[];
  snackbar: {
    label: string
  } | null;
}

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [],
    snackbar: null
  } as State),
  actions: {
    addNotification(notification: INotification) {
      this.notifications = [
        ...this.notifications,
        {
          ...notification,
          id: v4()
        }
      ];
    },
    addErrorNotification(notification: INotification) {
      this.addNotification({
        ...notification,
        icon: 'warning',
        color: 'danger-600'
      });
    },
    addSuccessNotification(notification: INotification) {
      this.addNotification({
        ...notification,
        icon: 'check',
        color: 'primary-500'
      });
    }
  }
});


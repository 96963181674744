
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: {
    BaseIcon
  }
})
export default class NavDropdownButton extends Vue {}

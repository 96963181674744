
import { Vue, Options } from 'vue-class-component';
import { BaseButton } from '@/lib/components/Button';
import useClipboard from 'vue-clipboard3';

@Options({
  components: { BaseButton },
  props: {
    context: {
      type: String,
      default: ''
    },
    copyEnable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class CopyableTextSummary extends Vue {
  context!: string;

  get formatContext() {
    return this.context.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  }

  async copyToClipboard() {
    try {
      const temp = this.formatContext;
      const titleRegex = /<\/*b\s*[/]?>/gi;
      const newLineRegex = /<br\s*[/]?>/gi;
      const afterNewLine = temp.replace(newLineRegex, '\r\n');
      const afterTitle = afterNewLine.replace(titleRegex, '\r\n');
      const container = this.$refs.container;
      const { toClipboard } = useClipboard();
      await toClipboard(afterTitle, container);
      this.$emit('success');
    } catch (error) {
      this.$emit('error');
    }
  }
}

import { CustomerBranding, Feature, Organisation, Permission, User } from '@/models';
import { defineStore } from 'pinia';
import { UserService } from '@/services/api';
import { i18n, switchLocale } from '@/i18n/i18n';
import { v4 } from 'uuid';
import { IdlesService } from '@/services';
import { IdleJs } from '@sbourouis/idle-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_LEGAL_REGION } from '@/constants/legal';

dayjs.extend(utc);
const userService = new UserService();

interface State {
  currentUser: User | null;
  currentOrganisationId: string | null;
  token: string | null;
  refreshToken: string | null;
  expireDate: string | null;
  isMaintenance: boolean;
  uuid: string | null;
  isIdle: boolean;
  idle: any | null;
}

export const useSessionStore = defineStore('session', {
  state: (): State => ({
    currentUser: null,
    token: null,
    refreshToken: null,
    expireDate: null,
    isMaintenance: false,
    isIdle: false,
    idle: null,
    uuid: null,
    currentOrganisationId: window.localStorage.getItem('organisationId') ?? null
  }),
  getters: {
    currentOrganisation: (state: State): Organisation | null => state.currentUser?.organisations
      .find((organisation) => organisation.id === state.currentOrganisationId) ?? null,
    permissions(): string[] {
      if (this.currentOrganisation?.permissions) {
        return this.currentOrganisation.permissions.map((permission: Permission) => permission.key);
      }
      return [];
    },
    features(): Feature[] {
      return [
        ...(this.currentUser?.features ?? []),
        ...(this.currentOrganisation?.domain?.features ?? [])
      ];
    },
    legalRegions(): string[] {
      const regions = this.currentOrganisation?.domain?.customer?.legal_regions;
      return regions?.length ? regions : [DEFAULT_LEGAL_REGION];
    },
    customerBranding(): CustomerBranding {
      return this.currentOrganisation?.domain?.customer?.branding ?? CustomerBranding.BPM;
    }
  },
  actions: {
    async login(credential) {
      const response = await userService.login(credential);
      this.token = response.access_token;
      this.refreshToken = response.refresh_token;
      this.setExpireDate(response.expires_in);
      return response;
    },
    setCurrentOrganisationId(organisationId: string) {
      this.currentOrganisationId = organisationId;
      window.localStorage.setItem('organisationId', organisationId);
    },
    async refreshUserToken(params) {
      const response = await userService.refreshToken(params);
      this.token = response.access_token;
      this.refreshToken = response.refresh_token;
      this.setExpireDate(response.expires_in);
      return response;
    },
    setExpireDate(expiresIn: number) {
      this.expireDate = dayjs.utc().add(expiresIn, 'second').format('YYYY-MM-DD HH:mm:ss');
    },
    async revokeUserToken(params) {
      return await userService.revokeToken(params);
    },
    async fetchUserOTP(params) {
      return await userService.mfaAssociate(params);
    },
    async fetchUserSms(params) {
      return await userService.mfaAssociate(params);
    },
    async fetchUserSmsChallenge(params) {
      return await userService.mfaChallenge(params);
    },
    async fetchCurrentUser() {
      try {
        const response = await userService.getCurrent();
        this.setCurrentUser(response);

        const locale = response.locale;
        if (locale) {
          switchLocale(locale);
        }
        const savedOrganisationId = window.localStorage.getItem('organisationId');

        if (!savedOrganisationId) {
          // TODO: Maybe prompt instead of defaulting to the first?
          this.setCurrentOrganisationId(response.organisations[0]?.id ?? null);
          return;
        }

        const organisationInResponse = response.organisations.find(
          (organisation: Organisation) => organisation.id === savedOrganisationId
        );

        if (organisationInResponse) {
          this.setCurrentOrganisationId(organisationInResponse?.id ?? null);
        } else {
          // TODO: Maybe show an error that they don't appear to belong to the org unit they were last and prompt them to select another?
          this.setCurrentOrganisationId(response.organisations[0]?.id ?? null);
        }
      } catch (e) {
        // TODO: maybe handle the error properly? Show a notification, a snackbar? to be decided
        console.error(e);
      }
    },

    async saveUser(user) {
      const response = await userService.updateCurrent(user);
      this.setCurrentUser({
        ...(this.currentUser ? this.currentUser : {}),
        ...response
      });
      return response;
    },

    updateCurrentUser(user: Partial<User>) {
      this.currentUser = {
        ...(this.currentUser ? this.currentUser : {}),
        ...user
      };
    },

    setCurrentUser(user: User | null) {
      this.currentUser = user;

      if (user) {
        if (user.locale !== i18n.global.locale.value) {
          switchLocale(user.locale);
        }
        if (this.idle) {
          this.idle.stop();
        }
        const idleTime = user.inactivity_timeout_seconds * 1000;
        const idle = new IdleJs({
          idle: idleTime, // idle time in ms
          onIdle: () => {
            this.isIdle = true;
          },
          keepTracking: true, // set it to false if you want to be notified only on the first idleness change
          startAtIdle: true // set it to true if you want to start in the idle state
        });
        this.isIdle = false;
        this.idle = idle;
        idle.start();

        if (!this.uuid) {
          const id = v4();
          this.uuid = id;
          IdlesService.addIdle(id);
        } else if (!IdlesService.hasIdle(this.uuid)) {
          IdlesService.addIdle(this.uuid);
        }
      }
    }
  }
});


import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    imageUrl: {
      type: String
    },
    text: {
      type: String,
      default: ''
    }
  }
})
export default class BaseImagePreview extends Vue {}

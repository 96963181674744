
import { Vue, Options } from 'vue-class-component';
import ResizeObserver from 'resize-observer-polyfill';
import { ColorName } from '@/lib';
import * as helper from '@/lib/helpers/slider.helpers';

@Options({
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1,
      validator: (value) => value > 0
    },
    modelValue: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary-500'
    },
    enableShortcuts: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'regular',
      validator: (value: string) => ['small', 'regular'].includes(value)
    }
  }
})
export default class BaseSlider extends Vue {
  color!: ColorName;
  percent = 0;
  isThumbMoving = false;
  arrowsEnabled = false;
  boundingClientRect!: DOMRect;

  modelValue!: number;
  min!: number;
  max!: number;
  step!: number;
  enableShortcuts!: boolean;
  disabled!: boolean;
  vertical!: boolean;

  created() {
    this.percent = helper.getPercent(this.modelValue, this.min, this.max);
    this.$watch('modelValue', () => this.onPropertyChange());
    this.$watch('min', () => this.onPropertyChange());
    this.$watch('max', () => this.onPropertyChange());
    window.addEventListener('mouseup', () => (this.isThumbMoving = false));
    window.addEventListener('mousemove', this.onThumbMouseMove);
  }

  mounted() {
    this.onResize();
    new ResizeObserver(() => this.onResize()).observe(this.$el as HTMLElement);
    window.addEventListener('resize', this.onResize);
    window.addEventListener('click', () => (this.arrowsEnabled = false));
    if (this.enableShortcuts) {
      window.addEventListener('keydown', this.onKeyDown);
    }
  }

  unmounted() {
    window.removeEventListener('mouseup', () => (this.isThumbMoving = false));
    window.removeEventListener('mousemove', this.onThumbMouseMove);
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('click', () => (this.arrowsEnabled = false));
    if (this.enableShortcuts) {
      window.removeEventListener('keydown', this.onKeyDown);
    }
  }

  onThumbMouseDown() {
    this.isThumbMoving = true;
    this.arrowsEnabled = true;
  }

  onThumbMouseMove(ev: MouseEvent) {
    if (this.isThumbMoving) {
      this.updateValue(ev.clientX, ev.clientY);
    }
  }

  onResize() {
    if (this.$refs.slider) {
      this.boundingClientRect = (this.$refs.slider as HTMLElement).getBoundingClientRect();
    }
  }

  onKeyDown(ev: KeyboardEvent) {
    if (this.arrowsEnabled && !this.disabled && this.enableShortcuts) {
      if (ev.key === 'ArrowUp' || ev.key === 'ArrowLeft') {
        const newValue = this.modelValue - this.step;
        if (newValue >= this.min) {
          this.$emit('update:modelValue', newValue);
        }
      }
      if (ev.key === 'ArrowDown' || ev.key === 'ArrowRight') {
        const newValue = this.modelValue + this.step;
        if (newValue <= this.max) {
          this.$emit('update:modelValue', newValue);
        }
      }
    }
  }

  onPropertyChange() {
    if (!helper.checkProps(this.min, this.max, this.modelValue, this.step)) {
      throw new Error('The given properties are incorrect.');
    }
    this.percent = helper.getPercent(this.modelValue, this.min, this.max);
  }

  updateValue(clientX: number, clientY: number) {
    if (!this.disabled) {
      const newValue = this.vertical
        ? helper.getValueFromPositionVertical(
          clientY,
          this.min,
          this.max,
          this.boundingClientRect.top,
          this.boundingClientRect.height
        )
        : helper.getValueFromPosition(
          clientX,
          this.min,
          this.max,
          this.boundingClientRect.left,
          this.boundingClientRect.width
        );
      if (newValue !== this.modelValue && newValue <= this.max && newValue >= this.min) {
        this.$emit('update:modelValue', newValue);
        this.$emit('change', newValue);
      }
    }
  }
}

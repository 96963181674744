
import { Vue, Options } from 'vue-class-component';
import { ISuggestion } from '@/lib';
import { FullIndicator, Indicator } from '@/models';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseChip } from '@/lib/components/Chip';
import { SearchSelect } from '@/lib/components/Search';

@Options({
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  components: { SearchSelect, BaseChip, BaseIcon }
})
export default class ScanSigns extends Vue {
  modelValue!: Array<FullIndicator>;
  list!: Array<Indicator>;
  add = false;

  get indicators(): Array<ISuggestion> {
    return this.modelValue.map((sign) => ({
      id: sign.indicator.id,
      label: sign.indicator.name
    }));
  }

  mounted() {
    window.addEventListener('click', () => (this.add = false));
  }

  removeSign(id: string) {
    const value = [...this.modelValue].filter((chip) => chip.id !== id);
    this.$emit('update:modelValue', value);
    this.$emit('remove', id);
  }

  removeSignFromSearchSelect(id: string) {
    const sign = this.modelValue.find((sign) => sign.indicator.id === id);
    if (sign) {
      this.$emit('remove', sign.id);
      const value = [...this.modelValue].filter((s) => s.id !== sign.id);
      this.$emit('update:modelValue', value);
    }
  }
}

import apiClient from '@/services/api/client/apiClient';
import {
  CorrectionResponse,
  MeniconBloomDayCorrectionRequestBody,
  MeniconBloomNightCorrectionRequestBody,
  MeniconLens,
  MeniconLensDifferenceMapsRequestBody,
  MeniconLensListItem,
  MeniconLensListRequestBody,
  MeniconLensPropertiesRequestBody,
  MeniconLensRecalculationRequestBody,
  MeniconLensRecalculationResponse
} from '@/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class MeniconLensOrderService {
  endpoint = 'menicon/v1/lens';

  async getLensList(
    params: MeniconLensListRequestBody,
    config: AxiosRequestConfig = {}
  ): Promise<Array<MeniconLensListItem>> {
    return (await apiClient.get(this.endpoint, { ...config, params, timeout: 200000 })).data;
  }

  async getLensProperties(
    params: MeniconLensPropertiesRequestBody,
    lensId: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<MeniconLens>> {
    return await apiClient.get(`${this.endpoint}/${lensId}`, {
      ...config,
      params,
      timeout: 200000
    });
  }

  async getBloomNightLensCorrections(
    params: MeniconBloomNightCorrectionRequestBody,
    lensId: string,
    config: AxiosRequestConfig = {}
  ): Promise<CorrectionResponse> {
    return await apiClient.get(`${this.endpoint}/${lensId}/bloom-night-corrections/`, {
      ...config,
      params,
      timeout: 200000
    });
  }

  async getBloomDayLensCorrections(
    params: MeniconBloomDayCorrectionRequestBody,
    lensId: string,
    config: AxiosRequestConfig = {}
  ): Promise<CorrectionResponse> {
    return await apiClient.get(`${this.endpoint}/${lensId}/bloom-day-corrections`, {
      ...config,
      params,
      timeout: 200000
    });
  }

  async getDifferenceMaps(params: MeniconLensDifferenceMapsRequestBody, config: AxiosRequestConfig): Promise<string> {
    const data = (await apiClient.get('menicon/v1/difference-map', {
      ...config,
      params,
      timeout: 200000,
      responseType: 'blob'
    })) as BlobPart;

    return URL.createObjectURL(new Blob([data]));
  }

  async getLensRecalculation(
    params: MeniconLensRecalculationRequestBody,
    config: AxiosRequestConfig = {}
  ): Promise<MeniconLensRecalculationResponse> {
    return await apiClient.get(`${this.endpoint}/recalculation`, {
      ...config,
      params,
      timeout: 200000
    });
  }
}

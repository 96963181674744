
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: { BaseIcon },
  props: {
    label: {
      type: String,
      default: ''
    },
    autoDismiss: {
      type: Boolean,
      default: true
    },
    dismissAfter: {
      type: Number,
      default: 3000
    },
    action: {
      type: Object
    },
    color: {
      type: String,
      default: 'base',
      validator: (value: string) => ['base', 'danger', 'success'].includes(value)
    },
    icon: {
      type: String,
      default: 'tick'
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
})
export default class BaseSnackbar extends Vue {
  autoDismiss!: boolean;
  dismissAfter!: number;
  timeout?: number;
  color!: string;

  get bgColorClass(): string {
    if (this.color === 'danger') {
      return 'bg-danger-500';
    }

    if (this.color === 'success') {
      return 'bg-success-600';
    }

    return 'bg-gray-900';
  }

  get separatorColorClass(): string {
    if (this.color === 'danger') {
      return 'border-danger-300';
    }

    if (this.color === 'success') {
      return 'border-success-300';
    }

    return 'border-gray-800';
  }

  get actionColorClass(): string {
    if (this.color === 'danger') {
      return 'text-danger-200';
    }

    if (this.color === 'success') {
      return 'text-success-200';
    }

    return 'text-gray-500';
  }

  get dismissColorClass(): string {
    if (this.color === 'danger') {
      return 'hover:text-danger-300';
    }

    if (this.color === 'success') {
      return 'hover:text-success-300';
    }

    return 'hover:text-gray-500';
  }

  mounted() {
    this.setDismiss();
    this.$watch('autoDismiss', () => this.setDismiss());
    this.$watch('dismissAfter', () => this.setDismiss());
  }

  setDismiss() {
    if (this.autoDismiss) {
      this.timeout = window.setTimeout(() => this.$emit('dismiss'), this.dismissAfter);
    } else if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }
}

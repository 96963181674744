import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmartFormSummarySection = _resolveComponent("SmartFormSummarySection", true)!
  const _component_SmartFormSummaryItem = _resolveComponent("SmartFormSummaryItem")!

  return (_ctx.conditionsMet)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent('h' + (_ctx.level + 2)), {
          class: _normalizeClass(["font-bold text-gray-900 text-xl leading-tight", { 'mt-8': _ctx.level > 0 }])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.section.heading), 1)
          ]),
          _: 1
        }, 8, ["class"])),
        (_ctx.level > 0)
          ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.section.items, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.id
          }, [
            (item.type === 'section')
              ? (_openBlock(), _createBlock(_component_SmartFormSummarySection, {
                  key: 0,
                  section: item,
                  "patient-id": _ctx.patientId,
                  "participant-id": _ctx.participantId,
                  "form-submission-id": _ctx.formSubmissionId,
                  locale: _ctx.locale,
                  level: _ctx.level + 1
                }, null, 8, ["section", "patient-id", "participant-id", "form-submission-id", "locale", "level"]))
              : (_openBlock(), _createBlock(_component_SmartFormSummaryItem, {
                  key: item.id,
                  item: item,
                  "patient-id": _ctx.patientId,
                  "participant-id": _ctx.participantId,
                  "form-submission-id": _ctx.formSubmissionId,
                  locale: _ctx.locale,
                  class: "mt-8"
                }, null, 8, ["item", "patient-id", "participant-id", "form-submission-id", "locale"]))
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
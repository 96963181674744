
import { Vue, Options } from 'vue-class-component';
import { IOption, ManagementDestinationOption, ManagementStepField } from '@/lib';
import {
  Diagnosis,
  ManagementPlanDestination,
  ManagementPlanDiagnosesGroupModel,
  ManagementPlanStep,
  ManagementPlanTimeframe
} from '@/models';
import { Condition } from '@/lib/components/Diagnosis';
import ManagementPlanStepAdder from './ManagementPlanStepAdder.vue';

@Options({
  props: {
    actionId: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => ({
        diagnoses: [],
        steps: [],
        destinations: []
      })
    },
    destinations: {
      type: Array,
      default: () => []
    },
    timeframes: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { ManagementPlanStepAdder, Condition }
})
export default class ManagementPlanDiagnosesGroup extends Vue {
  value!: ManagementPlanDiagnosesGroupModel;
  destinations!: Array<ManagementPlanDestination>;
  timeframes!: Array<ManagementPlanTimeframe>;
  get defaultField() {
    return [
      {
        placeholder: this.$t('custom.uhb.management.destination') as string,
        type: 'select',
        value: ''
      },
      {
        placeholder: this.$t('custom.uhb.management.timeframe') as string,
        type: 'select',
        value: ''
      },
      {
        placeholder: this.$t('custom.uhb.management.enter-notes') as string,
        type: 'text',
        value: ''
      }
    ];
  }

  get diagnoses(): Array<Diagnosis> {
    return this.value.diagnoses;
  }

  get steps(): Array<ManagementPlanStep> {
    return this.value.steps;
  }

  get timeframeOptions(): Array<IOption> {
    return this.timeframes.map((timeframe) => ({
      label: timeframe.name,
      value: timeframe.id
    }));
  }

  get destinationOptions(): Array<ManagementDestinationOption> {
    return this.destinations.map((destination) => ({
      label: destination.name,
      value: destination.id,
      require_timeframe: destination.require_timeframe
    }));
  }

  get stepsValue(): Array<Array<ManagementStepField>> {
    if (this.steps.length > 0) {
      return this.steps.reduce((acc, val) => {
        acc.push([
          {
            step_id: val.id,
            placeholder: this.$t('custom.uhb.management.destination') as string,
            type: 'select',
            value: val.destination.id
          },
          {
            step_id: val.id,
            placeholder: this.$t('custom.uhb.management.timeframe') as string,
            type: 'select',
            value: val.timeframe.id
          },
          {
            step_id: val.id,
            placeholder: this.$t('custom.uhb.management.enter-notes') as string,
            type: 'text',
            value: val.notes
          }
        ]);
        return acc;
      }, [] as Array<Array<ManagementStepField>>);
    }
    return [this.defaultField];
  }
}


import { Vue, Options } from 'vue-class-component';
import { IAccount } from '@/lib';
import { BaseButton } from '@/lib/components/Button';

import AccountListItem from './AccountListItem.vue';
import { i18n } from '@/i18n/i18n';

@Options({
  components: { AccountListItem, BaseButton },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    buttonText: {
      type: String,
      default() {
        return i18n.global.t('platform.patient.add-account') as string;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    showEditButtons: {
      type: Boolean,
      default: true
    },
    showDeleteButtons: {
      type: Boolean,
      default: true
    }
  }
})
export default class AccountList extends Vue {
  items!: Array<IAccount>;
  buttonText!: string;
  disabled!: boolean;
  readonly!: boolean;
  showAddButton!: boolean;
  showEditButtons!: boolean;
  showDeleteButtons!: boolean;

  get displayedItems(): Array<IAccount> {
    return this.items.filter((a) => a.value);
  }
}

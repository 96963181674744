
import { Vue, Options } from 'vue-class-component';
import { IChartLegend } from '@/lib';
import ChartLegendItem from './BaseChartLegendItem.vue';

@Options({
  components: {
    ChartLegendItem
  },
  props: {
    items: {
      type: Object,
      default: () => ({})
    }
  }
})
export default class ChartLegend extends Vue {
  items!: IChartLegend;
}

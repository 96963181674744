
import { Vue, Options } from 'vue-class-component';
import LoginLayout from '@/lib/layouts/LoginLayout.vue';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: {
    BaseIcon,
    LoginLayout
  }
})
export default class UnsupportedBrowser extends Vue {}

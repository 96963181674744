import { Encounter } from '@/models';
import { GenericService } from '../generic.service';
import { AxiosRequestConfig } from 'axios';

export class JourneyEncounterService extends GenericService<Encounter> {
  constructor(patientId: string, journeyId: string) {
    super();
    this.endpoint = `/menicon/v1/patients/${patientId}/journeys/${journeyId}/encounters`;
  }

  async complete(encounterId: string, params: AxiosRequestConfig = {}): Promise<Encounter> {
    return await this.update(
      encounterId,
      {
        completed_at: new Date().toISOString()
      },
      params
    );
  }
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row bg-gray-50 border border-solid border-gray-200 rounded-md text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSegment = _resolveComponent("BaseSegment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_BaseSegment, {
        key: item.value,
        label: item.label,
        selected: _ctx.modelValue === item.value,
        style: {"margin":"-1px"},
        onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.value))
      }, null, 8, ["label", "selected", "onClick"]))
    }), 128))
  ]))
}

import { Vue, Options } from 'vue-class-component';
import { SupportTicketsService } from '@/services/api';
import { BaseButton, ButtonLink } from '@/lib/components/Button';
import BaseModal from './BaseModal.vue';
import { useSessionStore } from '@/stores/session.store';

@Options({
  components: {
    BaseModal,
    BaseButton,
    ButtonLink
  }
})
export default class SupportModal extends Vue {
  sending = false;
  subject = 'In-app Support Request';
  body = '';
  error = false;
  ticketNumber = '';
  supportTicketService = new SupportTicketsService();
  sessionStore = useSessionStore();
  supportEmail = 'support@bigpicturemedical.com';

  async send() {
    try {
      this.error = false;
      this.sending = true;

      const response = await this.supportTicketService.create({
        user_id: this.sessionStore.currentUser.id,
        organisational_unit_id: this.sessionStore.currentOrganisationId,
        subject: this.subject,
        body: this.body
      });

      this.ticketNumber = response.data.id;
    } catch (error) {
      this.error = true;
      throw error;
    } finally {
      this.sending = false;
    }
  }

  close() {
    this.$emit('close');
  }
}


import { Vue, Options } from 'vue-class-component';
import { ManagementStepField } from '@/lib';
import { ManagementPlanStepParam, ManagementPlanStepSelected } from '@/models';
import { BaseButton } from '@/lib/components/Button';
import ManagementPlanStep from './ManagementPlanStep.vue';
import { i18n } from '@/i18n/i18n';

@Options({
  props: {
    title: {
      type: String,
      default(): string {
        return i18n.global.t('custom.uhb.management.steps') as string;
      }
    },
    singularItemName: {
      type: String,
      default(): string {
        return i18n.global.t('custom.uhb.management.step') as string;
      }
    },
    fields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    destinations: {
      type: Array,
      default: () => []
    },
    timeframes: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { ManagementPlanStep, BaseButton }
})
export default class StepAdder extends Vue {
  value!: Array<Array<ManagementStepField>>;
  fields!: Array<ManagementStepField>;
  steps: Array<Array<ManagementStepField>> = [];

  get selectedCombinations(): Array<ManagementPlanStepSelected> {
    return this.value.reduce((acc, step) => {
      if (step[0].value && step[1].value) {
        acc.push({
          destination_id: step[0].value,
          timeframe_id: step[1].value
        });
      }
      return acc;
    }, [] as Array<ManagementPlanStepSelected>);
  }

  removeStep(step_index: number): void {
    const id = this.steps[step_index][0].step_id;
    if (id) {
      this.$emit('deleteStep', { step_id: id });
    } else {
      const steps = [...this.steps];
      steps.splice(step_index, 1);
      this.steps = steps;
    }
  }

  addStep(params: ManagementPlanStepParam): void {
    this.$emit('addStep', params);
  }

  updateStep(params: ManagementPlanStepParam): void {
    this.$emit('updateStep', params);
  }

  appendStep(): void {
    this.steps.push(this.fields);
  }

  mounted() {
    this.init();
    this.$watch('value', () => this.init(), { deep: true });
  }

  init() {
    this.steps = this.value;
  }
}

import { IOption } from '@/lib';
import { i18n } from '@/i18n/i18n';

export const defaultRoutes = (): IOption[] => [
  {
    label: i18n.global.t('platform.medication.routes.oral') as string,
    value: 'Oral'
  },

  { value: 'Inhaled', label: i18n.global.t('platform.medication.routes.inhaled') as string },

  {
    label: i18n.global.t('platform.medication.routes.topical') as string,
    value: 'Topical'
  },
  {
    label: i18n.global.t('platform.medication.routes.both-eyes') as string,
    value: 'Both Eyes'
  },
  {
    label: i18n.global.t('platform.medication.routes.right-eye') as string,
    value: 'Right Eye'
  },
  {
    label: i18n.global.t('platform.medication.routes.left-eye') as string,
    value: 'Left Eye'
  },
  {
    label: i18n.global.t('platform.medication.routes.both-ears') as string,
    value: 'Both Ears'
  },
  {
    label: i18n.global.t('platform.medication.routes.right-ear') as string,
    value: 'Right Ear'
  },
  {
    label: i18n.global.t('platform.medication.routes.left-ear') as string,
    value: 'Left Ear'
  },
  {
    label: i18n.global.t('platform.medication.routes.intramuscular') as string,
    value: 'Intramuscular'
  },
  {
    label: i18n.global.t('platform.medication.routes.subcutaneous') as string,
    value: 'Subcutaneous'
  },
  {
    label: i18n.global.t('platform.medication.routes.intravenous') as string,
    value: 'Intravenous'
  },
  {
    label: i18n.global.t('platform.medication.routes.sublingual') as string,
    value: 'Sublingual'
  },
  {
    label: i18n.global.t('platform.medication.routes.rectal') as string,
    value: 'Rectal'
  },
  {
    label: i18n.global.t('platform.medication.routes.vaginal') as string,
    value: 'Vaginal'
  }
];
export const doseUnitOptions = (): IOption[] => [
  {
    value: 'mg',
    label: i18n.global.t('platform.medication.units.mg') as string
  },
  {
    value: 'mL',
    label: i18n.global.t('platform.medication.units.ml') as string
  },
  {
    value: 'drops',
    label: i18n.global.t('platform.medication.units.drops') as string
  },
  {
    value: 'tablets',
    label: i18n.global.t('platform.medication.units.tablets') as string
  },
  {
    value: 'units',
    label: i18n.global.t('platform.medication.units.units') as string
  },
  {
    value: 'apply',
    label: i18n.global.t('platform.medication.units.apply') as string
  },
  {
    value: 'puffs',
    label: i18n.global.t('platform.medication.units.puffs') as string
  },
  {
    value: 'micrograms',
    label: i18n.global.t('platform.medication.units.micrograms') as string
  }
];
export const frequencyOptions = (): IOption[] => [
  {
    label: i18n.global.t('platform.medication.frequencies.daily') as string,
    value: 'Daily'
  },
  {
    value: 'Twice Daily',
    label: i18n.global.t('platform.medication.frequencies.2-daily') as string
  },
  {
    label: i18n.global.t('platform.medication.frequencies.3-daily') as string,
    value: 'Three times a day'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.4-daily') as string,
    value: 'Four times a day'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.night') as string,
    value: 'At night'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.morning') as string,
    value: 'In the morning'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.9') as string,
    value: '0900'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.12') as string,
    value: '1200'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.15') as string,
    value: '1500'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.18') as string,
    value: '1800'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.21') as string,
    value: '2100'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.hourly') as string,
    value: 'Hourly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.2-hourly') as string,
    value: 'Every 2 Hours'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.3-4-hourly') as string,
    value: 'Every 3-4 Hours'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.6-hourly') as string,
    value: 'Every 6 Hours'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.weekly') as string,
    value: 'Weekly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.fortnightly') as string,
    value: 'Fortnightly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.monthly') as string,
    value: 'Monthly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.3-monthly') as string,
    value: '3 Monthly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.6-monthly') as string,
    value: '6 Monthly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.yearly') as string,
    value: 'Yearly'
  }
];

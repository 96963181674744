import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "jammer p-14 bg-primary-500 rounded-xl text-white text-lg" }
const _hoisted_2 = { class: "max-w-5xl" }
const _hoisted_3 = { class: "text-2xl font-bold" }
const _hoisted_4 = { class: "mt-8 flex space-x-4" }
const _hoisted_5 = { class: "flex-grow space-x-4" }
const _hoisted_6 = { class: "flex-row-reverse space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseModal, { "vertical-align": "top" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.body.split('\n'), (line) => {
              return (_openBlock(), _createElementBlock("p", {
                key: line,
                class: "mt-6"
              }, _toDisplayString(line), 1))
            }), 128)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leftOptions, (option) => {
                  return (_openBlock(), _createBlock(_component_BaseButton, {
                    key: option.label ? option.label : option,
                    size: "large",
                    disabled: _ctx.disabled,
                    "left-icon": option.iconPosition === 'left' ? option.icon : '',
                    "right-icon": option.iconPosition === 'right' ? option.icon : '',
                    color: option.color,
                    onClick: ($event: any) => (_ctx.click(option))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.label ? option.label : option), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "left-icon", "right-icon", "color", "onClick"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rightOptions, (option) => {
                  return (_openBlock(), _createBlock(_component_BaseButton, {
                    key: option.label ? option.label : option,
                    size: "large",
                    disabled: _ctx.disabled,
                    "left-icon": option.iconPosition === 'left' ? option.icon : '',
                    "right-icon": option.iconPosition === 'right' ? option.icon : '',
                    color: option.color,
                    onClick: ($event: any) => (_ctx.click(option))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.label ? option.label : option), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "left-icon", "right-icon", "color", "onClick"]))
                }), 128))
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
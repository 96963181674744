
import { Options, Vue } from 'vue-class-component';
import { IOption } from '@/lib';
import BaseSegment from './BaseSegment.vue';

@Options({
  components: { BaseSegment },
  props: {
    items: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Number,
      default: 0
    }
  }
})
export default class BaseSegmentList extends Vue {
  items!: IOption[];
}

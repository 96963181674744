
import { Vue, Options } from 'vue-class-component';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseSelect } from '@/lib/components/Select';
import { BaseNumberInput } from '@/lib/components/Input';
import { RadioGroup } from '@/lib/components/Radio';
import { IPredefinedTerminologyList, IValidationError, IOption } from '@/lib';
import { CopdProgram, CopdProgramSetting, FilterTag, ICodeValueEntity, TagGroupName } from '@/models';
import { predefinedTerminologyListFactory } from '@/lib/helpers/terminology.helper';
import { CopdTerminologyService, CopdProgramService, FilterTagService } from '@/services/api';
import { FEATURES } from '@/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { useNotificationStore } from '@/stores/notification.store';

const roomAirCode = '722742002';

@Options({
  props: {
    currentSettings: {
      type: Object,
      default: () => ({})
    },
    programId: {
      type: String,
      required: true
    },
    onHomeMonitoring: {
      type: Object,
      default: null
    }
  },
  components: { BaseButton, BaseSelect, BaseIcon, BaseNumberInput, RadioGroup }
})

export default class PatientSettingsModal extends Vue {
  currentSettings!: CopdProgramSetting;
  copdProgramService = new CopdProgramService();
  programId!: string;
  request: CancelTokenSource | null = null;
  settings: CopdProgramSetting = { ...this.currentSettings };
  oxygenTypes: IPredefinedTerminologyList = predefinedTerminologyListFactory([]);
  errors: IValidationError | null = null;
  homeMonitoringOptions: Array<FilterTag> = [];
  selectedHomeMonitoringOption: string = this.onHomeMonitoring?.id || '';
  tagService = new FilterTagService();
  copdTerminologyService = new CopdTerminologyService();
  notificationStore = useNotificationStore();

  created() {
    if (this.tagsFeatureFlag) {
      this.fetchHomeMonitoringOptions();
    }
  }

  mounted() {
    this.fetchOxygenTypes();
  }

  get tagsFeatureFlag(): boolean {
    return isFeatureFlagEnabled(FEATURES.TAGS);
  }

  get validationErrors() {
    return this.errors?.errors;
  }

  get oxygenValue(): string {
    return this.settings.method_of_oxygen_delivery ? this.settings.method_of_oxygen_delivery.code : '';
  }

  get showFlowRate(): boolean {
    const oxygenMethod = this.oxygenTypes.find(this.settings.method_of_oxygen_delivery.code);
    return !!oxygenMethod && oxygenMethod.code !== roomAirCode;
  }

  get homeMonitoringValues(): Array<IOption> {
    return this.homeMonitoringOptions.map((option: FilterTag) => ({
      value: option.id,
      label: option.name
    }));
  }

  get homeMonitoringValidationError() {
    return this.validationErrors && this.validationErrors.home_monitoring && this.validationErrors.home_monitoring[0];
  }

  updateSettings(newVal: { [key: string]: ICodeValueEntity }) {
    this.settings = {
      ...this.settings,
      ...newVal
    };
    Object.keys(newVal).map((key) => this.clearError(`settings.${key}`));
  }

  clearError(key: string) {
    delete this.errors?.errors[key];
  }

  onOxygenTypeChange(event: string) {
    const newOxygenType = this.oxygenTypes.find(event);
    this.settings.method_of_oxygen_delivery = newOxygenType || this.settings.method_of_oxygen_delivery;

    if (!this.showFlowRate) {
      delete this.settings.flow_rate;
    }
  }

  updateHomeMonitoringOption(option: FilterTag['id']): void {
    this.selectedHomeMonitoringOption = option;
    this.clearError('home_monitoring');
  }

  async fetchOxygenTypes() {
    try {
      const response = await this.copdTerminologyService.getMethodOfOxygenDelivery();
      this.oxygenTypes = predefinedTerminologyListFactory(response.data);
    } catch (e) {
      if (e.response?.data) {
        if (e.response.status === 422) {
          this.errors = { errors: e.response.data.errors, message: e.response.data.message };
        } else {
          this.errors = {
            message: e.response.data.message,
            errors: {}
          };
        }
      }
    }
  }

  closePatientSettingsModal() {
    this.$emit('close');
  }

  async fetchHomeMonitoringOptions() {
    try {
      const response = await this.tagService.getTagsForGroupName(TagGroupName.HomeMonitoring);
      this.homeMonitoringOptions = response.sort((a: FilterTag, b: FilterTag): number => b.name.localeCompare(a.name));
    } catch (e: unknown) {
      if ((e as AxiosError).response?.status === 422) {
        this.fetchErrors = (e as AxiosError).response?.data?.errors;
      } else {
        this.fetchErrors = {};
      }
    }
  }

  async updatePatientSettings() {

    const copdProgramRequest: Partial<CopdProgram> = {};
    copdProgramRequest.settings = this.settings as CopdProgramSetting;

    if (this.tagsFeatureFlag) {
      copdProgramRequest.home_monitoring = this.selectedHomeMonitoringOption;
    }

    try {
      this.request = axios.CancelToken.source();

      await this.copdProgramService.update(
        this.programId,
        copdProgramRequest,
        { cancelToken: this.request.token }
      );

      this.request = null;
      this.errors = null;
      this.$emit('close', true);

      this.notificationStore.snackbar = {
        label: this.$t('custom.uhb.copd.patient-settings-updated')
      };
    } catch (e) {
      if (e.response && e.response.status === 422) {
        this.errors = { errors: e.response.data.errors, message: e.response.data.message };
      } else {
        this.errors = {
          message: e.response.data.message,
          errors: {}
        };
      }
    }
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-center rounded-lg shadow-lg", [_ctx.bgColorClass, _ctx.sizeClasses, _ctx.textColorClass]])
  }, [
    _createVNode(_component_BaseIcon, {
      name: _ctx.name,
      stroke: false,
      height: _ctx.iconSize,
      class: "fill-current"
    }, null, 8, ["name", "height"])
  ], 2))
}

import { Vue, Options } from 'vue-class-component';
import { Table } from '@/lib/components/DataTable';
import { BaseSelect, SelectInput } from '@/lib/components/Select';
import { YesNoAnswer } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { FileUpload } from '@/lib/components/Upload';
import { BaseTextarea } from '@/lib/components/Textarea';
import { BaseTextInput } from '@/lib/components/Input';
import { RadioJammerModal } from '@/lib/components/Modals';
import { RadioGroup } from '@/lib/components/Radio';
import { BaseDatePicker } from '@/lib/components/Date';
import { MedicationSearch } from '@/lib/components/Search';
import { ChecklistItem, CheckboxGroup } from '@/lib/components/Checkbox';
import { CardImage } from '@/lib/components/Image';

import { FormItem } from '@/models';

import SmartFormRepeater from './SmartFormRepeater.vue';
// @ts-ignore
import { defineAsyncComponent } from 'vue';
import {
  getAnswer
} from '@/helpers/smart-form.helper';
import { useSmartFormStore } from '@/stores/smartForm.store';

@Options({
  name: 'SmartFormItem', // Name is required for recursive components
  props: {
    item: {
      type: Object,
      required: true
    },

    patientId: {
      type: String,
      default: null
    },

    participantId: {
      type: String,
      default: null
    },

    formSubmissionId: {
      type: String,
      required: true
    },

    next: {
      type: Function,
      required: true
    },

    back: {
      type: Function,
      required: true
    },

    level: {
      type: Number,
      default: 0
    },

    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RadioGroup,
    RadioJammerModal,
    CheckboxGroup,
    ChecklistItem,
    BaseTextInput,
    BaseDatePicker,
    MedicationSearch,
    BaseIcon,
    BaseSelect,
    BaseTextarea,
    SelectInput,
    SmartFormRepeater,
    FileUpload,
    Table,
    YesNoAnswer,
    CardImage
  }
})
export default class SmartFormItem extends Vue {
  componentTypes: { [key: string]: string } = {
    text: 'BaseTextInput',
    checkbox: 'CheckboxGroup',
    'list-item': 'ChecklistItem',
    date: 'BaseDatePicker',
    table: 'Table',
    search: 'MedicationSearch',
    label: 'MkBadge',
    select: 'BaseSelect',
    textarea: 'BaseTextarea',
    radio: 'RadioGroup',
    'radio-jammer': 'RadioJammerModal',
    selectInput: 'SelectInput',
    repeater: 'SmartFormRepeater',
    file: 'FileUpload',
    'yes-no': 'YesNoAnswer',
    cardImage: 'CardImage',
    // clinicalBlock: 'SmartFormClinicalBlock',
    'openEHR-EHR-EVALUATION.family_history.v2': 'FamilyHistoryClinicalBlock',
    'openEHR-EHR-EVALUATION.tobacco_smoking_summary.v1': 'SmokingSummaryClinicBlock'
  };

  item!: FormItem;
  formSubmissionId!: string;
  patientId?: string;
  participantId?: string;
  validating = false;
  smartForm = useSmartFormStore();

  get componentType() {
    if (this.item.type === 'component') {
      return defineAsyncComponent(
        () =>
          import(
            /* webpackChunkName: "component-[request]" */
            `@/${this.item.component}.vue`
          )
      );
    }

    return this.componentTypes[this.item.type];
  }

  get isPrefilled() {
    return this.smartForm.getItemIsPrefilled(this.formSubmissionId, this.item.id);
  }

  get answer() {
    return getAnswer(this.smartForm, this.formSubmissionId, this.item);
  }

  set answer(value) {
    this.smartForm.answers = {
      ...this.smartForm.answers,
      [this.formSubmissionId]: {
        ...this.smartForm.answers[this.formSubmissionId],
        [this.item.id]: {
          value,
          is_prefilled: false
        }
      }
    };

    this.smartForm.dirty = {
      ...this.smartForm.dirty,
      [this.formSubmissionId]: [...new Set([...(this.smartForm.dirty[this.formSubmissionId] || []), this.item.id])]
    };
  }

  get conditionsMet() {
    return this.smartForm.getItemConditionsMet(this.formSubmissionId, this.item.conditions);
  }

  get error() {
    return this.smartForm.getError(this.formSubmissionId, this.item.id);
  }

  get hyphenatedLabel() {
    const label = this.item?.label || this.item.props?.label;
    return label?.toLowerCase().replaceAll(' ', '-') ?? '';
  }

  mounted() {
    this.$watch('conditionsMet', this.clearAnswerWhenConditionsNotMet);
  }

  beforeUnmount() {
    this.clearAnswerWhenConditionsNotMet();
  }

  clearAnswerWhenConditionsNotMet() {
    if (!this.conditionsMet && typeof this.answer !== 'undefined' && this.answer !== null) {
      this.answer = null;
      this.saveAnswers();
    }
  }

  async saveAnswers() {
    this.validating = true;
    await this.smartForm.saveAnswers({
      patientId: this.patientId,
      participantId: this.participantId,
      formSubmissionId: this.formSubmissionId,
      validateItems: this.item.validateItems,
      useDebounce: true
    });
    this.validating = false;
  }

  gridClasses(item: {
    layout: string;
    cols: number;
    justifyItems: 'auto' | 'start' | 'end' | 'center' | 'stretch';
    alignItems: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  }): string {
    if (item.layout === 'grid') {
      let layoutClasses = `mt-6 grid gap-4 grid-cols-${item.cols || 1}`;
      if (item.justifyItems) {
        layoutClasses += ` justify-items-${item.justifyItems}`;
      }
      if (item.alignItems) {
        layoutClasses += ` items-${item.alignItems}`;
      }
      return layoutClasses;
    }

    return 'space-y-12';
  }
}

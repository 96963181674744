
import { Vue, Options } from 'vue-class-component';
import GroupTreeSegment from './GroupTreeSegment.vue';
import { IGroupSegment } from '@/lib';

@Options({
  name: 'GroupTree', // Name is required for recursive components
  components: {
    GroupTreeSegment
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    depth: {
      type: Number,
      default: 0
    }
  }
})
export default class GroupTree extends Vue {
  item!: IGroupSegment;
  depth!: number;
}

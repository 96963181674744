import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { MPIPatient } from '@/models';
import apiClient from '@/services/api/client/apiClient';
import { GenericService } from '@/services/api/generic.service';

export class OrganisationExternalPatientService extends GenericService<MPIPatient> {
  endpoint = '';

  constructor(organisationId: string) {
    super();
    this.endpoint = `v1/organisations/${organisationId}/external-patients`;
  }

  async attach(
    patientId: string,
    changes: Partial<MPIPatient>,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<MPIPatient>> {
    return await apiClient.patch(`${this.endpoint}/${patientId}/attach-patient`, changes, params);
  }

  async search(params: AxiosRequestConfig = {}): Promise<Array<MPIPatient>> {
    return (await apiClient.get(`${this.endpoint}/search-patient`, params)).data;
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-xl p-8 flex flex-col" }
const _hoisted_2 = { class: "flex flex-row items-start space-x-4" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "text-xl font-semibold text-gray-900 self-center" }
const _hoisted_5 = { class: "mt-4 text-base font-regular leading-normal text-gray-600" }
const _hoisted_6 = { class: "mt-8 w-full justify-start flex flex-col space-y-4" }
const _hoisted_7 = { class: "mt-8 flex flex-end justify-end space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseIcon, {
          name: "crossroad",
          stroke: false,
          height: "2rem"
        })
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.copd.assign-to-org')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('custom.uhb.copd.assign-to-org-desc')), 1),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_BaseSelect, {
        modelValue: _ctx.organisationalUnitIdToAssign,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.organisationalUnitIdToAssign) = $event)),
        error: 
          _ctx.errors &&
            _ctx.errors.errors &&
            _ctx.errors.errors.assigned_organisational_unit_id &&
            _ctx.errors.errors.assigned_organisational_unit_id[0]
        ,
        label: _ctx.$t('custom.uhb.copd.assign-to-org'),
        options: _ctx.organisationOptions,
        placeholder: _ctx.$t('custom.uhb.copd.select-org'),
        "data-dusk": "assign-to-organisation-options"
      }, null, 8, ["modelValue", "error", "label", "options", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "ghost",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "primary",
        disabled: !_ctx.organisationalUnitIdToAssign,
        "data-dusk": "assign-to-organisation-update",
        onClick: _withModifiers(_ctx.assignToOrganisation, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.update')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}

import { Vue, Options } from 'vue-class-component';
import { i18n, supportedLocales } from '@/i18n/i18n';
import { v4 } from 'uuid';
import { DatePicker } from 'v-calendar';
import { BaseTextInput } from '@/lib/components/Input';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { localisedMask } from '@/helpers/date.helper';
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export interface IMasks {
  input: string;
}

@Options({
  components: {
    BaseTextInput,
    DatePicker
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'date',
      validators: (value: string) => ['date', 'dateTime'].includes(value)
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    iconPosition: {
      type: String,
      default: 'right'
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
      default: false
    },
    /**
     * When providing a custom mask please provide a mask per locale
     * Add the custom mask to all supportedLocales within the i18n file.
     */
    masks: {
      type: Object,
      default: null
    },
    /**
     * Provide the locale if used within a form schema
     */
    locale: {
      type: String,
      default: null
    }
  }
})
export default class BaseDatePicker extends Vue {
  id: string = v4();
  modelValue!: string;
  maxDate!: string;
  mode!: 'date' | 'dateTime';
  minDate!: string;
  masks!: IMasks;
  locale!: string;

  /**
   * The format that is emitted by the component
   * */
  get emittedFormat() {
    if (this.mode === 'dateTime') {
      return 'YYYY-MM-DD HH:mm';
    }
    return 'YYYY-MM-DD';
  }

  get is24hr() {
    const supportedLocale = supportedLocales.find(
      (localeObj) => localeObj.code === this.localeToValidate
    );
    return supportedLocale?.is24hr ?? true;
  }

  get minimumDate() {
    return this.minDate ? dayjs(this.minDate).format() : '';
  }

  get maximumDate() {
    return this.maxDate ? dayjs(this.maxDate).format() : '';
  }

  // Date format for v-calendar's model (DatePicker)
  get date() {
    return this.modelValue ? dayjs(this.modelValue).format() : '';
  }

  set date(value) {
    let emitValue = '';
    if (value && dayjs(value).isValid()) {
      const dayjsDate = dayjs(value);
      if (this.mode === 'dateTime') {
        emitValue = dayjsDate.toISOString();
      } else {
        emitValue = dayjs(value).format(this.emittedFormat);
      }
    }

    this.emitDate(emitValue);
  }

  // Localised date for text input
  get localisedDate() {
    return this.date
      ? dayjs(this.date, this.emittedFormat).local().format(this.localisedMask)
      : '';
  }

  get localisedMask() {
    const defaultInputMask = 'DD/MM/YYYY';
    if (this.masks) {
      return this.masks.input ? this.masks.input : defaultInputMask;
    }
    return localisedMask(this.localeToValidate, this.mode);
  }

  get localeToValidate(): string {
    return this.locale || i18n.global.locale.value;
  }

  get datePickerRef() {
    return this.$refs.datePicker;
  }

  get datePickerMasks() {
    return {
      masks: {
        input: this.localisedMask
      }
    };
  }

  mounted() {
    window.addEventListener('wheel', () => this.clickAway());
    window.addEventListener('resize', () => this.clickAway());
  }

  unmounted() {
    window.removeEventListener('wheel', () => this.clickAway());
    window.removeEventListener('resize', () => this.clickAway());
  }

  async onValueChange(value: string | number | Date) {
    let emitValue = '';
    await this.showCalendar();

    // User can manually input the date.
    if (typeof value === 'string') {
      if (
        value.length &&
        dayjs(value, this.localisedMask).isValid()
      ) {
        emitValue = dayjs(value, this.localisedMask).format(
          this.emittedFormat
        );

        // We only trigger calendar.move when calendar is displayed and the input date is a valid date.
        if (this.datePickerRef) {
          try {
            await this.datePickerRef.move({
              month: dayjs(value, this.localisedMask).month() + 1,
              year: dayjs(value, this.localisedMask).year()
            });
          } catch (exception) {
            // If input date is not at the valid date range (minDate or maxDate), the calendar will switch to current date.
            await this.datePickerRef.move(new Date());
          }
        }
      }
    }
    await this.datePickerRef.hidePopover();
    this.emitDate(emitValue);
  }

  emitDate(emitValue: string) {
    if (emitValue && dayjs(emitValue).isValid() || emitValue === '') {
      this.$emit('update:modelValue', emitValue);
      this.$emit('change', emitValue);
    }
  }

  async onValueClear() {
    this.$emit('update:modelValue', '');
    await this.clickAway();
  }

  async showCalendar() {
    const datePicker = this.datePickerRef;
    if (datePicker) {
      await datePicker.showPopover();
    }
  }

  async clickAway() {
    const datePicker = this.datePickerRef;
    if (datePicker) {
      await datePicker.hidePopover();
    }
  }
}

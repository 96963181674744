import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-10" }
const _hoisted_2 = {
  tabindex: "0",
  class: "focus:outline-none text-xl font-bold text-gray-900"
}
const _hoisted_3 = { class: "mt-8 grid grid-cols-2 gap-x-6 gap-y-8" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-row items-center justify-between border rounded py-4 px-5 h-12 self-end"
}
const _hoisted_5 = { class: "text-base text-gray-900 font-medium" }
const _hoisted_6 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SearchInput, {
        modelValue: _ctx.search,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('search', $event)))
        ],
        class: "col-span-2",
        icon: "search",
        label: _ctx.$t('custom.uhb.diagnosis.condition'),
        placeholder: _ctx.$t('custom.uhb.diagnosis.search-condition'),
        suggestions: _ctx.suggestions,
        disabled: !!_ctx.diagnosis,
        onSubmit: _ctx.onSearch,
        onScrolled: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('loadNext')))
      }, null, 8, ["modelValue", "label", "placeholder", "suggestions", "disabled", "onSubmit"]),
      _createVNode(_component_BaseSelect, {
        modelValue: _ctx.locationId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.locationId) = $event)),
        placeholder: _ctx.$t('platform.common.enter-value'),
        class: _normalizeClass({ 'col-span-2': !_ctx.isPresent }),
        options: _ctx.locationOptions,
        label: _ctx.$t('platform.patient.location')
      }, null, 8, ["modelValue", "placeholder", "class", "options", "label"]),
      (_ctx.isPresent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.differential')), 1),
            _createVNode(_component_BaseSwitch, {
              modelValue: _ctx.differential,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.differential) = $event))
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isPresent)
        ? (_openBlock(), _createBlock(_component_BaseSelect, {
            key: 1,
            modelValue: _ctx.stageId,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.stageId) = $event)),
            placeholder: _ctx.$t('platform.common.enter-value'),
            options: _ctx.stageOptions,
            label: _ctx.$t('custom.uhb.diagnosis.stage'),
            disabled: !_ctx.condition
          }, null, 8, ["modelValue", "placeholder", "options", "label", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.isPresent)
        ? (_openBlock(), _createBlock(_component_BaseSelect, {
            key: 2,
            modelValue: _ctx.statusId,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.statusId) = $event)),
            placeholder: _ctx.$t('platform.common.enter-value'),
            options: _ctx.statusOptions,
            label: _ctx.$t('platform.common.status')
          }, null, 8, ["modelValue", "placeholder", "options", "label"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_BaseButton, {
        size: "large",
        disabled: _ctx.disabled,
        onClick: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.saveButtonText), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "ghost",
        class: "ml-4",
        onClick: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full max-w-xl p-8 flex flex-col space-y-4" }
const _hoisted_2 = { class: "flex flex-row items-start space-x-2" }
const _hoisted_3 = { class: "flex -ml-3" }
const _hoisted_4 = { class: "text-xl font-semibold text-gray-900 self-center" }
const _hoisted_5 = { class: "text-base font-regular leading-normal text-gray-600 pb-2" }
const _hoisted_6 = { class: "w-full justify-start flex flex-col space-y-4" }
const _hoisted_7 = { class: "flex w-full justify-between space-x-2" }
const _hoisted_8 = {
  key: 0,
  class: "flex justify-between p-4 mt-3 text-md font-semibold leading-none text-white bg-danger-400 border-danger-600 rounded-lg"
}
const _hoisted_9 = { class: "flex w-3/4" }
const _hoisted_10 = { class: "flex flex-end justify-end space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseDatePicker = _resolveComponent("BaseDatePicker")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseIcon, {
          name: "outcome",
          stroke: false,
          height: "2.5rem"
        })
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.copd.schedule-next-consult')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('custom.uhb.copd.schedule-next-consult-desc')), 1),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_BaseDatePicker, {
          modelValue: _ctx.consult.scheduled_at,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.consult.scheduled_at) = $event)),
          error: _ctx.errors && _ctx.errors.errors.scheduled_at && _ctx.errors.errors.scheduled_at[0],
          label: _ctx.$t('custom.uhb.copd.next-consult-at'),
          "min-date": _ctx.today,
          placeholder: _ctx.$t('custom.uhb.copd.select-next-consult-at'),
          "data-dusk": "set-next-consult-date-picker"
        }, null, 8, ["modelValue", "error", "label", "min-date", "placeholder"]),
        _createVNode(_component_BaseSelect, {
          modelValue: _ctx.consult.type,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.consult.type) = $event)),
          class: "h-full flex-1",
          error: _ctx.errors && _ctx.errors.errors.type && _ctx.errors.errors.type[0],
          label: _ctx.$t('custom.uhb.copd.next-consult-type'),
          options: _ctx.consultTypeList,
          placeholder: _ctx.$t('custom.uhb.copd.select-next-consult-type'),
          "data-dusk": "set-next-consult-consult-type"
        }, null, 8, ["modelValue", "error", "label", "options", "placeholder"])
      ]),
      _createVNode(_component_BaseCheckbox, {
        modelValue: _ctx.patientScheduledExternally,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.patientScheduledExternally) = $event)),
        label: _ctx.$t('custom.uhb.copd.patient-scheduled-externally'),
        shadow: true,
        border: true,
        "data-dusk": "set-next-consult-patient-scheduled-externally"
      }, null, 8, ["modelValue", "label"]),
      (_ctx.errors && _ctx.errors.message)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.errors.message), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_BaseButton, {
          class: "mt-8",
          size: "large",
          color: "ghost",
          onClick: _ctx.closeModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_BaseButton, {
          class: "mt-8",
          size: "large",
          color: "primary",
          disabled: !_ctx.patientScheduledExternally || !_ctx.ensureScheduledAtIsOnOrAfterToday,
          loading: _ctx.submitting,
          onClick: _ctx.setNextConsult
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.update')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "onClick"])
      ])
    ])
  ]))
}
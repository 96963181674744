import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between space-x-8" }
const _hoisted_2 = { class: "flex flex-col flex-shrink" }
const _hoisted_3 = { class: "text-lg font-bold text-gray-900" }
const _hoisted_4 = { class: "text-gray-600 mt-4 leading-5" }
const _hoisted_5 = { class: "flex flex-row items-center justify-between mt-8 bg-gray-50 h-14 pl-6 pr-3 border rounded border-gray-300" }
const _hoisted_6 = { class: "space-x-3" }
const _hoisted_7 = { class: "text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_component_BaseCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('platform.device.key')), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('platform.device.key-instructions')), 1)
        ]),
        _createVNode(_component_BaseButton, {
          "data-dusk": "download-installer-button",
          color: "secondary",
          class: "flex flex-shrink-0",
          "left-icon": "add",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('download')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.device.download')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.deviceKey), 1),
          (_ctx.deviceKeyIsReady)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "border-none outline-none text-primary-500 font-bold text-xs",
                "data-dusk": "copy-key-button",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCopy && _ctx.onCopy(...args)))
              }, _toDisplayString(_ctx.$t('platform.common.copy').toUpperCase()), 1))
            : _createCommentVNode("", true)
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
          "data-dusk": "regenerate-key-button",
          color: "ghost",
          size: "small",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deviceKeyModal = true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.regenerate')), 1)
          ]),
          _: 1
        })), [
          [_directive_allow, 'device:update']
        ])
      ]),
      (_ctx.deviceKeyModal)
        ? (_openBlock(), _createBlock(_component_ActionModal, {
            key: 0,
            "data-dusk": "device-key-modal",
            title: _ctx.$t('platform.device.regenerate-key'),
            body: _ctx.$t('platform.device.regenerate-key-message'),
            actions: _ctx.deviceKeyModalActions,
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deviceKeyModal = false))
          }, null, 8, ["title", "body", "actions"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
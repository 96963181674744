import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between leading-tight mb-3"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-gray-500 font-medium text-xs"
}
const _hoisted_6 = {
  key: 1,
  class: "text-xs font-semibold text-primary-500"
}
const _hoisted_7 = {
  key: 0,
  class: "inline-block align-top ml-2"
}
const _hoisted_8 = { class: "h-4 w-4 bg-primary-500 rounded flex justify-center" }
const _hoisted_9 = { class: "text-white bg-primary-500 text-2xs font-semibold self-center" }
const _hoisted_10 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_BasePopover = _resolveComponent("BasePopover")!

  return (_openBlock(), _createBlock(_component_BasePopover, {
    ref: "selectCheckboxPopover",
    width: _ctx.isFilter ? 'unset; min-width:100%': '100%',
    "close-on-selection": false,
    class: _normalizeClass(["inline-flex flex-col", {'w-full':!_ctx.isFilter}])
  }, {
    menu: _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createBlock(_component_BaseCheckbox, {
            key: _ctx.isFilter ? option.id : option.value,
            ref_for: true,
            ref: "baseCheckbox",
            modelValue: _ctx.checkboxModel,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkboxModel) = $event)),
            "data-dusk": "base-select-checkbox-input",
            label: option.label,
            value: _ctx.isFilter ? option.id : option.value,
            border: false,
            shadow: false,
            "hover-bg-class": "bg-gray-100",
            "onUpdate:listObject": _ctx.updateModelArray
          }, null, 8, ["modelValue", "label", "value", "onUpdate:listObject"]))
        }), 128))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ((_ctx.label && _ctx.label.length) || _ctx.$slots.action)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", {
                for: _ctx.id,
                class: "text-base font-medium leading-tight"
              }, _toDisplayString(_ctx.label), 9, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.isOptional)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
                  : _createCommentVNode("", true),
                (_ctx.$slots.action)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "action")
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          tabindex: "0",
          class: _normalizeClass(["flex cursor-pointer items-center bg-white border h-12", {
          'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
            _ctx.error,
          'outline-primary-500': _ctx.focused,
          'rounded-md': _ctx.isFilter,
          'rounded w-full': !_ctx.isFilter,
        }]),
          onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.openPopup && _ctx.openPopup(...args)), ["enter","space"]))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["ml-5 appearance-none bg-white focus:outline-none", {
            'text-gray-400': _ctx.modelValue.length === 0,
            'leading-none': _ctx.lineHeight === 1,
            'leading-tight': _ctx.lineHeight > 1,
            'pr-4 w-full': !_ctx.isFilter,
            'text-sm font-medium text-gray-800 whitespace-no-wrap': _ctx.isFilter,
          }])
          }, _toDisplayString(_ctx.placeholderText), 3),
          (_ctx.isFilter && _ctx.badgeNumber > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.badgeNumber), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_BaseIcon, {
            height: "1.25em",
            class: "text-gray-500 rounded-full p-px stroke-1.5 ml-3 mr-3",
            name: "chevron-down"
          })
        ], 34),
        _createVNode(_component_InputTextHelper, {
          error: _ctx.error,
          description: _ctx.description
        }, null, 8, ["error", "description"])
      ])
    ]),
    _: 3
  }, 8, ["width", "class"]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "absolute inset-0 flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["relative btn focus:outline-none", [
      _ctx.disabled ? 'btn-disabled' : `btn-${_ctx.color}`,
      _ctx.block ? 'btn-block' : '',
      `btn-${_ctx.size}`,
      _ctx.loading ? 'cursor-wait' : ''
    ]]),
    disabled: _ctx.disabled || _ctx.loading
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["flex flex-row items-center", { 'opacity-0': _ctx.loading }])
    }, [
      (_ctx.leftIcon && _ctx.leftIcon.length)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            name: _ctx.leftIcon,
            position: "left",
            class: "stroke-1.5"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.rightIcon && _ctx.rightIcon.length)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 1,
            class: "order-1 stroke-1.5",
            name: _ctx.rightIcon,
            position: "right"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_LoadingIcon)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
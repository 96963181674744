import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ease-linear transition-all duration-300 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.outCustomClass)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(['bg-' + _ctx.color + _ctx.innerCustomClass, "absolute transition-width duration-300 z-40 top-0 left-0 opacity-75"]),
            style: _normalizeStyle('width:' + _ctx.value + '%; height: ' + _ctx.loadingBarHeight)
          }, null, 6)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
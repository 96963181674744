
import { Vue, Options } from 'vue-class-component';
import SmartFormSummaryItem from './SmartFormSummaryItem.vue';
import { FormSection } from '@/models';
import { i18n } from '@/i18n/i18n';
import { useSmartFormStore } from '@/stores/smartForm.store';

@Options({
  name: 'SmartFormSummarySection', // Name is required for recursive components
  props: {
    section: {
      type: Object,
      required: true
    },

    patientId: {
      type: String,
      default: null
    },

    participantId: {
      type: String,
      default: null
    },

    formSubmissionId: {
      type: String,
      required: true
    },

    level: {
      type: Number,
      default: 0
    },

    locale: {
      type: String,
      default: i18n.global.locale.value
    }
  },
  components: {
    SmartFormSummaryItem
  }
})
export default class SmartFormSummarySection extends Vue {
  section!: FormSection;
  formSubmissionId!: string;
  smartForm = useSmartFormStore();

  get conditionsMet() {
    return this.smartForm.getItemConditionsMet(this.formSubmissionId, this.section.conditions);
  }
}

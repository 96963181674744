import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3303656"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "bg-white border border-gray-200 rounded-t-lg p-6 shadow" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "text-gray-500" }
const _hoisted_5 = { class: "bg-white border border-gray-200 rounded-b-lg px-6 shadow" }
const _hoisted_6 = { class: "flex justify-between my-6" }
const _hoisted_7 = { class: "flex-col" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = {
  key: 0,
  class: "text-success-800"
}
const _hoisted_10 = { class: "items-center" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "text-gray-900 text-base mr-2" }
const _hoisted_13 = { class: "text-gray-400" }
const _hoisted_14 = {
  key: 1,
  class: "text-danger-700"
}
const _hoisted_15 = { class: "items-center" }
const _hoisted_16 = { class: "flex" }
const _hoisted_17 = { class: "text-base mr-2" }
const _hoisted_18 = { class: "text-gray-400" }
const _hoisted_19 = {
  key: 2,
  class: "text-primary-500 items-center"
}
const _hoisted_20 = { class: "items-center" }
const _hoisted_21 = { class: "flex" }
const _hoisted_22 = { class: "text-gray-900 text-base mr-2" }
const _hoisted_23 = { class: "text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_LoadingBar = _resolveComponent("LoadingBar")!
  const _component_file_pond = _resolveComponent("file-pond")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pr-1", _ctx.error ? 'border border-danger-700' : ''])
  }, [
    (_ctx.uploadingFiles.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('platform.common.file-upload.files', [_ctx.uploadingFiles.length])), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.sizeLimitLabel), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadingFiles, (file, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass([_ctx.uploadingFiles.length !== index + 1 ? 'border-b-2' : ''])
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      (file.status === 5 || !file.hasOwnProperty('status'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_BaseIcon, {
                                name: "check",
                                class: "mr-2"
                              }),
                              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.file-upload.uploaded')), 1)
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, _toDisplayString(file.name) + _toDisplayString(file.extension ? '.' + file.extension : '') + " - ", 1),
                              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.formatBytes(file.size)), 1)
                            ])
                          ]))
                        : (file.error)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createVNode(_component_BaseIcon, {
                                  name: "warning",
                                  class: "mr-2",
                                  height: "1rem"
                                }),
                                _createTextVNode(_toDisplayString(file.error), 1)
                              ]),
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, _toDisplayString(file.name) + " - ", 1),
                                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.formatBytes(file.size)), 1)
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _createVNode(_component_BaseIcon, {
                                  name: "loader",
                                  class: "mr-2"
                                }),
                                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.file-upload.file-processing')) + " - " + _toDisplayString(_ctx.getPercent(file.progress)) + "% ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("div", _hoisted_22, _toDisplayString(file.name) + " - ", 1),
                                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.formatBytes(file.size)), 1)
                              ])
                            ]))
                    ])
                  ]),
                  (file.status === 3)
                    ? (_openBlock(), _createBlock(_component_BaseButton, {
                        key: 0,
                        color: "ghost",
                        onClick: ($event: any) => (_ctx.cancelFile(file))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.file-upload.button-abort-item-processing')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (file.status === 5 || !file.hasOwnProperty('status'))
                    ? (_openBlock(), _createBlock(_component_BaseButton, {
                        key: 1,
                        color: "ghost",
                        onClick: ($event: any) => (_ctx.removeFile(file))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.file-upload.button-remove-item')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (file.error)
                    ? (_openBlock(), _createBlock(_component_BaseButton, {
                        key: 2,
                        color: "ghost",
                        onClick: ($event: any) => (_ctx.dismissFile(file))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.dismiss')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                (file.status === 3)
                  ? (_openBlock(), _createBlock(_component_LoadingBar, {
                      key: 0,
                      class: "mb-4 -mt-2",
                      value: file.progress * 100,
                      "loading-bar-height": "8px",
                      color: "success-500",
                      "out-custom-class": "h-5 relative w-full rounded-full overflow-hidden bg-primary-50",
                      "inner-custom-class": " m-1.5 rounded"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_file_pond, {
      ref: "filePond",
      name: "file",
      "label-idle": _ctx.labelIdle,
      "label-invalid-field": _ctx.$t('platform.common.file-upload.invalid-field'),
      "label-file-waiting-for-size": _ctx.$t('platform.common.file-upload.file-waiting-for-size'),
      "label-file-size-not-available": _ctx.$t('platform.common.file-upload.file-size-not-available'),
      "label-file-loading": _ctx.$t('platform.common.file-upload.file-loading'),
      "label-file-load-error": _ctx.$t('platform.common.file-upload.file-load-error'),
      "label-file-processing": _ctx.$t('platform.common.file-upload.file-processing'),
      "label-file-processing-complete": _ctx.$t('platform.common.file-upload.file-processing-complete'),
      "label-file-processing-aborted": _ctx.$t('platform.common.file-upload.file-processing-aborted'),
      "label-file-processing-error": _ctx.$t('platform.common.file-upload.file-processing-error'),
      "label-file-processing-revert-error": _ctx.$t('platform.common.file-upload.file-processing-revert-error'),
      "label-file-remove-error": _ctx.$t('platform.common.file-upload.file-remove-error'),
      "label-tap-to-cancel": _ctx.$t('platform.common.file-upload.tap-to-cancel'),
      "label-tap-to-retry": _ctx.$t('platform.common.file-upload.tap-to-retry'),
      "label-tap-to-undo": _ctx.$t('platform.common.file-upload.tap-to-undo'),
      "label-button-remove-item": _ctx.$t('platform.common.file-upload.button-remove-item'),
      "label-button-abort-item-load": _ctx.$t('platform.common.file-upload.button-abort-item-load'),
      "label-button-retry-item-load": _ctx.$t('platform.common.file-upload.button-retry-item-load'),
      "label-button-abort-item-processing": _ctx.$t('platform.common.file-upload.button-abort-item-processing'),
      "label-button-undo-item-processing": _ctx.$t('platform.common.file-upload.button-undo-item-processing'),
      "label-button-retry-item-processing": _ctx.$t('platform.common.file-upload.button-retry-item-processing'),
      "label-button-process-item": _ctx.$t('platform.common.file-upload.button-process-item'),
      "label-max-file-size-exceeded": _ctx.$t('platform.common.file-upload.max-file-size-exceeded'),
      "label-max-file-size": _ctx.$t('platform.common.file-upload.max-file-size'),
      "label-max-total-file-size": _ctx.$t('platform.common.file-upload.max-total-file-size'),
      "label-file-type-not-allowed": _ctx.$t('platform.common.file-upload.file-type-not-allowed'),
      "file-validate-type-label-expected-types": _ctx.$t('platform.common.file-upload.file-validate-type-label-expected-types'),
      "image-validate-size-label-format-error": _ctx.$t('platform.common.file-upload.image-validate-size-label-format-error'),
      "image-validate-size-label-image-size-too-small": _ctx.$t('platform.common.file-upload.image-validate-size-label-image-size-too-small'),
      "image-validate-size-label-image-size-too-big": _ctx.$t('platform.common.file-upload.image-validate-size-label-image-size-too-big'),
      "image-validate-size-label-expected-min-size": _ctx.$t('platform.common.file-upload.image-validate-size-label-expected-min-size'),
      "image-validate-size-label-expected-max-size": _ctx.$t('platform.common.file-upload.image-validate-size-label-expected-max-size'),
      "image-validate-size-label-image-resolution-too-low": _ctx.$t('platform.common.file-upload.image-validate-size-label-image-resolution-too-low'),
      "image-validate-size-label-image-resolution-too-high": _ctx.$t('platform.common.file-upload.image-validate-size-label-image-resolution-too-high'),
      "image-validate-size-label-expected-min-resolution": _ctx.$t('platform.common.file-upload.image-validate-size-label-expected-min-resolution'),
      "image-validate-size-label-expected-max-resolution": _ctx.$t('platform.common.file-upload.image-validate-size-label-expected-max-resolution'),
      class: "mt-4 border-dashed border border-gray-500 rounded",
      "allow-file-type-validation": _ctx.allowFileTypeValidation,
      "accepted-file-types": _ctx.extension,
      server: _ctx.server,
      "allow-multiple": "true",
      files: _ctx.files,
      "max-file-size": _ctx.fileSizeLimit,
      onAddfile: _ctx.handleAddFile,
      onProcessfile: _ctx.handleProcessFile,
      onProcessfilestart: _ctx.handleProcessFileStart,
      onProcessfileprogress: _ctx.handleProcessFileProgress,
      onRemovefile: _ctx.handleRemoveFile,
      onProcessfiles: _ctx.save,
      onProcessfileabort: _ctx.handleAbort
    }, null, 8, ["label-idle", "label-invalid-field", "label-file-waiting-for-size", "label-file-size-not-available", "label-file-loading", "label-file-load-error", "label-file-processing", "label-file-processing-complete", "label-file-processing-aborted", "label-file-processing-error", "label-file-processing-revert-error", "label-file-remove-error", "label-tap-to-cancel", "label-tap-to-retry", "label-tap-to-undo", "label-button-remove-item", "label-button-abort-item-load", "label-button-retry-item-load", "label-button-abort-item-processing", "label-button-undo-item-processing", "label-button-retry-item-processing", "label-button-process-item", "label-max-file-size-exceeded", "label-max-file-size", "label-max-total-file-size", "label-file-type-not-allowed", "file-validate-type-label-expected-types", "image-validate-size-label-format-error", "image-validate-size-label-image-size-too-small", "image-validate-size-label-image-size-too-big", "image-validate-size-label-expected-min-size", "image-validate-size-label-expected-max-size", "image-validate-size-label-image-resolution-too-low", "image-validate-size-label-image-resolution-too-high", "image-validate-size-label-expected-min-resolution", "image-validate-size-label-expected-max-resolution", "allow-file-type-validation", "accepted-file-types", "server", "files", "max-file-size", "onAddfile", "onProcessfile", "onProcessfilestart", "onProcessfileprogress", "onRemovefile", "onProcessfiles", "onProcessfileabort"])
  ], 2))
}
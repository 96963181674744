import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pl-2 text-sm leading-tight font-regular text-gray-600" }
const _hoisted_2 = { class: "px-2 text-sm font-medium leading-tight text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("dl", {
    class: _normalizeClass(["flex justify-between items-center", { 'bg-alert-200 rounded py-1': _ctx.alert }])
  }, [
    _createElementVNode("dt", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("dd", _hoisted_2, _toDisplayString(_ctx.value), 1)
  ], 2))
}
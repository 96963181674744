
import { Vue, Options } from 'vue-class-component';
import { IGalleryImage } from '@/lib';

@Options({
  props: {
    images: {
      type: Array,
      default: () => [],
      validator: (value) => !value.find((image: IGalleryImage) => !image.id || !image.thumbnailUri)
    }
  }
})
export default class OverlapImage extends Vue {
  images!: IGalleryImage[];

  setBackgroundImage(image: string) {
    return { backgroundImage: `url(${image})` };
  }

  setOverlap(index: number) {
    return { left: `${index * -10}px` };
  }
}

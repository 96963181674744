import { GenericService } from './generic.service';
import { Role, User, PaginatedResponse } from '@/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class OrganisationRoleService extends GenericService<Role> {
  constructor(organisationId: string) {
    super();
    this.endpoint = `v1/organisations/${organisationId}/roles`;
  }

  async setPermissions(roleId: number, permissions: Array<string>, params: AxiosRequestConfig = {}): Promise<Role> {
    return await apiClient.put(`${this.endpoint}/${roleId}/permissions`, { permissions }, params);
  }

  async getAssignments(
    roleId: number,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<Array<User>> | PaginatedResponse<Array<User>>> {
    return await apiClient.get(`${this.endpoint}/${roleId}/users`, params);
  }
}


import { Vue, Options } from 'vue-class-component';
import uniq from 'lodash-es/uniq';
import * as helper from '@/lib/helpers/slider.helpers';
import { BaseSlider } from '@/lib/components/Slider';

@Options({
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    enableShortcuts: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary-500'
    },
    annotated: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: 'regular',
      validator: (value: string) => ['small', 'regular'].includes(value)
    }
  },
  components: { BaseSlider }
})
export default class SlicesSlider extends Vue {
  max!: number;
  annotated!: Array<number>;

  get uniq(): Array<number> {
    return uniq(this.annotated);
  }

  mounted() {
    this.$watch(
      'annotated',
      () => {
        if (this.isInvalid(this.annotated)) {
          throw new Error('The array should only contains numbers.');
        }
      },
      { deep: true }
    );
  }

  isInvalid(annotated: Array<number>) {
    return annotated.filter((el) => isNaN(+el) || !el.toString().length).length;
  }

  getPercent(value: number) {
    return helper.getPercent(value, 0, this.max);
  }

  onInput(value: number) {
    this.$emit('update:modelValue', value);
    this.$emit('change', value);
  }
}

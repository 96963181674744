
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  props: {
    /**
     * A vue-Router object or string specifying the URL to navigate to
     */
    to: {
      type: [String, Object],
      default: null
    },
    rightIcon: {
      type: String,
      default: ''
    },
    leftIcon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary',
      validator: (value) =>
        ['primary', 'secondary', 'success', 'alert', 'danger', 'ghost', 'muted', 'clear'].includes(value)
    },
    size: {
      type: String,
      default: 'regular',
      validator: (value) => ['small', 'regular', 'large', 'xl'].includes(value)
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseIcon
  }
})
export default class ButtonLink extends Vue {}

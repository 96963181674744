import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = ["data-dusk", "onClick", "onMouseover"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_DataTableHeading = _resolveComponent("DataTableHeading")!
  const _component_DataTableCell = _resolveComponent("DataTableCell")!
  const _component_DataTableLayout = _resolveComponent("DataTableLayout")!

  return (_openBlock(), _createBlock(_component_DataTableLayout, {
    class: _normalizeClass({ 'opacity-50 pointer-events-none': _ctx.loading && _ctx.rows.length > 0 })
  }, {
    default: _withCtx(() => [
      (_ctx.showTableHeader)
        ? (_openBlock(), _createElementBlock("thead", _hoisted_1, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, i) => {
                return (_openBlock(), _createBlock(_component_DataTableHeading, {
                  key: column.name,
                  "data-dusk": `table-th-${i}`,
                  "show-sort-button": column.sortable,
                  "column-name": column.name,
                  "is-active": _ctx.sortColumn === column.name,
                  "current-direction": _ctx.sortColumn === column.name ? _ctx.sortDirection : 'asc',
                  "custom-style": _ctx.customHeadingStyle,
                  class: _normalizeClass([
            column.align && column.width
              ? 'text-' + column.align + ' w-' + column.width
              : column.align
                ? 'text-' + column.align
                : 'text-left',
            _ctx.customHeadingClasses
          ]),
                  onSort: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sort', $event)))
                }, {
                  default: _withCtx(() => [
                    (column.icon)
                      ? (_openBlock(), _createBlock(_component_MkBadge, {
                          key: 0,
                          stroked: !column.icon,
                          color: column.iconColor,
                          size: "small",
                          filled: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(column.icon), 1)
                          ]),
                          _: 2
                        }, 1032, ["stroked", "color"]))
                      : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "columnLabel", { column: column }, () => [
                      _createTextVNode(_toDisplayString(column.label), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["data-dusk", "show-sort-button", "column-name", "is-active", "current-direction", "custom-style", "class"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("tbody", null, [
        (_ctx.rows.length === 0 || _ctx.loading)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
              _createElementVNode("td", {
                colspan: _ctx.columns.length,
                class: "py-16 font-semibold text-center text-gray-900 bg-white border rounded-lg"
              }, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.common.loading')), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.noDataMessage), 1)
                    ], 64))
              ], 8, _hoisted_3)
            ]))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.rows, (row, i) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: i,
                class: _normalizeClass({
          ..._ctx.checkInRowClass(row.id),
          'bg-primary-50': row.isMuted,
          'bg-white': !row.isMuted
        }),
                style: _normalizeStyle(`--animation-delay: ${i * 6}0ms;`),
                "data-dusk": `table-row-${i}`,
                onClick: ($event: any) => (_ctx.$emit('row-click', row)),
                onMouseover: ($event: any) => (_ctx.$emit('row-hover', i)),
                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('row-hover-leave')))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                  return (_openBlock(), _createBlock(_component_DataTableCell, {
                    key: column.name,
                    class: _normalizeClass(`text-${column.align || 'left'}`),
                    "cell-style-classes": _ctx.cellStyleClasses,
                    "is-muted": row.isMuted,
                    "data-dusk": `table-row-${i}-${column.name}`
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, `cell.${column.name}`, {
                        row: row,
                        value: row[column.name],
                        rowIndex: i
                      }, () => [
                        (_ctx.displayValueInCell)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(row[column.name]), 1)
                            ], 64))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["class", "cell-style-classes", "is-muted", "data-dusk"]))
                }), 128))
              ], 46, _hoisted_4))
            }), 128))
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}
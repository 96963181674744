
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    isMuted: {
      type: Boolean,
      default: false
    },
    cellStyleClasses: {
      type: String,
      default: null
    }
  }
})
export default class BaseTableCell extends Vue {
  isMuted!: boolean;
}

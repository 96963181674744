
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import uniq from 'lodash-es/uniq';
import { BaseIcon } from '@/lib/components/Icon';
import { NavDropdown, NavDropdownButton } from '@/lib/components/Navigation';
import { PopoverButton } from '@/lib/components/Popover';
import { Study } from '@/models';
import StudyGallery from './StudyGallery.vue';

@Options({
  components: {
    PopoverButton,
    NavDropdownButton,
    NavDropdown,
    StudyGallery,
    BaseIcon
  },
  props: {
    excludeSeries: {
      type: Array,
      default: () => []
    },
    studies: {
      type: Array,
      default: () => []
    },
    signs: {
      type: Array,
      default: () => []
    },
    currentStudyId: {
      type: String,
      default: ''
    }
  }
})
export default class SeriesSelector extends Vue {
  studies!: Array<Study>;
  excludeSeries!: Array<string>;
  year = -1;
  currentStudyId!: string;

  get currentStudyYear() {
    const study = this.studies.find((study) => study.id === this.currentStudyId);
    return study ? dayjs(study.studyTime).year() : this.years[0];
  }

  get filteredStudies() {
    return this.studies
      .map((study) => ({
        ...study,
        series: study.series.filter((s) => !this.excludeSeries.includes(s.id))
      }))
      .filter((study) => study.series.length && study.series.find((series) => series.images.length));
  }

  get studiesOfSelectedYear() {
    return this.filteredStudies.filter((study) => dayjs(study.studyTime).year() === this.year);
  }

  get years(): Array<number> {
    return uniq(this.filteredStudies.map((study) => dayjs(study.studyTime).year()).sort((a, b) => b - a));
  }

  mounted() {
    if (this.years.length) {
      this.year = this.currentStudyYear;
    }
  }

  selectSeries(value: { studyId: string; seriesId: string }) {
    const study = this.studies.find((study) => study.id === value.studyId);
    if (study) {
      const series = study.series.find((s) => s.id === value.seriesId);
      this.$emit('select', series);
    }
  }
}

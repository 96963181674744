import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-row rounded h-10" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        key: item.value,
        type: "button",
        class: _normalizeClass(["rounded px-4 text-center cursor-pointer text-sm font-medium select-none text-white", [_ctx.modelValue === item.value ? 'bg-primary-500 rounded' : 'bg-gray-900']]),
        onClick: ($event: any) => (_ctx.changeMode(item.value))
      }, [
        (item.icon && item.icon.length)
          ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              class: "text-gray-300 text-base mr-3",
              name: item.icon
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(item.name), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}
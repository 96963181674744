import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-between items-center p-4" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { class: "text-base font-regular text-gray-900 mr-4" }
const _hoisted_5 = { class: "text-base font-regular text-gray-500 mr-4" }
const _hoisted_6 = { class: "flex flex-row items-center" }
const _hoisted_7 = {
  key: 0,
  class: "text-xs font-regular text-gray-500 mr-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.logoUri && _ctx.logoUri.length)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: _ctx.label,
            src: _ctx.logoUri,
            class: "w-8 h-8 mr-4"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.label === _ctx.nhsNumberLabel)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 1,
            class: "mr-2 flex items-center",
            name: "nhs-logo"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattedLabel), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formattedId), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.lastConnection && _ctx.lastConnection.length)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('platform.patient.connected', { date: _ctx.formatDate(_ctx.lastConnection) })), 1))
        : _createCommentVNode("", true),
      (!_ctx.readonly && _ctx.showEditButton)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            "data-cy": "edit-btn",
            class: "mr-4",
            color: "muted",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('edit')), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.edit')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.readonly && _ctx.showDeleteButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            type: "button",
            "data-cy": "delete-btn",
            class: "focus:outline-none",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('delete')), ["prevent"]))
          }, [
            _createVNode(_component_BaseIcon, {
              name: "delete",
              class: "text-gray-400 text-xl hover:text-gray-900 focus:text-gray-900"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
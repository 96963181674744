import apiClient from '@/services/api/client/apiClient';
import {
  ManagementPlan,
  ManagementPlanAddStepRequest,
  ManagementPlanDestination,
  ManagementPlanStep,
  ManagementPlanTimeframe,
  ManagementPlanUpdateStepRequest,
  PaginatedResponse
} from '@/models';
import { GenericService } from '@/services/api/generic.service';
import { AxiosResponse } from 'axios';

export class ManagementPlanService extends GenericService<ManagementPlan> {
  endpoint = '';

  constructor(patientId: string) {
    super();
    this.endpoint = `v1/patients/${patientId}/management-plans`;
  }

  async addManagementPlanStep(
    management_plan_id: string,
    action_id: string,
    body: ManagementPlanAddStepRequest
  ): Promise<AxiosResponse<ManagementPlanStep>> {
    return await apiClient.post(`${this.endpoint}/${management_plan_id}/actions/${action_id}/steps`, body);
  }

  async getManagementPlan(management_plan_id: string) {
    return await apiClient.get(`${this.endpoint}/${management_plan_id}`);
  }

  async updateManagementPlanStep(
    management_plan_id: string,
    action_id: string,
    step_id: string,
    body: ManagementPlanUpdateStepRequest
  ): Promise<AxiosResponse<ManagementPlanStep>> {
    return await apiClient.patch(`${this.endpoint}/${management_plan_id}/actions/${action_id}/steps/${step_id}`, body);
  }

  async deleteManagementPlanStep(management_plan_id: string, action_id: string, step_id: string) {
    return await apiClient.delete(`${this.endpoint}/${management_plan_id}/actions/${action_id}/steps/${step_id}`);
  }

  async getManagementPlanDestinationsList(
    pageNumber = 1
  ): Promise<PaginatedResponse<Array<ManagementPlanDestination>>> {
    return await apiClient.get(`v1/management-destinations?page=${pageNumber}`);
  }

  async getManagementPlanTimeframesList(
    pageNumber = 1
  ): Promise<PaginatedResponse<Array<ManagementPlanTimeframe>>> {
    return await apiClient.get(`v1/management-timeframes?page=${pageNumber}`);
  }
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupTree = _resolveComponent("GroupTree")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_GroupTree, {
        key: index,
        item: item,
        onCreateChild: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('createChild', $event)))
      }, null, 8, ["item"]))
    }), 128))
  ]))
}
import { GenericService } from '@/services/api/generic.service';
import { FormSubmission } from '@/models';

export class PatientFormSubmissionService extends GenericService<FormSubmission> {
  endpoint = '';

  constructor(patientId: string) {
    super();
    this.endpoint = `v1/patients/${patientId}/form-submissions`;
  }
}

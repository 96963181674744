import { User } from '@/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from './client/apiClient';

interface Session {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export class UserService {
  async login(params: AxiosRequestConfig = {}): Promise<Session> {
    return await apiClient.post('v1/login', params);
  }

  async refreshToken(params: AxiosRequestConfig = {}): Promise<Session> {
    return await apiClient.post('v1/refresh-token', params);
  }

  async revokeToken(params: AxiosRequestConfig = {}) {
    return await apiClient.post('v1/revoke-token', params);
  }

  async mfaAssociate(params: AxiosRequestConfig = {}) {
    return await apiClient.post('v1/mfa-associate', params);
  }

  async mfaChallenge(params: AxiosRequestConfig = {}) {
    return await apiClient.post('v1/mfa-challenge', params);
  }

  async resetMFA(params: AxiosRequestConfig = {}) {
    return await apiClient.post('v1/users/current/reset-mfa', params);
  }

  async updateAccountSetting(data: Partial<User>, params: AxiosRequestConfig = {}): Promise<AxiosResponse<User>> {
    return await apiClient.patch('v1/users/current/account-setting', data, params);
  }

  async requestResetPasswordEmail(data: { email: string }, params: AxiosRequestConfig = {}) {
    return await apiClient.post('v1/request-reset-password', data, params);
  }

  async setPassword(
    data: { password: string; password_confirmation: string; token: string; email: string },
    params: AxiosRequestConfig = {}
  ) {
    return await apiClient.put('v1/reset-password', data, params);
  }

  async setPasswordAndActivate(
    data: { password: string; password_confirmation: string; token: string },
    params: AxiosRequestConfig = {}
  ) {
    return await apiClient.put('v1/set-password-and-activate', data, params);
  }

  async sendInvite(data: { token: string }): Promise<void> {
    await apiClient.post('v1/send-invite', data);
  }

  async getCurrent(params: AxiosRequestConfig = {}): Promise<User> {
    return (await apiClient.get('v1/me', params)).data;
  }

  async updateCurrent(changes: Partial<User>, params: AxiosRequestConfig = {}): Promise<User> {
    return (await apiClient.patch('v1/me', changes, params)).data;
  }

  async searchUserByEmail(params: AxiosRequestConfig = {}): Promise<User[]> {
    return (await apiClient.get('v1/user-search-by-email', params)).data;
  }
}

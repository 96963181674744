
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    label: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
})
export default class BaseSegment extends Vue {}

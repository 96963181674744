import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col px-3 py-4 space-y-2" }
const _hoisted_2 = { class: "flex flex-row items-center space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseSelect, {
        modelValue: _ctx.updatedStep.destination_id,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.updatedStep.destination_id) = $event)),
          _ctx.updateOrCreateStep
        ],
        options: _ctx.destinationOptions,
        "line-height": 1.25,
        placeholder: _ctx.$t('custom.uhb.management.destination'),
        disabled: _ctx.loading,
        class: "flex-1 w-96"
      }, null, 8, ["modelValue", "options", "line-height", "placeholder", "disabled", "onUpdate:modelValue"]),
      _createVNode(_component_BaseSelect, {
        modelValue: _ctx.updatedStep.timeframe_id,
        "onUpdate:modelValue": [
          _cache[1] || (_cache[1] = ($event: any) => ((_ctx.updatedStep.timeframe_id) = $event)),
          _ctx.updateOrCreateStep
        ],
        options: _ctx.timeframeOptions,
        placeholder: _ctx.$t('custom.uhb.management.timeframe'),
        class: "w-48",
        disabled: _ctx.timeframeSelectStatus || _ctx.loading
      }, null, 8, ["modelValue", "options", "placeholder", "disabled", "onUpdate:modelValue"]),
      _createVNode(_component_BaseButton, {
        class: "w-12 h-12",
        color: "ghost",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeStep(_ctx.stepIndex)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseIcon, {
            class: "text-base",
            name: "delete"
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_BaseTextInput, {
      modelValue: _ctx.updatedStep.notes,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.updatedStep.notes) = $event)),
      class: "flex",
      "full-height": "",
      placeholder: _ctx.$t('custom.uhb.management.enter-notes'),
      disabled: !_ctx.value[0].step_id || _ctx.loading,
      onChange: _ctx.updateOrCreateStep
    }, null, 8, ["modelValue", "placeholder", "disabled", "onChange"])
  ]))
}
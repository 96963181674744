
import { Vue, Options } from 'vue-class-component';
import { Message } from '@/models';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseButton } from '@/lib/components/Button';
import { BasePopover } from '@/lib/components/Popover';
import { BaseTextInput } from '@/lib/components/Input';
import { BaseModal } from '@/lib/components/Modals';
import { LoadingIcon } from '@/lib/components/Loading';
import ThreadMessage from './ThreadMessage.vue';

@Options({
  components: {
    LoadingIcon,
    BaseTextInput,
    ThreadMessage,
    BaseButton,
    BaseIcon,
    BaseModal,
    BasePopover
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    messages: {
      type: Array,
      default: () => []
    },
    patient: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class ThreadModal extends Vue {
  messages!: Array<Message>;
  answer = '';
  scrollHeight = 0;

  mounted() {
    this.scrollDown();
    this.$watch(
      'messages',
      (curr, old) => {
        if (curr.length && !old.length) {
          this.scrollDown();
        } else if (curr.length > old.length) {
          const messagesDiv = this.$refs.messages as HTMLElement;
          if (messagesDiv && messagesDiv.scrollHeight > this.scrollHeight) {
            messagesDiv.scrollTop = messagesDiv.scrollHeight - this.scrollHeight;
            this.scrollHeight = messagesDiv.scrollHeight;
          }
        }
      },
      { deep: true }
    );
  }

  scrollDown() {
    const messagesDiv = this.$refs.messages as HTMLElement;
    if (messagesDiv) {
      messagesDiv.scrollTop = messagesDiv.scrollHeight;
    }
  }

  onScroll(ev: UIEvent) {
    const target = ev.target as HTMLElement;
    if (target.scrollTop === 0) {
      this.scrollHeight = target.scrollHeight;
      this.$emit('scrolled');
    }
  }

  send() {
    if (this.answer && this.answer.trim().length) {
      this.$emit('send', this.answer);
      this.scrollDown();
    }
    this.answer = '';
  }
}

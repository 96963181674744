import { Organisation } from './organisational-unit.model';
import { Tag, TagFilter } from '@/models/tag.model';

export enum TagGroupName {
  HospitalTeam = 'hospital-team',
  CommunityTeam = 'community-team',
  HomeMonitoring = 'home-monitoring',
  ScheduledLocations = 'scheduled-locations',
  ConsultSites = 'consult-sites',
  CareTeam = 'care-team',
  Reviewer = 'reviewer',
  Speciality = 'speciality',
  TriageStatus = 'triage-status',
  Type = 'type',
}

export interface TagGroup {
  id: string;
  type: 'tagGroups';
  attributes: {
    name: TagGroupName;
    organisationalUnitId?: Organisation['id'];
    createdAt?: string;
    updatedAt?: string;
  },
  relationships?: {
    tags: Tag[]
  }
}

export interface TagGroupApiFormat {
  id: string;
  name: string;
  organisational_unit_id: Organisation['id'];
  created_at: string;
  updated_at: string;
  attributes?: {
    name: TagGroupName;
  },
  relationships?: {
    tags: Tag[]
  }
}

export interface TagGroupFilter {
  id: string,
  name: string;
  tags: TagFilter[]
}

export interface FilteredTag{
  id: string,
  label: string;
  checked: boolean;
}

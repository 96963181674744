
import { Vue, Options } from 'vue-class-component';
import { FormSection } from '@/models';
import SummaryNavItem from './SummaryNavItem.vue';

@Options({
  components: { SummaryNavItem },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: ''
    }
  }
})
export default class SummaryNav extends Vue {
  items!: Array<FormSection>;
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap mt-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SearchInput, {
      ref: "searchInput",
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      icon: "search",
      suggestions: _ctx.suggestions,
      placeholder: _ctx.placeholder,
      error: _ctx.error,
      onSubmit: _ctx.add
    }, null, 8, ["modelValue", "suggestions", "placeholder", "error", "onSubmit"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedModel, (value) => {
        return (_openBlock(), _createElementBlock("div", {
          key: value.id,
          class: "flex flex-wrap items-center bg-white m-1 px-3 py-1 rounded-full border hover:shadow-md hover:border-gray-400 cursor-pointer",
          onClick: ($event: any) => (_ctx.remove(value))
        }, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(value), 1),
          _createVNode(_component_BaseIcon, {
            class: "bg-gray-400 text-white rounded-full text-xl p-1",
            name: "close"
          })
        ], 8, _hoisted_2))
      }), 128))
    ])
  ]))
}
import { GenericService } from './generic.service';
import { Device, DeviceModel } from '@/models';
import apiClient from '@/services/api/client/apiClient';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export class OrganisationDeviceService extends GenericService<Device> {
  constructor(organisationId: string) {
    super();
    this.endpoint = `v1/organisations/${organisationId}/devices`;
  }

  async getDeviceModels(params: AxiosRequestConfig = {}): Promise<AxiosResponse<Array<DeviceModel>>> {
    return await apiClient.get('v1/devices/device-models', params);
  }

  async generateDeviceKey(deviceId: string): Promise<{ key: string }> {
    return await apiClient.get(`${this.endpoint}/${deviceId}/device-key`);
  }

  async download(url: string, isPdf = false) {
    const link = document.createElement('a');
    if (isPdf) {
      const response = await Axios.get(url, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      link.href = URL.createObjectURL(blob);
      const fileName = url.split('/').pop();
      if (fileName) {
        link.setAttribute('download', fileName);
      }
    } else {
      link.href = url;
      const fileName = url.split('/').pop();
      if (fileName) {
        link.setAttribute('download', fileName);
      }
    }
    link.click();
  }
}


import { Vue, Options } from 'vue-class-component';
import { Laterality } from '@/custom/menicon/models';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import BaseSwitch from '@/lib/components/Switch/BaseSwitch.vue';
import BaseTextInput from '@/lib/components/Input/BaseTextInput.vue';
import { VARefraction } from '@/lib';
import VASelect from '@/lib/components/Select/VASelect.vue';
import { vaUnits } from '@/constants';
import { VaUnit } from '@/models';
import { i18n } from '@/i18n/i18n';

@Options({
  components: { VASelect, BaseTextInput, BaseSwitch, BaseIcon },
  props: {
    laterality: {
      type: String,
      default: Laterality.right,
      validator: (value: string) => [...Object.keys(Laterality), 'both'].includes(value as Laterality)
    },
    showAdd: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object,
      default: () => ({
        sphere: '',
        cylinder: '',
        axis: '',
        visual_acuity: '',
        axial_length: '',
        addition: ''
      })
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    showAxialLength: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    vaLabel: {
      type: String,
      default() {
        return i18n.global.t('platform.eyes.bcva') as string;
      }
    },
    vaDescription: {
      type: String,
      default() {
        return i18n.global.t('platform.eyes.best-corrected-va') as string;
      }
    },
    vaUnit: {
      type: String,
      default: VaUnit.SIX_SIX,
      validator: (value: string) => vaUnits.includes(value)
    }
  }
})
export default class VARefractionCard extends Vue {
  laterality!: Laterality | 'both';
  modelValue!: VARefraction;
  showAxialLength!: boolean;
  showAdd!: boolean;

  unsavedValue: VARefraction = {
    sphere: '',
    cylinder: '',
    axis: '',
    visual_acuity: '',
    axial_length: '',
    addition: ''
  };

  get lateralityProps(): {
    [laterality: string]: { icon: string; label: string; onlyVA: boolean };
    } {
    return {
      [Laterality.right]: {
        icon: 'right-eye',
        label: this.$t('platform.eyes.right-eye') as string,
        onlyVA: false
      },
      [Laterality.left]: {
        icon: 'left-eye',
        label: this.$t('platform.eyes.left-eye') as string,
        onlyVA: false
      },
      both: {
        icon: 'both-eyes',
        label: this.$t('platform.eyes.both-eyes') as string,
        onlyVA: true
      }
    };
  }

  get emittedValue(): Partial<VARefraction> {
    const { sphere, cylinder, axis, axial_length, addition } = this.unsavedValue;
    return {
      sphere,
      cylinder,
      axis,
      axial_length,
      addition
    };
  }

  get cols(): number {
    let cols = 6;
    if (!this.showAdd) {
      cols -= 1;
    }
    if (!this.showAxialLength) {
      cols -= 1;
    }
    return cols;
  }

  get properties(): { icon: string; label: string; onlyVA: boolean } {
    return this.lateralityProps[this.laterality];
  }

  created() {
    this.unsavedValue = this.modelValue;
  }

  updateValue(updates: { [key: string]: any }) {
    this.$emit('update:modelValue', {
      ...this.modelValue,
      ...updates
    });
    this.$emit('update', updates);
  }
}

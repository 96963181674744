export default new class FilterStorageService {
  VIRTUAL_WARD = 'virtual-ward';

  set(key: string, value: string) {
    localStorage.setItem(`${key}-filter`, value);
  }

  get(key: string) {
    return localStorage.getItem(`${key}-filter`);
  }

  exists(key: string) {
    return !!localStorage.getItem(`${key}-filter`);
  }

  remove(key: string) {
    return localStorage.removeItem(`${key}-filter`);
  }
}();

import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(["px-5 py-4", [
      _ctx.isMuted ? 'bg-primary-50' : 'bg-white',
      _ctx.cellStyleClasses || 'border-t border-b first:border-l first:rounded-l-lg last:border-r last:rounded-r-lg'
    ]])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
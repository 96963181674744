
import { Vue, Options } from 'vue-class-component';
import { ClearButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: {
    BaseIcon,
    ClearButton
  },
  props: {
    imageUrl: {
      type: String
    },
    icon: {
      type: String
    },
    count: {
      type: Number
    },
    removable: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
})
export default class BaseChip extends Vue {}

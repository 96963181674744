
import { Vue, Options } from 'vue-class-component';
import BaseIcon from './BaseIcon.vue';

type ColorName = 'primary' | 'secondary' | 'success' | 'alert' | 'danger' | 'gray';
type SizeName = 'xsmall' | 'small' | 'regular' | 'large';

interface IGlyphColorDefinition {
  bg: string;
  softText: string;
  softBg: string;
}

type GlyphColorDefinitionList = {
  [key in ColorName]: IGlyphColorDefinition;
};

type SizeClassList = {
  [key in SizeName]: {
    icon: string;
    div: string;
  };
};

@Options({
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'primary',
      validator: (value: string) => ['primary', 'secondary', 'success', 'alert', 'danger', 'gray'].includes(value)
    },
    size: {
      type: String,
      default: 'regular',
      validator: (value: string) => ['xsmall', 'small', 'regular', 'large'].includes(value)
    },
    fill: {
      type: String,
      default: 'solid',
      validator: (value: string) => ['solid', 'soft'].includes(value)
    }
  },
  components: { BaseIcon }
})
export default class IconGlyph extends Vue {
  color!: ColorName;
  size!: SizeName;
  fill!: 'soft' | 'solid';
  colors: GlyphColorDefinitionList = {
    primary: {
      bg: 'primary-500',
      softText: 'primary-500',
      softBg: 'primary-100'
    },

    secondary: {
      bg: 'secondary-500',
      softText: 'secondary-500',
      softBg: 'secondary-100'
    },

    success: {
      bg: 'success-500',
      softText: 'success-500',
      softBg: 'success-100'
    },

    alert: {
      bg: 'alert-400',
      softText: 'alert-600',
      softBg: 'alert-100'
    },

    danger: {
      bg: 'danger-500',
      softText: 'danger-500',
      softBg: 'danger-200'
    },

    gray: {
      bg: 'gray-700',
      softText: 'gray-600',
      softBg: 'gray-100'
    }
  };

  sizes: SizeClassList = {
    xsmall: {
      icon: '12px',
      div: 'w-6 h-6'
    },
    small: {
      icon: '16px',
      div: 'w-8 h-8'
    },
    regular: {
      icon: '24px',
      div: 'w-12 h-12'
    },
    large: {
      icon: '32px',
      div: 'w-16 h-16'
    }
  };

  get textColorClass(): string {
    return `text-${this.fill === 'soft' ? this.colors[this.color].softText : 'white'}`;
  }

  get bgColorClass(): string {
    return `bg-${this.colors[this.color][this.fill === 'soft' ? 'softBg' : 'bg']}`;
  }

  get sizeClasses(): string {
    return this.sizes[this.size].div;
  }

  get iconSize(): string {
    return this.sizes[this.size].icon;
  }
}


import { Vue, Options } from 'vue-class-component';
import { v4 } from 'uuid';
import { IOption, IOptionGroup } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';

@Options({
  components: { BaseIcon, InputTextHelper },
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    displayError: {
      type: Boolean,
      default: true
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    iconStroke: {
      type: Boolean,
      default: false
    },
    truncate: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    error: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    iconColor: {
      type: String
    },
    iconHeight: {
      type: String,
      default: '25px'
    },
    instructions: {
      type: String,
      default: null
    },
    lineHeight: {
      type: Number,
      default: 1
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class BaseSelect extends Vue {
  id: string = v4();
  options!: Array<IOption | IOptionGroup>;
  modelValue!: string;
  focused = false;

  get item() {
    return this.findRecursive(this.options, this.modelValue);
  }

  findRecursive(options: Array<IOption | IOptionGroup>, value: string): IOption | null | undefined {
    const searchTree = (item: IOption | IOptionGroup, value: string): IOption | null => {
      if ('value' in item && (item as IOption).value === value) {
        return item;
      }
      let result: IOption | null = null;
      if ('options' in item && item.options?.length) {
        for (let i = 0; !result && i < item.options.length; i++) {
          result = searchTree(item.options[i], value);
        }
      }
      return result;
    };
    return options.map((opt) => searchTree(opt, value)).find((opt) => !!opt);
  }

  focus() {
    this.focused = true;
  }

  blur() {
    this.focused = false;
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorLayout = _resolveComponent("ErrorLayout")!

  return (_openBlock(), _createBlock(_component_ErrorLayout, {
    type: "500",
    title: _ctx.$t('platform.error.500.title'),
    message: _ctx.$t('platform.error.500.message')
  }, null, 8, ["title", "message"]))
}

import { Options, Vue } from 'vue-class-component';
import { getSuggestions } from '@/helpers/search.helper';
import { IOption, ISuggestion } from '@/lib';
import { SearchInput } from '@/lib/components/Search';

@Options({
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    error: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    clear: {
      type: Boolean,
      default: false
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    iconStroke: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SearchInput
  }
})
export default class SelectInput extends Vue {
  search = '';
  options!: IOption[];
  modelValue!: string | number;

  mounted() {
    this.search = this.getMatchSuggestion(this.options, this.modelValue)?.label ?? '';
  }

  get suggestionsList(): ISuggestion[] {
    if (this.search) {
      return getSuggestions(this.convertOptionsToSuggestions(this.filteredList), this.search);
    }

    return this.convertOptionsToSuggestions(this.options);
  }

  get filteredList(): IOption[] {
    return this.options.filter((item) => !this.search.includes(item.label));
  }

  convertOptionsToSuggestions(options: IOption[]): ISuggestion[] {
    if (!options.length) {
      return [];
    }
    return options.map(({ value, label }) => ({
      id: value,
      label
    } as ISuggestion));
  }

  validateOrClear(): void {
    const suggestions = this.convertOptionsToSuggestions(this.options);
    const valid = this.fuzzyQuery(suggestions, this.search);
    const exactMatch = this.getMatchSuggestion(this.options, this.search);
    // If user typed the exact match to option, trigger save
    if (exactMatch) {
      this.search = exactMatch.label;
      this.updateModelValue(exactMatch?.value ?? '');
      // close the popover buttons
      this.$refs.searchInput.close();
    }

    if (!valid || !exactMatch) {
      this.search = '';
      this.updateModelValue('');
      // close the popover buttons
      this.$refs.searchInput.close();
    }
  }

  getMatchSuggestion(list: IOption[], keyword: string | number): IOption | undefined {
    if (!keyword || !list.length) {
      return;
    }
    return list.find(({ label, value }) =>
      (label.toLowerCase() === keyword.toString().toLowerCase() ||
      value?.toString()?.toLowerCase() === keyword?.toString().toLowerCase())
    );
  }

  validateInputOrClear(value: ISuggestion): void {
    const suggestions = this.convertOptionsToSuggestions(this.options);
    const valid = this.fuzzyQuery(suggestions, value.label);
    if (valid) {
      this.search = value.label;
      this.updateModelValue(value?.id ?? '');
    } else {
      this.search = '';
    }
  }

  fuzzyQuery(list: ISuggestion[], keyWord: string): boolean {
    if (!keyWord || !list.length) {
      return false;
    }
    const arr: ISuggestion[] = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].label?.toLowerCase().indexOf(keyWord?.toLowerCase()) >= 0) {
        arr.push(list[i]);
      }
    }
    return arr.length > 0;
  }

  updateModelValue(value: string): void {
    this.$emit('update:modelValue', value);
    this.$emit('change');
  }
}

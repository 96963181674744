
import { Vue, Options } from 'vue-class-component';
import DataTableLayout from './DataTableLayout.vue';
import DataTableCell from './DataTableCell.vue';
import DataTableHeading from './DataTableHeading.vue';
import { IColumn } from '@/lib';
import { i18n } from '@/i18n/i18n';

@Options({
  name: 'DataTable',
  components: {
    DataTableLayout,
    DataTableCell,
    DataTableHeading
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    columns: {
      type: Array,
      required: true
    },

    rows: {
      type: Array,
      required: true
    },

    sort: {
      type: String,
      default: ''
    },

    showTableHeader: {
      type: Boolean,
      default: true
    },

    selectedRowIds: {
      type: Array,
      default: () => []
    },

    noDataMessage: {
      type: String,
      default() {
        return i18n.global.t('platform.common.table-no-results');
      }
    },

    customHeadingStyle: {
      type: String,
      default: null
    },

    customHeadingClasses: {
      type: String,
      default: null
    },

    cellStyleClasses: {
      type: String,
      default: null
    },

    displayValueInCell: {
      type: Boolean,
      default: true
    }
  }
})
export default class DataTable extends Vue {
  columns!: IColumn[];
  sort!: string;
  selectedRowIds!: string[];

  get sortColumn() {
    // Remove the leading '-' if it exists
    return this.sort.replace(/^-/, '');
  }

  get sortDirection() {
    return this.sort.substr(0, 1) === '-' ? 'desc' : 'asc';
  }

  checkInRowClass(id: string) {
    return this.selectedRowIds.includes(id) ? 'text-gray-900' : '';
  }
}

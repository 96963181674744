import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "text-sm font-medium text-gray-400" }
const _hoisted_3 = { class: "text-base font-semibold text-gray-900 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.value), 1)
  ]))
}
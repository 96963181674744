import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = {
  key: 2,
  class: "mt-6 p-4 bg-gray-100 rounded text-gray-900"
}
const _hoisted_6 = { class: "inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_SmartFormSummaryItem = _resolveComponent("SmartFormSummaryItem", true)!

  return (_ctx.conditionsMet && _ctx.item.type !== 'hidden')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.item.label || (_ctx.item.props?.label && _ctx.itemTypeHasValue) || _ctx.item.icon || _ctx.item.type === 'spacer')
          ? (_openBlock(), _createBlock(_resolveDynamicComponent('h' + (_ctx.level + 3)), {
              key: 0,
              class: "flex items-center font-semibold text-gray-900"
            }, {
              default: _withCtx(() => [
                (_ctx.item.icon)
                  ? (_openBlock(), _createBlock(_component_BaseIcon, {
                      key: 0,
                      name: _ctx.item.icon,
                      height: "24px",
                      color: "white",
                      class: "mr-3"
                    }, null, 8, ["name"]))
                  : _createCommentVNode("", true),
                (_ctx.item.label)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.item.label), 1))
                  : _createCommentVNode("", true),
                (_ctx.item.props?.label)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.item.props.label), 1))
                  : _createCommentVNode("", true),
                (_ctx.item.type === 'spacer')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "   "))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.item.type === 'component' || _ctx.item.type === 'repeater' || _ctx.item.type === 'list-item')
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), _mergeProps({
              key: 1,
              id: _ctx.item.id,
              readonly: "",
              item: _ctx.item,
              model: _ctx.answer,
              value: _ctx.answer,
              "model-value": _ctx.answer
            }, _ctx.item.props, {
              "patient-id": _ctx.patientId,
              "participant-id": _ctx.participantId,
              "form-submission-id": _ctx.formSubmissionId
            }), null, 16, ["id", "item", "model", "value", "model-value", "patient-id", "participant-id", "form-submission-id"]))
          : _createCommentVNode("", true),
        (_ctx.itemTypeHasValue)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formattedAnswer), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.item.items && _ctx.item.type !== 'repeater')
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass([_ctx.item.layout === 'grid' ? 'grid gap-4 grid-cols-' + _ctx.item.cols || 1 : 'mt-6'])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.items, (subItem) => {
                return (_openBlock(), _createBlock(_component_SmartFormSummaryItem, {
                  key: subItem.id,
                  item: subItem,
                  "patient-id": _ctx.patientId,
                  "participant-id": _ctx.participantId,
                  "form-submission-id": _ctx.formSubmissionId,
                  locale: _ctx.locale,
                  level: _ctx.level + 1
                }, null, 8, ["item", "patient-id", "participant-id", "form-submission-id", "locale", "level"]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}

import { Vue, Options } from 'vue-class-component';
import BaseModal from './BaseModal.vue';
import InvitePatientModal from './InvitePatientModal.vue';
import AssignToOrgModal from './AssignToOrgModal.vue';
import AssignToTeamModal from './AssignToTeamModal.vue';
import ReviewMeasurementsModal from './ReviewMeasurementsModal.vue';
import DischargeProgramModal from './DischargeProgramModal.vue';
import PatientSettingsModal from './PatientSettingsModal.vue';
import SetNextConsultModal from './SetNextConsultModal.vue';
import { FEATURES } from '@/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    assignedOrg: {
      type: Object,
      default: null
    },
    assignedCommunityTeam: {
      type: Object,
      default: null
    },
    assignedHospitalTeam: {
      type: Object,
      default: null
    },
    onHomeMonitoring: {
      type: Object,
      default: null
    },
    programId: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    toBeReviewedList: {
      type: Array,
      default: () => []
    },
    nextConsult: {
      type: Object,
      default: null
    },
    settings: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseModal,
    InvitePatientModal,
    AssignToOrgModal,
    AssignToTeamModal,
    ReviewMeasurementsModal,
    DischargeProgramModal,
    PatientSettingsModal,
    SetNextConsultModal
  }
})
export default class PatientHomeMonitoringActionModal extends Vue {
  status!: string;

  get tagsFeatureFlag(): boolean {
    return isFeatureFlagEnabled(FEATURES.TAGS);
  }
}


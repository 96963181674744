
import { Vue, Options } from 'vue-class-component';
import { IAction } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: {
    BaseIcon
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'primary-500'
    },
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    display: {
      type: Boolean,
      default: false
    },
    autoDismiss: {
      type: Boolean,
      default: true
    },
    dismissAfter: {
      type: Number,
      default: 3000
    },
    action: {
      type: Object,
      default: null
    }
  }
})
export default class BaseNotification extends Vue {
  color!: string;
  action!: IAction;
  dismissAfter!: number;
  display!: boolean;
  autoDismiss!: boolean;
  timeout?: number;

  mounted() {
    this.setDismiss();
    this.$watch('autoDismiss', () => this.setDismiss());
    this.$watch('display', () => this.setDismiss());
    this.$watch('dismissAfter', () => this.setDismiss());
  }

  setDismiss() {
    if (this.display && this.autoDismiss) {
      this.timeout = window.setTimeout(() => this.$emit('dismiss'), this.dismissAfter);
    } else if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onActionClick(action: IAction, event: MouseEvent) {
    action.onClick(event);
    event.stopPropagation();
  }
}


import { Vue, Options } from 'vue-class-component';
import { IAction } from '@/lib';
import { initPopper, updatePopper, Instance as PopperInstance } from '@/lib/helpers/popover.helper';

@Options({
  props: {
    position: {
      type: String,
      default: 'right'
    },
    width: {
      type: String,
      default: 'unset'
    },
    dark: {
      type: Boolean,
      default: false
    },
    closeOnSelection: {
      type: Boolean,
      default: true
    }
  }
})
export default class BasePopover extends Vue {
  items!: Array<IAction>;
  style = '';
  position!: 'right' | 'left';
  isOpen = false;
  maxHeight: null | number = null;
  width!: string;
  closeOnSelection!: boolean;
  popper!: PopperInstance;

  get menuStyle() {
    return `width: ${this.width};${this.maxHeight ? `max-height:${this.maxHeight}px` : ''}`;
  }

  async mounted() {
    if (this.$refs.main && this.$refs.menu) {
      this.popper = initPopper(this.$refs.main as HTMLElement, this.$refs.menu as HTMLElement, this.position);
      await this.updateWatchPopper();
    }
    this.popoverClickListener();
    this.setMenuMaxHeight();
    window.addEventListener('resize', () => this.setMenuMaxHeight());
  }

  unmounted() {
    window.removeEventListener('click', this.close);
    window.removeEventListener('resize', this.setMenuMaxHeight);
  }

  async updateWatchPopper() {
    await updatePopper(this.popper);
    this.$watch('position', async (newVal) => {
      await updatePopper(this.popper, newVal);
    });
    this.$watch('isOpen', async () => {
      await updatePopper(this.popper);
    });
  }

  setMenuMaxHeight() {
    const parentEl = (this.$refs.default || this.$refs.button) as HTMLElement;
    const parentElRect = parentEl?.getBoundingClientRect();
    if (!parentElRect) {
      return;
    }
    const heightToSet = window.innerHeight - parentElRect.bottom - 40;
    this.maxHeight = heightToSet > 180 ? heightToSet : 180;
  }

  popoverClickListener() {
    window.addEventListener('click', (e) => {
      const element = e.target as HTMLElement;
      const menuEl = this.$refs.menu as HTMLElement;
      if (this.isOpen) {
        if (this.closeOnSelection || (!this.closeOnSelection && !menuEl.contains(element))) {
          this.close();
        }
      } else if (this.$el.contains(element)) {
        this.open();
      }
    });
  }

  close() {
    this.isOpen = false;
  }

  open() {
    this.isOpen = true;
  }
}

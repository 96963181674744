
import { Vue, Options } from 'vue-class-component';
import { v4 } from 'uuid';
import { ISuggestion } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';
import { PopoverButton } from '@/lib/components/Popover';
import { ClearButton, ButtonLink } from '@/lib/components/Button';
import { i18n } from '@/i18n/i18n';

@Options({
  components: {
    ButtonLink,
    ClearButton,
    PopoverButton,
    InputTextHelper,
    BaseIcon
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    clear: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default() {
        return i18n.global.t('platform.common.search');
      }
    },
    label: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array
    },
    maxHeight: {
      type: String,
      default: 'unset'
    },
    dark: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    iconStroke: {
      type: Boolean,
      default: true
    },
    iconHeight: {
      type: String,
      default: '16px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    alwaysShowResults: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    wrap: {
      type: Boolean,
      default: false
    },
    addButtonLink: {
      type: [String, Object],
      default: null
    },
    addButtonLinkLabel: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'regular'
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Boolean,
      default: false
    }
  }
})
export default class SearchInput extends Vue {
  suggestions?: Array<ISuggestion>;
  modelValue!: string;
  id = v4();
  isOpen = false;

  mounted() {
    window.addEventListener('click', this.close);
  }

  unmounted() {
    window.removeEventListener('click', this.close);
  }

  formatText(value: string) {
    return value.replace(this.modelValue, `<b>${this.modelValue}</b>`);
  }

  onScroll(ev: UIEvent) {
    const target = ev.target as HTMLElement;
    const bottomOfTarget = target.scrollTop + target.offsetHeight >= target.scrollHeight;
    if (bottomOfTarget) {
      this.$emit('scrolled');
    }
  }

  close() {
    this.isOpen = false;
  }

  onInput(value: string) {
    (this.$refs.list as HTMLElement).scrollTop = 0;
    this.$emit('update:modelValue', value);
    this.$emit('change', value);
  }

  onItemClick(suggestion: ISuggestion) {
    this.$emit('update:modelValue', suggestion.label);
    this.$emit('change', suggestion);
    this.$emit('submit', suggestion);
  }

  onBlur() {
    this.$emit('blur');
    this.$emit('validate');
  }
}

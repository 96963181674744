
import { Vue, Options } from 'vue-class-component';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { getDobFromISOString } from '@/helpers/patient.helper';
import dayjs from 'dayjs';

@Options({
  components: { BaseIcon },
  props: {
    createdAt: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  }
})
export default class PrefillFormNotification extends Vue {
  createdAt!: string;
  username!: string;

  get date() {
    return this.$d(getDobFromISOString(this.createdAt), 'shortMonth');
  }

  get time() {
    return dayjs(this.createdAt).format('hh:mm A');
  }
}


import { Vue, Options } from 'vue-class-component';

const cssUnits = ['px', 'pt', 'cm', 'mm', 'em', 'rem', '%', 'ch', 'vh', 'lh'];

@Options({
  props: {
    name: String,
    position: {
      type: String,
      default: '',
      validator: (value: string) => ['', 'right', 'left', 'top', 'bottom'].includes(value)
    },
    height: {
      type: String,
      default: '1em',
      validator: (value: string) =>
        cssUnits.some((unit) => {
          const split = value.trim().split(unit);
          return split.length && !isNaN(+split[0].trim());
        })
    },
    stroke: {
      type: Boolean,
      default: true
    }
  }
})
export default class BaseIcon extends Vue {
  name!: string;
}


import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    alert: {
      type: Boolean,
      default: false
    }
  }
})
export default class CopdUhbModifiedAnthonisenCriteriaMeasurementReading extends Vue {
  //
}

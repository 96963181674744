import { compare, extractValueFromRepeaterFormAnswer, isRepeaterAnswers } from '@/helpers/smart-form.helper';
import { Answer, FormAnswer, FormCondition, FormSubmission } from '@/models';
import apiClient from '@/services/api/client/apiClient';
import { debounce, keyBy, mapValues } from 'lodash-es';
import { defineStore } from 'pinia';

interface State {
  answers: {
    [formSubmissionId: string]: FormAnswer
  },
  dirty: {
    [formSubmissionId: string]: string[]
  },
  errors: {
    [formSubmissionId: string]: {
        [itemId: string]: string;
    }
  },
  promises: Promise<any>[],
  completed: { [formSubmissionId: string]: boolean },
  error: boolean;
}

export const useSmartFormStore = defineStore('smartForm', {
  state: () => ({
    answers: {},
    dirty: {},
    errors: {},
    promises: [],
    completed: {},
    error: false
  } as State),
  getters: {
    getAnswer: (state) => (formSubmissionId: string, itemId: string) => {
      if (typeof state.answers[formSubmissionId] === 'undefined') {
        return null;
      }
      return state.answers[formSubmissionId][itemId]?.value;
    },
    getItemIsPrefilled: (state) => (formSubmissionId: string, itemId: string) =>
      state.answers[formSubmissionId]?.[itemId]?.is_prefilled || false,
    getItemConditionsMet: (state) => (formSubmissionId: string, conditions: FormCondition[][]) => {
      if (!conditions || conditions.length === 0) {
        return true;
      }

      return conditions
        .map((conditionGroup: FormCondition[]) => {
          for (const condition of conditionGroup) {
            const items = condition.item.split('.');
            const itemId = items.length ? (items.shift() as string) : condition.item;
            const property = items.join('.');
            const answers = state.answers[formSubmissionId];
            let answer: Answer = null;
            if (answers) {
              answer = answers[itemId]?.value;
              if (answer && property) {
                answer = answer[property];
              }

              if (isRepeaterAnswers(answer)) {
                answer = extractValueFromRepeaterFormAnswer(answer as FormAnswer[]);
              }
            }
            if (!compare(answer, condition.operator, condition.value)) {
              return false;
            }
          }
          return true;
        })
        .includes(true);
    },
    getError: (state) => (formSubmissionId: string, itemId: string) => {
      if (typeof state.errors[formSubmissionId] === 'undefined') {
        return null;
      }
      return state.errors[formSubmissionId][itemId];
    },
    getCompleted: (state) =>
      (formSubmissionId: string): boolean =>
        state.completed[formSubmissionId]
  },
  actions: {
    complete(formSubmissionId: string) {
      this.completed = {
        ...this.completed,
        [formSubmissionId]: true
      };
    },
    clearErrors(formSubmissionId: string) {
      if (typeof this.errors[formSubmissionId] !== 'undefined') {
        this.errors[formSubmissionId] = {};
      }
    },
    clearError(formSubmissionId: string, itemId: string) {
      if (typeof this.errors[formSubmissionId] !== 'undefined') {
        const { [itemId]: remove, ...rest } = this.errors[formSubmissionId];
        this.errors[formSubmissionId] = rest;
      }
    },
    async saveAnswers(
      {
        patientId,
        participantId,
        formSubmissionId,
        organisationId,
        validateItems,
        useDebounce
      }: {
            patientId?: string;
            participantId?: string;
            formSubmissionId: string;
            organisationId: string;
            validateItems?: Array<string>;
            useDebounce: boolean;
        }
    ) {
      const saveAnswers = () => {
        if (!this.dirty[formSubmissionId]?.length) {
          return Promise.resolve();
        }

        const promise = new Promise(async (resolve, reject) => {
          // Grab the items we're trying to save and unmark them as dirty
          const itemIds = [...this.dirty[formSubmissionId]];

          if (validateItems?.length) {
            itemIds.push(...validateItems);
          }

          this.dirty = {
            ...this.dirty,
            [formSubmissionId]: []
          };

          try {
            const route = patientId
              ? `v1/patients/${patientId}/form-submissions/${formSubmissionId}`
              : `v1/anonymous-participants/${participantId}/form-submissions/${formSubmissionId}`;
            const response = await apiClient.patch<FormSubmission>(route, {
              organisational_unit_id: organisationId,
              answers: mapValues(keyBy(itemIds), (itemId) => this.getAnswer(formSubmissionId, itemId))
            });
            // Clear any previous error status as this request was successful
            this.error = false;
            const validationErrors = response.data?.validation?.errors || {};

            // Store any validation errors
            for (const [itemId, errors] of Object.entries(validationErrors)) {
              this.errors = {
                ...this.errors,
                [formSubmissionId]: {
                  ...this.errors[formSubmissionId],
                  [itemId]: errors[0]
                }
              };
            }

            // Re-mark any items that failed validation as dirty
            this.dirty = {
              ...this.dirty,
              [formSubmissionId]: [
                ...new Set([
                  ...this.dirty[formSubmissionId],
                  // If the error key is a nested, just mark the parent key as dirty
                  ...Object.keys(validationErrors).map((key) => key.split('.')[0])
                ])
              ]
            };

            // Clear errors for anything that we updated that didn't have an error in the response
            Object.keys(this.errors[formSubmissionId] || [])
              .filter((errorId) => itemIds.some((itemId) => errorId.startsWith(itemId)))
              .filter((errorId) => !Object.keys(validationErrors).includes(errorId))
              .forEach((errorId) => {
                if (typeof this.errors[formSubmissionId] === 'undefined') {
                  return;
                }
                const { [errorId]: remove, ...rest } = this.errors[formSubmissionId];
                this.errors[formSubmissionId] = rest;
              }
              );

            resolve(response);
          } catch (error) {
            // Re-mark all items as dirty
            this.dirty = {
              ...this.dirty,
              [formSubmissionId]: [...this.dirty[formSubmissionId], ...itemIds]
            };

            this.error = true;

            reject(error);
          } finally {
            // Remove this promise from the list of promises now that it has finalised
            this.promises = this.promises.filter((p) => p !== promise);
          }
        });

        // Save the promise to the list of promises so that we can wait for all promises to resolve
        this.promises = [...this.promises, promise];

        return promise;
      };
      if (useDebounce) {
        await debounce(saveAnswers, 100)();
      } else {
        await saveAnswers();
      }
    }
  }
});

const tokenService = {
  getToken() {
    return localStorage.getItem('access_token');
  },
  setToken(token: string) {
    localStorage.setItem('access_token', token);
  },
  setRefreshToken(refresh_token: string) {
    localStorage.setItem('refresh_token', refresh_token);
  },
  setExpireDate(expire_date: string) {
    localStorage.setItem('expire_date', expire_date);
  },
  setLoadingToken(loading: string) {
    localStorage.setItem('loading_token', loading);
  },
  removeToken() {
    localStorage.clear();
  }
};

export default tokenService;

import { RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { Error404, UnsupportedBrowser } from '@/views/ErrorPage';
import { i18n } from '@/i18n/i18n';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
    meta: { authRequired: false, loginPage: true, title: i18n.global.t('platform.login.title') }
  },
  {
    path: '/new-password',
    name: 'new-password',
    props: true,
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (!to.query.token) {
        return next({ name: 'login' });
      }

      return next();
    },
    component: () => import(/* webpackChunkName: "new-password" */ '../views/users/NewPasswordPage.vue'),
    meta: {
      authRequired: false,
      title: i18n.global.t('platform.reset.new-password')
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/users/ResetPasswordPage.vue'),
    meta: {
      authRequired: false,
      title: i18n.global.t('platform.profile.reset-password')
    }
  },
  {
    path: '/weak-password',
    name: 'weak-password',
    props: true,
    component: () => import(/* webpackChunkName: "weak-password" */ '../views/users/WeakPasswordPage.vue'),
    meta: { authRequired: false, title: i18n.global.t('platform.profile.weak-password') },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (!to.params.email) {
        return next({ name: 'login' });
      }
      return next();
    }
  },
  {
    path: '/portal/documents/:documentId',
    props: true,
    component: () => import(/* webpackChunkName: "patient-document" */ '../views/portal/PatientDocumentPage.vue'),
    meta: { authRequired: false, title: i18n.global.t('platform.document-portal.title') }
  },
  {
    path: '/user-agreement',
    name: 'user-agreement',
    component: () => import(/* webpackChunkName: "agreement" */ '../views/UserAgreementPage.vue'),
    meta: { authRequired: true, title: i18n.global.t('platform.user-agreement.title') }
  },
  {
    path: '/admin',
    name: 'domain-admin',
    component: () => import(/* webpackChunkName: "domain-admin" */ '../views/admin/AdminLayout.vue'),
    meta: { authRequired: true, isAdmin: true },
    redirect: '/admin/users',
    children: [
      {
        path: 'users',
        name: 'domain-admin-users',
        component: () => import(/* webpackChunkName: "users" */ '../views/admin/UsersPage.vue'),
        meta: { authRequired: true, title: i18n.global.t('platform.user.users'), isAdmin: true }
      },
      {
        path: 'organisations',
        name: 'domain-admin-organisations',
        component: () => import(/* webpackChunkName: "organisations" */ '../views/admin/OrganisationsPage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.org-unit.org-units'),
          isAdmin: true
        }
      },
      {
        path: 'organisations/:organisationId',
        name: 'domain-admin-organisation',
        props: true,
        component: () => import(/* webpackChunkName: "org" */ '../views/admin/OrganisationLayout.vue'),
        meta: { authRequired: true, title: i18n.global.t('platform.org-unit.org-unit') },
        redirect: (to) => ({
          name: 'domain-admin-organisation-details',
          params: { organisationId: to.params.organisationId }
        }),
        children: [
          {
            path: 'details',
            component: () => import(/* webpackChunkName: "org-details" */ '../views/admin/OrganisationDetailPage.vue'),
            props: true,
            name: 'domain-admin-organisation-details',
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.org-unit.org-unit'),
              isAdmin: true
            }
          },
          {
            path: 'settings',
            component: () =>
              import(/* webpackChunkName: "org-settings" */ '../views/admin/OrganisationSettingsPage.vue'),
            props: true,
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.org-unit.settings'),
              isAdmin: true
            }
          },
          {
            path: 'preferences',
            component: () =>
              import(/* webpackChunkName: "org-settings" */ '../views/admin/OrganisationPreferencesPage.vue'),
            props: true,
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.settings.preferences'),
              isAdmin: true
            }
          },
          {
            path: 'users',
            name: 'domain-admin-organisation-users',
            component: () => import(/* webpackChunkName: "users" */ '../views/users/UsersPage.vue'),
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.user.users'),
              isAdmin: true
            }
          },
          {
            path: 'users/new',
            name: 'domain-admin-organisation-users-new',
            component: () => import(/* webpackChunkName: "new-user" */ '../views/users/NewUserPage.vue'),
            props: (route) => ({
              organisationId: route.params.organisationId,
              usersPath: `/admin/organisations/${route.params.organisationId}/users`
            }),
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.user.new'),
              isAdmin: true
            }
          },
          {
            path: 'users/:userId',
            name: 'domain-admin-organisation-users-edit',
            component: () => import(/* webpackChunkName: "edit-user" */ '../views/users/EditUserPage.vue'),
            props: (route) => ({
              organisationId: route.params.organisationId,
              userId: route.params.userId,
              usersPath: `/admin/organisations/${route.params.organisationId}/users`
            }),
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.user.edit'),
              isAdmin: true
            }
          },
          {
            path: 'roles',
            component: () => import(/* webpackChunkName: "org-roles" */ '../views/settings/RolesPage.vue'),
            props: true,
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.settings.roles-permissions'),
              isAdmin: true
            }
          },
          {
            path: 'roles/new',
            name: 'domain-admin-new-role',
            component: () => import(/* webpackChunkName: "org-roles" */ '../views/settings/NewRolePage.vue'),
            props: (route) => ({
              organisationId: route.params.organisationId,
              rolesPath: `/admin/organisations/${route.params.organisationId}/roles`
            }),
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.settings.roles-permissions'),
              isAdmin: true
            }
          },
          {
            path: 'roles/:roleId',
            component: () => import(/* webpackChunkName: "role" */ '../views/settings/RolePage.vue'),
            props: true,
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.role.roles'),
              isAdmin: true
            }
          },
          {
            path: 'roles/:roleId/users',
            component: () => import(/* webpackChunkName: "role" */ '../views/settings/RoleUsersPage.vue'),
            props: true,
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.role.roles'),
              isAdmin: true
            }
          },
          {
            path: 'roles/:roleId/users/:userId',
            component: () => import(/* webpackChunkName: "edit-user" */ '../views/users/EditUserPage.vue'),
            props: (route) => ({
              organisationId: route.params.organisationId,
              userId: route.params.userId,
              usersPath: `/admin/organisations/${route.params.organisationId}/roles/${route.params.roleId}/users`
            }),
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.user.edit'),
              isAdmin: true
            }
          },
          {
            path: 'devices',
            component: () => import(/* webpackChunkName: "org-devices" */ '../views/settings/DevicesPage.vue'),
            props: true,
            name: 'domain-admin-devices',
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.settings.devices'),
              isAdmin: true
            }
          },
          {
            path: 'devices/new',
            name: 'domain-admin-new-device',
            component: () => import(/* webpackChunkName: "org-devices" */ '../views/settings/NewDevicePage.vue'),
            props: true,
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.settings.devices'),
              isAdmin: true
            }
          },
          {
            path: 'devices/:deviceId',
            name: 'domain-admin-edit-device',
            component: () => import(/* webpackChunkName: "org-devices" */ '../views/settings/DevicePage.vue'),
            props: true,
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.settings.devices'),
              isAdmin: true
            }
          }
        ]
      },
      {
        path: 'legal',
        name: 'domain-admin-legal',
        component: () => import(/* webpackChunkName: "legal" */ '../views/legal/LegalPage.vue'),
        meta: { authRequired: true, title: i18n.global.t('platform.legal.title') },
        props: () => ({
          routePrefix: 'domain-admin-',
          showLayout: false
        }),
        children: [
          {
            path: ':region/privacy-policy',
            name: 'domain-admin-legal-privacy-policy',
            component: () => import(/* webpackChunkName: "legal-privacy-policy" */ '../views/legal/PrivacyPolicyPage.vue'),
            meta: {
              authRequired: true,
              title: i18n.global.t('platform.legal.privacy-policy.title')
            }
          },
          {
            path: ':region/terms-of-service',
            name: 'domain-admin-legal-terms-service',
            component: () => import(/* webpackChunkName: "legal-terms-service" */ '../views/legal/TermsServicePage.vue'),
            meta: { authRequired: true, title: i18n.global.t('platform.legal.terms-service.title') }
          }
        ]
      }
    ]
  },
  {
    path: '/settings',
    name: 'settings',
    redirect: '/settings/profile',
    component: () => import(/* webpackChunkName: "settings" */ '../views/settings/SettingsPage.vue'),
    meta: { authRequired: true },
    children: [
      {
        path: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/settings/ProfilePage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.settings.profile'),
          isUserSettings: true
        }
      },
      {
        path: 'account-setting',
        component: () => import(/* webpackChunkName: "account-setting" */ '../views/settings/AccountSettingPage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.settings.account-setting'),
          isUserSettings: true
        }
      },
      {
        path: 'users',
        name: 'settings-users',
        component: () => import(/* webpackChunkName: "users" */ '../views/users/UsersPage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.settings.users'),
          permission: 'user:read'
        }
      },
      {
        path: 'users/new',
        name: 'settings-users-new',
        component: () => import(/* webpackChunkName: "new-user" */ '../views/users/NewUserPage.vue'),
        props: () => ({
          usersPath: '/settings/users'
        }),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.user.new'),
          permission: 'user:create'
        }
      },
      {
        path: 'users/:userId',
        name: 'settings-users-edit',
        component: () => import(/* webpackChunkName: "edit-user" */ '../views/users/EditUserPage.vue'),
        props: (route) => ({
          userId: route.params.userId,
          usersPath: '/settings/users'
        }),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.user.edit'),
          permission: 'user:update'
        }
      },
      {
        path: 'roles',
        name: 'settings-roles',
        component: () => import(/* webpackChunkName: "role" */ '../views/settings/RolesPage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.role.roles'),
          permission: 'role:read'
        }
      },
      {
        path: 'roles/new',
        name: 'settings-new-role',
        component: () => import(/* webpackChunkName: "new-role" */ '../views/settings/NewRolePage.vue'),
        props: () => ({
          rolesPath: '/settings/roles'
        }),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.role.new'),
          permission: 'role:create'
        }
      },
      {
        path: 'roles/:roleId',
        component: () => import(/* webpackChunkName: "role" */ '../views/settings/RolePage.vue'),
        props: (route) => ({
          roleId: route.params.roleId
        }),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.role.edit'),
          permission: 'role:read'
        }
      },
      {
        path: 'roles/:roleId/users',
        component: () => import(/* webpackChunkName: "role-users" */ '../views/settings/RoleUsersPage.vue'),
        props: (route) => ({
          roleId: route.params.roleId
        }),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.role.roles'),
          permission: 'role:update'
        }
      },
      {
        path: 'roles/:roleId/users/:userId',
        component: () => import(/* webpackChunkName: "edit-user" */ '../views/users/EditUserPage.vue'),
        props: (route) => ({
          userId: route.params.userId,
          usersPath: `/settings/roles/${route.params.roleId}/users`
        }),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.role.roles'),
          permission: 'role:update'
        }
      },
      {
        path: 'devices',
        name: 'settings-devices',
        component: () => import(/* webpackChunkName: "device" */ '../views/settings/DevicesPage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.settings.devices'),
          permission: 'device:read'
        }
      },
      {
        path: 'devices/new',
        name: 'settings-new-device',
        component: () => import(/* webpackChunkName: "device" */ '../views/settings/NewDevicePage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.settings.devices'),
          permission: 'device:create'
        }
      },
      {
        path: 'devices/:deviceId',
        component: () => import(/* webpackChunkName: "device" */ '../views/settings/DevicePage.vue'),
        name: 'settings-edit-device',
        props: (route) => ({
          deviceId: route.params.deviceId
        }),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.settings.devices'),
          permission: 'device:read'
        }
      },
      {
        path: 'escalation-emails-setting',
        component: () =>
          import(
            /* webpackChunkName: "escalation-emails-setting" */ '../views/settings/EscalationEmailsSettingPage.vue'
          ),
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.uhb.escalation-email.setting'),
          permission: 'org-unit:read'
        }
      },
      {
        path: 'preferences',
        component: () =>
          import(/* webpackChunkName: "org-settings" */ '../views/admin/OrganisationPreferencesPage.vue'),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.settings.preferences'),
          permission: 'org-unit-preference:update'
        }
      }
    ]
  },
  {
    path: '/participants',
    name: 'participant-list',
    component: () => import(/* webpackChunkName: "participants" */ '../custom/research/views/ParticipantListPage.vue'),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.navigation.participants'),
      permission: 'anonymous-participant:read'
    }
  },
  {
    path: '/participants/new',
    component: () => import(/* webpackChunkName: "new-participant" */ '../custom/research/views/NewParticipant.vue'),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.participant.new'),
      permission: 'anonymous-participant:create'
    }
  },
  {
    path: '/participants/:participantId',
    component: () => import(/* webpackChunkName: "participant" */ '../custom/research/views/ParticipantPage.vue'),
    props: true,
    redirect: (to) => ({ name: 'participant-forms', params: { participantId: to.params.participantId } }),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.participant.participant'),
      permission: 'anonymous-participant:read'
    },
    children: [
      {
        path: 'details',
        name: 'participant-details',
        component: () =>
          import(/* webpackChunkName: "participant-details" */ '../custom/research/views/ParticipantDetailsPage.vue'),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.participant.details'),
          permission: 'anonymous-participant:read'
        }
      },
      {
        path: 'forms',
        name: 'participant-forms',
        component: () =>
          import(
            /* webpackChunkName: "participant-form-submissions" */ '../views/patient/PatientFormSubmissionsPage.vue'
          ),
        props: true,
        meta: { authRequired: true, title: i18n.global.t('platform.settings.forms') }
      },
      {
        path: 'forms/:formSubmissionId',
        component: () =>
          import(
            /* webpackChunkName: "participant-form-submission" */ '../views/patient/PatientFormSubmissionPage.vue'
          ),
        props: true,
        meta: { authRequired: true, title: i18n.global.t('platform.form.form') }
      }
    ]
  },
  {
    path: '/patients',
    name: 'patient-list',
    component: () => import(/* webpackChunkName: "patients" */ '../views/patient/PatientListPage.vue'),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.navigation.patients'),
      permission: 'patient:read'
    }
  },
  {
    path: '/patient-search',
    name: 'patient-search',
    component: () => import(/* webpackChunkName: "patients" */ '../views/patient/ExternalPatientLookUpPage.vue'),
    meta: {
      authRequired: true,
      title: i18n.global.t('custom.uhb.patient.search'),
      permission: 'external-lookup-patient:read'
    }
  },
  {
    path: '/patients/new',
    component: () => import(/* webpackChunkName: "new-patient" */ '../views/patient/NewPatientPage.vue'),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.patient.new'),
      permission: 'patient:create'
    }
  },
  {
    path: '/patients/:patientId',
    component: () => import(/* webpackChunkName: "patient" */ '../views/patient/PatientPage.vue'),
    props: true,
    redirect: (to) => ({ name: 'patient-details', params: { patientId: to.params.patientId } }),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.patient.patient'),
      permission: 'patient:read'
    },
    children: [
      {
        path: 'copd-virtual-ward-enrolment',
        name: 'copd-virtual-ward-enrolment',
        component: () => import(/* webpackChunkName: "copd-requirements" */ '../views/copd/CopdConfigPage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.uhb.copd.name'),
          permission: 'patient:read'
        },
        props: true
      },
      {
        path: 'consults',
        name: 'patient-consults',
        component: () => import(/* webpackChunkName: "patient-consults" */ '../views/patient/PatientConsultsPage.vue'),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.uhb.consult.consults'),
          permission: 'consult:read'
        }
      },
      {
        // TODO: Remove Consults routes from /patients route as we have the patient_id in the Consult
        path: 'consults/:consultId/image-review',
        component: () => import(/* webpackChunkName: "image-review" */ '../views/image-review/ImageReview.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.uhb.review.ophthalmological'),
          permission: 'review:update'
        },
        props: true
      },
      {
        path: 'consults/:consultId/image-review/:reviewId',
        component: () => import(/* webpackChunkName: "image-review" */ '../views/image-review/ImageReview.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.uhb.review.ophthalmological'),
          permission: 'review:update'
        },
        props: true
      },
      {
        path: 'prescriptions',
        name: 'patient-prescriptions',
        component: () =>
          import(
            /* webpackChunkName: "menicon-prescriptions" */ '../custom/menicon/views/patient/PrescriptionsPage.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.menicon.prescriptions.patient-prescriptions'),
          permission: 'menicon-prescription:read'
        }
      },
      {
        path: 'prescriptions/:journeyId',
        name: 'prescription-details',
        component: () =>
          import(
            /* webpackChunkName: "menicon-prescriptions" */ '../custom/menicon/views/patient/PrescriptionDetailsPage.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.menicon.prescriptions.patient-prescriptions'),
          permission: 'menicon-prescription:read'
        }
      },
      {
        path: 'prescriptions/:journeyId/encounters/:encounterId',
        name: 'patient-encounter',
        component: () =>
          import(/* webpackChunkName: "menicon-prescription" */ '../custom/menicon/views/encounter/EncounterPageLoader.vue'),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.menicon.prescriptions.patient-prescriptions')
        }
      },
      {
        path: 'details',
        name: 'patient-details',
        component: () => import(/* webpackChunkName: "patient-details" */ '../views/patient/PatientDetailsPage.vue'),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.patient.details'),
          permission: 'patient:read'
        }
      },
      {
        path: 'virtual-ward/:programId',
        name: 'patient-virtual-ward',
        component: () =>
          import(/* webpackChunkName: "patient-virtual-ward" */ '../views/patient/PatientHomeMonitoringPage.vue'),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.uhb.copd.virtual-ward'),
          permission: 'patient:read'
        }
      },
      {
        path: 'virtual-ward/:programId/new-measurement',
        name: 'copd-new-measurement',
        component: () =>
          import(/* webpackChunkName: "copd-new-measurement" */ '../views/copd/CopdNewMeasurementPage.vue'),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.uhb.copd.virtual-ward'),
          permission: 'patient:read'
        }
      },
      {
        path: 'scans',
        name: 'patient-scans',
        component: () => import(/* webpackChunkName: "patient-scans" */ '../views/patient/PatientScansPage.vue'),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.patient.scans'),
          permission: 'patent-scans:read'
        }
      },
      {
        path: 'forms',
        name: 'patient-forms',
        component: () =>
          import(/* webpackChunkName: "patient-form-submissions" */ '../views/patient/PatientFormSubmissionsPage.vue'),
        props: true,
        meta: { authRequired: true, title: i18n.global.t('platform.settings.forms') }
      },
      {
        path: 'forms/:formSubmissionId',
        component: () =>
          import(/* webpackChunkName: "patient-form-submission" */ '../views/patient/PatientFormSubmissionPage.vue'),
        props: true,
        meta: { authRequired: true, title: i18n.global.t('platform.form.form') }
      },
      // TODO: Menicon-specific route - determine a nice way to define customer-specific routes elsewhere.
      {
        path: 'virtual-doctor',
        name: 'virtual-doctor',
        props: true,
        // TODO: test redirection
        redirect: 'virtual-doctor/survey-results',
        meta: { authRequired: true, title: i18n.global.t('custom.menicon.virtual-doctor.title') }
      },
      {
        path: 'virtual-doctor/survey-results',
        name: 'patient-survey-results',
        component: () =>
          import(
            /* webpackChunkName: "virtual-doctor-list" */ '../custom/menicon/views/patient/virtual-doctor/VirtualDoctorSurveyResultsPage.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.menicon.virtual-doctor.survey-results'),
          permission: 'menicon-virtual-doctor:read'
        }
      },
      {
        path: 'virtual-doctor/instructions',
        name: 'vd-instructions',
        component: () =>
          import(
            /* webpackChunkName: "virtual-doctor-list" */ '../custom/menicon/views/patient/virtual-doctor/VirtualDoctorInstructions.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.menicon.virtual-doctor.survey-results'),
          permission: 'virtual-doctor-instructions:read'
        }
      },
      {
        path: 'virtual-doctor/survey-results/:surveyResultId',
        component: () =>
          import(
            /* webpackChunkName: "virtual-doctor-single" */ '../custom/menicon/views/patient/virtual-doctor/VirtualDoctorSurveyResultPage.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.menicon.virtual-doctor.survey-result'),
          permission: 'menicon-virtual-doctor:read'
        }
      },
      {
        name: 'patient-messages',
        path: 'virtual-doctor/messages',
        component: () =>
          import(
            /* webpackChunkName: "virtual-doctor-list" */ '../custom/menicon/views/patient/virtual-doctor/VirtualDoctorMessages.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.menicon.virtual-doctor.vd-messages'),
          permission: 'menicon-virtual-doctor-messaging:organisational-unit'
        }
      },
      {
        name: 'blocks-allergies',
        path: 'blocks/allergies',
        component: () =>
          import(
            /* webpackChunkName: "allergies-block" */ '../views/blocks/Allergies.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('projects.clinical-blocks.allergies')
        }
      },
      {
        name: 'blocks-management-plan',
        path: 'blocks/management-plan',
        component: () =>
          import(
            /* webpackChunkName: "management-plan-block" */ '../views/blocks/ManagementPlan.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('projects.clinical-blocks.management-plan')
        }
      },
      {
        name: 'blocks-tobacco-smoking-summary',
        path: 'blocks/tobacco-smoking-summary',
        component: () =>
          import(
            /* webpackChunkName: "tabacco-smoking-summary-block" */ '../views/blocks/TobaccoSmokingSummary.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('projects.clinical-blocks.tobacco-smoking-summary')
        }
      },
      {
        name: 'blocks-alcohol-consumption-summary',
        path: 'blocks/alcohol-consumption-summary',
        component: () =>
          import(
            /* webpackChunkName: "alcohol-consumption-summary-block" */ '../views/blocks/AlcoholConsumptionSummary.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('projects.clinical-blocks.alcohol-consumption-summary')
        }
      },
      {
        name: 'blocks-medications-statement',
        path: 'blocks/medications-statement',
        component: () =>
          import(
            /* webpackChunkName: "medications-statement-block" */ '../views/blocks/MedicationsStatement.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('projects.clinical-blocks.medications-statement')
        }
      },
      {
        name: 'blocks-family-history',
        path: 'blocks/family-history',
        component: () =>
          import(
            /* webpackChunkName: "family-history-block" */ '../views/blocks/FamilyHistory.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('projects.clinical-blocks.family-history')
        }
      },
      {
        name: 'blocks-problem-diagnosis-list',
        path: 'blocks/problem-diagnosis-list',
        component: () =>
          import(
            /* webpackChunkName: "problem-diagnosis-list-block" */ '../views/blocks/ProblemDiagnosisList.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('projects.clinical-blocks.problem-diagnosis-list')
        }
      },
      {
        name: 'blocks-presenting-complaint',
        path: 'blocks/presenting-complaint',
        component: () =>
          import(
            /* webpackChunkName: "presenting-complaint-block" */ '../views/blocks/PresentingComplaint.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('projects.clinical-blocks.presenting-complaint')
        }
      }
    ]
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import(/* webpackChunkName: "reviews" */ '../views/reviews/ReviewListPage.vue'),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.navigation.reviews'),
      permission: 'review:read'
    }
  },
  {
    path: '/worklists',
    name: 'worklists',
    component: () => import(/* webpackChunkName: "reviews" */ '../views/worklists/WorklistPage.vue'),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.navigation.worklists'),
      permission: 'consult:schedule'
    }
  },
  {
    path: '/consults',
    name: 'consults',
    component: () => import(/* webpackChunkName: "reviews" */ '../views/consults/ConsultListPage.vue'),
    meta: {
      authRequired: true,
      title: i18n.global.t('platform.navigation.consults'),
      permission: 'consult:read'
    }
  },
  {
    path: '/worklist/:worklistId',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "worklist-configuration" */ '../views/worklist-configuration/WorklistConfigurationPage.vue'
      ),
    redirect: (to) => ({ name: 'worklist-configuration', params: { worklistId: to.params.worklistId } }),
    meta: { authRequired: false },
    children: [
      {
        path: 'configuration',
        name: 'worklist-configuration',
        component: () =>
          import(
            /* webpackChunkName: "worklist-configuration" */ '../views/worklist-configuration/WorklistConfigurationPage.vue'
          ),
        props: true,
        meta: {
          authRequired: true,
          title: i18n.global.t('custom.uhb.worklist-configuration.configuration')
        }
      }
    ]
  },
  {
    path: '/virtual-ward',
    name: 'virtual-ward',
    component: () => import(/* webpackChunkName: "virtual-ward" */ '../views/copd/ProgramListPage.vue'),
    meta: { authRequired: true, title: i18n.global.t('custom.uhb.copd.virtual-ward'), permission: 'patient:read' }
  },
  {
    path: '/episodes/:pathway',
    name: 'episode-list',
    component: () => import(/* webpackChunkName: "episodes" */ '../views/episodes/EpisodeListPage.vue'),
    meta: { authRequired: true, title: i18n.global.t('platform.episodes.episodes'), permission: 'patient:read' }
  },
  {
    path: '/no-org-unit',
    name: 'no-org-unit',
    component: () => import(/* webpackChunkName: "no-org-unit" */ '../views/NoOrganisationPage.vue'),
    meta: { authRequired: true, title: i18n.global.t('platform.error.no-org-unit.name') }
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/legal/LegalPage.vue'),
    meta: { authRequired: true, title: i18n.global.t('platform.legal.title') },
    children: [
      {
        path: ':region/privacy-policy',
        name: 'legal-privacy-policy',
        component: () => import(/* webpackChunkName: "legal-privacy-policy" */ '../views/legal/PrivacyPolicyPage.vue'),
        meta: {
          authRequired: true,
          title: i18n.global.t('platform.legal.privacy-policy.title')
        }
      },
      {
        path: ':region/terms-of-service',
        name: 'legal-terms-service',
        component: () => import(/* webpackChunkName: "legal-terms-service" */ '../views/legal/TermsServicePage.vue'),
        meta: { authRequired: true, title: i18n.global.t('platform.legal.terms-service.title') }
      }
    ]
  },
  {
    path: '/unsupported-browser',
    name: 'unsupported-browser',
    component: UnsupportedBrowser,
    meta: { title: i18n.global.t('platform.error.unsupported-browser.title') }
  },
  {
    name: '404',
    path: '/404',
    component: Error404,
    meta: { title: '404' }
  },
  {
    name: 'catch-all',
    path: '/:pathMatch(.*)*',
    component: Error404,
    meta: { title: '404' }
  }
];

export default routes;


import { Vue, Options } from 'vue-class-component';
import { FormSection } from '@/models';
import SmartFormItem from './SmartFormItem.vue';
import PageLoading from '@/lib/components/Loading/PageLoading.vue';
import { useSmartFormStore } from '@/stores/smartForm.store';

@Options({
  name: 'SmartFormSection', // Name is required for recursive components
  props: {
    section: {
      type: Object,
      required: true
    },

    patientId: {
      type: String,
      default: null
    },

    participantId: {
      type: String,
      default: null
    },

    formSubmissionId: {
      type: String,
      required: true
    },

    next: {
      type: Function,
      required: true
    },

    back: {
      type: Function,
      required: true
    },

    level: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageLoading,
    SmartFormItem
  }
})
export default class SmartFormSection extends Vue {
  formSubmissionId!: string;
  section!: FormSection;
  loadings: string[] = [];
  smartForm = useSmartFormStore();

  get conditionsMet() {
    return this.smartForm.getItemConditionsMet(this.formSubmissionId, this.section.conditions);
  }

  checkLoading(event: string) {
    if (this.loadings.includes(event)) {
      this.loadings.splice(this.loadings.indexOf(event), 1);
    } else {
      this.loadings.push(event);
    }
  }
}

import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "select-none relative flex-grow min-h-32",
    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
    onMousedown: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.updateValue($event.clientX, $event.clientY)), ["stop"]))
  }, [
    _createElementVNode("div", {
      ref: "slider",
      class: _normalizeClass(["absolute", {
        'h-full': _ctx.vertical,
        'w-px': _ctx.vertical && _ctx.size === 'small',
        'w-2': _ctx.vertical && _ctx.size === 'regular',
        'w-full': !_ctx.vertical,
        'h-px': !_ctx.vertical && _ctx.size === 'small',
        'h-2': !_ctx.vertical && _ctx.size === 'regular'
      }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["absolute", {
          'h-full': _ctx.vertical,
          'w-px': _ctx.vertical && _ctx.size === 'small',
          'w-1': _ctx.vertical && _ctx.size === 'regular',
          'w-full': !_ctx.vertical,
          'h-px': !_ctx.vertical && _ctx.size === 'small',
          'h-1': !_ctx.vertical && _ctx.size === 'regular',
          'bg-gray-800': _ctx.dark,
          'bg-gray-300': !_ctx.dark
        }]),
        style: _normalizeStyle(
          _ctx.vertical
            ? 'transform-origin: 100% 100%; transform: translateX(0px) scale3d(1,' + (100 - _ctx.percent) / 100 + ', 1;'
            : 'transform-origin: 100% 100%; transform: translateX(0px) scale3d(' + (100 - _ctx.percent) / 100 + ', 1, 1;'
        )
      }, null, 6),
      _createElementVNode("div", {
        class: _normalizeClass([[
          _ctx.disabled ? 'bg-gray-300' : 'bg-' + _ctx.color,
          _ctx.vertical ? (_ctx.size === 'small' ? 'h-full w-px' : 'h-full w-1') : _ctx.size === 'small' ? 'w-full h-px' : 'w-full h-1'
        ], "absolute"]),
        style: _normalizeStyle(
          _ctx.vertical
            ? 'transform-origin: 0 0; transform: translateX(0px) scale3d(1, ' + _ctx.percent / 100 + ', 1)'
            : 'transform-origin: 0 0; transform: translateX(0px) scale3d(' + _ctx.percent / 100 + ', 1, 1)'
        )
      }, null, 6)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute", _ctx.vertical ? 'h-full' : 'w-full']),
      style: _normalizeStyle(
        _ctx.vertical
          ? 'transform: translateY(-' + (100 - _ctx.percent) + '%)'
          : 'transform: translateX(-' + (100 - _ctx.percent) + '%)'
      )
    }, [
      _createElementVNode("button", {
        type: "button",
        style: _normalizeStyle(_ctx.size === 'small' ? 'right: -5px; bottom: -5px;' : 'right: -10px; bottom: -10px;'),
        class: _normalizeClass(["absolute rounded-full cursor-pointer focus:outline-none", [_ctx.disabled ? 'bg-gray-300' : 'cursor-grab bg-' + _ctx.color, _ctx.size === 'small' ? 'h-2 w-2' : 'h-4 w-4']]),
        onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onThumbMouseDown())),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onThumbMouseDown()))
      }, null, 38)
    ], 6),
    _renderSlot(_ctx.$slots, "default")
  ], 32))
}

import { Vue, Options } from 'vue-class-component';
import { BaseCard } from '@/lib/components/Card';
import { BaseSelect } from '@/lib/components/Select';
import { BaseNumberInput } from '@/lib/components/Input';
import { RadioGroup } from '@/lib/components/Radio';
import HospitalTeamSelect from './HospitalTeamSelect.vue';
import { IErrors, IOption, IPredefinedTerminologyList } from '@/lib';
import {
  CopdProgramRequest,
  CopdProgramRequestPayload,
  FilterTag,
  ICodeValueEntity,
  TagGroupName
} from '@/models';
import { CopdProgramService, CopdTerminologyService, FilterTagService } from '@/services/api';
import { predefinedTerminologyListFactory } from '@/lib/helpers/terminology.helper';
import { AxiosError } from 'axios';
import { FEATURES } from '@/constants';
import capitalize from 'lodash-es/capitalize';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';

// Constant used until value start coming from BE
const OXYGEN_DELIVERY_CODE = '722742002';

@Options({
  components: { RadioGroup, BaseCard, BaseSelect, BaseNumberInput, HospitalTeamSelect },
  props: {
    program: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  }
})
export default class CopdSetup extends Vue {
  program!: CopdProgramRequest;
  oxygenTypes: IPredefinedTerminologyList = predefinedTerminologyListFactory([]);
  errors!: IErrors;
  inputErrors: IErrors = {};
  fetchErrors: IErrors | null = null;
  selectedHomeMonitoringOption: FilterTag['id'] = '';
  homeMonitoringOptions: Array<FilterTag> = [];
  tagService = new FilterTagService();


  copdProgramService = new CopdProgramService();
  copdTerminologyService = new CopdTerminologyService();

  mounted() {
    this.inputErrors = this.errors;
    this.fetchOxygenTypes();

    this.$watch('errors', (newVal) => {
      this.inputErrors = newVal;
    });
  }

  created() {
    if (this.tagsFeatureFlag) {
      this.fetchHomeMonitoringOptions();
    }
  }

  get tagsFeatureFlag(): boolean {
    return isFeatureFlagEnabled(FEATURES.TAGS);
  }

  get validationErrors() {
    return this.inputErrors || this.fetchErrors;
  }

  get homeMonitoringValidationError() {
    return this.validationErrors && this.validationErrors.home_monitoring && this.validationErrors.home_monitoring[0];
  }

  get inspiredOxygenLabel(): string {
    return capitalize(this.$t('custom.uhb.copd.inspired-oxygen'));
  }

  get inspiredOxygenPlaceholder(): string {
    return capitalize(
      this.$t(
        'platform.common.select-entity',
        [this.$t('custom.uhb.copd.inspired-oxygen')]
      )
    );
  }

  get oxygenValue(): string {
    return this.program.settings.method_of_oxygen_delivery ? this.program.settings.method_of_oxygen_delivery.code : '';
  }

  get flowRateLabel(): string {
    return capitalize(this.$t('custom.uhb.copd.flow-rate'));
  }

  get showFlowRate(): boolean {
    // TODO : return this value from BE
    const roomAirCode = OXYGEN_DELIVERY_CODE;
    const oxygenMethod = this.oxygenTypes.find(this.oxygenValue);
    return !!oxygenMethod && oxygenMethod.code !== roomAirCode;
  }

  get flowRateOptions(): string[] {
    return ['0.5', '1', '2', '3', '4', '5', '6', '7'];
  }

  get homeMonitoringValues(): Array<IOption> {
    return this.homeMonitoringOptions.map((option: FilterTag) => ({
      value: option.id,
      label: option.name
    }));
  }

  updateSettings(newVal: { [key: string]: ICodeValueEntity }) {
    const updated = {
      ...this.program,
      settings: {
        ...this.program.settings,
        ...newVal
      }
    };

    if (updated.settings.method_of_oxygen_delivery.code === OXYGEN_DELIVERY_CODE) {
      delete updated.settings.flow_rate;
    }

    this.triggerUpdateProgram(updated);

    Object.keys(newVal).forEach((updatedField) => {
      this.inputErrors && this.inputErrors[`settings.${updatedField}`] && delete this.inputErrors[`settings.${updatedField}`];
    });
  }

  updateProgram(updated: CopdProgramRequestPayload): void {
    const updatedProgram = {
      ...this.program,
      ...updated
    };

    this.triggerUpdateProgram(updatedProgram);

    Object.keys(updated).forEach((updatedField) => {
      this.inputErrors && this.inputErrors[`${updatedField}`] && delete this.inputErrors[`${updatedField}`];
    });
  }

  triggerUpdateProgram(updated: CopdProgramRequest): void {
    this.$emit('update-program', updated);
  }

  async fetchOxygenTypes() {
    try {
      const response = await this.copdTerminologyService.getMethodOfOxygenDelivery();
      this.oxygenTypes = predefinedTerminologyListFactory(response.data);
    } catch (e: unknown) {
      if ((e as AxiosError).response?.status === 422) {
        this.fetchErrors = (e as AxiosError).response?.data?.errors;
      } else {
        this.fetchErrors = {};
      }
    }
  }

  async fetchHomeMonitoringOptions() {
    try {
      const response = await this.tagService.getTagsForGroupName(TagGroupName.HomeMonitoring);
      this.homeMonitoringOptions = response.sort((a: FilterTag, b: FilterTag): number => b.name.localeCompare(a.name));
    } catch (e: unknown) {
      if ((e as AxiosError).response?.status === 422) {
        this.fetchErrors = (e as AxiosError).response?.data?.errors;
      } else {
        this.fetchErrors = {};
      }
    }
  }

  updateHomeMonitoringOption(option: FilterTag['id']): void {
    this.selectedHomeMonitoringOption = option;
    this.updateProgram({ home_monitoring: option });
  }
}

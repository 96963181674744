import { Domain, Feature } from '@/models';
import { useSessionStore } from '@/stores/session.store';

export const isFeatureFlagEnabled = (featureKey: string): boolean => {
  const sessionStore = useSessionStore();
  const feature = sessionStore.features.find((feature: Feature) => feature.key === featureKey);
  return !!feature && feature.enabled;
};

export const isFeatureFlagEnabledOnDomain = (featureKey: string, domain: Domain): boolean => {
  const feature: Feature = domain.features.find(({ key }) => key === featureKey);
  return !!feature && feature.enabled;
};


import { Vue, Options } from 'vue-class-component';
import { RouteLocationNormalized } from 'vue-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { User } from '@/models';
import { PageLoading, BaseSnackbar, MaintenanceModal } from '@/lib/components';
import { TokenService, IdlesService } from '@/services';
import { HealthCheckService } from '@/services/api';
import { getBrand } from '@/services/brand.service';
import { logoutService } from '@/services/auth';
import { useSessionStore } from '@/stores/session.store';
import { useUiStore } from '@/stores/ui.store';
import { INotification } from '@/lib';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(utc);

@Options({
  components: {
    BaseSnackbar,
    PageLoading,
    MaintenanceModal
  }
})
export default class App extends Vue {
  healthCheckService = new HealthCheckService();
  autoHealthCheckExpired = false;
  healthCheckTimeIndex = 0;
  healthCheckInitIndex = 0;
  brandingLoaded = false;
  intervalId: number | null = null;
  sessionStore = useSessionStore();
  uiStore = useUiStore();
  notificationStore = useNotificationStore();

  get snackbar(): INotification {
    return this.notificationStore.snackbar;
  }

  get appId(): string | null {
    return this.sessionStore.uuid;
  }

  toggleAutoHealthCheckExpired(): void {
    this.autoHealthCheckExpired = !this.autoHealthCheckExpired;
  }

  mounted() {
    window.addEventListener('beforeunload', () => this.removeIdle());
    window.addEventListener('storage', this.onStorageChange);
    this.$watch('appId', (id: string | null, oldId: string | null) => this.removeIdle(oldId));
  }

  beforeUnmount() {
    this.removeIdle();
    window.removeEventListener('beforeunload', () => this.removeIdle());
    window.removeEventListener('storage', this.onStorageChange);
  }

  removeIdle(id: string | null = this.appId) {
    if (id) {
      IdlesService.removeIdle(id);
    }
  }

  get currentUser(): User {
    return this.sessionStore.currentUser;
  }

  get isMaintenance(): boolean {
    return this.sessionStore.isMaintenance;
  }

  get isIdle() {
    return this.sessionStore.isIdle;
  }

  get loading(): boolean {
    return this.uiStore.loading;
  }

  get bodyScrollLock() {
    return this.uiStore.bodyScrollLock;
  }

  async created() {
    this.uiStore.headerBranding = await getBrand();
    this.brandingLoaded = true;
    this.onCurrentUserChange(this.currentUser);
    if (this.$route.meta?.isAdmin) {
      this.sessionStore.setCurrentOrganisationId(null);
    }
    this.$watch('currentUser', this.onCurrentUserChange);
    this.$watch('isMaintenance', this.maintenanceHandler);
    this.$watch('isIdle', this.onIsIdleChanged);
    this.$watch('$route', (to: RouteLocationNormalized) => (document.title = (to.meta?.title as string) || ''));
  }

  dismissSnackbar() {
    this.notificationStore.snackbar = null;
  }

  async onStorageChange() {
    const access_token = localStorage.getItem('access_token');
    if (!access_token && this.$route.name !== 'login') {
      await this.$router.push({ name: 'login' });
    }
  }

  onIsIdleChanged(isIdle: boolean | null, wasIdle: boolean | null) {
    if (this.appId && isIdle !== null) {
      IdlesService.setActive(this.appId, !isIdle);
      const activeIdles = IdlesService.getActives();
      const accessToken = localStorage.getItem('access_token');
      if (accessToken && isIdle && !wasIdle && !activeIdles.length) {
        this.notificationStore.addNotification({
          title: 'Inactivity detected. Logging out.',
          color: 'warning',
          icon: 'lock'
        });
        setTimeout(async () => {
          await logoutService();
        }, 3000);
      }
    }
  }

  onCurrentUserChange(currentUser: User | null) {
    if (currentUser && !this.intervalId) {
      this.refreshTokenIfNeeded();
    }
  }

  refreshTokenIfNeeded() {
    this.intervalId = window.setInterval(async () => {
      const expireDate = localStorage.getItem('expire_date');
      const loadingToken = localStorage.getItem('loading_token');
      if (
        loadingToken === 'false' &&
        expireDate &&
        dayjs(expireDate).diff(dayjs.utc().format('YYYY-MM-DD HH:mm:ss'), 'second') <= 60
      ) {
        TokenService.setLoadingToken('true');
        try {
          await this.sessionStore.refreshUserToken({
            refresh_token: localStorage.getItem('refresh_token')
          });
          TokenService.setToken(this.sessionStore.token);
          TokenService.setRefreshToken(this.sessionStore.refreshToken);
          TokenService.setExpireDate(this.sessionStore.expireDate);
          TokenService.setLoadingToken('false');
        } catch (e) {
          TokenService.removeToken();
          if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
          }
        }
      }
    }, 1000);
  }

  async maintenanceInit(limit: number, interval: number) {
    try {
      await this.healthCheckService.getHealthStatus();
      this.sessionStore.isMaintenance = false;
      this.$router.go(0);
    } catch {
      if (this.healthCheckInitIndex === limit) {
        // Trigger manual health check
        this.toggleAutoHealthCheckExpired();
      } else if (this.healthCheckInitIndex < limit) {
        this.callWithTime(2000, interval);
        this.healthCheckInitIndex++;
      }
    }
  }

  callWithTime(time: number, interval: number): void {
    clearInterval(interval);
    this.healthCheckTimeIndex++;
    setTimeout(this.maintenanceInit, time);
  }

  async maintenanceHandler(newStatus: boolean, oldStatus: boolean) {
    this.healthCheckTimeIndex = 0;
    this.healthCheckInitIndex = 0;
    const interval = 500;
    // This number is used to trigger health check ping to become manual after 5 secs
    const limit = 5;

    if (newStatus && typeof oldStatus !== 'undefined' && !oldStatus) {
      await this.maintenanceInit(limit, interval);
    }
  }
}

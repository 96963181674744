import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    type: "button",
    class: ["flex items-center justify-center w-8 h-8 rounded-full bg-white transition-all duration-150 focus:outline-none", {
      'cursor-default': _ctx.disabled,
      'text-gray-900 bg-gray-200': _ctx.active && !_ctx.disabled,
      'focus:text-gray-900 focus:bg-gray-200 hover:text-gray-900 hover:bg-gray-200 cursor-pointer': !_ctx.active && !_ctx.disabled
    }]
  }, _ctx.$props, { disabled: _ctx.disabled }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between leading-tight mb-3"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = ["id", "value", "disabled"]
const _hoisted_6 = {
  key: 0,
  value: "",
  selected: "",
  disabled: ""
}
const _hoisted_7 = ["label"]
const _hoisted_8 = ["value", "disabled"]
const _hoisted_9 = ["value", "disabled"]
const _hoisted_10 = {
  key: 2,
  class: "w-full h-full p-4 text-gray-900 border-none rounded bg-gray-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((_ctx.label && _ctx.label.length) || _ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: _normalizeClass(["text-base font-medium leading-tight", { 'text-transparent bg-gray-200 rounded-lg': _ctx.loading }])
          }, _toDisplayString(_ctx.label), 11, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isOptional)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["font-medium text-xs", _ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500'])
                }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
              : _createCommentVNode("", true),
            (_ctx.$slots.action)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["text-xs font-semibold", _ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-primary-500'])
                }, [
                  _renderSlot(_ctx.$slots, "action")
                ], 2))
              : _createCommentVNode("", true)
          ]),
          (_ctx.instructions && _ctx.instructions.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["instructions", _ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500'])
              }, _toDisplayString(_ctx.instructions), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center w-full h-12 bg-white border rounded", {
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          _ctx.error,
        'outline-primary-500': _ctx.focused && !_ctx.loading
      }])
    }, [
      _renderSlot(_ctx.$slots, "left"),
      (!_ctx.loading && _ctx.icon && _ctx.icon.length)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            class: _normalizeClass(['text-' + _ctx.iconColor, _ctx.iconPosition === 'right' ? 'order-1' : 'mr-2']),
            position: _ctx.iconPosition === 'left' ? 'right' : 'left',
            name: _ctx.icon,
            height: _ctx.iconHeight,
            stroke: _ctx.iconStroke
          }, null, 8, ["class", "position", "name", "height", "stroke"]))
        : _createCommentVNode("", true),
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("select", _mergeProps({ key: 1 }, _ctx.$attrs, {
            id: _ctx.id,
            ref: "select",
            class: ["w-full h-full pr-10 leading-normal appearance-none bg-white form-select focus:outline-none", {
          'text-gray-400': _ctx.modelValue === '',
          'leading-none': _ctx.lineHeight === 1,
          'leading-tight': _ctx.lineHeight > 1,
          invisible: _ctx.loading,
          truncate: _ctx.truncate
        }],
            value: _ctx.modelValue,
            disabled: _ctx.disabled,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
            onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args)))
          }), [
            (_ctx.placeholder)
              ? (_openBlock(), _createElementBlock("option", _hoisted_6, _toDisplayString(_ctx.placeholder), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: option.value
              }, [
                (option.options)
                  ? (_openBlock(), _createElementBlock("optgroup", {
                      key: 0,
                      label: option.label
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.options, (nestedOption) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: nestedOption.value,
                          value: nestedOption.value,
                          disabled: nestedOption.disabled
                        }, _toDisplayString(nestedOption.label), 9, _hoisted_8))
                      }), 128))
                    ], 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("option", {
                      key: typeof option === 'object' ? option.value : option,
                      value: typeof option === 'object' ? option.value : option,
                      disabled: option.disabled
                    }, _toDisplayString(typeof option === 'object' ? option.label : option), 9, _hoisted_9))
              ], 64))
            }), 128))
          ], 16, _hoisted_5))
        : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.item ? _ctx.item.label : ''), 1)),
      _renderSlot(_ctx.$slots, "right")
    ], 2),
    _createVNode(_component_InputTextHelper, {
      error: _ctx.displayError ? _ctx.error : '',
      loading: _ctx.loading,
      description: _ctx.description
    }, null, 8, ["error", "loading", "description"])
  ]))
}
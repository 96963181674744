import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-xl p-8 flex flex-col space-y-4" }
const _hoisted_2 = { class: "flex flex-row items-start space-x-2" }
const _hoisted_3 = { class: "flex -ml-3" }
const _hoisted_4 = { class: "text-xl font-semibold text-gray-900 self-center" }
const _hoisted_5 = { class: "text-base font-regular leading-normal text-gray-600 pb-2" }
const _hoisted_6 = { class: "w-full justify-start flex flex-col space-y-4" }
const _hoisted_7 = { class: "flex flex-end justify-end space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseIcon, {
          name: "outcome",
          stroke: false,
          height: "2.5rem"
        })
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.copd.discharge-patient')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('custom.uhb.copd.discharge-desc')), 1),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_BaseSelect, {
        "model-value": _ctx.dischargeReasonValue,
        "data-dusk": "discharge-reason",
        error: _ctx.errors && _ctx.errors.errors.reason && _ctx.errors.errors.reason[0],
        label: _ctx.$t('custom.uhb.copd.outcome-reason'),
        options: _ctx.dischargeReasons,
        placeholder: _ctx.$t('custom.uhb.copd.select-reason'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateDischargeReason($event)))
      }, null, 8, ["model-value", "error", "label", "options", "placeholder"]),
      (_ctx.alreadyDischarged)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            size: "small",
            color: "ghost",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.close')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_BaseButton, {
          class: "mt-8",
          size: "large",
          color: "ghost",
          onClick: _ctx.closeDischargeModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_BaseButton, {
          class: "mt-8",
          size: "large",
          color: "alert",
          "data-dusk": "discharge-patient-button",
          onClick: _ctx.dischargePatient
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.copd.discharge-patient')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}
import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.conditionsMet && _ctx.item.type !== 'hidden')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
      'p-6 bg-white border rounded-lg shadow': _ctx.item.container === 'card'
    })
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), _mergeProps({
          id: _ctx.item.id,
          "is-optional": _ctx.item.optional,
          "model-value": _ctx.modelValue,
          error: _ctx.error,
          readonly: _ctx.readonly
        }, { ..._ctx.item.props, ..._ctx.$attrs }), null, 16, ["id", "is-optional", "model-value", "error", "readonly"]))
      ], 2))
    : _createCommentVNode("", true)
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["text-gray-600 select-none inline-flex items-center", {
        'border border-danger-700 hover:border-danger-700 focus:border-danger-700 p-2 rounded':
          _ctx.error && _ctx.error.length,
        [`${_ctx.wrapperClass}`]: _ctx.wrapperClass
      }])
    }, [
      (_ctx.label && _ctx.label.length)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.id,
            style: {"width":"max-content"},
            class: _normalizeClass([[_ctx.labelPosition === 'left' ? '' : 'order-1', _ctx.labelClass, _ctx.modelValue ? 'text-gray-900' : 'text-gray-600'], "relative"])
          }, _toDisplayString(_ctx.label), 11, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("input", _mergeProps({
        id: _ctx.id,
        class: "w-0 outline-none hidden",
        type: "checkbox"
      }, _ctx.$attrs, { value: _ctx.modelValue }, _toHandlers(_ctx.inputListeners)), null, 16, _hoisted_3),
      _createElementVNode("div", {
        class: _normalizeClass([[
          _ctx.modelValue ? 'bg-' + _ctx.color : _ctx.dark ? 'bg-black' : 'bg-gray-300',
          _ctx.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        ], "rounded-lg w-8 h-4"]),
        "data-dusk": "switch",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleValue()))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.modelValue ? 'enabled' : '', "oval bg-white relative transition-all duration-200 ease-in-out rounded-full h-3 w-3"])
        }, null, 2)
      ], 2)
    ], 2),
    _createVNode(_component_InputTextHelper, {
      error: _ctx.error,
      description: _ctx.description
    }, null, 8, ["error", "description"])
  ]))
}
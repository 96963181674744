import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "relative flex flex-col select-none w-84 p-8 space-y-6 focus:outline-none",
  tabindex: "0"
}
const _hoisted_2 = {
  class: "flex justify-center text-orange",
  style: {"width":"100%"}
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col items-center rounded-lg justify-center p-6 text-gray-900 space-y-4"
}
const _hoisted_4 = {
  key: 0,
  class: "text-center text-lg font-bold"
}
const _hoisted_5 = {
  key: 1,
  class: "text-center text-base font-medium leading-tight"
}
const _hoisted_6 = { class: "flex flex-col space-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseIcon, {
            name: "warning",
            style: {"width":"58px","height":"58px","color":"#e49a0e"}
          })
        ]),
        (_ctx.title && _ctx.title.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.title && _ctx.title.length)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.title), 1))
                : _createCommentVNode("", true),
              (_ctx.message && _ctx.message.length)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.message), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            type: "button",
            class: "bg-danger-500 hover:bg-danger-600 rounded-lg flex justify-center py-4 text-white text-base font-semibold",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exit')))
          }, _toDisplayString(_ctx.$t('custom.uhb.review.unlock')), 1),
          _createElementVNode("button", {
            type: "button",
            class: "hover:bg-gray-500 hover:text-white rounded-lg flex justify-center py-4 opacity-50 text-gray-900 text-base font-semibold border border-gray-500",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }, _toDisplayString(_ctx.$t('platform.common.close')), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
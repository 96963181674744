
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import { FullIndicator, Study } from '@/models';
import { BaseButton } from '@/lib/components/Button';
import StudyGalleryItem from './StudyGalleryItem.vue';
import { i18n } from '@/i18n/i18n';

@Options({
  components: { BaseButton, StudyGalleryItem },
  props: {
    studies: {
      type: Array,
      default: () => []
    },
    signs: {
      type: Array,
      default: () => []
    },
    seriesLoad: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    scanHoverMessage: {
      type: String,
      default() {
        return i18n.global.t('platform.scans.open-in-viewer');
      }
    }
  }
})
export default class StudyGallery extends Vue {
  studies!: Array<Study>;
  signs!: Array<FullIndicator>;
  seriesLoad!: Array<string>;

  getImageSeriesSigns(id: string) {
    return this.signs.filter((sign) => sign.series_id === id);
  }

  openViewer(studyId: string, seriesId = '') {
    this.$emit('open', { studyId, seriesId });
  }

  formatDate(date: string) {
    return dayjs(date).format('D MMM YYYY');
  }
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSlider = _resolveComponent("BaseSlider")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
    onMousedown: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createVNode(_component_BaseSlider, {
      ref: "slider",
      class: "h-full",
      max: _ctx.max,
      min: 0,
      step: 1,
      "model-value": _ctx.modelValue,
      color: _ctx.color,
      size: _ctx.size,
      "enable-shortcuts": _ctx.enableShortcuts,
      vertical: "",
      dark: "",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniq, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item,
            style: _normalizeStyle('transform: translateY(-' + (100 - _ctx.getPercent(item)) + '%)'),
            class: _normalizeClass(["absolute h-full text-gray-500", _ctx.size === 'small' ? 'ml-3' : 'ml-6'])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["absolute bg-white rounded-full", _ctx.size === 'small' ? 'h-px w-px' : 'h-1 w-1']),
              style: _normalizeStyle(_ctx.size === 'small' ? 'bottom: -2px;' : 'bottom: -5px;')
            }, null, 6)
          ], 6))
        }), 128))
      ]),
      _: 1
    }, 8, ["max", "model-value", "color", "size", "enable-shortcuts"])
  ], 32))
}

import { Vue, Options } from 'vue-class-component';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';

@Options({
  components: { BaseIcon, BaseButton },
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (value: string) => ['primary', 'success', 'alert', 'danger'].includes(value)
    },
    value: {
      type: [Array, String]
    },
    badge: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    list: {
      type: Boolean,
      default: false
    },
    button: {
      type: Object,
      default: null
    }
  }
})
export default class BaseAlert extends Vue {
  value!: string | string[];
  color!: string;
  button!: {
    label: string;
    click: () => void;
  } | null;

  closed = false;

  get labelArray(): string[] {
    return Array.isArray(this.value) ? this.value : [this.value];
  }

  get textColorClass(): string {
    return `text-${this.color}-800`;
  }

  get bgColorClass(): string {
    return `bg-${this.color}-100`;
  }
}

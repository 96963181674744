
import { Vue, Options } from 'vue-class-component';
import { BaseTooltip } from '@/lib/components/Tooltip';

@Options({
  props: {
    modelValue: {
      type: [String, Boolean],
      default: ''
    },
    value: {
      type: [String, Boolean],
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    labelPosition: {
      type: String,
      validator: (value: string) => ['left', 'right'].includes(value),
      default: 'right'
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    }
  },
  components: { BaseTooltip }
})
export default class BaseRadio extends Vue {
  modelValue!: boolean | string;
  value!: boolean | string;
  loading!: boolean;
  light!: boolean;
  labelPosition!: string;
  disabled!: boolean;
  error!: string | null;

  get style() {
    return {
      label: this.light
        ? ['inline-flex items-center mr-2']
        : [
          'rounded flex items-center border border-solid transition-all duration-150 cursor-pointer space-x-3 p-4',
          this.disabled
            ? 'border-gray-300 bg-gray-100 cursor-not-allowed'
            : this.error
              ? 'bg-white border-danger-700'
              : this.checked && !this.disabled
                ? 'bg-white border-primary-500 outline-primary-500 shadow'
                : 'bg-white border-gray-300 border border-solid shadow hover:shadow-md hover:border-gray-400'
        ],
      radio: [
        this.light && this.error ? 'form-radio outline-danger-500' : 'form-radio outline-none',
        this.labelPosition === 'right' ? (this.light ? 'mr-3' : '') : 'order-2 ml-3'
      ]
    };
  }

  get checked(): boolean {
    return !this.loading && this.modelValue === this.value;
  }

  onChange(value: any) {
    if (!this.loading && !this.disabled) {
      this.$emit('update', value);
      this.$emit('update:modelValue', value);
    }
  }
}


import { Vue, Options } from 'vue-class-component';
import { colors } from '@/lib/constants';

@Options({
  props: {
    innerColor: {
      type: String,
      default: 'primary-500',
      validator: (value: any): boolean => colors.includes(value)
    },
    size: {
      type: Number,
      default: 100
    },
    value: {
      type: Number,
      default: 0
    }
  }
})
export default class AxialRotation extends Vue {
  innerColor!: string;

  get circleColorClass(): string {
    return `text-${this.innerColor}`;
  }
}

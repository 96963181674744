import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unansweredSections, (section) => {
      return (_openBlock(), _createBlock(_component_MkBadge, {
        key: section.id,
        color: "alert",
        size: "large",
        label: _ctx.$t('platform.error.unanswered-questions', [section.name])
      }, null, 8, ["label"]))
    }), 128))
  ]))
}
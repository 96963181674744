import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountListItem = _resolveComponent("AccountListItem")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedItems, (item, index) => {
      return (_openBlock(), _createBlock(_component_AccountListItem, {
        id: item.value,
        key: item.typeId,
        label: item.label,
        "logo-uri": item.logoUri,
        "last-connection": item.lastConnection,
        "data-cy": `account-${item.label}`,
        readonly: _ctx.readonly,
        class: _normalizeClass(["border-b border-gray-300 account-item", index === 0 ? 'border-t' : '']),
        "show-edit-button": _ctx.showEditButtons,
        "show-delete-button": _ctx.showDeleteButtons,
        onEdit: ($event: any) => (_ctx.$emit('edit', item)),
        onDelete: ($event: any) => (_ctx.$emit('delete', item))
      }, null, 8, ["id", "label", "logo-uri", "last-connection", "data-cy", "readonly", "class", "show-edit-button", "show-delete-button", "onEdit", "onDelete"]))
    }), 128)),
    (_ctx.showAddButton && !_ctx.readonly)
      ? (_openBlock(), _createBlock(_component_BaseButton, {
          key: 0,
          "data-cy": "add-btn",
          color: "ghost",
          "left-icon": "add",
          disabled: _ctx.disabled,
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('add-account')), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
          ]),
          _: 1
        }, 8, ["disabled"]))
      : _createCommentVNode("", true)
  ]))
}

import { Vue, Options } from 'vue-class-component';
import { FEATURES, genderOptions, sexOptions } from '@/constants';
import { getDobFromISOString, getNhsNumberFromPatient } from '@/helpers/patient.helper';
import { Patient } from '@/models';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { usePatientStore } from '@/stores/patient.store';

@Options({
  props: {
    homeMonitoring: {
      type: String,
      default: '—'
    },
    patient: {
      type: Object,
      required: true
    }
  }
})
export default class RowPatientSummary extends Vue {
  patient!: Patient;
  homeMonitoring!: string;
  patientStore = usePatientStore();

  get tagFeatureFlagEnabled(): boolean {
    return isFeatureFlagEnabled(FEATURES.TAGS);
  }

  get gender() {
    return genderOptions().find((option) => option.value === this.patient.sex);
  }

  get sex() {
    return sexOptions().find((option) => option.value === this.patient.sex);
  }

  get useGender() {
    return !!this.patient.gender; // this.$store.state.currentOrganisation.uses_gender && this.patient.gender;
  }

  get showNhsNumber() {
    return this.patientStore.showNhsNumber;
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'shortMonth');
  }

  get nhsNumberFormatted(): string {
    const nhsNumber = getNhsNumberFromPatient(this.patient);
    return nhsNumber ? nhsNumber : this.$t('platform.common.none').toString();
  }
}

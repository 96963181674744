
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    imageUrl: {
      type: String,
      default: null
    },
    imagePath: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    }
  }
})
export default class CardImage extends Vue {
  imageUrl!: string;
  imagePath!: string;
  get getImageUrl(): string {
    if (!this.imageUrl && !this.imagePath) {
      return '';
    }
    return this.imageUrl ? this.imageUrl : require(`@/assets/images/${this.imagePath}`);
  }
}

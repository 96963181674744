import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "bg-white select-none flex flex-row w-112 items-stretch justify-between border border-solid border-gray-300 rounded-lg",
  "data-dusk": "notification"
}
const _hoisted_2 = { class: "p-5 flex flex-row items-center" }
const _hoisted_3 = { class: "flex-1 flex flex-col" }
const _hoisted_4 = { class: "font-medium text-base text-gray-900" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm text-gray-500"
}
const _hoisted_6 = { class: "flex flex-col border-solid border-gray-300 border-l" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            class: _normalizeClass(["text-2xl mr-4", 'text-' + _ctx.color]),
            name: _ctx.icon
          }, null, 8, ["class", "name"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: _normalizeClass(["text-gray-500 text-sm w-full h-full cursor-pointer flex justify-center items-center px-6 hover:text-gray-900", { 'border-b border-solid border-gray-300': _ctx.action }]),
        "data-dusk": "close-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss')))
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('platform.common.close')), 1)
      ], 2),
      (_ctx.action)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "text-gray-500 text-sm w-full h-full cursor-pointer flex justify-center items-center px-6 hover:text-gray-900",
            "data-dusk": "action-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onActionClick(_ctx.action, $event)))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.action.label), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}

import { Vue, Options } from 'vue-class-component';
import { IGalleryImage } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import HorizontalScroll from '@/lib/layouts/HorizontalScrollableLayout.vue';

@Options({
  props: {
    images: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Number,
      default: 0
    }
  },
  components: {
    HorizontalScroll,
    BaseIcon
  }
})
export default class ViewerThumbnailGallery extends Vue {
  images!: Array<IGalleryImage>;
  modelValue!: number;

  mounted() {
    this.$watch('modelValue', this.centerValue);
  }

  centerValue() {
    const index = Math.max(0, Math.min(this.images.length - 1, this.modelValue));
    const currentImageElement = (this.$refs.scrollContainer as Vue).$el.children[index] as HTMLElement;
    const currentImageElementOffsetCenter = currentImageElement.offsetLeft + currentImageElement.clientWidth / 2;
    const scrollContainerCenter = (this.$refs.scrollContainer as Vue).$el.clientWidth / 2;
    (this.$refs.scrollContainer as Vue).$el.scroll({
      left: currentImageElementOffsetCenter - scrollContainerCenter,
      behavior: 'smooth'
    });
  }

  prev() {
    if (this.modelValue > 0) {
      this.$emit('update:modelValue', this.modelValue - 1);
    }
  }

  next() {
    if (this.modelValue < this.images.length - 1) {
      this.$emit('update:modelValue', this.modelValue + 1);
    }
  }
}

import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-dusk": "viewer-loader",
  style: {"box-shadow":"inset 0 0 0 1000px rgba(0, 0, 0, 0.9)"},
  class: "absolute inset-0 z-10 flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_BaseSnackbar = _resolveComponent("BaseSnackbar")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    ref: "viewer",
    class: "h-full w-full relative",
    style: {"background-color":"#0e0e0e"}
  }, _ctx.$attrs, {
    onContextmenu: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onContextMenu($event))),
    onMouseup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMouseUp())),
    onMousedown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMouseDown($event)))
  }), [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("canvas", {
      ref: "transformedCanvas",
      class: _normalizeClass(["transformed-canvas h-full w-full", [_ctx.transformActive ? '' : 'hidden']])
    }, null, 2),
    _createElementVNode("canvas", {
      ref: "referenceLineCanvas",
      class: _normalizeClass(["h-full w-full absolute inset-0 z-10", [_ctx.enableReferenceLine ? '' : 'hidden']])
    }, null, 2),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LoadingIcon)
        ]))
      : _createCommentVNode("", true),
    (_ctx.colourFilterSnackbar)
      ? (_openBlock(), _createBlock(_component_BaseSnackbar, {
          key: 1,
          "dismiss-after": 5000,
          "auto-dismiss": "",
          label: _ctx.colourFilter.label + ' has been applied',
          class: "z-40",
          icon: "check",
          color: "success",
          dark: "",
          onDismiss: _ctx.dismissColourFilterSnackbar
        }, null, 8, ["label", "onDismiss"]))
      : _createCommentVNode("", true)
  ], 16))
}

import { Vue, Options } from 'vue-class-component';
import BaseDatePicker from '@/lib/components/Date/BaseDatePicker.vue';
import { BaseTextInput } from '@/lib/components/Input';
import { RadioGroup } from '@/lib/components/Radio';
import { CheckboxGroup } from '@/lib/components/Checkbox';
import { BaseSelect, SelectInput } from '@/lib/components/Select';
import { MedicationSearch } from '@/lib/components/Search';
import { BaseTextarea } from '@/lib/components/Textarea';

import { FormItem } from '@/models';
// @ts-ignore
import { defineAsyncComponent } from 'vue';
import { useSmartFormStore } from '@/stores/smartForm.store';

@Options({
  components: {
    RadioGroup,
    CheckboxGroup,
    BaseTextInput,
    BaseDatePicker,
    MedicationSearch,
    BaseSelect,
    BaseTextarea,
    SelectInput
  },

  props: {
    modelValue: {
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    error: {
      type: String,
      default: null
    },

    formSubmissionId: {
      type: String,
      required: true
    },

    readonly: {
      type: Boolean,
      default: false
    }
  }
})
export default class SmartFormRepeaterItem extends Vue {
  // This is a subset of the types available in SmartFormItem
  componentTypes: { [key: string]: string } = {
    text: 'BaseTextInput',
    checkbox: 'CheckboxGroup',
    date: 'BaseDatePicker',
    search: 'MedicationSearch',
    label: 'MkBadge',
    select: 'BaseSelect',
    textarea: 'BaseTextarea',
    radio: 'RadioGroup',
    selectInput: 'SelectInput'
  };

  item!: FormItem;
  formSubmissionId!: string;
  patientId!: string;
  modelValue!: any;
  smartForm = useSmartFormStore();

  get componentType() {
    if (this.item.type === 'component') {
      return defineAsyncComponent(
        () =>
          import(
            /* webpackChunkName: "component-[request]" */
            `@/${this.item.component}.vue`
          )
      );
    }

    return this.componentTypes[this.item.type];
  }

  get conditionsMet() {
    return this.smartForm.getItemConditionsMet(this.formSubmissionId, this.item.conditions);
  }

  mounted() {
    this.$watch('conditionsMet', this.clearAnswerWhenConditionsNotMet);
  }

  beforeUnmount() {
    this.clearAnswerWhenConditionsNotMet();
  }

  clearAnswerWhenConditionsNotMet() {
    if (this.modelValue !== null && typeof this.modelValue !== 'undefined' && !this.conditionsMet) {
      this.$emit('update:modelValue', null);
      this.$emit('change');
    }
  }
}

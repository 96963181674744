import { AxiosRequestConfig } from 'axios';
import { ExternalPatientsLookupResponse } from '@/models';
import apiClient from '@/services/api/client/apiClient';
import qs from 'qs';

export class ExternalPatientLookupService {
  endpoint = '';

  constructor(organisationId: string) {
    this.endpoint = `v1/organisations/${organisationId}/external-lookup/patients`;
  }

  async search(params: AxiosRequestConfig = {}): Promise<ExternalPatientsLookupResponse> {
    return await apiClient.get(this.endpoint, {
      params,
      timeout: 60000,
      paramsSerializer: (params) => qs.stringify(params.params, { encode: false })
    });
  }
}

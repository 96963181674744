import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4527324c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-center rounded-full w-20 h-20 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InlineSvg, {
      id: "loading-svg",
      src: require('@/assets/branding/logos/logo-big-picture-medical-regular.svg'),
      class: ""
    }, null, 8, ["src"])
  ]))
}

import { Vue, Options } from 'vue-class-component';
import { v4 } from 'uuid';
import { IOption } from '@/lib';
import BasePopover from '@/lib/components/Popover/BasePopover.vue';
import PopoverButton from '@/lib/components/Popover/PopoverButton.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { InputTextHelper } from '@/lib/components/Helper';

@Options({
  components: { BaseIcon, PopoverButton, BasePopover, InputTextHelper },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array, // Array<{ value: string; label: string; disabled: boolean; }>
      default: () => []
    },
    error: {
      type: String,
      default: null
    },
    lineHeight: {
      type: Number,
      default: 1
    }
  }
})
export default class BaseSelectDropdown extends Vue {
  id: string = v4();
  options!: IOption[];
  modelValue!: IOption['value'];
  focused = false;

  get selectedLabel() {
    const selected = this.options.find((option: IOption) => this.selectedOption === option.value);
    return selected?.label;
  }

  get selectedOption() {
    return this.modelValue;
  }

  set selectedOption(value) {
    this.$emit('update:modelValue', value);
  }

  openPopup() {
    (this.$refs.selectDropdownPopover as BasePopover).open();
  }

  focus() {
    this.focused = true;
  }

  blur() {
    this.focused = false;
  }
}

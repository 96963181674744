import { Review } from '@/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class PatientReviewService {
  endpoint = '';

  constructor(patientId: string) {
    this.endpoint = `v1/patients/${patientId}/reviews`;
  }

  async update(reviewId: string, review: Partial<Review>, params: AxiosRequestConfig = {}): Promise<Review> {
    return await apiClient.patch(`${this.endpoint}/${reviewId}`, review, params);
  }

  async start(
    data: {
      organisational_unit_id: string;
      consult_id?: string;
      study_id?: string;
    },
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<Review>> {
    return await apiClient.post(this.endpoint, data, params);
  }

  async complete(reviewId: string, params: AxiosRequestConfig = {}): Promise<AxiosResponse<Review>> {
    return await apiClient.patch(
      `${this.endpoint}/${reviewId}`,
      {
        completed: true
      },
      params
    );
  }

  async fetch(reviewId: string, params: AxiosRequestConfig = {}): Promise<AxiosResponse<Review>> {
    return await apiClient.get(`${this.endpoint}/${reviewId}`, params);
  }

  async unlock(reviewId: string, params: AxiosRequestConfig = {}) {
    return await apiClient.patch(`${this.endpoint}/${reviewId}`, {
      ...params,
      locked_by_user_id: null
    });
  }

  async lock(reviewId: string, userId: string, params: AxiosRequestConfig = {}) {
    return await apiClient.patch(`${this.endpoint}/${reviewId}`, {
      ...params,
      locked_by_user_id: userId
    });
  }

  async resend(reviewId: string, organisationId: string, params: AxiosRequestConfig = {}) {
    return await apiClient.post(`${this.endpoint}/${reviewId}/resend-patient-letter`, {
      ...params,
      organisational_unit_id: organisationId
    });
  }
}

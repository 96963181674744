
import { Vue, Options } from 'vue-class-component';
import { SavingStatus } from '@/lib/constants';
import BrandLogo from '@/lib/components/Branding/BrandLogo.vue';

@Options({
  components: { BrandLogo },
  props: {
    name: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },

    status: {
      type: Number,
      default: SavingStatus.UNSET
    }
  }
})
export default class WorkflowFooter extends Vue {
  SavingStatus = SavingStatus;
}

import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lock = _resolveComponent("Lock")!

  return (_openBlock(), _createBlock(_Transition, {
    appear: "",
    "enter-active-class": "transition-opacity duration-150 ease-out",
    "leave-active-class": "transition-opacity duration-150 ease-in",
    "enter-from-class": "opacity-0",
    "leave-from-class": "opacity-0"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: "outer-modal",
        class: _normalizeClass(["fixed inset-0 z-100 flex justify-center filter-blur-none", _ctx.verticalAlign === 'center' ? 'items-center' : 'pt-10']),
        style: _normalizeStyle(_ctx.dark ? 'background-color: rgba(0, 0, 0, 0.8)' : 'background-color: rgba(246, 246, 246, 0.9)'),
        "data-dusk": "modal-box",
        onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onBackgroundClick($event)))
      }, [
        _createVNode(_component_Lock, { "return-focus": "" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              role: "dialog",
              style: {"height":"max-content"},
              class: _normalizeClass([[_ctx.danger ? 'shadow-outline-danger-transparent' : 'shadow-lg', 'bg-' + _ctx.bgColor, _ctx.dialogClass], "rounded-xl max-h-screen overflow-y-auto"])
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 2)
          ]),
          _: 3
        })
      ], 38)
    ]),
    _: 3
  }))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "table-auto border bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
          return (_openBlock(), _createElementBlock("th", {
            key: col.name,
            class: "py-2 border font-semibold text-gray-900"
          }, _toDisplayString(col.label), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, i) => {
        return (_openBlock(), _createElementBlock("tr", { key: i }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
            return (_openBlock(), _createElementBlock("td", {
              key: col.name,
              class: "py-2 text-center text-gray-900 border"
            }, _toDisplayString(row[col.name]), 1))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}
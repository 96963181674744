
import { Vue, Options } from 'vue-class-component';
import { BaseButton } from '@/lib/components/Button';
import { BaseSelect } from '@/lib/components/Select';
import { BaseIcon } from '@/lib/components/Icon';
import axios, { CancelTokenSource } from 'axios';
import { CopdProgramService, CopdAssignableOrganisationService } from '@/services/api';
import { IOption, IValidationError } from '@/lib';
import { Organisation } from '@/models';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    programId: {
      type: String,
      required: true
    }
  },
  components: { BaseButton, BaseSelect, BaseIcon }
})
export default class AssignToOrgModal extends Vue {
  programId!: string;
  organisationalUnitIdToAssign: string | null = null;
  request: CancelTokenSource | null = null;
  errors: IValidationError = {
    errors: {},
    message: ''
  };

  assignableOrganisations: Organisation[] = [];

  assignableOrganisationService = new CopdAssignableOrganisationService();
  copdProgramService = new CopdProgramService();
  notificationStore = useNotificationStore();

  mounted() {
    this.fetchAssignableOrganisations();
    this.$emit('record-event', 'started assigning patient to organisation');
  }

  async fetchAssignableOrganisations() {
    try {
      const response = await this.assignableOrganisationService.index();
      this.assignableOrganisations = response.data;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.copd.error-fetching-assignable-organisations')
      });
    }
  }

  get organisationOptions(): Array<IOption> {
    return this.assignableOrganisations.map((organisation) => ({
      value: organisation.id,
      label: organisation.name
    }));
  }

  async assignToOrganisation() {
    if (this.organisationalUnitIdToAssign === null) {
      return;
    }

    try {
      this.request = axios.CancelToken.source();
      await this.copdProgramService.update(
        this.programId,
        { assigned_organisational_unit_id: this.organisationalUnitIdToAssign },
        { cancelToken: this.request.token }
      );

      const assignedOrg =
        this.assignableOrganisations.find((organisation) => organisation.id === this.organisationalUnitIdToAssign) ??
        null;

      this.$emit('update-assigned-org', assignedOrg);
      this.$emit('record-event', 'completed assigning patient to organisation');
      this.$emit('close');
      this.organisationalUnitIdToAssign = null;
    } catch (e) {
      this.errors = e.response.data;
    }
  }
}

import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "bg-white border border-gray-200 rounded-lg p-6 space-y-5 shadow" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center" }
const _hoisted_3 = { class: "text-lg font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_VASelect = _resolveComponent("VASelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-row items-center space-x-3", { 'bg-gray-200 rounded text-transparent': _ctx.loading }])
      }, [
        _createVNode(_component_BaseIcon, {
          name: _ctx.properties.icon,
          height: "20px",
          stroke: false,
          class: _normalizeClass({ invisible: _ctx.loading })
        }, null, 8, ["name", "class"]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.properties.label), 1),
        (_ctx.isOptional)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([_ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-xs font-medium cursor-default capitalize mt-1"])
            }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(`grid gap-4 grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-${_ctx.cols}`)
    }, [
      (!_ctx.properties.onlyVA)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.unsavedValue.sphere,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.unsavedValue.sphere) = $event)),
              "data-dusk": `${_ctx.laterality}-eye-sphere`,
              label: _ctx.$t('platform.eyes.sphere'),
              placeholder: _ctx.$t('platform.common.enter-value'),
              "decimal-places": 2,
              symbol: "D",
              error: _ctx.errors.sphere && _ctx.errors.sphere[0],
              loading: _ctx.loading,
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateValue(_ctx.emittedValue)))
            }, null, 8, ["modelValue", "data-dusk", "label", "placeholder", "error", "loading"]),
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.unsavedValue.cylinder,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.unsavedValue.cylinder) = $event)),
              "data-dusk": `${_ctx.laterality}-eye-cylinder`,
              label: _ctx.$t('platform.eyes.cylinder'),
              placeholder: _ctx.$t('platform.common.enter-value'),
              "decimal-places": 2,
              symbol: "D",
              error: _ctx.errors.cylinder && _ctx.errors.cylinder[0],
              loading: _ctx.loading,
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateValue(_ctx.emittedValue)))
            }, null, 8, ["modelValue", "data-dusk", "label", "placeholder", "error", "loading"]),
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.unsavedValue.axis,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.unsavedValue.axis) = $event)),
              "data-dusk": `${_ctx.laterality}-eye-axis`,
              label: _ctx.$t('platform.eyes.axis'),
              placeholder: _ctx.$t('platform.common.enter-value'),
              "decimal-places": 0,
              symbol: "º",
              error: _ctx.errors.axis && _ctx.errors.axis[0],
              loading: _ctx.loading,
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateValue(_ctx.emittedValue)))
            }, null, 8, ["modelValue", "data-dusk", "label", "placeholder", "error", "loading"])
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_VASelect, {
        "data-dusk": `${_ctx.laterality}-va-select`,
        "model-value": _ctx.modelValue.visual_acuity,
        "va-unit": _ctx.vaUnit,
        placeholder: _ctx.$t('platform.eyes.va.placeholder'),
        label: _ctx.vaLabel,
        description: _ctx.vaDescription,
        loading: _ctx.loading,
        error: _ctx.errors.visual_acuity && _ctx.errors.visual_acuity[0],
        clear: "",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateValue({ visual_acuity: $event, ..._ctx.emittedValue })))
      }, null, 8, ["data-dusk", "model-value", "va-unit", "placeholder", "label", "description", "loading", "error"]),
      (!_ctx.properties.onlyVA && _ctx.showAdd)
        ? (_openBlock(), _createBlock(_component_BaseTextInput, {
            key: 1,
            modelValue: _ctx.unsavedValue.addition,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.unsavedValue.addition) = $event)),
            label: _ctx.$t('platform.eyes.add'),
            "data-dusk": `${_ctx.laterality}-eye-add`,
            placeholder: "0.00",
            symbol: "D",
            error: _ctx.errors.addition && _ctx.errors.addition[0],
            loading: _ctx.loading,
            onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateValue(_ctx.emittedValue)))
          }, null, 8, ["modelValue", "label", "data-dusk", "error", "loading"]))
        : _createCommentVNode("", true),
      (!_ctx.properties.onlyVA && _ctx.showAxialLength)
        ? (_openBlock(), _createBlock(_component_BaseTextInput, {
            key: 2,
            modelValue: _ctx.unsavedValue.axial_length,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.unsavedValue.axial_length) = $event)),
            label: _ctx.$t('platform.eyes.axial-length'),
            placeholder: _ctx.$t('platform.common.enter-value'),
            "decimal-places": 2,
            "data-dusk": `${_ctx.laterality}-eye-axial-length`,
            symbol: "mm",
            error: _ctx.errors.axial_length && _ctx.errors.axial_length[0],
            loading: _ctx.loading,
            onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateValue(_ctx.emittedValue)))
          }, null, 8, ["modelValue", "label", "placeholder", "data-dusk", "error", "loading"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}
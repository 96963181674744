import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex bg-white border-t border-gray-300 h-18" }
const _hoisted_2 = { class: "flex items-center justify-between w-full mx-auto max-w-screen-2xl" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = { class: "flex flex-row text-gray-400 text-medium" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!
  const _component_FooterLink = _resolveComponent("FooterLink")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.branding !== _ctx.bpmBranding)
          ? (_openBlock(), _createBlock(_component_InlineSvg, {
              key: 0,
              src: require('@/assets/images/logo-bigpicture-poweredby.svg'),
              class: "mx-10 h-8"
            }, null, 8, ["src"]))
          : (_openBlock(), _createBlock(_component_InlineSvg, {
              key: 1,
              class: "mx-10 h-6",
              src: require('@/assets/images/logo-bigpicture-text.svg')
            }, null, 8, ["src"])),
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, i) => {
            return (_openBlock(), _createElementBlock("li", { key: i }, [
              _createVNode(_component_FooterLink, { to: link }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(link.label), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128)),
          (_ctx.$meniconEducationalPortalUrl)
            ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                _createVNode(_component_FooterLink, {
                  href: _ctx.$meniconEducationalPortalUrl,
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.navigation.education-portal')) + " ", 1),
                    _createVNode(_component_BaseIcon, {
                      name: "arrow-top-right",
                      class: "ml-2"
                    })
                  ]),
                  _: 1
                }, 8, ["href"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "left-content")
      ]),
      _renderSlot(_ctx.$slots, "app-status")
    ])
  ]))
}

import { Vue, Options } from 'vue-class-component';
import { Tool } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseTooltip } from '@/lib/components/Tooltip';
import { BaseButton } from '@/lib/components/Button';

@Options({
  components: { BaseButton, BaseTooltip, BaseIcon },
  props: {
    tools: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: null
    }
  }
})
export default class AnnotationToolbar extends Vue {
  tools!: Array<Tool>;
}


import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  components: { BaseIcon }
})
export default class SearchSelectItem extends Vue {}

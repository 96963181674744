
import { Vue, Options } from 'vue-class-component';
import { IGroupSegment } from '@/lib';
import GroupTree from './GroupTree.vue';

@Options({
  components: {
    GroupTree
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
})
export default class GroupTreeList extends Vue {
  items!: Array<IGroupSegment>;
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex relative" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-center text-white w-8 h-8 rounded-lg bg-gray-900 opacity-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images.slice(0, 3), (image, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: image.id,
        class: "absolute border border-2 border-white w-8 h-8 relative bg-no-repeat bg-center rounded-lg",
        style: _normalizeStyle([_ctx.setBackgroundImage(image.thumbnailUri), _ctx.setOverlap(index)])
      }, [
        (index === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " +" + _toDisplayString(_ctx.images.length - 3), 1))
          : _createCommentVNode("", true)
      ], 4))
    }), 128))
  ]))
}
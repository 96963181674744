import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-3 justify-between items-center space-x-2" }
const _hoisted_2 = { class: "col-span-2" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  class: "text-base text-gray-900",
  "data-cy": "row-patient-summary-age"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  class: "text-sm text-gray-500",
  "data-cy": "row-patient-summary-deceased-date"
}
const _hoisted_7 = {
  key: 1,
  class: "justify-between space-x-1"
}
const _hoisted_8 = {
  key: 0,
  class: "text-sm text-gray-500",
  "data-cy": "row-patient-summary-sex"
}
const _hoisted_9 = {
  key: 1,
  class: "text-sm text-gray-500",
  "data-cy": "row-patient-summary-sex"
}
const _hoisted_10 = {
  class: "text-sm text-gray-500",
  "data-cy": "row-patient-summary-age"
}
const _hoisted_11 = {
  class: "text-sm font-regular text-gray-500",
  "data-cy": "row-patient-summary-dob"
}
const _hoisted_12 = {
  key: 0,
  class: "flex space-x-2"
}
const _hoisted_13 = { class: "text-sm text-gray-500" }
const _hoisted_14 = { class: "self-end text-sm text-gray-900" }
const _hoisted_15 = { class: "flex space-x-2" }
const _hoisted_16 = { class: "text-sm text-gray-500" }
const _hoisted_17 = {
  class: "self-end text-sm text-gray-900",
  "data-cy": "row-patient-summary-mrn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.patient.last_name) + ", " + _toDisplayString(_ctx.patient.first_name), 1)
      ]),
      (_ctx.patient.deceased_at)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('custom.uhb.patient.deceased-at', { deceasedDate: _ctx.formatDob(_ctx.patient.deceased_at) })), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.useGender && _ctx.gender)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(`${_ctx.gender.label}, `), 1))
              : (_ctx.sex)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(`${_ctx.sex.label}, `), 1))
                : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('platform.patient.years', { age: _ctx.patient.age })), 1),
            _createElementVNode("span", _hoisted_11, "(" + _toDisplayString(_ctx.formatDob(_ctx.patient.date_of_birth)) + ")", 1)
          ]))
    ]),
    _createElementVNode("div", null, [
      (_ctx.tagFeatureFlagEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('custom.uhb.copd.home-monitoring')), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.homeMonitoring), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('custom.uhb.patient.nhs')), 1),
        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.nhsNumberFormatted), 1)
      ])
    ])
  ]))
}
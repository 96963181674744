import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "rounded-lg bg-gray-300 px-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ClearButton = _resolveComponent("ClearButton")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    style: {"min-width":"max-content"},
    class: ["p-2 space-x-2 shadow inline-flex items-center text-sm font-medium select-none rounded-full", _ctx.dark ? 'bg-gray-800 text-white' : 'bg-white text-gray-900 border']
  }, _ctx.$attrs), [
    (_ctx.imageUrl && _ctx.imageUrl.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "w-4 h-4 block rounded-full bg-cover bg-no-repeat bg-center",
          style: _normalizeStyle('background-image: url(' + _ctx.imageUrl + ')')
        }, null, 4))
      : _createCommentVNode("", true),
    (_ctx.icon && _ctx.icon.length)
      ? (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 1,
          name: _ctx.icon,
          class: "w-4 h-4"
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (_ctx.count && _ctx.count > 1)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.count), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.removable)
      ? (_openBlock(), _createBlock(_component_ClearButton, {
          key: 3,
          dark: _ctx.dark,
          class: "",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('removed')), ["stop"]))
        }, null, 8, ["dark"]))
      : _createCommentVNode("", true)
  ], 16))
}
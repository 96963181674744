
import { Vue, Options } from 'vue-class-component';
import { apiClient, ConsultService } from '@/services/api';
import { FormAlert, FormCompletion, FormConfig, FormItem, FormSection, FormSubmission, Patient } from '@/models';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { BaseButton, ButtonLink } from '@/lib/components/Button';
import EmergencyModal from '@/lib/components/Modals/EmergencyModal.vue';
import PrefillFormNotification from '@/lib/components/Notification/PrefillFormNotification.vue';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import SmartFormComplete from './SmartFormComplete.vue';
import SmartFormStatus from './SmartFormStatus.vue';
import SmartFormSection from './SmartFormSection.vue';

import { getItemsRecursively } from '@/helpers/smart-form.helper';
import { SavingStatus } from '@/lib/constants';
import { useSessionStore } from '@/stores/session.store';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    PrefillFormNotification,
    SmartFormStatus,
    EmergencyModal,
    BaseButton,
    BaseIcon,
    WorkflowLayout,
    SmartFormSection,
    SmartFormComplete,
    ButtonLink
  },
  props: {
    config: {
      type: Object,
      required: true
    },

    formId: {
      type: String,
      default: null
    },

    participantId: {
      type: String,
      default: null
    },

    patientId: {
      type: String,
      default: null
    },

    patient: {
      type: Object,
      default: null
    },

    formSubmissionId: {
      type: String,
      required: true
    },

    prefilledFormSubmission: {
      type: Object,
      default: null
    }
  },
  inheritAttrs: false
})
export default class SmartForm extends Vue {
  config!: FormConfig;
  formSubmissionId!: string;
  patientId?: string;
  participantId?: string;
  patient!: Patient;
  formId!: string;
  loading = false;
  smartFormDraft = false;
  showValidationPopup = false;
  closedModals: string[] = [];
  prefilledFormSubmission!: FormSubmission | null;
  unregisterRouterGuard?: () => void;
  smartForm = useSmartFormStore();

  consultService: ConsultService = new ConsultService();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  get isPrefilled(): boolean {
    const currentSection = this.activeSections[this.step];
    return !!(
      this.prefilledFormSubmission &&
      ((currentSection && currentSection.prefill && currentSection.prefill.length) ||
        this.sectionItems.some((item) => item.prefill && item.prefill.length))
    );
  }

  get isPrescription(): boolean {
    return this.config.type === 'prescription';
  }

  get status(): SavingStatus {
    if (this.error) {
      return SavingStatus.ERROR;
    }
    if (this.saving) {
      return SavingStatus.SAVING;
    }
    if (this.isDirty) {
      return SavingStatus.UNSAVED;
    }
    return SavingStatus.SAVED;
  }

  get exitDraftLabel(): string {
    if (this.participantId) {
      return this.$t('platform.form.exit-to-list') as string;
    } else if (this.isPrescription) {
      return this.$t('platform.patient.exit-to-prescriptions-list') as string;
    }
    return this.$t('custom.uhb.consult.exit-to-list') as string;
  }

  get exitDraftHeading(): string {
    return (
      this.isPrescription ? this.$t('custom.menicon.prescriptions.exit') : this.$t('custom.uhb.consult.exit')
    ) as string;
  }

  get alerts(): FormAlert[] {
    return this.config.alerts
      ? this.config.alerts.map((alert: FormAlert) => ({
        ...alert,
        active: this.smartForm.getItemConditionsMet(this.formSubmissionId, alert.conditions)
      }))
      : [];
  }

  get hideButtons(): boolean {
    return !!(this.activeSections[this.step] && this.activeSections[this.step].hide_action_buttons);
  }

  get activeSections(): Array<FormSection> {
    return this.config.sections.filter((section: FormSection) =>
      this.smartForm.getItemConditionsMet(this.formSubmissionId, section.conditions)
    );
  }

  get completedSection(): FormCompletion {
    if (!Array.isArray(this.config.completion)) {
      return this.config.completion;
    }

    return this.config.completion.filter((complete: FormCompletion) =>
      this.smartForm.getItemConditionsMet(this.formSubmissionId, complete.conditions)
    )[0];
  }

  get steps(): string[] {
    return this.activeSections.map((section: FormSection) => section.name);
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get saving(): boolean {
    return this.smartForm.promises.length > 0;
  }

  get completed(): boolean {
    return this.smartForm.getCompleted(this.formSubmissionId);
  }

  get error() {
    return this.smartForm.error;
  }

  get errorCount(): number {
    return this.smartForm.errors[this.formSubmissionId]
      ? Object.values(this.smartForm.errors[this.formSubmissionId]).length
      : 0;
  }

  get errorsInCurrentStep(): Array<string> {
    const errors = this.smartForm.errors[this.formSubmissionId];
    if (errors) {
      const errorFormItemIds = Object.keys(errors);
      return this.currentStepFormItemIds.filter((formItemId: string) =>
        errorFormItemIds.some((errorFormItemId) => errorFormItemId.startsWith(formItemId))
      );
    }
    return [];
  }

  get isDirty(): boolean {
    return (
      this.smartForm.dirty[this.formSubmissionId] &&
      this.smartForm.dirty[this.formSubmissionId].length > 0
    );
  }

  get currentStepFormItemIds(): Array<string> {
    if (this.activeSections.length) {
      const activeSection = this.activeSections[this.step];
      // Get the ids of each form item and it's child items into a flat map
      return activeSection && activeSection.items
        ? activeSection.items.flatMap(getItemsRecursively).map((item) => item.id)
        : [];
    }
    return [];
  }

  get sectionItems(): Array<FormItem> {
    const activeSection = this.activeSections[this.step];
    return activeSection && activeSection.items ? activeSection.items.flatMap(getItemsRecursively) : [];
  }

  get backToLabel() {
    return this.patient
      ? (this.$t('platform.patient.back-to-patient') as string)
      : (this.$t('platform.participant.back-to-participant') as string);
  }

  get exitToListLabel() {
    return this.patient ? this.$t('platform.patient.exit-to-list') : this.$t('platform.participant.exit-to-list');
  }

  created() {
    // Make sure we always start on the first step when navigating to this page
    if (this.step > 0) {
      this.$router.replace(this.$route.path);
    }
    this.$watch(
      'alerts',
      (current: FormAlert[], previous: FormAlert[]) => {
        current.filter((alert) => {
          const prevAlert = previous.find((a) => a.id === alert.id);
          if (this.closedModals.includes(alert.id) && prevAlert && prevAlert.active && !alert.active) {
            this.closedModals = [...this.closedModals].filter((id) => id !== alert.id);
          }
        });
      },
      { deep: true }
    );
    this.$watch('formSaving', (curr, old) => {
      // if form has been saved and there are errors on the current page, relaunch validation as items may not be there anymore
      if (!curr && old && this.errorCount) {
        this.validateSection();
      }
    });
  }

  back() {
    if (this.step === 0) {
      this.cancel();
      return;
    }

    // Clear validation errors on back
    this.showValidationPopup = false;
    this.smartForm.clearErrors(this.formSubmissionId);
    this.$router.go(-1);
  }

  async next() {
    this.loading = true;

    let useDebounce = true;
    if (this.step === this.steps.length - 1) {
      useDebounce = false;
    }
    await this.validateSection();

    if (this.isDirty) {
      await this.saveAnswers(useDebounce);
    }
    await Promise.allSettled(this.smartForm.promises);

    // Clear errors if items are not in the section
    this.errorsInCurrentStep.map((itemId) => {
      const item = this.sectionItems.find((item: FormItem) => item.id === itemId);
      if (!item || !this.smartForm.getItemConditionsMet(this.formSubmissionId, item.conditions)) {
        this.smartForm.clearError(this.formSubmissionId, itemId);
      }
    });

    if (this.errorsInCurrentStep?.length) {
      return Promise.reject();
    }

    await this.validateSection();

    try {
      // @ts-ignore
      await this.$router.push({
        ...this.$route,
        query: {
          step: String(this.step + 2) // Add 2 because the URL param is 1-based
        }
      });
    } finally {
      this.loading = false;
      this.showValidationPopup = false;
    }

    return Promise.resolve();
  }

  async tryNext() {
    try {
      await this.next();
    } catch (e) {
      this.showValidationPopup = true;
      this.loading = false;
    }
  }

  async complete() {
    // Push a route so that the final step can hook into the process if needed
    await this.tryNext();
    if (!this.errorCount) {
      try {
        const route = this.patientId
          ? `v1/patients/${this.patientId}/form-submissions/${this.formSubmissionId}`
          : `v1/anonymous-participants/${this.participantId}/form-submissions/${this.formSubmissionId}`;
        await apiClient.patch(route, {
          completed: true
        });
        this.disableBack();
        await this.smartForm.complete(this.formSubmissionId);
      } catch (e) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.form.complete-error')
        });
        this.back();
      }
    }
  }

  closeModal(id: string) {
    this.closedModals = [...this.closedModals, id];
  }

  draftAndExit() {
    this.smartFormDraft = false;
    let route;
    if (this.participantId && !this.patientId) {
      route = `/participants/${this.participantId}/forms`;
    } else if (this.isPrescription) {
      route = `/patients/${this.patientId}/prescriptions`;
    } else {
      route = `/patients/${this.patientId}/consults`;
    }
    this.$router.push(route);
  }

  saveAndExit() {
    this.saveAnswers();
    this.cancel();
  }

  cancel() {
    const route = this.patientId ? `/patients/${this.patientId}` : `/participants/${this.participantId}`;
    this.$router.push(route);
  }

  disableBack() {
    // Make the back button go back to the patient list instead of going back through steps
    this.unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (to.query.step && to.query.step < this.$route.query.step) {
        const route = this.patientId ? `/patients/${this.patientId}` : `/participants/${this.participantId}`;
        next(route);
      } else {
        if (this.unregisterRouterGuard) {
          this.unregisterRouterGuard();
        }
        next();
      }
    });
  }

  beforeUnmount() {
    if (this.unregisterRouterGuard) {
      this.unregisterRouterGuard();
    }
  }

  async saveAnswers(useDebounce = true) {
    return await this.smartForm.saveAnswers({
      patientId: this.patientId,
      participantId: this.participantId,
      formSubmissionId: this.formSubmissionId,
      organisationId: this.sessionStore.currentOrganisationId,
      useDebounce
    });
  }

  async validateSection() {
    const currentSection = this.activeSections[this.step];
    if (currentSection) {
      const route = this.patientId
        ? `v1/patients/${this.patientId}/form-submissions/${this.formSubmissionId}/sections/${currentSection.id}`
        : `v1/anonymous-participants/${this.participantId}/form-submissions/${this.formSubmissionId}/sections/${currentSection.id}`;
      const response = await apiClient.get<{
        id: string;
        validation: {
          passes: boolean;
          errors: { [id: string]: string[] };
        };
      }>(route);

      if (Object.keys(response.data.validation.errors).length === 0) {
        this.smartForm.clearErrors(this.formSubmissionId);
      }

      for (const [itemId, errors] of Object.entries(response.data.validation.errors)) {
        if (this.smartForm.getError(this.formSubmissionId, itemId)) {
          continue; // Don't replace existing errors as they are likely to be more specific
        }

        this.smartForm.errors = {
          ...this.smartForm.errors,
          [this.formSubmissionId]: {
            ...this.smartForm.errors[this.formSubmissionId],
            [itemId]: errors[0]
          }
        };
      }
    }
  }
}

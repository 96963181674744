
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
})
export default class SummaryItem extends Vue {}

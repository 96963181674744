
import { Vue, Options } from 'vue-class-component';
import { BaseButton } from '@/lib/components/Button';
import { BaseTextInput } from '@/lib/components/Input';
import { Patient } from '@/models';
import axios, { CancelTokenSource } from 'axios';
import { CopdPatientService } from '@/services/api';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  components: { BaseButton, BaseTextInput }
})
export default class InvitePatientModal extends Vue {
  patient!: Patient;
  inviteErrors = '';
  request: CancelTokenSource | null = null;

  copdPatientService = new CopdPatientService();

  async invitePatient() {
    try {
      this.request = axios.CancelToken.source();
      await this.copdPatientService.invitePatient(this.patient.id, {
        cancelToken: this.request.token
      });
      this.inviteErrors = '';
      this.request = null;
      this.$emit('update-invitation-banner');
    } catch (e) {
      this.inviteErrors = e.response.data.message;

      if (!axios.isCancel(e)) {
        this.inviteErrors = e.response.data.message;
      }
    }
  }
}

import { Prescription } from '@/models/prescription.model';
import { User } from '@/models/user.model';
import { Laterality } from '@/custom/menicon/models/laterality';

export interface Encounter {
  id: string;
  type: EncounterTypeName;
  r_visual_acuity?: string;
  r_sphere?: string;
  r_cylinder?: string;
  r_axial_length?: string;
  r_mls_id?: string;
  r_hvid?: string;
  r_flat_k?: string;
  r_steep_k?: string;
  r_flat_k_axis?: string;
  r_addition?: string;
  r_axis?: string;
  r_is_expected_treatment_zone?: boolean;
  r_is_expected_centration?: boolean;
  r_pattern?: 'a' | 'b';
  l_visual_acuity?: string;
  l_sphere?: string;
  l_cylinder?: string;
  l_axial_length?: string;
  l_mls_id?: string;
  l_hvid?: string;
  l_flat_k?: string;
  l_steep_k?: string;
  l_flat_k_axis?: string;
  l_addition?: string;
  l_axis?: string;
  r_power_correction?: string;
  l_power_correction?: string;
  l_is_expected_treatment_zone?: boolean;
  l_is_expected_centration?: boolean;
  l_pattern?: 'a' | 'b';
  is_cyclopegic_refraction?: boolean;
  combined_visual_acuity?: string;
  form_submission_id?: string;
  completed_at: string;
  updated_at: string;
  prescriptions: Prescription[];
  journey_id: string;
  last_activity?: {
    user: User;
    name: EncounterTypeName;
    completed_at: string;
  };
  order?: {
    id: string;
    name: string;
    status?: string;
  }; // order circular dependency
  journey?: {
    id: string;
    type?: string;
    patient_id: string;
    organisational_unit_id: string;
    ended_at?: string;
    ended_reasons?: { code: string; description?: string }[];
    ended_further_details?: string;
  };
  laterality?: Laterality | null;
  is_va_satisfactory?: boolean;
  l_is_va_satisfactory?: boolean;
  r_is_va_satisfactory?: boolean;
  r_distance_visual_acuity?: string;
  l_distance_visual_acuity?: string;
  combined_distance_visual_acuity?: string;
  va_unit: VaUnit;
  k_unit: KUnit;
  slit_lamp_conditions?: { [key in SlitLampCondition]?: SlitLampConditionGrading };
  is_slit_lamp_condition_none?: boolean;
  slit_lamp_details?: string;
  pre_fitting_slit_lamp_conditions?: { [key in SlitLampCondition]?: SlitLampConditionGrading };
  pre_fitting_is_slit_lamp_condition_none?: boolean;
  pre_fitting_slit_lamp_details?: string;
  locale?: string;
}

export enum SlitLampCondition {
  CORNEAL_STAINING = 'corneal-staining',
  CORNEAL_INFILTRATES = 'corneal-infiltrates',
  PAPILLARY_REACTION = 'papillary-reaction',
  CONJUNCTIVAL_HYPERMIA = 'conjunctival-hyperemia',
  CORNEAL_NEOVASCULARISATION = 'corneal-neovascularisation'
}

export interface SlitLampConditionGrading {
  r_grading?: number;
  l_grading?: number;
}

export enum VaUnit {
  SIX_SIX = '6/6',
  TWENTY_TWENTY = '20/20',
  DECIMAL = 'decimal',
  LOG_MAR = 'logMar',
  MONOYER = 'Monoyer'
}

export enum KUnit {
  MM = 'mm',
  DIOPTRES = 'D'
}

export enum EncounterTypeName {
  INITIAL_MEASUREMENT = 'initial-measurement',
  BLOOM_DAY_1_WEEK_LENS_EFFICACY_ASSESSMENT = 'bloom-day-1-week-lens-efficacy-assessment',
  BLOOM_DAY_3_OR_9_MONTHS_HEALTH_ASSESSMENT = 'bloom-day-3-or-9-months-health-assessment',
  BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT = 'bloom-day-6-or-12-months-progress-assessment',
  BLOOM_DAY_LENS_COLLECTION = 'bloom-day-lens-collection',
  BLOOM_NIGHT_1_NIGHT_FOLLOW_UP = 'build-up-1-night',
  BLOOM_NIGHT_1_WEEK_FOLLOW_UP = 'build-up-1-week',
  BLOOM_NIGHT_3_OR_9_MONTHS_ASSESSMENT = 'bloom-night-3-month-check',
  BLOOM_NIGHT_6_OR_12_MONTHS_ASSESSMENT = 'bloom-night-progress-assessment',
  BLOOM_NIGHT_3_WEEK_LENS_EFFICACY_ASSESSMENT = 'bloom-night-lens-efficacy-assessment',
  BLOOM_NIGHT_COLLECTION = 'bloom-night-collection',
  LENS_REORDER = 'lens-reorder'
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col fixed top-0 z-10 right-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseNotification = _resolveComponent("BaseNotification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (notification, index) => {
      return (_openBlock(), _createBlock(_component_BaseNotification, {
        key: notification.id,
        action: notification.action,
        color: notification.color,
        icon: notification.icon,
        title: notification.title,
        label: notification.label,
        display: index < _ctx.displayItems,
        "dismiss-after": _ctx.dismissAfter,
        "auto-dismiss": _ctx.autoDismiss,
        class: _normalizeClass(
        index < _ctx.displayItems
          ? 'mb-4 z-20'
          : index === _ctx.displayItems
            ? 'opacity-75 mt-0 z-10 overflow-hidden'
            : index === _ctx.displayItems + 1
              ? 'opacity-50 mt-0 z-10 overflow-hidden'
              : 'opacity-0'
      ),
        style: _normalizeStyle(
        index === _ctx.displayItems
          ? 'transform: translateY(-110%);'
          : index === _ctx.displayItems + 1
            ? 'transform: translateY(-200%);'
            : ''
      ),
        onDismiss: ($event: any) => (_ctx.dismissNotification(notification.id))
      }, null, 8, ["action", "color", "icon", "title", "label", "display", "dismiss-after", "auto-dismiss", "class", "style", "onDismiss"]))
    }), 128))
  ]))
}
import { GenericService } from './generic.service';
import apiClient from '@/services/api/client/apiClient';
import { ICodeValueEntity } from '@/models';

export class HealthRecordScaleService extends GenericService<ICodeValueEntity[]> {
  endpoint = 'health-record/scales';

  async getScalesBorg(): Promise<any> {
    return await apiClient.get(`${this.endpoint}/borg-breathlessness-scale/amount-of-breathlessness`);
  }
}

import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = ["id", "checked", "disabled", "readonly"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: _ctx.id,
      type: "checkbox",
      checked: _ctx.modelValue,
      disabled: _ctx.disabled,
      readonly: _ctx.readonly,
      class: _normalizeClass(["form-checkbox mr-3", _ctx.readonly ? 'cursor-default' : 'cursor-pointer']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick($event))),
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.checked)))
    }, null, 42, _hoisted_2),
    (_ctx.label && _ctx.label.length)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["font-medium text-base select-none cursor-pointer", _ctx.modelValue ? 'text-gray-900' : 'text-gray-500']),
          for: _ctx.id
        }, _toDisplayString(_ctx.label), 11, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}
import { GenericService } from './generic.service';
import apiClient from '@/services/api/client/apiClient';
import { AxiosResponse } from 'axios';
import { ICodeValueEntity } from '@/models';

export class CopdTerminologyService extends GenericService<ICodeValueEntity[]> {
  endpoint = 'health-record/terminologies';

  async getMethodOfOxygenDelivery(): Promise<AxiosResponse<ICodeValueEntity[]>> {
    return await apiClient.get(`${this.endpoint}/method-of-oxygen-delivery`);
  }
}

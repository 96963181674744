import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex rounded-lg shadow p-3" }
const _hoisted_2 = { class: "ml-4" }
const _hoisted_3 = { class: "font-bold text-gray-900" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseIcon, {
      name: "primary-vector",
      height: "48px",
      stroke: false
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('platform.form.prefill-form-notification-title')), 1),
      _createElementVNode("div", {
        class: "text-gray-500",
        innerHTML: 
          _ctx.$t('platform.form.prefill-form-notification-sub-title', {
            date: _ctx.date,
            time: _ctx.time,
            username: _ctx.username
          })
        
      }, null, 8, _hoisted_4)
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex divide-x divide-gray-200 -mx-4" }
const _hoisted_2 = { class: "w-3/12 px-4 flex flex-col justify-between" }
const _hoisted_3 = { class: "space-y-2 text-sm" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "leading-none text-gray-900 font-medium" }
const _hoisted_6 = { class: "leading-none text-gray-500" }
const _hoisted_7 = {
  key: 0,
  class: "leading-none"
}
const _hoisted_8 = { class: "-m-1.5" }
const _hoisted_9 = { class: "table-auto w-full" }
const _hoisted_10 = { class: "text-gray-500 text-sm font-normal mt-2" }
const _hoisted_11 = { class: "w-4/12 px-4 leading-none" }
const _hoisted_12 = { class: "table-auto w-full" }
const _hoisted_13 = { class: "w-5/12 px-4 space-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_CopdMeasurementRow = _resolveComponent("CopdMeasurementRow")!
  const _component_CopdUhbModifiedAnthonisenCriteriaMeasurementReading = _resolveComponent("CopdUhbModifiedAnthonisenCriteriaMeasurementReading")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { padding: "5" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_MkBadge, {
                label: _ctx.$t('custom.uhb.copd.' + _ctx.labelState, [_ctx.reviewedAt]),
                color: _ctx.labelColor,
                size: "xsmall",
                filled: _ctx.isNotReviewed
              }, null, 8, ["label", "color", "filled"])
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.createdAt), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('custom.uhb.copd.created-by', [_ctx.measurement.attributes.created_by])), 1)
          ]),
          (_ctx.isReviewed && _ctx.review)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("table", _hoisted_9, [
                    _createVNode(_component_CopdMeasurementRow, {
                      label: _ctx.$t('custom.uhb.copd.outcome'),
                      value: _ctx.outcomeLabel(_ctx.review.attributes.outcome)
                    }, null, 8, ["label", "value"]),
                    (_ctx.isChange || _ctx.isDischarge)
                      ? (_openBlock(), _createBlock(_component_CopdMeasurementRow, {
                          key: 0,
                          label: _ctx.$t('custom.uhb.copd.outcome-reason'),
                          value: _ctx.reasonLabel(_ctx.review.attributes.reason)
                        }, null, 8, ["label", "value"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('custom.uhb.copd.reviewed-by')) + " " + _toDisplayString(_ctx.review.attributes.created_by), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("table", _hoisted_12, [
            _createVNode(_component_CopdMeasurementRow, {
              label: _ctx.$t('custom.uhb.copd.spo2'),
              value: _ctx.measurement.attributes.pulse_oximetry.spo2
              ? _ctx.$t('custom.uhb.copd.spo2-value', [_ctx.measurement.attributes.pulse_oximetry.spo2])
              : '—',
              target: _ctx.$t('custom.uhb.copd.spo2-prescribed-value', [_ctx.settings.spo2_range_low, _ctx.settings.spo2_range_high]),
              alert: _ctx.measurement.attributes.pulse_oximetry.spo2
              && _ctx.measurement.attributes.pulse_oximetry.spo2 <= _ctx.settings.spo2_range_low
            }, null, 8, ["label", "value", "target", "alert"]),
            _createVNode(_component_CopdMeasurementRow, {
              label: _ctx.$t('custom.uhb.copd.inspired-oxygen'),
              value: _ctx.measurement.attributes.pulse_oximetry.method_of_oxygen_delivery.label,
              target: `(${_ctx.settings.method_of_oxygen_delivery.label})`
            }, null, 8, ["label", "value", "target"]),
            _createVNode(_component_CopdMeasurementRow, {
              label: _ctx.$t('custom.uhb.copd.flow-rate'),
              value: 
              _ctx.measurement.attributes.pulse_oximetry.flow_rate
                ? `${_ctx.measurement.attributes.pulse_oximetry.flow_rate} ${_ctx.$t('custom.uhb.copd.litres-per-min')}`
                : `—`
            ,
              target: _ctx.settings.flow_rate ? `(${_ctx.settings.flow_rate} ${_ctx.$t('custom.uhb.copd.litres-per-min')})` : `—`
            }, null, 8, ["label", "value", "target"]),
            _createVNode(_component_CopdMeasurementRow, {
              label: _ctx.$t('custom.uhb.copd.borg'),
              value: _ctx.measurement.attributes.borg_breathlessness_scale.amount_of_breathlessness.value
            }, null, 8, ["label", "value"]),
            _createVNode(_component_CopdMeasurementRow, {
              label: _ctx.$t('custom.uhb.copd.heart-rate'),
              value: 
              _ctx.measurement.attributes.pulse.rate
                ? _ctx.$t('custom.uhb.copd.heart-rate-value', [_ctx.measurement.attributes.pulse.rate])
                : `—`
            
            }, null, 8, ["label", "value"]),
            (_ctx.measurement.attributes.respiration)
              ? (_openBlock(), _createBlock(_component_CopdMeasurementRow, {
                  key: 0,
                  label: _ctx.$t('custom.uhb.copd.respiration-rate'),
                  value: 
              _ctx.measurement.attributes.respiration.rate
                ? _ctx.$t('custom.uhb.copd.respiration-rate-value', [_ctx.measurement.attributes.respiration.rate])
                : '—'
            
                }, null, 8, ["label", "value"]))
              : _createCommentVNode("", true),
            (_ctx.measurement.attributes.body_temperature)
              ? (_openBlock(), _createBlock(_component_CopdMeasurementRow, {
                  key: 1,
                  label: _ctx.$t('custom.uhb.copd.temperature'),
                  value: 
              _ctx.$t('custom.uhb.copd.temperature-value', [_ctx.measurement.attributes.body_temperature.temperature.magnitude])
            
                }, null, 8, ["label", "value"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uhbModifiedAnthonisenCriteriaMeasurementReadings, (uhbModifiedAnthonisenCriteriaMeasurementReading) => {
            return (_openBlock(), _createBlock(_component_CopdUhbModifiedAnthonisenCriteriaMeasurementReading, {
              key: uhbModifiedAnthonisenCriteriaMeasurementReading.label,
              label: uhbModifiedAnthonisenCriteriaMeasurementReading.label,
              value: uhbModifiedAnthonisenCriteriaMeasurementReading.value,
              alert: uhbModifiedAnthonisenCriteriaMeasurementReading.alert
            }, null, 8, ["label", "value", "alert"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-gray-900 text-sm font-medium" }
const _hoisted_2 = {
  key: 0,
  class: "text-gray-500 text-sm font-normal inline-block mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", {
      class: _normalizeClass(["p-1.5 align-top text-gray-500 text-sm font-normal", { 'bg-alert-200 rounded-l': _ctx.alert }])
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("td", {
      class: _normalizeClass(["p-1.5 align-top", { 'bg-alert-200 rounded-r': _ctx.alert }])
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.value), 1),
      (_ctx.target)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.target), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
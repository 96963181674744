import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "overflow-hidden"
}
const _hoisted_2 = {
  key: "loading-auth-service-placeholder",
  class: "auth-booting w-full min-h-screen flex flex-col items-center content-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MaintenanceModal = _resolveComponent("MaintenanceModal")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_BaseSnackbar = _resolveComponent("BaseSnackbar")!
  const _component_PageLoading = _resolveComponent("PageLoading")!
  const _directive_scroll_lock = _resolveDirective("scroll-lock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading && _ctx.brandingLoaded)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: "root-router-view",
          class: _normalizeClass([_ctx.isMaintenance && 'filter-blur', "root-router-view"])
        }, [
          _createVNode(_component_MaintenanceModal, { "auto-fetch-limit": _ctx.autoHealthCheckExpired }, null, 8, ["auto-fetch-limit"]),
          _createVNode(_component_RouterView),
          (_ctx.snackbar)
            ? (_openBlock(), _createBlock(_component_BaseSnackbar, {
                key: 0,
                label: _ctx.snackbar.label,
                "auto-dismiss": _ctx.snackbar.autoDismiss,
                "dismiss-after": _ctx.snackbar.dismissAfter,
                color: _ctx.snackbar.color,
                icon: _ctx.snackbar.icon,
                onDismiss: _ctx.dismissSnackbar
              }, null, 8, ["label", "auto-dismiss", "dismiss-after", "color", "icon", "onDismiss"]))
            : _createCommentVNode("", true)
        ], 2)), [
          [_directive_scroll_lock, _ctx.bodyScrollLock]
        ])
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PageLoading)
        ]))
  ]))
}
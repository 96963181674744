
import { Vue, Options } from 'vue-class-component';
import { HealthCheckService } from '@/services/api';

import { BaseIcon } from '@/lib/components/Icon';
import { BaseButton } from '@/lib/components/Button';
import BaseModal from './BaseModal.vue';
import { useSessionStore } from '@/stores/session.store';

@Options({
  props: {
    autoFetchLimit: {
      type: Boolean
    }
  },
  components: {
    BaseIcon,
    BaseButton,
    BaseModal
  }
})
export default class MaintenanceModal extends Vue {
  healthCheckService = new HealthCheckService();
  sessionStore = useSessionStore();
  isCheckingServiceHealth = false;

  get isMaintenance() {
    return this.sessionStore.isMaintenance;
  }

  toggleIsChecking(): void {
    this.isCheckingServiceHealth = !this.isCheckingServiceHealth;
  }

  async manualHealthCheck() {
    this.toggleIsChecking();

    try {
      await this.healthCheckService.getHealthStatus();
      this.sessionStore.isMaintenance = false;
      this.toggleIsChecking();
      this.$router.go(0);
    } catch {
      this.toggleIsChecking();
    }
  }
}

import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["tabIndex"]
const _hoisted_2 = ["tabIndex"]
const _hoisted_3 = ["tabIndex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.hasLeadingGuards)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          tabIndex: _ctx.disabled ? -1 : 0,
          style: _normalizeStyle(_ctx.hidden),
          "aria-hidden": "true"
        }, null, 12, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.hasLeadingGuards)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          tabIndex: _ctx.disabled ? -1 : 1,
          style: _normalizeStyle(_ctx.hidden),
          "aria-hidden": "true"
        }, null, 12, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _mergeProps(_ctx.groupAttr, {
      "data-lock": "",
      onFocusout: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
    }), [
      _renderSlot(_ctx.$slots, "default")
    ], 16),
    (_ctx.hasTailingGuards)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          tabIndex: _ctx.disabled ? -1 : 0,
          style: _normalizeStyle(_ctx.hidden),
          "aria-hidden": "true"
        }, null, 12, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}
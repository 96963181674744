import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-6 h-full sm:max-w-xl py-8 md:max-w-3xl lg:max-w-5xl xl:max-w-6xl sm:mx-auto mx-4" }
const _hoisted_2 = { class: "flex flex-row justify-between" }
const _hoisted_3 = { class: "flex flex-col space-y-2" }
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = { class: "flex flex-row items-center space-x-6" }
const _hoisted_6 = { class: "flex flex-col space-y-1" }
const _hoisted_7 = { class: "text-xs font-medium leading-tight text-gray-500" }
const _hoisted_8 = { class: "text-sm font-medium" }
const _hoisted_9 = { class: "flex flex-col space-y-1" }
const _hoisted_10 = { class: "text-xs font-medium leading-tight text-gray-500" }
const _hoisted_11 = { class: "text-sm font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_FormSummarySection = _resolveComponent("FormSummarySection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseButton, {
          color: "clear",
          size: "small",
          "left-icon": "arrow-left",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.config.name), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('platform.common.date-created')), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.formatDate(_ctx.date)), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('platform.common.completed-by')), 1),
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.userName), 1)
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeSections, (section) => {
      return (_openBlock(), _createBlock(_component_FormSummarySection, {
        key: section.id,
        section: section,
        "patient-id": _ctx.patientId,
        "participant-id": _ctx.participantId,
        "form-submission-id": _ctx.formSubmissionId,
        locale: _ctx.locale,
        level: 0
      }, null, 8, ["section", "patient-id", "participant-id", "form-submission-id", "locale"]))
    }), 128))
  ]))
}
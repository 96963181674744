
import { Vue, Options } from 'vue-class-component';
import ResizeObserver from 'resize-observer-polyfill';
import { TooltipPosition } from '@/lib';
import * as helper from '@/lib/helpers/tooltip.helper';

@Options({
  props: {
    position: {
      type: String,
      default: 'below',
      validator: (value) => ['before', 'after', 'above', 'right', 'left', 'below', 'top', 'bottom'].includes(value)
    },
    horizontalAlignment: {
      type: String,
      default: 'center',
      validator: (value) => ['center', 'right', 'left'].includes(value)
    },
    showDelay: {
      default: 0,
      type: Number
    },
    hideDelay: {
      default: 0,
      type: Number
    },
    disabled: {
      default: false,
      type: Boolean
    }
  }
})
export default class BaseTooltip extends Vue {
  showDelay = 0;
  hideDelay = 0;
  private showTooltip = false;
  private position!: TooltipPosition;
  private horizontalAlignment!: 'center' | 'right' | 'left';
  private style = '';
  private hideTimeoutId: number | null = null;
  private showTimeoutId: number | null = null;
  private observer: ResizeObserver | null = null;

  mounted() {
    this.setTooltipPosition();
    this.$watch('position', () => this.setTooltipPosition());
    window.addEventListener('wheel', () => this.setTooltipPosition());
    window.addEventListener('resize', () => this.setTooltipPosition());
    this.observer = new ResizeObserver(() => this.setTooltipPosition());
    this.observer.observe(this.$refs.target as HTMLElement);
  }

  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener('wheel', () => this.setTooltipPosition());
    window.removeEventListener('resize', () => this.setTooltipPosition());
  }

  show(delay: number = this.showDelay) {
    if (this.hideTimeoutId) {
      window.clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }

    this.showTimeoutId = window.setTimeout(() => {
      this.showTooltip = true;
      this.showTimeoutId = null;
    }, delay);
  }

  hide(delay: number = this.hideDelay) {
    if (this.showTimeoutId) {
      window.clearTimeout(this.showTimeoutId);
      this.showTimeoutId = null;
    }

    this.hideTimeoutId = window.setTimeout(() => {
      this.showTooltip = false;
      this.hideTimeoutId = null;
    }, delay);
  }

  setTooltipPosition() {
    const content = this.$refs.content as HTMLElement;
    if (content) {
      this.style = helper.isElementVisible(content.getBoundingClientRect())
        ? this.getTooltipPosition(this.position, this.horizontalAlignment)
        : this.getTooltipPosition(helper.getFallbackPosition(this.position), this.horizontalAlignment);
    }
  }

  private getTooltipPosition(position: TooltipPosition, horizontalAlignment: 'center' | 'right' | 'left') {
    return helper.getTooltipStyle(
      position,
      this.$refs.content as HTMLElement,
      this.$refs.target as HTMLElement,
      horizontalAlignment
    );
  }
}


import { Vue, Options } from 'vue-class-component';
import { logoutService } from '@/services/auth';
import { Organisation } from '@/models';
import { BaseAvatar } from '@/lib/components/Avatar';
import SupportModal from '@/lib/components/Modals/SupportModal.vue';
import { PopoverButton, BasePopover, PopoverLink } from '@/lib/components/Popover';
import NavDropdownButton from './NavDropdownButton.vue';
import BrandLogo from '@/lib/components/Branding/BrandLogo.vue';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    NavDropdownButton,
    BasePopover,
    PopoverButton,
    PopoverLink,
    SupportModal,
    BaseAvatar,
    BrandLogo
  },
  props: {
    displayOrganisationsList: {
      type: Boolean,
      default: true
    }
  }
})
export default class MainNavigation extends Vue {
  showSupportModal = false;
  logout = logoutService;
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  get env() {
    return process.env.VUE_APP_ENV;
  }

  get isAdmin() {
    return this.$route.meta?.isAdmin || !this.sessionStore.currentOrganisationId;
  }

  get user() {
    return this.sessionStore.currentUser;
  }

  get userName() {
    return {
      given_name: this.sessionStore.currentUser.given_name,
      family_name: this.sessionStore.currentUser.family_name
    };
  }

  get url(): string {
    return this.sessionStore.currentUser.avatar_url;
  }

  get currentOrganisation() {
    return this.sessionStore.currentOrganisation || {};
  }

  get organisations(): Array<Organisation> {
    return this.user?.organisations || [];
  }

  async accessDomainAdmin() {
    await this.$router.push('/admin');
    this.sessionStore.setCurrentOrganisationId(null);
  }

  switchOrganisation(organisation: Organisation) {
    if (this.currentOrganisation.id !== organisation.id || this.isAdmin) {
      this.sessionStore.setCurrentOrganisationId(organisation.id);
      this.notificationStore.snackbar = {
        label: this.$t('platform.org-unit.switch-message', [organisation.name]),
        autoDismiss: true,
        dismissAfter: 6000,
        color: 'success',
        icon: 'tick'
      };
      const routePermission = this.$route.meta?.permission;
      const permissions = this.sessionStore.permissions || [];
      // When we switch from domain admin to an org or
      // When we switch an org and we don't have the permission to access the page anymore
      // We should redirect the user
      if ((routePermission && !permissions.includes(routePermission)) || this.isAdmin) {
        if (this.$route.path.includes('settings')) {
          this.$router.push({ name: 'settings' });
        } else if (permissions.includes('anonymous-participant:read')) {
          this.$router.push({ name: 'participant-list' });
        } else if (permissions.includes('patient:read')) {
          this.$router.push({ name: 'patient-list' });
        } else {
          this.$router.push({ name: 'settings' });
        }
      }
    }
  }
}

import { GenericService } from '@/services/api/generic.service';
import { User } from '@/models';
import { AxiosResponse } from 'axios';
import apiClient from './client/apiClient';

export class DomainUserService extends GenericService<User> {
  constructor(domainId: string) {
    super();
    this.endpoint = `v1/domains/${domainId}/users`;
  }

  async addUser(userId: string): Promise<AxiosResponse> {
    return await apiClient.put(`${this.endpoint}/${userId}`);
  }

  async resetPassword(userId: string): Promise<AxiosResponse> {
    return await apiClient.post(`${this.endpoint}/${userId}/reset-password`);
  }

  async resendInvite(userId: string): Promise<AxiosResponse> {
    return await apiClient.post(`${this.endpoint}/${userId}/resend-invite`);
  }

  async updateAccountSetting(userId: string, params: Partial<User>) {
    return await apiClient.patch(`${this.endpoint}/${userId}/update-account-setting`, params);
  }

  async updateProfile(userId: string, changes: Partial<User>): Promise<AxiosResponse<User>> {
    return await apiClient.patch(`${this.endpoint}/${userId}/update-profile`, changes);
  }
}


import { Vue, Options } from 'vue-class-component';
import { INotification } from '@/lib';
import BaseNotification from './BaseNotification.vue';

@Options({
  components: {
    BaseNotification
  },
  props: {
    displayItems: {
      type: Number,
      default: 3
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    autoDismiss: {
      type: Boolean,
      default: true
    },
    dismissAfter: {
      type: Number,
      default: 6000
    }
  }
})
export default class NotificationStack extends Vue {
  modelValue!: Array<INotification>;
  dismissAfter!: number;

  dismissNotification(id: string) {
    const notifications = [...this.modelValue].filter((notification: INotification) => notification.id !== id);
    this.$emit('update:modelValue', notifications);
  }
}


import { Vue, Options } from 'vue-class-component';
import { v4 } from 'uuid';

@Options({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
})
export default class PermissionGroupItem extends Vue {
  readonly!: boolean;
  disabled!: boolean;
  id = v4();

  onClick(ev: UIEvent) {
    if (this.readonly || this.disabled) {
      ev.preventDefault();
    }
  }
}


import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseButton } from '@/lib/components/Button';

@Options({
  components: { BaseButton, BaseIcon },
  props: {
    title: {
      type: String,
      default: ''
    }
  }
})
export default class CardHeader extends Vue {
  goBack() {
    this.$emit('back');
  }
}


import { Vue, Options } from 'vue-class-component';
import { ISuggestion } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import SearchSelectItem from './SearchSelectItem.vue';
import { i18n } from '@/i18n/i18n';

@Options({
  components: { SearchSelectItem, BaseIcon },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default() {
        return i18n.global.t('platform.common.search');
      }
    },
    label: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: true
    },
    absolute: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Boolean,
      default: true
    }
  }
})
export default class SearchSelect extends Vue {
  modelValue!: Array<ISuggestion>;
  searchValue = '';
  list!: Array<ISuggestion>;
  filter!: boolean;
  width = 'max-content';

  mounted() {
    this.width = `${(this.$refs.search as HTMLInputElement).clientWidth}px`;
  }

  get filteredList() {
    return this.searchValue.length && this.filter
      ? this.list.filter((item) => item.label.toLowerCase().trim().includes(this.searchValue.toLowerCase().trim()))
      : this.list;
  }

  isSelected(id: string) {
    return this.modelValue.map((v) => v.id).includes(id);
  }

  add(item: ISuggestion) {
    this.$emit('add', item);
    this.$emit('update:modelValue', [...this.modelValue, item]);
    this.searchValue = '';
  }

  remove(id: string) {
    const index = this.modelValue.findIndex((item) => item.id === id);
    const value = [...this.modelValue];
    value.splice(index, 1);
    this.$emit('remove', id);
    this.$emit('update:modelValue', value);
  }

  onSearch(value: string) {
    this.$emit('search', value);
    (this.$refs.list as HTMLElement).scrollTop = 0;
  }

  onScroll(ev: UIEvent) {
    const target = ev.target as HTMLElement;
    const bottomOfTarget = target.scrollTop + target.offsetHeight >= target.scrollHeight;
    if (bottomOfTarget) {
      this.$emit('scrolled');
    }
  }
}


import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';
import BaseRadio from './BaseRadio.vue';

interface IRadioGroupOption {
  label: string;
  value: any;
  title?: string;
}

@Options({
  components: {
    BaseIcon,
    BaseRadio
  },
  props: {
    icon: {
      type: String,
      default: null
    },

    iconHeight: {
      type: String,
      default: '25px'
    },

    label: {
      type: String,
      default: null
    },

    options: {
      type: Array,
      required: true
    },

    modelValue: {
      type: [String, Boolean],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    error: {
      type: String,
      default: null
    },
    light: {
      type: Boolean,
      default: false
    },

    isOptional: {
      type: Boolean,
      default: false
    },
    labelPosition: {
      type: String,
      validator: (value: string) => ['left', 'right'].includes(value),
      default: 'right'
    }
  }
})
export default class RadioGroup extends Vue {
  options!: string[] | IRadioGroupOption[];

  onUpdate(value: any) {
    this.$emit('update:modelValue', value);
    this.$emit('change', value);
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-between items-center" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "text-gray-900 text-lg font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseButton, {
        class: "mr-4 w-8 h-8",
        size: "small",
        color: "ghost",
        onClick: _ctx.goBack
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseIcon, {
            name: "arrow-left",
            class: "stroke-1.5"
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
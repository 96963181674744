
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    outCustomClass: {
      type: String,
      default: 'relative block'
    },
    innerCustomClass: {
      type: String,
      default: ''
    },
    loadingBarHeight: {
      type: String,
      default: '2px'
    },
    color: {
      type: String,
      default: 'primary-500'
    },
    value: {
      type: Number,
      default: 0
    }
  }
})
export default class LoadingBar extends Vue {}


import { Vue, Options } from 'vue-class-component';
import axios, { CancelTokenSource } from 'axios';

import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseSelect } from '@/lib/components/Select';

import { IValidationError } from '@/lib';
import { CopdDischargeReason, CopdMeasurementDischargeParams, CopdOutcome } from '@/models';
import { CopdProgramService } from '@/services/api';
import { selectableDischargeReasons } from '@/helpers/home-monitoring.helper';

@Options({
  props: {
    programId: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  },
  components: { BaseButton, BaseSelect, BaseIcon }
})
export default class DischargeProgramModal extends Vue {
  programId!: string;
  dischargeReasonValue: CopdDischargeReason | null = null;
  request: CancelTokenSource | null = null;
  alreadyDischarged = false;

  errors: IValidationError = {
    errors: {},
    message: ''
  };

  copdProgramService = new CopdProgramService();

  mounted() {
    this.$emit('record-event', 'started discharging patient from pathway');
  }

  async dischargePatient() {
    try {
      //   TODO: Measurements list has to be validated with BE
      const dischargeParams: CopdMeasurementDischargeParams = {
        outcome: CopdOutcome.discharge,
        reason: this.dischargeReasonValue,
        measurements: []
      };
      this.request = axios.CancelToken.source();
      await this.copdProgramService.dischargeProgram(this.programId as string, dischargeParams, {
        cancelToken: this.request.token
      });

      this.$emit('record-event', 'completed discharging patient from pathway');
      this.$emit('close', true);
      this.$emit('discharged', true);
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.errors = e.response.data;
      }
      if (e.response.status === 400) {
        this.errors = { message: e.response.data.message, errors: {} };
        this.alreadyDischarged = true;
      } else {
        this.errors = e.response.data;
      }
    }
  }

  updateDischargeReason(reason: CopdDischargeReason) {
    this.dischargeReasonValue = reason;
  }

  closeDischargeModal() {
    this.dischargeReasonValue = null;
    this.$emit('close');
  }

  get dischargeReasons() {
    return selectableDischargeReasons();
  }
}


import { Vue, Options } from 'vue-class-component';

interface userName {
  given_name: string;
  family_name: string;
}

@Options({
  props: {
    userName: {
      type: Object as () => userName,
      default: () => ({
        given_name: '',
        family_name: ''
      })
    },
    url: {
      type: String,
      default: ''
    }
  }
})
export default class BaseAvatar extends Vue {
  userName!: userName;
  url!: string;

  get initials() {
    return ((this.userName.given_name || '').charAt(0) + (this.userName.family_name || '').charAt(0)).toUpperCase();
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!

  return (_openBlock(), _createBlock(_component_BaseSelect, {
    "data-dusk": "copd-program-hospital-team",
    "model-value": _ctx.hospitalTeam,
    label: _ctx.hospitalTeamLabel,
    placeholder: _ctx.hospitalTeamPlaceholder,
    options: _ctx.hospitalTeamOptions,
    error: _ctx.validationErrors,
    disabled: _ctx.disabled,
    class: "w-full",
    "onUpdate:modelValue": _ctx.updateHospitalTeam
  }, null, 8, ["model-value", "label", "placeholder", "options", "error", "disabled", "onUpdate:modelValue"]))
}
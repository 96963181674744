
import { Vue, Options } from 'vue-class-component';
import BaseSelectInput from './BaseSelectInput.vue';
import { IOption } from '@/lib';
import { vaUnits } from '@/constants';
import { VaUnit } from '@/models';
import { getVaOptions } from '@/helpers/encounter.helper';

@Options({
  components: {
    BaseSelectInput
  },
  props: {
    vaUnit: {
      type: String,
      default: VaUnit.SIX_SIX,
      validator: (value: string) => vaUnits.includes(value)
    },
    modelValue: {
      type: String,
      default: ''
    },
    clear: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class VASelect extends Vue {
  vaUnit!: VaUnit;

  get options(): IOption[] {
    return getVaOptions(this.vaUnit);
  }
}


import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: { BaseIcon },
  props: {
    items: {
      type: Array,
      required: true,
      validator: (value) => value.length === 2
    },
    modelValue: {
      type: Number,
      default: 0
    }
  }
})
export default class ViewerNavigationSwitch extends Vue {
  items!: Array<{
    icon: string;
    name: string;
    value: number;
  }>;

  modelValue!: number;

  changeMode(mode: number) {
    if (mode !== this.modelValue) {
      this.$emit('update:modelValue', mode);
    }
  }
}


import { Vue, Options } from 'vue-class-component';
import ErrorLayout from '@/lib/layouts/ErrorLayout.vue';

@Options({
  components: {
    ErrorLayout
  }
})
export default class Error500 extends Vue {}


import { Vue, Options } from 'vue-class-component';
import Lock from '@/lib/components/Modals/Lock.vue';
import { useUiStore } from '@/stores/ui.store';

@Options({
  components: {
    Lock
  },

  props: {
    verticalAlign: {
      type: String,
      default: 'center',
      validator: (value) => ['top', 'center'].includes(value)
    },

    bgColor: {
      type: String,
      default: 'white'
    },

    dialogClass: {
      type: String,
      default: ''
    },

    danger: {
      type: Boolean,
      default: false
    },

    closeOnBackgroundClick: {
      type: Boolean,
      default: true
    },

    dark: {
      type: Boolean,
      default: false
    }
  }
})
export default class BaseModal extends Vue {
  closeOnBackgroundClick!: boolean;
  uiStore = useUiStore();

  mounted() {
    this.registerCloseOnEscapeHandler();
    if (this.uiStore) {
      this.uiStore.bodyScrollLock = true;
    }
  }

  onBackgroundClick($event: MouseEvent) {
    const element = $event.target as HTMLInputElement;
    if (this.closeOnBackgroundClick && element && element.id === 'outer-modal') {
      this.$emit('close');
    }
  }

  registerCloseOnEscapeHandler() {
    document.addEventListener('keydown', this.escapeHandler);
  }

  escapeHandler(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.$emit('close');
    }
  }

  beforeUnmount() {
    document.removeEventListener('keydown', this.escapeHandler);
    this.uiStore.bodyScrollLock = false;
  }
}

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-4" }
const _hoisted_2 = { class: "text-sm space-x-1" }
const _hoisted_3 = { class: "font-semibold text-gray-900" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-600"
}
const _hoisted_5 = { class: "text-sm font-regular text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["p-6 text-base font-regular leading-normal", _ctx.sent ? 'bg-primary-500 text-white shadow-lg ml-24' : 'shadow text-gray-900 border border-gray-300 mr-24']),
      style: _normalizeStyle(_ctx.sent ? 'border-radius: 20px 20px 4px 20px' : 'border-radius: 20px 20px 20px 4px')
    }, _toDisplayString(_ctx.content), 7),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row space-x-2", _ctx.sent ? 'justify-end' : 'justify-start'])
    }, [
      (_ctx.sender.type === 'caregiver')
        ? (_openBlock(), _createBlock(_component_MkBadge, {
            key: 0,
            size: "xsmall"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.caregiver')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.sender.name), 1),
        (_ctx.patient && _ctx.sender.type === 'caregiver')
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.on-behalf-of', [`${_ctx.patient.first_name} ${_ctx.patient.last_name}`])), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.dateFromNow), 1)
    ], 2)
  ]))
}
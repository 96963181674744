
import { Vue, Options } from 'vue-class-component';
import { FormSection } from '@/models';
import { useSmartFormStore } from '@/stores/smartForm.store';

@Options({
  props: {
    formSubmissionId: {
      type: String,
      required: true
    },
    sections: {
      type: Array,
      default: () => []
    },
    excludeTypes: {
      type: Array,
      default: () => []
    },
    excludeIds: {
      type: Array,
      default: () => []
    }
  }
})
export default class SmartFormStatus extends Vue {
  sections!: FormSection[];
  excludeIds!: Array<string>;
  excludeTypes!: Array<string>;
  formSubmissionId!: string;
  smartForm = useSmartFormStore();

  get unansweredSections() {
    const excludeTypes = [...this.excludeTypes, 'group', 'section', 'spacer', 'table'];
    const getItemsRecursively = (item: any) => {
      if (
        !this.excludeIds.includes(item.id) && // exclude ids
        this.smartForm.getItemConditionsMet(this.formSubmissionId, item.conditions) // check that the item is visible
      ) {
        if (item.items) {
          const items = item.items.flatMap(getItemsRecursively);
          // if item is not an excluded type and is unanswered, return it with item.items
          if (
            !excludeTypes.includes(item.type) &&
            typeof item.type === 'string' && // if type is undefined, do not include item
            !this.smartForm.getAnswer(this.formSubmissionId, item.id)
          ) {
            return [...items, item];
          }
          return items;
        } else if (
          !excludeTypes.includes(item.type) &&
          typeof item.type === 'string' &&
          !this.smartForm.getAnswer(this.formSubmissionId, item.id)
        ) {
          return [item];
        }
      }
      return [];
    };

    return this.sections.reduce((acc, curr) => {
      if (!this.excludeIds.includes(curr.id)) {
        const unanswered = curr.items ? curr.items.flatMap(getItemsRecursively) : [];
        if (unanswered.length) {
          acc.push({
            name: curr.name,
            unanswered: unanswered.length
          });
        }
      }
      return acc;
    }, [] as { name: string; unanswered: number }[]);
  }
}


import { Vue, Options } from 'vue-class-component';
import { PopoverButton } from '@/lib/components/Popover';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseSwitch } from '@/lib/components/Switch';
import { NavDropdown } from '@/lib/components/Navigation';

@Options({
  components: { BaseSwitch, NavDropdown, BaseIcon, PopoverButton },
  props: {
    items: {
      type: Array,
      validator: (value) => value.length,
      required: true
    },
    modelValue: {
      type: Array
    },
    lock: {
      type: Boolean,
      default: true
    },
    showAnnotations: {
      type: Boolean,
      default: true
    }
  }
})
export default class ViewerSelect extends Vue {
  items!: [
    {
      icon: string;
      name: string;
      value: Array<number>;
    }
  ];

  modelValue!: Array<number>;
  selectedItem: {
    icon: string;
    name: string;
    value: Array<number>;
  } | null = null;

  mounted() {
    this.$watch(
      'modelValue',
      () =>
        (this.selectedItem =
          this.items.find((item) => JSON.stringify(item.value) === JSON.stringify(this.modelValue)) || null),
      { deep: true }
    );
  }
}

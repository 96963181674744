import { Encounter } from '@/models/encounter.model';
import { JourneyTypeName } from '@/models/journey-type.model';
import { Laterality } from '@/custom/menicon/models/laterality';

export interface EndedReason {
  code: EndedReasonCode;
  description?: string;
}

export interface Journey {
  id: string;
  type?: JourneyTypeName;
  patient_id: string;
  organisational_unit_id: string;
  ended_at?: string;
  ended_reasons?: EndedReason[];
  ended_further_details?: string;
  encounters?: Array<Encounter>;
  draft_encounters?: Array<Encounter>;
  complete_encounters?: Array<Encounter>;
  laterality?: Laterality | null;
  last_activity?: Encounter;
}

export enum EndedReasonCode {
  END_PROGRAM_DEFAULT = 2000,
  END_PROGRAM_INAPPROPRIATE_FIT,
  END_PROGRAM_VA_INSUFFICIENT,
  END_PROGRAM_PATIENT_REACTION_TO_LENS,
  END_PROGRAM_PATIENT_DOES_NOT_LIKE_LENS,
  END_PROGRAM_OTHER_REASON = 2099
}

export interface EndPrescriptionValue {
  reasons: Array<EndedReasonCode>;
  details: string;
}

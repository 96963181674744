import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupTreeSegment = _resolveComponent("GroupTreeSegment")!
  const _component_GroupTree = _resolveComponent("GroupTree", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_GroupTreeSegment, {
      class: _normalizeClass(["mb-6", { 'bg-gray-100 text-gray-500': !_ctx.item.isActive }]),
      style: _normalizeStyle('margin-left: ' + _ctx.depth * 2 + 'rem'),
      "enable-add": _ctx.item.add,
      actions: _ctx.item.actions,
      name: _ctx.item.name,
      description: _ctx.item.description,
      label: _ctx.item.label,
      "data-dusk": _ctx.item.id,
      onCreateChild: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('createChild', _ctx.item.id)))
    }, null, 8, ["class", "style", "enable-add", "actions", "name", "description", "label", "data-dusk"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child, index) => {
      return (_openBlock(), _createBlock(_component_GroupTree, {
        key: index,
        depth: +_ctx.depth + 1,
        item: child,
        onCreateChild: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('createChild', $event)))
      }, null, 8, ["depth", "item"]))
    }), 128))
  ]))
}
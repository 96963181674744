import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_SearchSelectItem = _resolveComponent("SearchSelectItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'p-2': !_ctx.absolute }),
    style: _normalizeStyle(_ctx.dark ? 'background: rgba(28, 28, 28, 0.9)' : '')
  }, [
    (_ctx.label && _ctx.label.length)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([_ctx.dark ? 'text-white' : 'text-gray-900', "text-base font-medium leading-tight"])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "search",
      style: _normalizeStyle(_ctx.dark ? 'background: rgba(0, 0, 0, 0.4)' : ''),
      class: _normalizeClass([{ border: !_ctx.dark, 'mt-3': _ctx.label && _ctx.label.length }, "flex flex-row items-center w-full cursor-text rounded shadow text-sm p-3"]),
      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$refs.input.focus()), ["stop"]))
    }, [
      _createVNode(_component_BaseIcon, {
        class: "text-gray-500 text-base mr-3",
        name: "search"
      }),
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
        style: {"background":"transparent"},
        class: "flex text-gray-500 focus:outline-none rounded",
        type: "text",
        placeholder: _ctx.placeholder,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSearch($event.target.value))),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus')))
      }, null, 40, _hoisted_1), [
        [_vModelText, _ctx.searchValue]
      ])
    ], 6),
    _createElementVNode("div", {
      class: _normalizeClass(["relative w-full scrollbar-dark", { 'scrollbar-dark': _ctx.dark }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["w-full rounded shadow", [
          _ctx.filteredList && _ctx.filteredList.length && _ctx.isOpen ? 'block' : 'hidden',
          _ctx.absolute ? 'absolute mt-1 z-10' : ''
        ]]),
        style: _normalizeStyle(_ctx.dark && _ctx.absolute ? 'background: rgba(28, 28, 28, 0.9)' : ''),
        onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("div", {
          ref: "list",
          class: "whitespace-no-wrap w-full overflow-hidden overflow-y-auto rounded-lg max-h-75 mt-4",
          onScroll: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.onScroll($event)), ["stop"])),
          onWheel: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredList, (item) => {
            return (_openBlock(), _createBlock(_component_SearchSelectItem, {
              key: item.id,
              class: "w-full",
              "model-value": _ctx.isSelected(item.id),
              dark: _ctx.dark,
              "onUpdate:modelValue": ($event: any) => ($event ? _ctx.add(item) : _ctx.remove(item.id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["model-value", "dark", "onUpdate:modelValue"]))
          }), 128))
        ], 544)
      ], 6)
    ], 2)
  ], 6))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PermissionGroupItem = _resolveComponent("PermissionGroupItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PermissionGroupItem, {
        label: _ctx.category,
        readonly: _ctx.readonly,
        "model-value": _ctx.isCategoryChecked,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCategoryChange($event)))
      }, null, 8, ["label", "readonly", "model-value"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (permission, permissionIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: permission.key,
          class: "mt-3 ml-6"
        }, [
          _createVNode(_component_PermissionGroupItem, {
            label: permission.name,
            "model-value": permission.isChecked,
            readonly: _ctx.readonly,
            "onUpdate:modelValue": ($event: any) => (_ctx.onPermissionChange(permissionIndex, $event))
          }, null, 8, ["label", "model-value", "readonly", "onUpdate:modelValue"])
        ]))
      }), 128))
    ])
  ]))
}
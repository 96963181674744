
import { Options, Vue } from 'vue-class-component';
import PageLoading from '@/lib/components/Loading/PageLoading.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import axios from 'axios';
import { BaseAlert } from '@/lib/components/Banner';
import { DEFAULT_LEGAL_REGION } from '@/constants/legal';

@Options({
  components: { PageLoading, BaseButton, BaseAlert },
  props: {
    type: {
      type: String,
      validator: (value: string) => ['privacy-policy', 'terms-service'].includes(value),
      default: 'privacy-policy'
    },
    countryCode: {
      type: String,
      default: DEFAULT_LEGAL_REGION
    },
    /**
     * Force all links to open in a new tab
     * Used when we don't want to take user away from current page - e.g new patient registration
     */
    forceLinksNewTab: {
      type: Boolean,
      default: false
    },
    fallbackRegion: {
      type: String,
      default: DEFAULT_LEGAL_REGION
    },
    fallbackBranding: {
      type: String,
      default: 'big-picture'
    },
    branding: {
      type: String,
      default: null
    }
  }
})
export default class LegalPolicy extends Vue {
  legalPolicyHtmls: HTMLElement[][] = [];
  loading = true;
  error = false;
  type!: string;
  branding!: string | null;
  countryCode!: string;
  forceLinksNewTab!: boolean;
  fallbackRegion!: string;
  fallbackBranding!: string;
  isFallback = false;
  legalPolicyUrl = 'https://bigpicturemedical.com/legal';

  get isEnglishRegion(): boolean{
    return this.countryCode.includes('en') || ['gb', 'us', 'au', 'sg'].includes(this.countryCode);
  }

  async mounted() {
    await this.fetchPolicyHtml();
    this.$watch('countryCode', async () => {
      await this.fetchPolicyHtml();
    });
  }

  getHtmlUrl(countryCode = this.fallbackRegion, branding = this.fallbackBranding): string {
    const baseUrl = process.env.VUE_APP_LEGAL_BASE_URL ?? this.legalPolicyUrl;
    return `${baseUrl}/${branding}/${countryCode}/${this.type}`;
  }

  async setHtmlAttribute() {
    await this.$nextTick(); // Wait for vue to build the DOM
    const targetEl = this.$refs.htmlContainer as HTMLElement;
    if (targetEl) {
      const anchorTags = targetEl.getElementsByTagName('a');
      for (let i = 0; i < anchorTags.length; i++) {
        anchorTags[i].setAttribute('target', '_blank');
      }
    }
  }

  async fetchPolicyHtml() {
    this.loading = true;
    this.error = false;
    this.$emit('fetch-start');
    this.isFallback = false;
    const bpmPolicy = await this.getPolicyWithFallback(this.countryCode);
    if (bpmPolicy) {
      this.legalPolicyHtmls = [
        bpmPolicy
      ];
    } else if (!this.branding) {
      this.error = true;
    }
    if (this.branding) {
      const brandedPolicy = await this.getPolicyWithFallback(this.countryCode, this.branding?.toLowerCase());
      if (brandedPolicy) {
        this.legalPolicyHtmls = [...this.legalPolicyHtmls, brandedPolicy];
      } else if (bpmPolicy === null) {
        this.error = true;
      }
    }
    if (!this.error) {
      this.$emit('fetch-success');
      if (this.forceLinksNewTab) {
        await this.setHtmlAttribute();
      }
    }
    this.loading = false;
  }

  async getPolicyWithFallback(countryCode = this.fallbackRegion, branding = this.fallbackBranding) {
    try {
      // Get policy for current region
      return await this.loadPolicy(countryCode, branding);
    } catch (e) {
      if (countryCode === this.fallbackRegion) {
        // policy couldn't be loaded for default region, return null
        return null;
      }
      if (branding === this.fallbackBranding) {
        this.isFallback = true;
      }
      // Fallback to default region policy
      return await this.getPolicyWithFallback(this.fallbackRegion, branding);
    }
  }

  async loadPolicy(countryCode = this.fallbackRegion, branding = this.fallbackBranding): Promise<string | Error> {
    const responseData = (await axios.get(this.getHtmlUrl(countryCode, branding))).data;
    return responseData ? this.removeHtmlClassAttrs(responseData) : new Error('Error with fetching legal policy data.');
  }

  removeHtmlClassAttrs(htmlElement: string): string {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlElement, 'text/html');
    const element = parsedHtml.querySelectorAll('div, h2, h3, p, a');
    for (let i = 0; i < element.length; i++) {
      if (element[i].className) {
        element[i].className = '';
      }
    }

    return parsedHtml.body.innerHTML;
  }
}

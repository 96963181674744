
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';

@Options({
  props: {
    label: {
      type: String,
      required: false
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    },
    error: {
      type: String,
      required: false
    }
  },
  components: {
    BaseIcon,
    InputTextHelper
  }
})
export default class BaseNumberInput extends Vue {
  modelValue!: string;
  readonly!: boolean;
  disabled!: boolean;
  $refs!: {
    input: HTMLInputElement;
  };

  stepUp() {
    this.$refs.input.stepUp();

    this.$emit('update:modelValue', this.$refs.input.value);
  }

  stepDown() {
    this.$refs.input.stepDown();

    this.$emit('update:modelValue', this.$refs.input.value);
  }
}

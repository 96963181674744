
import { Vue, Options } from 'vue-class-component';
import BaseSelect from './BaseSelect.vue';
import { BaseTextarea } from '@/lib/components/Textarea';
import { IOption, IValidationError } from '@/lib';
import { ConsultRejectService } from '@/services/api';
import { RejectReasonValue, REJECT_REASON_MAX_LENGTH } from '@/models';

@Options({
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        selectedReason: '',
        additionalReason: ''
      })
    }
  },
  components: { BaseSelect, BaseTextarea }
})
export default class RejectReason extends Vue {
  modelValue!: RejectReasonValue;

  showAdditional = false;
  showLimitCharError = false;
  maxCharAdditionalInfo = 500;
  rejectReasonsOptions: IOption[] = [];

  private reasonMaxLength: number = REJECT_REASON_MAX_LENGTH;

  consultRejectService = new ConsultRejectService();
  fetchErrors: IValidationError | null = null;

  mounted() {
    this.fetchRejectReasonsOptions();
  }

  async fetchRejectReasonsOptions() {
    try {
      const response =
        await this.consultRejectService.getConsultRejectOptions();
      const list: string[] = response.data
        ? response.data
        : (response as unknown as string[]);
      list.map((item) => {
        this.rejectReasonsOptions.push({
          label: this.$t(`custom.uhb.consult.reject-reasons.${item}`),
          value: item
        });
      });
    } catch (e) {
      if (e.response?.status === 422) {
        this.fetchErrors = e.response?.data?.errors;
      } else {
        this.fetchErrors = {
          message: e.response?.data?.message || '',
          errors: {}
        };
      }
    }
  }

  get isReasonMandatory() {
    const status = !!['reason-not-listed', 'refused-service-other'].includes(
      this.modelValue.selectedReason
    );

    this.modelValue.selectedReason !== '' &&
      !this.showAdditional &&
      this.toggleAdditional();
    return status;
  }

  updateSelect(event) {
    this.setReason({ selectedReason: event });
  }

  toggleAdditional() {
    return (this.showAdditional = !this.showAdditional);
  }

  setReason(value: Partial<RejectReasonValue>) {
    const updated = { ...this.modelValue, ...value };
    this.maxCharAdditionalInfo = 500 - updated.additionalReason.length;
    this.$emit('update-error-limit-char', this.maxCharAdditionalInfo);
    this.$emit('update:modelValue', { ...updated });
    this.$emit('select-reason', { ...updated });
  }
}


import { Vue, Options } from 'vue-class-component';
import ResizeObserver from 'resize-observer-polyfill';
// @ts-ignore
import cornerstone from 'cornerstone-core/dist/cornerstone.min.js';
// @ts-ignore
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.min.js';
import { IAnnotation, Tool } from '@/lib';
import { WebGlService } from '@/services/graphics/webgl.service';
import { LoadingIcon } from '@/lib/components/Loading';
import { BaseSnackbar } from '@/lib/components/Snackbar';
import { CornerstonePoint } from '@/models';

@Options({
  components: { LoadingIcon, BaseSnackbar },
  props: {
    enableTools: {
      type: Boolean,
      default: false
    },
    enableZoom: {
      type: Boolean,
      default: false
    },
    enablePan: {
      type: Boolean,
      default: false
    },
    enableReferenceLine: {
      type: Boolean,
      default: false
    },
    referenceLine: {
      type: Object,
      default: null
    },
    imageIds: {
      type: Array,
      default: () => [],
      validator: (value: Array<string>) => !value.find((v) => !v.startsWith('http'))
    },
    translation: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    currentImageIndex: {
      type: Number,
      default: 0
    },
    state: {
      type: Array,
      default: () => []
    },
    zoom: {
      type: Number,
      default: 1
    },
    zoomMin: {
      type: Number,
      default: 10
    },
    zoomMax: {
      type: Number,
      default: 1000
    },
    tool: {
      type: String,
      default: null
    },
    tools: {
      type: Array,
      default: () => []
    },
    colourFilter: {
      type: Object,
      default: () => ({ value: '', label: '' })
    },
    dimensions: {
      type: Object,
      default: () => ({
        widthMm: 0,
        heightMm: 0
      })
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
})
export default class ViewerItem extends Vue {
  imageIds!: Array<string>;
  currentImageIndex!: number;
  loading = false;
  loadingImageId: string | null = null; // current loading image id
  zoomMin!: number;
  zoomMax!: number;
  observer: ResizeObserver | null = null;

  // annotation tools
  state!: IAnnotation[];
  enableTools!: boolean;
  enableZoom!: boolean;
  enablePan!: boolean;
  enableReferenceLine!: boolean;
  tools!: Array<Tool>;
  tool!: string;
  dimensions!: {
    widthMm: number;
    heightMm: number;
  };

  referenceLine!: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    radius: number | null;
  };

  image = {
    height: 0,
    width: 0
  };

  // transformations
  zooming = false;
  translating = false;
  enabled = false;
  updating: IAnnotation | null = null;
  annotating = false;
  initialScale = 1; // current image initial scale
  initialZoom = 1;
  zoom!: number;
  translation!: { x: number; y: number };

  // colour transformations
  colourFilter!: { value: string; label: string };
  colourFilterSnackbar = false;
  transformActive = false;
  webGlService: WebGlService | null = null;

  // readonly
  readonly!: boolean;

  // @ts-ignore
  metadataProvider(type: string, imageId: string) {
    if (
      type === 'imagePlaneModule' &&
      imageId === this.imageIds[this.currentImageIndex] &&
      (this.enableTools || this.readonly)
    ) {
      return this.pixelSpacing;
    }
  }

  get pixelSpacing() {
    return {
      columnPixelSpacing: this.dimensions.widthMm / this.image.width,
      rowPixelSpacing: this.dimensions.heightMm / this.image.height
    };
  }

  mounted() {
    this.initialZoom = this.zoom;

    if (this.imageIds.length) {
      this.init();
    }
    cornerstone.metaData.addProvider(this.metadataProvider);
    // update displayed image
    this.$watch(
      'imageIds',
      () => {
        if (this.enabled) {
          this.onImagesUpdate();
        } else {
          this.init();
        }
      },
      { deep: true }
    );
  }

  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.enabled) {
      const element = this.$refs.viewer as HTMLElement;
      element.removeEventListener('cornerstonetoolsmeasurementcompleted', (ev: any) =>
        this.$emit('createAnnotation', ev.detail)
      );
      element.removeEventListener('cornerstonetoolsmeasurementmodified', (ev: any) =>
        this.$emit('updateAnnotation', ev.details)
      );
      element.removeEventListener('cornerstonetoolsmeasurementremoved', (ev: any) =>
        this.$emit('removeAnnotation', ev.detail.measurementData.uuid)
      );
      element.removeEventListener('cornerstoneimagerendered', (ev) => this.onRender(ev as CustomEvent));
      this.clearState();
      cornerstone.disable(element);
    }
  }

  init() {
    cornerstoneTools.init();

    const element = this.$refs.viewer as HTMLElement;
    cornerstone.enable(element);

    // update viewport on element resize
    this.observer = new ResizeObserver(async () => {
      cornerstone.resize(element);
      await this.loadImage(this.currentImageIndex, this.translation);
      this.setDefaultZoom();
    });
    this.observer.observe(element);

    this.onImagesUpdate();
    this.updateState();
    this.drawReferenceLine();

    this.$watch('currentImageIndex', () => {
      this.loadImage(this.currentImageIndex, this.translation);
    });
    // watch transformations update (zoom/pan/redFree)
    this.$watch('zoom', () => this.setZoom(this.zoom));
    this.$watch('translation', () => this.setTranslation(this.translation));
    this.$watch('colourFilter', () => this.setColourFilter(this.colourFilter));
    this.$watch('tool', (current, old) => this.setToolActive(current, old)); // activate selected tool
    this.$watch('state', () => this.updateState(), { deep: true });
    this.$watch('referenceLine', () => this.drawReferenceLine());
    this.$watch('readonly', () => this.setReadOnly());

    const canvas = this.$refs.transformedCanvas as HTMLCanvasElement;
    this.webGlService = new WebGlService(canvas);
    this.setColourFilter(this.colourFilter);

    // Pan Tool
    if (this.enablePan) {
      cornerstoneTools.addToolForElement(this.$refs.viewer, cornerstoneTools.PanTool);
      cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 }); // Pan on middle mouse button
      cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 }); // Pan on left mouse button
    }
    this.addTools(this.tools);
    this.setReadOnly();

    // On annotate, emit the new state
    element.addEventListener('cornerstonetoolsmeasurementcompleted', (ev: any) => {
      this.$emit('createAnnotation', ev.detail);
      this.annotating = false;
    });
    element.addEventListener('cornerstonetoolsmeasurementmodified', (ev: any) => {
      if (!this.updating) {
        const exists = this.state.find(
          (annotation) => annotation.measurementData.uuid === ev.detail.measurementData.uuid
        );
        if (exists) {
          this.updating = ev.detail;
        } else {
          this.annotating = true;
        }
      }
    });
    element.addEventListener('cornerstonetoolsmeasurementremoved', (ev: any) => {
      const exists = this.state.find(
        (annotation) => annotation.measurementData.uuid === ev.detail.measurementData.uuid
      );
      // If the current tool is the eraser and that the annotation exists, remove the annotation
      if (this.tool === 'Eraser' && exists) {
        this.$emit('removeAnnotation', ev.detail.measurementData.uuid);
      } else {
        // Check if the annotation has been updated or created outside the image bound
        let handleOutsideImage = false;
        const handles = ev.detail.measurementData.handles;
        const newHandles: { [key: string]: CornerstonePoint } = {};
        Object.keys(handles).forEach((name: string) => {
          const handle = handles[name];
          if (name !== 'textBox' && (handle.x < 0 || handle.y < 0)) {
            handleOutsideImage = true;
          }
          newHandles[name] = {
            ...handle,
            allowedOutsideImage: true
          };
        });
        if (handleOutsideImage) {
          const annotation = {
            ...ev.detail,
            measurementData: {
              ...ev.detail.measurementData,
              handles: newHandles
            }
          };
          if (exists) {
            this.$emit('updateAnnotation', annotation);
          } else {
            this.$emit('createAnnotation', annotation);
          }
        }
      }
    });
    // If the image is rendered (image changed, transformation), update translate/zoom/shaders
    element.addEventListener('cornerstoneimagerendered', (ev) => this.onRender(ev as CustomEvent));
    window.addEventListener('keydown', (ev) => {
      if (ev.key === 'Escape') {
        this.cancelDrawing(this.tool);
      }
    });

    // Add zoom (depends on the initial scale of the image)
    if (this.enableZoom) {
      cornerstoneTools.addToolForElement(this.$refs.viewer, cornerstoneTools.ZoomTool, {
        configuration: {
          minScale: (this.zoomMin * this.initialScale) / 100,
          maxScale: (this.zoomMax * this.initialScale) / 100,
          invert: true
        }
      });
      cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
    }
    this.enabled = true;
  }

  setReadOnly() {
    // If readonly , disable all tools.
    if (this.readonly) {
      this.disableAllTools();
    } else {
      this.setToolsPassive();
    }
  }

  updateState() {
    this.clearState();
    if (this.state) {
      this.state.map((annotation) => this.addState(annotation.toolName, annotation.measurementData));
    }

    this.loadImage(this.currentImageIndex, this.translation);
  }

  clearState() {
    cornerstoneTools.getElementToolStateManager(this.$refs.viewer).clear(this.$refs.viewer);
  }

  // Mouse events
  onMouseDown(ev: MouseEvent) {
    if (!this.tool) {
      if (ev.buttons === 2) {
        this.zooming = true;
      }
      if (ev.buttons === 4 || ev.buttons === 1) {
        this.translating = true;
      }
    }
  }

  onMouseUp() {
    if (!this.tool) {
      this.zooming = false;
      this.translating = false;
    }
    if (this.updating) {
      this.$emit('updateAnnotation', this.updating);
      this.updating = null;
    }
  }

  onContextMenu(ev: Event) {
    ev.preventDefault(); // disable context menu on Viewer
    return false;
  }

  // Tools management
  addTools(tools: Array<Tool>) {
    if (this.enableTools) {
      tools.map((tool) => {
        cornerstoneTools.addTool(cornerstoneTools[`${tool.cornerstoneName}Tool`], { configuration: tool.config });
        cornerstoneTools.setToolPassiveForElement(this.$refs.viewer, tool.cornerstoneName);
      });
    }
  }

  // on tool change, activate current tool and disable other tools
  setToolActive(current: string, old: string) {
    if (this.enableTools && current && !cornerstoneTools.isToolActiveForElement(this.$refs.viewer, current)) {
      const element = this.$refs.viewer;
      cornerstoneTools.setToolDisabledForElement(element, 'Pan'); // Disable pan when annotating
      this.cancelDrawing(old);
      this.disableAllTools();
      cornerstoneTools.setToolActiveForElement(element, current, {
        mouseButtonMask: 1
      }); // Activate tool on left mouse button
    } else {
      this.disableAllTools();
      if (this.enablePan) {
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 }); // Pan on middle mouse button
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 }); // Pan on left mouse button
      }
      if (this.enableZoom) {
        cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
      }
      if (this.enableTools) {
        this.setToolsPassive();
      }
    }
  }

  disableAllTools() {
    this.tools.map((tool) => cornerstoneTools.setToolEnabledForElement(this.$refs.viewer, tool.cornerstoneName));
  }

  setToolsPassive() {
    this.tools.map((tool) => cornerstoneTools.setToolPassiveForElement(this.$refs.viewer, tool.cornerstoneName));
  }

  addState(toolName: string, state: any) {
    cornerstoneTools
      .getElementToolStateManager(this.$refs.viewer)
      .addImageIdToolState(this.imageIds[this.currentImageIndex], toolName, state);
  }

  cancelDrawing(toolName: string) {
    if (toolName && this.annotating) {
      const element = this.$refs.viewer;
      const toolState = cornerstoneTools.getToolState(element, toolName);
      if (toolState && toolState.data && toolState.data.length) {
        const data = toolState.data[toolState.data.length - 1];
        if (toolName === 'FreehandRoi') {
          const tool = cornerstoneTools.getToolForElement(element, toolName);
          tool.cancelDrawing(element);
        } else {
          cornerstoneTools.removeToolState(element, toolName, data);
          cornerstone.updateImage(element);
        }
      }
    }
  }

  // Transformations
  onRender(ev: CustomEvent) {
    if (this.zooming && this.enableZoom) {
      this.$emit('setZoom', {
        value: (ev.detail.viewport.scale * 100) / this.initialScale,
        default: false
      });
    } else if (this.translating && this.enablePan) {
      this.$emit('translate', ev.detail.viewport.translation);
    }

    if (this.transformActive && this.webGlService !== null) {
      const element = this.$refs.viewer;
      this.webGlService.setSource(cornerstone.getEnabledElement(element).canvas);
      this.webGlService.draw();
    }

    this.drawReferenceLine();
  }

  setZoom(zoom: number) {
    if (this.enableZoom) {
      const element = this.$refs.viewer;
      const viewport = cornerstone.getViewport(element);
      viewport.scale = zoom * this.initialScale;
      cornerstone.setViewport(element, viewport);
    }
  }

  setTranslation(translation: { x: number; y: number }) {
    if (this.enablePan) {
      const element = this.$refs.viewer;
      const viewport = cornerstone.getViewport(element);
      if (viewport) {
        viewport.translation = translation;
        cornerstone.setViewport(element, viewport);
      }
    }
  }

  setColourFilter(filter: { value: string, label: string }) {
    const element = this.$refs.viewer;
    if (filter.value && this.webGlService !== null) {
      this.webGlService.setProgram('image', filter.value);
      this.webGlService.setSource(cornerstone.getEnabledElement(element).canvas);
      this.webGlService.draw();

      this.transformActive = true;
      cornerstone.getEnabledElement(element).canvas.style.display = 'none';
      this.colourFilterSnackbar = true;
    } else {
      this.transformActive = false;
      cornerstone.getEnabledElement(element).canvas.style.display = 'block';
      this.colourFilterSnackbar = false;
    }
  }

  drawReferenceLine() {
    if (this.enableReferenceLine) {
      const canvas = this.$refs.referenceLineCanvas as HTMLCanvasElement;
      if (canvas.getContext) {
        const context = canvas.getContext('2d');
        if (context) {
          context.clearRect(0, 0, canvas.width, canvas.height);
          if (this.referenceLine) {
            const midX = canvas.width / 2;
            const midY = canvas.height / 2;
            const scaleX = (this.initialScale * canvas.width) / canvas.clientWidth;
            const scaleY = (this.initialScale * canvas.height) / canvas.clientHeight;
            const x1 = midX + (this.referenceLine.x1 - this.image.width / 2) * scaleX;
            const y1 = midY + (this.referenceLine.y1 - this.image.height / 2) * scaleY;

            context.beginPath();
            context.lineWidth = 2;
            context.strokeStyle = '#a9d96c';
            context.fillStyle = '#a9d96c';
            if (this.referenceLine.radius) {
              const radiusX = this.referenceLine.radius * scaleX;
              const radiusY = this.referenceLine.radius * scaleY;
              context.fillRect(x1, y1, 2, 2);
              context.ellipse(x1, y1, radiusX, radiusY, 0, 0, 2 * Math.PI);
            } else {
              const x2 = midX + (this.referenceLine.x2 - this.image.width / 2) * scaleX;
              const y2 = midY + (this.referenceLine.y2 - this.image.height / 2) * scaleY;
              context.moveTo(x1, y1);
              context.lineTo(x2, y2);
            }
            context.stroke();
          }
        }
      }
    }
  }

  // Images
  async onImagesUpdate() {
    if (this.imageIds && this.imageIds.length) {
      await this.loadImage(this.currentImageIndex);
      this.setDefaultZoom();
      this.$emit('translate', { x: 0, y: 0 }); // reset translation
    }
  }

  setDefaultZoom() {
    const enabledElement = cornerstone.getEnabledElement(this.$refs.viewer);
    const width = enabledElement.image.width;
    const height = enabledElement.image.height;
    const verticalScale = enabledElement.canvas.height / (height * this.initialScale);
    const horizontalScale = enabledElement.canvas.width / (width * this.initialScale);

    // Apply new scale
    this.initialZoom = Math.min(horizontalScale, verticalScale);
    this.$emit('setZoom', { value: this.initialZoom * 100, default: true }); // reset zoom
  }

  /**
   * Load image and apply transformation
   * @param index Index of the image to load
   * @param translation Translation to apply
   */
  async loadImage(index: number, translation = { x: 0, y: 0 }) {
    const id = this.imageIds[index];
    if (id) {
      const element = this.$refs.viewer;
      this.loading = true;
      this.loadingImageId = id;
      // Cache image
      const image = await cornerstone.loadAndCacheImage(id);
      const viewport = cornerstone.getDefaultViewportForImage(element, image);
      this.initialScale = viewport.scale;
      // Apply zoom and translation on image viewport
      cornerstone.setViewport(element, {
        ...viewport,
        scale: this.zoom * this.initialScale,
        translation
      });
      if (this.loadingImageId === id) {
        this.image = {
          width: image.width,
          height: image.height
        };
        cornerstone.displayImage(element, image);
        this.loading = false;
      }
    }
  }

  dismissColourFilterSnackbar() {
    this.colourFilterSnackbar = false;
  }
}

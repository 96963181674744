// Define a v-feature-flagged function for feature flag checking.
import { Feature } from '@/models';
import { useSessionStore } from '@/stores/session.store';

const directive = {
  mounted: (el, binding) => {
    const sessionStore = useSessionStore();
    if (sessionStore.currentUser && sessionStore.features) {
      const features = sessionStore.features;
      const feature = features.find((feature: Feature) => feature.key === binding.value && feature.enabled);
      if (!feature && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }
  }
};

export default directive;

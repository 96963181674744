
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import GridLayout from '@/lib/layouts/GridLayout.vue';
import { PatientDetailsCard } from '@/lib/components/PatientSummary';

import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { NavDropdown } from '@/lib/components/Navigation';
import { BaseSwitch } from '@/lib/components/Switch';
import { BaseModal } from '@/lib/components/Modals';
import { CheckboxGroup } from '@/lib/components/Checkbox';
import { FEATURES, scansViewerColourFilters } from '@/constants';

import SlicesSlider from './SlicesSlider.vue';
import SeriesSelector from './SeriesSelector.vue';
import AnnotationToolbar from './AnnotationToolbar.vue';
import ScanSigns from './ScanSigns.vue';
import ViewerNavigationSwitch from './ViewerNavigationSwitch.vue';
import ViewerSelect from './ViewerSelect.vue';
import ViewerItem from './ViewerItem.vue';
import ViewerZoomInput from './ViewerZoomInput.vue';
import ViewerThumbnailGallery from './ViewerThumbnailGallery.vue';

import { DicomImageData, IAnnotation, IGalleryImage, IOption, ISuggestion, Tool } from '@/lib';
import {
  Anatomy,
  Annotation,
  AnnotationType,
  FullIndicator,
  Image,
  ImageSeries,
  InadequateReason,
  IndicatorRequest,
  Patient,
  Study
} from '@/models';
import { getSeriesDescription } from '@/helpers/series.helper';
import { getDobFromISOString, getNhsNumberFromPatient } from '@/helpers/patient.helper';

import { imageLoaderService } from '@/services';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { usePatientStore } from '@/stores/patient.store';

export enum ViewerMode {
  SINGLE_MODE,
  COMPARE_MODE
}

@Options({
  props: {
    tools: {
      type: Array,
      default: () => []
    },
    series: {
      type: Array,
      default: () => []
    },
    studies: {
      type: Array,
      default: () => []
    },
    zoomMin: {
      type: Number,
      default: 10
    },
    patient: {
      type: Object,
      default: null
    },
    seriesId: {
      type: String,
      default: ''
    },
    studyId: {
      type: String,
      default: ''
    },
    signsList: {
      type: Array,
      default: () => []
    },
    signs: {
      type: Array,
      default: () => []
    },
    anatomies: {
      type: Array,
      default: () => []
    },
    inadequateOptions: {
      type: Array,
      default: () => []
    },
    imagesRoute: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CheckboxGroup,
    BaseModal,
    BaseSwitch,
    SeriesSelector,
    ViewerSelect,
    ViewerNavigationSwitch,
    NavDropdown,
    PatientDetailsCard,
    AnnotationToolbar,
    ScanSigns,
    ViewerZoomInput,
    ViewerThumbnailGallery,
    SlicesSlider,
    BaseIcon,
    BaseButton,
    ViewerItem,
    GridLayout
  }
})
export default class Viewer extends Vue {
  ViewerMode = ViewerMode;
  patientStore = usePatientStore();
  // Annotation
  tools!: Array<Tool>;
  selectedTool = null;

  // Viewer
  readonly!: boolean;
  layout = [1];
  imageIndexes = [0]; // image indexes of each viewer
  seriesIndex = 0; // index of the selected series
  mode: ViewerMode = ViewerMode.SINGLE_MODE; // mode value
  openImagesSelector: number | null = null; // Number of the viewer in which you want to add a series
  selectedSeries: Array<ImageSeries | null> = [null]; // All the viewers selected series
  lock = true; // True if the zoom and pan should be synchronized
  showAnnotations = true; // Set false to hide the annotations on every viewers
  showInadequateModal: number | null = null;
  inadequateReasons: Array<string> = [];
  focused = 0; // focused viewer index

  // Transformations
  isColourFiltersEnabled = isFeatureFlagEnabled(FEATURES.COLOUR_TRANSFORMATIONS);
  availableColourFilters = scansViewerColourFilters();
  colourFilter = this.availableColourFilters[0];
  zooms = [100];
  defaultZooms: number[] = [];
  translations = [{ x: 0, y: 0 }];

  // props
  signs!: Array<FullIndicator>; // All the patient signs
  signsList!: Array<ISuggestion>; // List of all the signs that can be added
  anatomies!: Array<Anatomy>; // List of all the anatomies, used to add the signs to the anatomy, using laterality
  series!: Array<ImageSeries>; // study series
  studies!: Array<Study>; // All the studies, needed for the Compare view
  inadequateOptions!: Array<InadequateReason>;
  seriesId!: string; // initial selected series id
  studyId!: string; // selected study id
  imagesRoute!: string;
  patient!: Patient;

  // Annotate and Compare modes, used by the mode selector
  get modes() {
    return [
      {
        name: this.readonly
          ? (this.$t('platform.common.view') as string)
          : (this.$t('custom.uhb.scans.annotate') as string),
        icon: 'note',
        value: ViewerMode.SINGLE_MODE
      },
      {
        name: this.$t('platform.scans.compare') as string,
        icon: 'compare',
        value: ViewerMode.COMPARE_MODE
      }
    ];
  }

  get inadequacies() {
    return this.selectedSeries.map((series) => (series ? series.inadequacies : []));
  }

  get inadequateReasonsOptions(): Array<IOption> {
    return this.inadequateOptions.map((option: InadequateReason) => ({
      value: option.id,
      label: option.name
    }));
  }

  // Number of rows in the grid layout, calculated by getting the lcm of the layout array items
  get rows(): number {
    const gcd = (a: number, b: number): number => (a ? gcd(b % a, a) : b);
    const lcm = (a: number, b: number): number => (a * b) / gcd(a, b);
    return this.layout.reduce(lcm);
  }

  // The id of the series that does not appear in the SeriesSelector
  get excludedSeries(): Array<string> {
    return [];
  }

  // The layout options, the length of the array defines the number of columns and the value, the rows in each column
  // [1, 3, 2] means there are 3 columns, the first column has 1 viewer, the second 3 and the third 2.
  get layoutOptions(): Array<{
    icon: string;
    name: string;
    value: Array<number>;
  }> {
    return [
      { icon: '1x1', name: '1x1', value: [1, 1] },
      { icon: '1x2', name: '1x2', value: [1, 2] },
      { icon: '2x2', name: '2x2', value: [2, 2] }
    ];
  }

  get patientName(): string {
    return this.patient ? `${this.patient.last_name}, ${this.patient.first_name}` : '';
  }

  get patientInfos() {
    const nhsNumber = getNhsNumberFromPatient(this.patient);
    return this.patient
      ? {
        [this.$t('platform.patient.dob') as string]: this.formatDob(this.patient.date_of_birth),
        [this.$t('platform.patient.age') as string]: this.patient.age,
        [this.$t('platform.patient.sex') as string]: this.patient.sex,
        [this.$t('platform.patient.ethnicity') as string]: this.patient.ethnicity
          ? this.patient.ethnicity.title
          : this.$t('platform.patient.not-specified'),
        [this.$t('platform.patient.phone') as string]: this.patient.contact_number,
        ...(this.showNhsNumber ? { nhs: nhsNumber ? nhsNumber : this.$t('platform.common.none').toString() } : {}),
        ...(this.patient.minor_patients && this.patient.minor_patients.length > 0
          ? {
            [this.$t('custom.uhb.patient.alternative-ids') as string]: this.patient.minor_patients.map(
              (minor_patient) => minor_patient.mrn
            )
          }
          : {})
      }
      : {};
  }

  get showNhsNumber() {
    return this.patientStore.showNhsNumber;
  }

  // Annotations of all studies
  get allAnnotations(): Array<Annotation> {
    return this.studies.reduce((acc, s) => [...acc, ...s.annotations], [] as Array<Annotation>);
  }

  // Annotations of the displayed scans
  get currentAnnotations(): Array<Array<IAnnotation>> {
    return this.currentImages.map((image) => {
      if (this.showAnnotations && image) {
        const imageAnnotations = this.allAnnotations.filter((annotation) => annotation.imageId === image.id);
        return this.parseAnnotations(imageAnnotations);
      }
      return [];
    });
  }

  // Signs of the current image
  get currentSigns(): Array<FullIndicator> {
    const currentImage = this.currentImages[0];
    if (currentImage) {
      return this.signs.filter((sign) => sign.image_id === currentImage.id);
    }
    return [];
  }

  // Anatomy of the first viewer's series
  get currentAnatomy(): Anatomy | undefined {
    return this.anatomies.find((anatomy) => anatomy.laterality === this.series[this.seriesIndex].laterality);
  }

  // Image displayed on each viewer
  get currentImages(): Array<Image | null> {
    return this.selectedSeries.map((series, index) => (series ? series.images[this.imageIndexes[index]] : null));
  }

  // First viewer series, selected in the gallery
  get currentSeries(): ImageSeries {
    return this.series[this.seriesIndex];
  }

  // Reference lines coordinates of each viewer
  get referenceLines() {
    return this.currentImages.map((image) =>
      image
        ? {
          x1: image.octAlignmentX1,
          y1: image.octAlignmentY1,
          x2: image.octAlignmentX2,
          y2: image.octAlignmentY2,
          radius: image.octAlignmentRadius
        }
        : null
    );
  }

  // Images of the current series
  get imageSeries(): Array<Array<Image>> {
    return this.selectedSeries.map((series) => (series ? series.images.sort((a, b) => a.order - b.order) : []));
  }

  // Image uris of each viewer
  get imageIds(): Array<Array<string>> {
    return this.imageSeries.map((images) => images.map((image) => `${this.imagesRoute}/${image.id}`));
  }

  // Dimensions of each displayed images
  get dimensions() {
    return this.currentImages.map((image) => {
      if (image) {
        return {
          widthMm: image.widthMm,
          heightMm: image.heightMm
        };
      }
      return {
        widthMm: 0,
        heightMm: 0
      };
    });
  }

  // Data if the image to display on the viewer
  get imageData(): Array<DicomImageData> {
    return this.currentImages.map((image) => {
      if (image) {
        const series = this.studies
          .map((study) => study.series.find((s) => s.images.find((i) => i.id === image.id)))
          .find((s) => s);
        if (series) {
          return {
            description: getSeriesDescription(series),
            acquisitionDateTime: dayjs(series.seriesDate).format('MMM D, YYYY h:mm:ss A'),
            dimensions:
              image.widthMm && image.heightMm ? `${image.widthMm.toFixed(1)}mmx${image.heightMm.toFixed(1)}mm` : ''
          };
        }
      }
      return {};
    });
  }

  // Images that are displayed in the gallery
  get galleryImages(): Array<IGalleryImage> {
    return this.series
      .filter((series) => series.images && series.images.length)
      .map((series) => {
        let firstImage = series.images.find((image) => image.order === 1);
        firstImage = firstImage ? firstImage : series.images[0];
        return {
          id: series.id,
          image: null,
          imageUri: `${this.imagesRoute}/${firstImage.id}`,
          thumbnailUri: firstImage.thumbnailUri ? firstImage.thumbnailUri : `${this.imagesRoute}/${firstImage.id}`,
          placeholder: series.laterality ? series.laterality : '',
          date: series.seriesDate,
          description: getSeriesDescription(series),
          data: {
            thumbIndex: series.thumbIndex
          }
        };
      })
      .sort((a, b) => a.data.thumbIndex - b.data.thumbIndex); // sort by thumb index
  }

  // Number of viewer items
  get viewerItemsLength() {
    return this.layout.reduce((a, b) => a + b, 0);
  }

  // Indexes of annotated slices
  get annotatedSlicesIndexes() {
    return this.imageSeries.map((images) =>
      images
        .map((image, index) => {
          const annotation = this.allAnnotations.find((a) => a.imageId === image.id);
          return annotation ? index : null;
        })
        .filter((index) => index !== null)
    );
  }

  mounted() {
    // Open viewer on series with id seriesId
    if (this.seriesId && this.seriesId.length) {
      const index = this.series.findIndex((s) => s.id === this.seriesId);
      if (index > 0) {
        this.seriesIndex = index;
      }
    }
    this.selectedSeries = [this.series[this.seriesIndex]];
    this.inadequateReasons = this.series[this.seriesIndex]?.inadequacies?.map((i) => i.id) || [];
    this.$watch(
      'series',
      () => {
        this.inadequateReasons = this.series[this.seriesIndex]?.inadequacies?.map((i) => i.id) || [];
        this.selectedSeries = this.selectedSeries.slice().map((s) => {
          if (s) {
            return this.series.find((series) => series.id === s.id) || null;
          }
          return null;
        });
      },
      { deep: true }
    );
    window.addEventListener('keydown', (ev) => this.onKeydown(ev));
    window.addEventListener('wheel', (ev) => this.onScroll(ev as WheelEvent));
  }

  beforeUnmount() {
    window.removeEventListener('keydown', (ev) => this.onKeydown(ev));
    window.removeEventListener('wheel', (ev) => this.onScroll(ev as WheelEvent));
  }

  // EVENTS
  resetViewport(index: number) {
    // reset zoom and pan
    if (this.lock) {
      this.zooms = [...this.zooms].map((v, i) => this.defaultZooms[i]);
      this.translations = [...this.translations].map(() => ({ x: 0, y: 0 }));
    } else {
      this.zooms.splice(index, 1, this.defaultZooms[index]);
      this.translations.splice(index, 1, { x: 0, y: 0 });
    }
    this.colourFilter = this.availableColourFilters[0];
  }

  onKeydown(ev: KeyboardEvent) {
    if (this.focused >= 0) {
      const currentIndex = this.imageIndexes[this.focused];
      if (ev.key === 'ArrowUp') {
        this.updateImageIndex(this.focused, currentIndex - 1);
      } else if (ev.key === 'ArrowDown') {
        this.updateImageIndex(this.focused, currentIndex + 1);
      } else if (ev.key === 'PageDown') {
        this.updateImageIndex(this.focused, currentIndex + 10);
      } else if (ev.key === 'PageUp') {
        this.updateImageIndex(this.focused, currentIndex - 10);
      } else if (ev.key === 'Home') {
        this.updateImageIndex(this.focused, 0);
      } else if (ev.key === 'End') {
        this.updateImageIndex(this.focused, this.imageIds[this.focused].length);
      }
    }
  }

  onScroll(event: WheelEvent) {
    if (this.focused >= 0) {
      const currentIndex = this.imageIndexes[this.focused];
      if (event.deltaY > 0) {
        this.updateImageIndex(this.focused, currentIndex + 1);
      } else {
        this.updateImageIndex(this.focused, currentIndex - 1);
      }
    }
  }

  updateImageIndex(viewer: number, index: number) {
    if (this.mode === ViewerMode.COMPARE_MODE && this.lock) {
      this.imageIndexes.map((value, vIndex) => this.setImageIndex(vIndex, index));
    } else {
      this.setImageIndex(viewer, index);
    }
  }

  setImageIndex(viewer: number, index: number) {
    const length = this.imageIds[viewer].length;
    if (this.selectedSeries[viewer] && length > 1) {
      if (index >= length - 1) {
        this.imageIndexes.splice(viewer, 1, length - 1);
      } else if (index < 0) {
        this.imageIndexes.splice(viewer, 1, 0);
      } else {
        this.imageIndexes.splice(viewer, 1, index);
      }
    }
  }

  onSeriesChange() {
    this.imageIndexes.splice(0, 1, 0);
    this.selectedSeries.splice(0, 1, this.series[this.seriesIndex]);
    imageLoaderService.unshiftSeries(this.studyId, this.imagesRoute, this.series[this.seriesIndex], () =>
      this.$emit('updateProcessedImages')
    );
    this.inadequateReasons = this.series[this.seriesIndex].inadequacies.map((i) => i.id);
    this.colourFilter = this.availableColourFilters[0];
  }

  changeMode(mode: number) {
    if (mode === ViewerMode.SINGLE_MODE) {
      this.setLayout([1]);
    } else {
      this.selectedTool = null;
      this.setLayout([1, 1]);
    }
  }

  selectSeries(value: ImageSeries) {
    if (this.openImagesSelector) {
      this.imageIndexes.splice(this.openImagesSelector, 1, 0);
      this.selectedSeries.splice(this.openImagesSelector, 1, value);
      this.openImagesSelector = null;
      if (this.lock) {
        this.updateImageIndex(0, this.imageIndexes[0]);
      }
    }
  }

  translate(index: number, value: { x: number; y: number }) {
    if (this.lock) {
      this.translations = [...this.translations].map(() => ({ ...value }));
    } else {
      this.translations.splice(index, 1, {
        ...value
      });
    }
  }

  setZoom(index: number, value: { value: number; default: boolean }) {
    if (value.default) {
      this.defaultZooms[index] = value.value;
    }
    if (this.lock) {
      this.zooms = [...this.zooms].map((v, i) => value.value - this.defaultZooms[index] + this.defaultZooms[i]);
    } else {
      this.zooms.splice(index, 1, value.value);
    }
  }

  setLock(value: boolean) {
    this.lock = value;
    if (value) {
      this.zooms = [...this.zooms].map(() => this.zooms[0]);
      this.translations = [...this.translations].map(() => this.translations[0]);
      this.imageIndexes.map((value, viewer) => this.setImageIndex(viewer, this.imageIndexes[0]));
    }
  }

  // SIGNS MANAGEMENT
  addSign(sign: ISuggestion) {
    const image = this.currentImages[0];
    if (image) {
      const value: IndicatorRequest = {
        indicator_id: sign.id,
        ...(this.currentAnatomy ? { anatomy_id: this.currentAnatomy.id } : {}),
        image_id: image.id,
        series_id: this.series[this.seriesIndex].id
      };
      this.$emit('addSign', value);
    }
  }

  removeSign(id: string) {
    this.$emit('removeSign', id);
  }

  // ANNOTATIONS MANAGEMENT
  getCornerstoneName(type: AnnotationType) {
    switch (type) {
    case 'Angle':
      return 'Angle';
    case 'Arrow':
      return 'ArrowAnnotate';
    case 'Distance':
      return 'Length';
    case 'Freehand':
      return 'FreehandRoi';
    default:
      return '';
    }
  }

  getZephyrToolName(name: string) {
    switch (name) {
    case 'Angle':
      return 'Angle';
    case 'ArrowAnnotate':
      return 'Arrow';
    case 'Length':
      return 'Distance';
    case 'FreehandRoi':
      return 'Freehand';
    default:
      return null;
    }
  }

  getStudyFromSeriesId(seriesId: string): Study | undefined {
    return this.studies.find((study) => study.series.find((series) => series.id === seriesId));
  }

  parseAnnotations(annotations: Array<Annotation>) {
    return annotations.reduce((acc: IAnnotation[], current: Annotation) => {
      const toolName = this.getCornerstoneName(current.action);
      acc.push({
        toolName,
        measurementData: {
          ...JSON.parse(current.details as string),
          uuid: current.id
        }
      });
      return acc;
    }, [] as IAnnotation[]);
  }

  createAnnotation(index: number, annotation: IAnnotation) {
    const series = this.selectedSeries[index];
    const study = series ? this.getStudyFromSeriesId(series.id) : null;
    const image = this.currentImages[index];
    const action = this.getZephyrToolName(annotation.toolName);
    if (image && action) {
      const data: Partial<Annotation> = {
        imageId: image.id,
        ...(study ? { studyId: study.id } : {}),
        action,
        details: annotation.measurementData
      };
      this.$emit('createAnnotation', data);
    }
  }

  updateAnnotation(index: number, annotation: IAnnotation) {
    const series = this.selectedSeries[index];
    const study = series ? this.getStudyFromSeriesId(series.id) : null;
    const image = this.currentImages[index];
    const action = this.getZephyrToolName(annotation.toolName);
    if (image && action) {
      const data: Partial<Annotation> = {
        id: annotation.measurementData.uuid,
        imageId: image.id,
        ...(study ? { studyId: study.id } : {}),
        action,
        details: annotation.measurementData
      };
      this.$emit('updateAnnotation', data);
    }
  }

  removeAnnotation(index: number, id: string) {
    const series = this.selectedSeries[index];
    const study = series ? this.getStudyFromSeriesId(series.id) : null;
    this.$emit('removeAnnotation', {
      id,
      ...(study ? { studyId: study.id } : {})
    });
  }

  closeInadequateModal() {
    this.showInadequateModal = null;
    this.inadequateReasons = this.currentSeries.inadequacies.map((i) => i.id);
  }

  markScanInadequate() {
    this.$emit('setInadequate', {
      seriesId: this.currentSeries.id,
      reasons: this.inadequateReasons
    });
    this.closeInadequateModal();
  }

  // UTILS
  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }

  // Get the layout from the index of the viewer to calculate the row-span
  getLayout(index: number): number | null {
    let res: number | null = null;
    let found = false;
    this.layout.reduce((acc, val, i) => {
      const total = acc + val;
      if (!found && total > index) {
        res = this.layout[i];
        found = true;
      }
      return total;
    }, 0);
    return res;
  }

  setLayout(value: Array<number>) {
    const nbViewers = value.reduce((a, b) => a + b, 0);
    const diff = this.viewerItemsLength - nbViewers; // calculate the difference of viewers
    const indexes = [...this.imageIndexes];
    const selected = [...this.selectedSeries];
    const zooms = [...this.zooms];
    const defaultZooms = [...this.defaultZooms];
    const translations = [...this.translations];
    if (diff > 0) {
      // If there are less viewers, remove the data of the last ones
      indexes.splice(nbViewers);
      selected.splice(nbViewers);
      zooms.splice(nbViewers);
      translations.splice(nbViewers);
    } else if (diff < 0) {
      // If there are more viewers, add default data
      for (let i = 0; i > diff; i--) {
        indexes.push(0);
        defaultZooms.push(100);
        zooms.push(100);
        translations.push({ x: 0, y: 0 });
        selected.push(null);
      }
    }
    this.imageIndexes = indexes;
    this.selectedSeries = selected;
    this.defaultZooms = defaultZooms;
    this.zooms = zooms;
    this.translations = translations;
    this.layout = value;
  }
}

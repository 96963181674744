import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "font-medium text-gray-900 mb-3" }
const _hoisted_3 = {
  class: "flex flex-col",
  "data-dusk": "roles-selector"
}
const _hoisted_4 = ["data-dusk", "onClick"]
const _hoisted_5 = { class: "flex flex-row items-center space-x-4" }
const _hoisted_6 = ["checked"]
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = { class: "mt-3 text-sm text-gray-600 leading-tight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", {
      class: "overflow-y-scroll scrollbar-dark border border-gray-300 rounded",
      style: _normalizeStyle(`max-height: ${_ctx.maxHeight}`)
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: role.id,
            "data-dusk": `role-selector-${role.id}`,
            class: _normalizeClass(["flex flex-row border-gray-300 p-4 justify-between items-center cursor-pointer hover:shadow-md", { 'border-t': index }]),
            onClick: ($event: any) => (_ctx.onRoleClick(role.id))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("input", {
                type: "checkbox",
                class: "form-checkbox mr-3 w-4 h-4",
                checked: _ctx.modelValue.includes(role.id)
              }, null, 8, _hoisted_6),
              _createElementVNode("span", _hoisted_7, _toDisplayString(role.name), 1)
            ])
          ], 10, _hoisted_4))
        }), 128))
      ])
    ], 4),
    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('platform.role.select-user-roles')), 1)
  ]))
}
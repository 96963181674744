import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.tools && _ctx.tools.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "bg-gray-900 rounded py-1 px-2 flex flex-row space-x-2",
        onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tools, (tool) => {
          return (_openBlock(), _createBlock(_component_BaseTooltip, {
            key: tool.name,
            position: "above"
          }, {
            target: _withCtx(() => [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass([tool.cornerstoneName === _ctx.modelValue ? 'text-white bg-primary-500' : 'hover:bg-gray-800 text-gray-400', "flex justify-center hover:text-white items-center h-8 w-8 rounded"]),
                onClick: _withModifiers(($event: any) => (_ctx.$emit('update:modelValue', tool.cornerstoneName)), ["stop"])
              }, [
                _createVNode(_component_BaseIcon, {
                  name: tool.icon
                }, null, 8, ["name"])
              ], 10, _hoisted_1)
            ]),
            content: _withCtx(() => [
              _createTextVNode(_toDisplayString(tool.name), 1)
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_ctx.modelValue)
          ? (_openBlock(), _createBlock(_component_BaseButton, {
              key: 0,
              size: "small",
              color: "dark",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', null)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.close')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 32))
    : _createCommentVNode("", true)
}
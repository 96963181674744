
import { Vue, Options } from 'vue-class-component';
import { BaseSelect } from '@/lib/components/Select';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseDatePicker } from '@/lib/components/Date';
import { BaseTextInput } from '@/lib/components/Input';
import { genderOptionsExternalLookup } from '@/constants';
import { formattedNHSNumber, isNHSNumberValid } from '@/lib/helpers/nhs-number.helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isValidUKPostcode } from '@/lib/helpers/uhb-postcode.helper';
import { v4 } from 'uuid';
import { ExternalPatientSearchParam } from '@/models';
import BaseSearchInput from '@/lib/components/Search/BaseSearchInput.vue';
import { IOption } from '@/lib';

interface MpiPdsRequirement {
  label: string;
  isRequirementMet: boolean;
  isError: boolean;
}

type SearchType = 'MPI' | 'SPINE';

dayjs.extend(customParseFormat);

@Options({
  components: {
    BaseSearchInput,
    BaseIcon,
    BaseDatePicker,
    BaseTextInput,
    BaseSelect,
    BaseButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object,
      required: true
    },
    searchType: {
      type: String,
      required: true
    }
  }
})
export default class PatientSearchCard extends Vue {
  modelValue!: ExternalPatientSearchParam;
  searchType!: string;
  nhsNumberError = '';
  postcodeError = '';
  dobError = '';
  genderOptions = genderOptionsExternalLookup();
  masks = {
    input: 'YYYY-MM-DD'
  };

  minAge = 16;

  get isAnyError(): boolean {
    return Boolean(this.nhsNumberError) || Boolean(this.postcodeError) || Boolean(this.dobError);
  }

  get areMinimumSearchRequirementsMet(): boolean {
    if (this.mpiAndPdsRequirementFields[0]) {
      return this.mpiAndPdsRequirementFields[0].requirements.some((requirement) => requirement.isRequirementMet);
    }
    return false;
  }

  get mpiAndPdsRequirementFields(): Array<{
    label: string;
    type: SearchType;
    requirements: Array<MpiPdsRequirement>;
  }> {
    return [
      {
        label: this.$t('custom.uhb.patient.mpi-requirements') as string,
        requirements: this.mpiRequirementFields,
        type: 'MPI' as SearchType
      },
      {
        label: this.$t('custom.uhb.patient.pds-requirements') as string,
        requirements: this.pdsRequirementFields,
        type: 'SPINE' as SearchType
      }
    ].filter((requirement) => requirement.type === this.searchType);
  }

  get mpiRequirementFields(): Array<MpiPdsRequirement> {
    return [
      {
        label: this.$t('custom.uhb.patient.mpi-search-criteria-nhs') as string,
        isRequirementMet: this.isMpiPdsNhsNumberRequirementMet,
        isError: Boolean(this.nhsNumberError)
      },
      {
        label: this.$t('custom.uhb.patient.mpi-search-criteria-demographics') as string,
        isRequirementMet: this.isMpiDobPlusOneRequirementMet,
        isError: Boolean(this.postcodeError)
      }
    ];
  }

  get pdsRequirementFields(): Array<MpiPdsRequirement> {
    return [
      {
        label: this.$t('custom.uhb.patient.pds-search-criteria-nhs-dob') as string,
        isRequirementMet: this.isMpiPdsNhsNumberRequirementMet && Boolean(this.modelValue.dob),
        isError: Boolean(this.nhsNumberError)
      },
      {
        label: this.$t('custom.uhb.patient.pds-search-criteria-demographics') as string,
        isRequirementMet: this.isPdsFullNameSexDobRequirementMet,
        isError: false
      }
    ];
  }

  get isPdsFullNameSexDobRequirementMet(): boolean {
    return (
      Boolean(this.modelValue.givenName) &&
      Boolean(this.modelValue.familyName) &&
      Boolean(this.modelValue.gender) &&
      Boolean(this.modelValue.dob) &&
      Boolean(this.isFamilyNameMinimumLength) &&
      Boolean(this.isGivenNameMinimumLength)
    );
  }

  get isMpiPdsNhsNumberRequirementMet(): boolean {
    return Boolean(this.modelValue.nhsNumber) && isNHSNumberValid(this.modelValue.nhsNumber);
  }

  get isMpiDobPlusOneRequirementMet(): boolean {
    return (
      Boolean(this.modelValue.dob) &&
      ((Boolean(this.modelValue.givenName) && Boolean(this.isGivenNameMinimumLength)) ||
        (Boolean(this.modelValue.familyName) && Boolean(this.isFamilyNameMinimumLength)) ||
        Boolean(this.modelValue.gender) ||
        Boolean(this.modelValue.postcode))
    );
  }

  get filteredGenderOptions() {
    return this.genderOptions.filter((option: IOption) =>
      option.label.toLowerCase().includes((this.modelValue.gender || '').toLowerCase())
    );
  }

  created() {
    this.$watch('searchType', () => this.search());
  }

  mounted() {
    this.$watch('areMinimumSearchRequirementsMet', (value: boolean) => {
      this.$emit('update-minimum-search-requirements-check', value);
    });
  }

  validateName(ev: KeyboardEvent) {
    const isValid = /^[a-z ,.'-]+$/i.test(ev.key);
    if (!isValid) {
      ev.preventDefault();
    }
    return isValid;
  }

  validateDob(dobValue: string) {
    if (dobValue) {
      if (dobValue > this.maxBirthDate) {
        this.dobError = this.$t('custom.uhb.patient.max-dob-error', [this.minAge]) as string;
      } else {
        this.dobError = '';
      }
    }

    if (dobValue === '') {
      this.dobError = '';
    }

    this.modelValue.dob = dobValue;
  }

  get isGivenNameMinimumLength() {
    if (this.modelValue.givenName) {
      return this.modelValue.givenName.length > 1;
    }
    return false;
  }

  get isFamilyNameMinimumLength() {
    if (this.modelValue.familyName) {
      return this.modelValue.familyName.length > 1;
    }
    return false;
  }

  validatePostcode(postcodeValue: string) {
    if (postcodeValue) {
      this.postcodeError =
        isValidUKPostcode(postcodeValue) || !postcodeValue
          ? ''
          : (this.$t('platform.patient.postcode-is-invalid') as string);
    } else {
      this.postcodeError = this.$t('platform.patient.postcode-is-invalid') as string;
    }
    this.$emit('update:modelValue', { ...this.modelValue, postcode: postcodeValue });

    if (postcodeValue === '') {
      this.postcodeError = '';
    }
  }

  validateNHSNumber(nhsNumberValue = '', eventType = '') {
    if (nhsNumberValue && !isNHSNumberValid(nhsNumberValue)) {
      this.nhsNumberError = this.$t('custom.uhb.patient.nhs-number-error') as string;
    } else {
      this.nhsNumberError = '';
    }

    if (eventType === 'blur') {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        nhsNumber: formattedNHSNumber(nhsNumberValue)
      });
    } else {
      this.$emit('update:modelValue', { ...this.modelValue, nhsNumber: nhsNumberValue });
    }
  }

  get disableSearch() {
    return (
      this.isAnyError ||
      !(
        this.modelValue.nhsNumber || // search by nhsNumber only
        (this.modelValue.nhsNumber && this.oneOfOptionalFieldIsReady) || // search by nhsNumber + one of the optional field
        (this.modelValue.dob &&
          dayjs(this.modelValue.dob, this.masks.input).isValid() &&
          this.oneOfOptionalFieldIsReady)
      ) // search by dob + one of the optional field
    );
  }

  get oneOfOptionalFieldIsReady() {
    // one of the option field is ready and there's no postcode error.
    return (
      (this.modelValue.familyName || this.modelValue.givenName || this.modelValue.gender || this.modelValue.postcode) &&
      !this.postcodeError
    );
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get maxBirthDate() {
    return dayjs().subtract(this.minAge, 'year').format('YYYY-MM-DD');
  }

  search() {
    const searchParams = {
      GUID: v4(),
      type: this.searchType,
      ...(dayjs(this.modelValue.dob, this.masks.input).isValid()
        ? { dob: dayjs(this.modelValue.dob, this.masks.input).format('YYYYMMDD') }
        : {}),
      ...(this.modelValue.givenName ? { givenName: this.modelValue.givenName } : {}),
      ...(this.modelValue.familyName ? { familyName: this.modelValue.familyName } : {}),
      ...(this.modelValue.gender ? { gender: this.modelValue.gender } : {}),
      ...(this.modelValue.postcode ? { postcode: this.modelValue.postcode } : {}),
      ...(this.modelValue.nhsNumber ? { nhsNumber: this.modelValue.nhsNumber.replace(/\s/g, '') } : {})
    };

    this.$emit('search', searchParams);
  }

  clear() {
    this.$emit('update:modelValue', {
      nhsNumber: '',
      dob: '',
      givenName: '',
      familyName: '',
      gender: '',
      postcode: ''
    });
    this.nhsNumberError = '';
    this.postcodeError = '';
  }
}

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "target",
      style: {"width":"max-content"},
      onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show())),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hide())),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hide()))
    }, [
      _renderSlot(_ctx.$slots, "target")
    ], 544),
    _createElementVNode("div", {
      ref: "content",
      class: _normalizeClass(["fixed bg-gray-900 shadow-lg rounded-lg text-xs font-semibold text-white px-2 py-1 z-50 max-w-xs transition-opacity ease-out duration-200", [
        _ctx.showTooltip && !_ctx.disabled ? 'visible opacity-100' : 'invisible opacity-0',
        _ctx.position === 'before' || _ctx.position === 'left'
          ? 'mr-2'
          : _ctx.position === 'after' || _ctx.position === 'right'
            ? 'ml-2'
            : _ctx.position === 'above' || _ctx.position === 'top'
              ? 'mb-2'
              : _ctx.position === 'below' || _ctx.position === 'bottom'
                ? 'mt-2'
                : ''
      ]]),
      style: _normalizeStyle(_ctx.style)
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 6)
  ]))
}
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-white p-5 border rounded-lg space-y-4" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center" }
const _hoisted_3 = { class: "flex flex-row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "question"),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "options")
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center space-x-4"
}
const _hoisted_2 = { class: "flex flex-col flex-1 w-full leading-tight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (!_ctx.closed)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["flex flex-row min-h-12 text-left space-x-4 rounded-md p-3", [_ctx.textColorClass, _ctx.bgColorClass, !_ctx.value || _ctx.value.length > 1 ? 'items-start' : 'items-center']])
      }, [
        (_ctx.badge || _ctx.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.badge)
                ? (_openBlock(), _createBlock(_component_MkBadge, {
                    key: 0,
                    filled: "",
                    label: _ctx.badge,
                    color: _ctx.color,
                    size: "small"
                  }, null, 8, ["label", "color"]))
                : _createCommentVNode("", true),
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_BaseIcon, {
                    key: 1,
                    name: _ctx.icon,
                    height: "16px",
                    class: _normalizeClass({ 'mt-1': !_ctx.value || _ctx.value.length > 1 })
                  }, null, 8, ["name", "class"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.value)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.labelArray, (str, index) => {
                return (_openBlock(), _createElementBlock("span", { key: str }, [
                  (_ctx.list && _ctx.value.length > 1)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(index + 1) + ". ", 1)
                      ], 64))
                    : _createCommentVNode("", true),
                  _createTextVNode(_toDisplayString(str), 1)
                ]))
              }), 128))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        (_ctx.button)
          ? (_openBlock(), _createBlock(_component_BaseButton, {
              key: 1,
              size: "sm",
              color: "ghost",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.button.click()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.button.label), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
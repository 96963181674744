
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: { BaseIcon },
  props: {
    modelValue: {
      type: Number,
      default: 100
    },
    min: {
      type: Number,
      default: 50
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class ViewerZoomInput extends Vue {
  modelValue!: number;
  min!: number;
  disabled!: boolean;

  get roundedValue() {
    return Math.round(this.modelValue);
  }

  zoomIn() {
    if (!this.disabled) {
      this.$emit('update:modelValue', this.modelValue + 10);
    }
  }

  zoomOut() {
    if (this.modelValue - 10 >= this.min && !this.disabled) {
      this.$emit('update:modelValue', this.modelValue - 10);
    } else {
      this.$emit('update:modelValue', this.min);
    }
  }
}

import { GenericService } from './generic.service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  CopdAllProgramItem,
  CopdMeasurementDischargeParams,
  CopdMeasurementReviewParams,
  CopdProgramConsult,
  CopdProgramRequest,
  CopdProgramResponseData,
  JsonApiResponse
} from '@/models';
import apiClient from '../api/client/apiClient';

export class CopdProgramService extends GenericService<CopdProgramRequest> {
  endpoint = 'copd/programs';

  async getProgramWithIncludes(
    id: string | number,
    params: AxiosRequestConfig = {}
  ): Promise<JsonApiResponse<CopdProgramResponseData, any>> {
    return await apiClient.get(`${this.endpoint}/${id}`, params);
  }

  async createForPatient(
    patientId: string,
    model: Partial<CopdProgramRequest>,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<CopdProgramRequest>> {
    return await apiClient.post(
      `copd/patients/${patientId}/programs`,
      model,
      params
    );
  }

  async reviewMeasurements(
    id: string | number,
    model: CopdMeasurementReviewParams,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return await apiClient.post(`${this.endpoint}/${id}/review`, model, params);
  }

  async dischargeProgram(
    id: string | number,
    model: CopdMeasurementDischargeParams,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return await apiClient.post(`${this.endpoint}/${id}/review`, model, params);
  }

  async setNextConsult(
    id: string | number,
    model: CopdProgramConsult,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return await apiClient.post(
      `${this.endpoint}/${id}/consults`,
      model,
      params
    );
  }

  async getAllPrograms(
    patientId: string | number,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<CopdAllProgramItem[]>> {
    return await apiClient.get(`copd/patients/${patientId}/programs`, params);
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "pb-3"
}
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = ["for"]
const _hoisted_5 = {
  key: 0,
  class: "text-gray-500 font-medium text-xs"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled", "value", "readonly", "placeholder", "aria-placeholder"]
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              for: _ctx.label,
              class: "text-base font-medium leading-tight"
            }, _toDisplayString(_ctx.label), 9, _hoisted_4),
            (_ctx.isOptional)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["inline-flex text-base h-12 w-full font-medium items-center bg-white border text-gray-900 shadow rounded", {
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          _ctx.error
      }])
    }, [
      (_ctx.showButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            disabled: _ctx.disabled || _ctx.readonly,
            class: _normalizeClass([{ 'cursor-default bg-gray-100 text-gray-400': _ctx.disabled }, "w-1/4 border-r border-gray-300 focus:outline-none"]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stepDown && _ctx.stepDown(...args)))
          }, [
            _createVNode(_component_BaseIcon, { name: "minus" })
          ], 10, _hoisted_6))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "left"),
      _createElementVNode("input", _mergeProps(_ctx.$attrs, {
        ref: "input",
        class: ["ml-4 w-full h-full focus:outline-none flex-grow spin-appearance-none", [{ 'bg-gray-100 text-gray-400': _ctx.disabled }]],
        type: "number",
        disabled: _ctx.disabled,
        value: _ctx.modelValue,
        readonly: _ctx.readonly,
        placeholder: _ctx.placeholder,
        "aria-placeholder": _ctx.placeholder,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }), null, 16, _hoisted_7),
      (_ctx.showButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: _normalizeClass(["w-1/4 border-l border-gray-300 focus:outline-none", { 'cursor-default bg-gray-100 text-gray-400': _ctx.disabled }]),
            disabled: _ctx.disabled || _ctx.readonly,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.stepUp && _ctx.stepUp(...args)))
          }, [
            _createVNode(_component_BaseIcon, { name: "add" })
          ], 10, _hoisted_8))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "use-default")
    ], 2),
    _createVNode(_component_InputTextHelper, {
      error: _ctx.error,
      description: _ctx.description
    }, null, 8, ["error", "description"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_ctx.brandLogo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_InlineSvg, {
          src: _ctx.brandLogo,
          class: "inline-block",
          "data-dusk": `${_ctx.useDefault ? 'big-picture-medical' : _ctx.branding}-logo`
        }, null, 8, ["src", "data-dusk"])
      ]))
    : _createCommentVNode("", true)
}
import { GenericService } from './generic.service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';
import { Document } from '@/models';

export class PatientDocumentService extends GenericService<Document> {
  constructor(patientId: string) {
    super();
    this.endpoint = `v1/patients/${patientId}/documents`;
  }

  async fetchBase64Pdf(id: string | number, params: AxiosRequestConfig = {}): Promise<AxiosResponse<string>> {
    return await apiClient.get(`${this.endpoint}/${id}`, {
      ...params,
      headers: {
        ...(params.headers || {}),
        Accept: 'application/pdf;base64'
      }
    });
  }

  async getItemHtml(documentId: string, itemId: string, params: AxiosRequestConfig = {}): Promise<string> {
    return await apiClient.get(`${this.endpoint}/${documentId}/items/${itemId}`, {
      ...params,
      headers: {
        ...(params.headers || {}),
        Accept: 'text/html'
      }
    });
  }
}

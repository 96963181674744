import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'a'), _mergeProps({
    to: _ctx.to,
    class: ["btn", [_ctx.disabled ? 'btn-disabled' : `btn-${_ctx.color}`, _ctx.block ? 'btn-block' : '', `btn-${_ctx.size}`]],
    disabled: _ctx.disabled
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (_ctx.leftIcon && _ctx.leftIcon.length)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            name: _ctx.leftIcon,
            position: "left",
            class: "stroke-1.5"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      (_ctx.rightIcon && _ctx.rightIcon.length)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 1,
            class: "order-1 stroke-1.5",
            name: _ctx.rightIcon,
            position: "right"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["to", "disabled", "class"]))
}

import { Vue, Options } from 'vue-class-component';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseSelect } from '@/lib/components/Select';
import { BaseCheckbox } from '@/lib/components/Checkbox';
import { BaseDatePicker } from '@/lib/components/Date';

import { IValidationError } from '@/lib';
import { CopdConsultType, CopdProgramConsult } from '@/models';
import { CopdProgramService } from '@/services/api';
import { consultTypes } from '@/helpers/home-monitoring.helper';

import isToday from 'dayjs/plugin/isToday';
import dayjs from 'dayjs';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(isToday);

@Options({
  props: {
    programId: {
      type: String,
      required: true
    },
    nextConsult: {
      type: Object,
      default: null
    }
  },
  components: { BaseButton, BaseSelect, BaseIcon, BaseCheckbox, BaseDatePicker }
})
export default class SetNextConsultModal extends Vue {
  programId!: string;
  patientScheduledExternally = false;
  nextConsult!: CopdProgramConsult;
  consult: CopdProgramConsult = { scheduled_at: '', type: CopdConsultType.homeVisit };

  errors: IValidationError = {
    errors: {},
    message: ''
  };

  copdProgramService = new CopdProgramService();
  submitting = false;

  notificationStore = useNotificationStore();

  mounted() {
    if (
      this.nextConsult &&
      (dayjs().isBefore(dayjs(this.nextConsult.scheduled_at)) || dayjs(this.nextConsult.scheduled_at).isToday())
    ) {
      this.consult = {
        scheduled_at: this.nextConsult.scheduled_at,
        type: this.nextConsult.type
      };
    }
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get ensureScheduledAtIsOnOrAfterToday() {
    return (
      this.consult.scheduled_at &&
      (this.consult.scheduled_at >= this.today)
    );
  }

  get consultTypeList() {
    return consultTypes;
  }

  closeModal() {
    this.$emit('close');
  }

  async setNextConsult() {
    try {
      this.submitting = true;

      await this.copdProgramService.setNextConsult(this.programId as string, this.consult);

      this.$emit('close', true);

      this.notificationStore.snackbar = {
        label: this.$t('custom.uhb.copd.schedule-next-consult-completed')
      };
    } catch (e) {
      this.errors = e.response.data;
    } finally {
      this.submitting = false;
    }
  }
}

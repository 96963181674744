import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = { class: "text-sm font-semibold text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "w-3 h-3 mr-3 rounded-full",
      style: _normalizeStyle(_ctx.color ? 'background-color: ' + _ctx.color : '')
    }, null, 4),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ]))
}
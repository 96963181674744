
import { Vue, Options } from 'vue-class-component';
import HorizontalScroll from '@/lib/layouts/HorizontalScrollableLayout.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import NavDropdown from '@/lib/components/Navigation/NavDropdown.vue';
import PatientDetailsCard from '@/lib/components/PatientSummary/PatientDetailsCard.vue';
import { Patient } from '@/models';
import { getDobFromISOString, getNhsNumberFromPatient } from '@/helpers/patient.helper';
import { sexOptions, genderOptions } from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';

@Options({
  props: {
    steps: {
      type: Array,
      required: true
    },

    currentStep: {
      type: Number,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    patient: {
      type: Object,
      default: null
    }
  },
  components: {
    BaseIcon,
    HorizontalScroll,
    NavDropdown,
    PatientDetailsCard
  }
})
export default class WorkflowNavigation extends Vue {
  patient!: Patient | null;
  steps!: Array<string>;
  currentStep!: number;
  sessionStore = useSessionStore();
  patientStore = usePatientStore();

  sexOptions = sexOptions();
  genderOptions = genderOptions();

  get firstIndex(): number {
    return this.steps.findIndex((step) => step && step.length);
  }

  get patientNHSNumber(): string {
    const nhsNumber = getNhsNumberFromPatient(this.patient);
    return nhsNumber ? nhsNumber : this.$t('platform.common.none').toString();
  }

  get useGender() {
    return this.sessionStore.currentOrganisation?.uses_gender && this.patient?.gender;
  }

  get patientInfos() {
    return this.patient
      ? {
        [this.$t('platform.patient.dob') as string]: this.formatDob(this.patient.date_of_birth),
        [this.$t('platform.patient.age') as string]: this.patient.age,
        [(this.useGender ? this.$t('platform.patient.gender') : this.$t('platform.patient.sex')) as string]: this
          .useGender
          ? this.getGender(this.patient.gender)
          : this.getSex(this.patient.sex),
        [this.$t('platform.patient.ethnicity') as string]: this.patient.ethnicity
          ? this.patient.ethnicity.title
          : this.$t('platform.patient.not-specified'),
        [this.$t('platform.patient.phone') as string]: this.patient.contact_number,
        ...(this.showNhsNumber ? { nhs: this.patientNHSNumber } : {}),
        ...(this.patient.minor_patients && this.patient.minor_patients.length > 0
          ? {
            [this.$t('custom.uhb.patient.alternative-ids') as string]: this.patient.minor_patients.map(
              (minor_patient) => minor_patient.mrn
            )
          }
          : {})
      }
      : {};
  }

  get showNhsNumber() {
    return this.patientStore.showNhsNumber;
  }

  mounted() {
    this.$watch('currentStep', this.centerCurrentStep);
  }

  centerCurrentStep() {
    const scrollContainer = this.$refs.scrollContainer as Vue;
    if (scrollContainer) {
      const scrollContainerEl = scrollContainer.$el;
      if (scrollContainerEl) {
        const index = Math.max(0, Math.min(this.steps.length - 1, this.currentStep));
        const currentStepElement = scrollContainerEl.children[index] as HTMLElement;
        const currentStepElementOffsetCenter = currentStepElement.offsetLeft + currentStepElement.clientWidth / 2;
        const scrollContainerCenter = scrollContainerEl.clientWidth / 2;

        scrollContainerEl.scroll({
          left: currentStepElementOffsetCenter - scrollContainerCenter,
          behavior: 'smooth'
        });
      }
    }
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }

  getSex(sex?: string): string | TranslateResult | undefined {
    const sexOption = this.sexOptions.find((option) => option.value === sex);
    return sexOption ? sexOption.label : sex;
  }

  getGender(gender?: string): string | TranslateResult | undefined {
    const genderOption = this.genderOptions.find((option) => option.value === gender);
    return genderOption ? genderOption.label : gender;
  }
}

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row items-center justify-between mb-3"
}
const _hoisted_3 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_4 = ["disabled", "placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((_ctx.label && _ctx.label.length) || _ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            class: _normalizeClass(["inline-block text-base font-medium leading-tight", { 'text-transparent bg-gray-200 rounded-lg': _ctx.loading }])
          }, _toDisplayString(_ctx.label), 3),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.$slots.action)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([_ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-primary-500 cursor-pointer', "flex items-center text-sm font-medium text-right"])
                }, [
                  _renderSlot(_ctx.$slots, "action")
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.isOptional)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass([_ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-xs font-medium cursor-default capitalize"])
                }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["inline-flex border rounded object-contain max-w-12 justify-between", {
        [`${_ctx.inputClass}`]: _ctx.inputClass,
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          _ctx.error,
        'bg-gray-100 text-gray-400': _ctx.disabled,
        'border-primary-500': _ctx.focused && !_ctx.disabled
      }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["inline-flex w-full text-base font-medium text-center items-center bg-white border-gray-300 rounded text-gray-900 pl-3", { 'bg-gray-100 text-gray-400': _ctx.disabled }]),
        onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.$refs.input.focus()), ["stop"]))
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              name: _ctx.icon,
              stroke: false,
              class: "mr-3"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          ref: "input",
          class: _normalizeClass(["border-none outline-none rounded min-w-0 w-full", { 'bg-gray-100 text-gray-400': _ctx.disabled }]),
          type: "text",
          disabled: _ctx.disabled,
          placeholder: _ctx.placeholder,
          value: _ctx.formattedValue,
          onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focused = true)),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focused = false)),
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          onKeydown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateInput($event))),
          onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateChange($event.target.value)))
        }, null, 42, _hoisted_4), [
          [_vShow, !_ctx.loading]
        ])
      ], 2),
      (_ctx.units)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["inline-flex justify-center items-center bg-white text-base font-small text-center border-gray-300 border-l rounded rounded-l-none pr-4 pl-4", { 'bg-gray-100 text-gray-400': _ctx.disabled }])
          }, _toDisplayString(_ctx.units), 3))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_InputTextHelper, {
      error: _ctx.error,
      description: _ctx.description
    }, null, 8, ["error", "description"])
  ]))
}
import { GenericService } from './generic.service';
import { PatientConsultResponseData } from '@/models';

export class PatientConsultService extends GenericService<PatientConsultResponseData> {
  endpoint = '';

  constructor(patientId: string) {
    super();
    this.endpoint = `v1/patients/${patientId}/consults`;
  }
}

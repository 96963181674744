
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    color: {
      type: String
    },
    label: {
      type: String,
      default: ''
    }
  }
})
export default class BaseChartLegendItem extends Vue {}

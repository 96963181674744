import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  style: {"min-width":"calc(100vh - 150px)"},
  class: "flex flex-row justify-between items-center p-4 border-b border-gray-300"
}
const _hoisted_2 = { class: "flex flex-col space-y-1 text-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-base font-semibold text-gray-900"
}
const _hoisted_4 = {
  key: 1,
  class: "text-xs font-medium text-gray-500"
}
const _hoisted_5 = { class: "space-x-4" }
const _hoisted_6 = {
  key: 0,
  class: "flex inset-0 flex justify-center items-center h-40"
}
const _hoisted_7 = {
  key: 2,
  class: "flex flex-row border-t border-gray-300 items-center h-16 px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_ThreadMessage = _resolveComponent("ThreadMessage")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    "dialog-class": "container overflow-y-hidden px-0",
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          class: "cursor-pointer rounded-full bg-white border border-gray-300 hover:border-gray-500 flex items-center px-3 py-2 text-gray-500 text-xs font-medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_BaseIcon, {
            class: "mr-2",
            name: "chevron-left"
          }),
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.subTitle), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.readonly && !_ctx.loading)
            ? (_openBlock(), _createBlock(_component_BasePopover, {
                key: 0,
                width: "15rem",
                position: "left"
              }, {
                button: _withCtx(() => [
                  _createVNode(_component_BaseButton, { color: "muted" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.close-thread')), 1)
                    ]),
                    _: 1
                  })
                ]),
                menu: _withCtx(() => [
                  _createVNode(_component_BaseButton, {
                    class: "mb-2",
                    color: "danger",
                    block: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeThread')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.close-thread')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BaseButton, { color: "gray-200" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_LoadingIcon)
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            ref: "messages",
            class: "bg-white flex flex-col space-y-12 p-6 overflow-y-auto",
            style: _normalizeStyle(_ctx.readonly ? 'max-height: calc(100vh - 150px)' : 'max-height: calc(100vh - 200px)'),
            onScroll: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)), ["stop"]))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
              return (_openBlock(), _createBlock(_component_ThreadMessage, {
                key: message.id,
                content: message.message,
                date: message.created_time,
                sender: message.sender,
                sent: message.sender.type === 'user' || message.sender.type === 'staff',
                patient: _ctx.patient
              }, null, 8, ["content", "date", "sender", "sent", "patient"]))
            }), 128))
          ], 36)),
      (!_ctx.readonly && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.answer,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.answer) = $event)),
              class: "flex-1",
              "input-class": "border-none shadow-none",
              placeholder: _ctx.$t('custom.menicon.virtual-doctor.reply-to', [_ctx.patient.first_name]),
              autofocus: "",
              onKeydown: _withKeys(_ctx.send, ["enter"])
            }, null, 8, ["modelValue", "placeholder", "onKeydown"]),
            _createVNode(_component_BaseButton, { onClick: _ctx.send }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.send')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
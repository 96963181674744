
import { Vue, Options } from 'vue-class-component';
import { BaseButton } from '@/lib/components/Button';
import { BaseSelect } from '@/lib/components/Select';
import HospitalTeamSelect from '@/lib/components/CopdProgram/HospitalTeamSelect.vue';
import CommunityTeamSelect from '@/lib/components/CopdProgram/CommunityTeamSelect.vue';
import { BaseIcon } from '@/lib/components/Icon';
import { CopdProgramService, CopdAssignableOrganisationService } from '@/services/api';
import { IOption, IValidationError } from '@/lib';
import { useNotificationStore } from '@/stores/notification.store';
import {
  Organisation,
  CopdProgramRequest,
  Tag
} from '@/models';
import { AxiosError } from 'axios';
import { useSessionStore } from '@/stores/session.store';

@Options({
  props: {
    programId: {
      type: String,
      required: true
    },
    assignedHospitalTeam: {
      type: Object,
      default: null
    },
    assignedOrg: {
      type: Object,
      default: null
    },
    assignedCommunityTeam: {
      type: Object,
      default: null
    }
  },
  components: { BaseButton, BaseSelect, BaseIcon, HospitalTeamSelect, CommunityTeamSelect }
})
export default class AssignToOrgModal extends Vue {
  programId!: string;
  assignedHospitalTeam!: Tag | null;
  assignedOrg!: Organisation | null;
  assignedCommunityTeam!: Tag | null;

  errors: IValidationError = {
    errors: {},
    message: ''
  };

  selectedHospitalTeam: Tag | null = null;
  selectedCommunityOrg: Organisation | null = null;
  selectedCommunityTeam: Tag | null = null;
  assignableOrganisations: Organisation[] = [];
  assignableOrganisationService = new CopdAssignableOrganisationService();
  copdProgramService = new CopdProgramService();
  sessionStore = useSessionStore();

  notificationStore = useNotificationStore();

  mounted() {
    this.selectedHospitalTeam = this.assignedHospitalTeam;
    this.selectedCommunityOrg = this.assignedOrg;
    this.selectedCommunityTeam = this.assignedCommunityTeam;
    this.fetchAssignableCommunityOrgs();
    this.$emit('record-event', 'started assigning patient to hospital team / community team / organisation');
  }

  get currentOrganisation(): Partial<Organisation> {
    return this.sessionStore.currentOrganisation || {};
  }

  get currentOrganisationIsCommunityOrganisation(): boolean {
    return this.assignableOrganisations.map((organisation: Organisation) => organisation.id)
      .includes(this.currentOrganisation.id);
  }

  get organisationOptions(): Array<IOption> {
    return this.assignableOrganisations.map((organisation) => ({
      value: organisation.id,
      label: organisation.name
    }));
  }

  updateCommunityOrganisation(organisationId: string): void {
    this.resetValidationErrorMessages();
    this.selectedCommunityTeam = null;
    this.selectedCommunityOrg = this.assignableOrganisations
      .find((organisation) => organisation.id === organisationId) || null;
  }

  updateHospitalTeam(tag: Tag): void {
    this.selectedHospitalTeam = tag;
    this.resetValidationErrorMessages();
  }

  updateCommunityTeam(tag: Tag): void {
    this.resetValidationErrorMessages();
    this.selectedCommunityTeam = tag;
  }

  resetValidationErrorMessages(): void {
    this.errors = {
      errors: {},
      message: ''
    };
  }

  async fetchAssignableCommunityOrgs() {
    try {
      const response = await this.assignableOrganisationService.index();
      this.assignableOrganisations = response.data;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.copd.error-fetching-assignable-organisations')
      });
    }
  }

  async assignTeams() {
    const copdProgramRequest: Partial<CopdProgramRequest> = {
      hospital_team: ''
    };

    if (this.selectedHospitalTeam) {
      copdProgramRequest.hospital_team = this.selectedHospitalTeam.id;
    }

    if (this.selectedCommunityOrg) {
      copdProgramRequest.assigned_organisational_unit_id = this.selectedCommunityOrg.id;
    }

    if (this.selectedCommunityTeam) {
      copdProgramRequest.community_team = this.selectedCommunityTeam.id;
    }

    try {
      await this.copdProgramService.update(
        this.programId,
        copdProgramRequest
      );

      this.$emit('update-assigned-org', this.selectedCommunityOrg);
      this.$emit('update:assigned-hospital-team', this.selectedHospitalTeam);
      this.$emit('update:assigned-community-team', this.selectedCommunityTeam);

      this.$emit('record-event', 'completed assigning patient to hospital team / community team / organisation');
      this.$emit('close');
    } catch (e: unknown) {
      this.errors = (e as AxiosError).response?.data;
    }
  }
}

import TokenService from '@/services/token.service';
import * as Sentry from '@sentry/browser';
import router from '@/router';
import { useSessionStore } from '@/stores/session.store';

const logoutService = async () => {
  const sessionStore = useSessionStore();
  try {
    await sessionStore.revokeUserToken({
      token: sessionStore.refreshToken
    });
  } catch (e) {
    console.warn('Unable to revoke user token');
  } finally {
    TokenService.removeToken();
    sessionStore.setCurrentUser(null);
    Sentry.configureScope((scope) => scope.setUser({}));
    if (router.currentRoute.value.name !== 'login') {
      router.push({ name: 'login' });
    }
  }
};

export default logoutService;


import { Vue, Options } from 'vue-class-component';
import BaseModal from './BaseModal.vue';

@Options({
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  components: { BaseModal }
})
export default class EmergencyModal extends Vue {}

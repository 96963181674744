
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';
import BaseModal from './BaseModal.vue';

@Options({
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  components: { BaseIcon, BaseModal }
})
export default class LockConsultModal extends Vue {}

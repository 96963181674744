
import { Vue, Options } from 'vue-class-component';
import { IAction, ILabel } from '@/lib';
import { BasePopover, PopoverButton } from '@/lib/components/Popover';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseTooltip } from '@/lib/components/Tooltip';
import { SpecialityButton, BaseButton } from '@/lib/components/Button';

@Options({
  components: {
    BaseButton,
    BaseTooltip,
    PopoverButton,
    BasePopover,
    SpecialityButton,
    BaseIcon
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    label: {
      type: Object,
      default: null
    },
    enableAdd: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    }
  }
})
export default class GroupTreeSegment extends Vue {
  label!: ILabel;
  actions!: Array<IAction>;
  hover = false;
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-8" }
const _hoisted_2 = { class: "flex flex row justify-between items-center" }
const _hoisted_3 = { class: "text-xl font-bold" }
const _hoisted_4 = { class: "flex flex-row flex-wrap mt-8 -mb-2 -mr-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-center font-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudyGalleryItem = _resolveComponent("StudyGalleryItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studies, (study) => {
      return (_openBlock(), _createElementBlock("div", {
        key: study.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatDate(study.studyTime)), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(study.series, (imageSeries) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: imageSeries.id
            }, [
              (imageSeries.images.length)
                ? (_openBlock(), _createBlock(_component_StudyGalleryItem, {
                    key: 0,
                    "image-series": imageSeries,
                    loading: _ctx.seriesLoad.includes(imageSeries.id) && !_ctx.readonly,
                    signs: _ctx.getImageSeriesSigns(imageSeries.id),
                    "scan-hover-message": _ctx.scanHoverMessage,
                    "hover-class": _ctx.dark ? 'border border-white' : 'shadow-lg',
                    class: _normalizeClass(["w-75 h-53 mr-2 mb-2", _ctx.dark ? 'border border-gray-800' : '']),
                    onClick: ($event: any) => (_ctx.openViewer(study.id, imageSeries.id))
                  }, null, 8, ["image-series", "loading", "signs", "scan-hover-message", "hover-class", "class", "onClick"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]))
    }), 128)),
    (_ctx.studies.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.scans.no-studies')), 1))
      : _createCommentVNode("", true)
  ]))
}
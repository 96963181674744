import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "text-xs font-semibold text-primary-500" }
const _hoisted_4 = { class: "text-base leading-tight whitespace-wrap" }
const _hoisted_5 = { class: "mr-2 font-medium text-gray-900" }
const _hoisted_6 = { class: "font-regular text-gray-400" }
const _hoisted_7 = { class: "flex-none space-x-2" }
const _hoisted_8 = {
  type: "button",
  class: "focus:outline-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "flex flex-row items-center justify-between w-full p-4 bg-white" }, _ctx.$attrs), [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.location), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(!_ctx.presence ? _ctx.$t('custom.uhb.diagnosis.no-evidence-of', [_ctx.condition]) : _ctx.condition), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.stage), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.differential)
        ? (_openBlock(), _createBlock(_component_MkBadge, {
            key: 0,
            color: "gray",
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.diagnosis.differential')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.presence)
        ? (_openBlock(), _createBlock(_component_MkBadge, {
            key: 1,
            color: "gray",
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.diagnosis.absence')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.status && _ctx.status.name)
        ? (_openBlock(), _createBlock(_component_MkBadge, {
            key: 2,
            color: _ctx.getStatusColor(_ctx.status.name),
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.status.name), 1)
            ]),
            _: 1
          }, 8, ["color"]))
        : _createCommentVNode("", true),
      (_ctx.showActions)
        ? (_openBlock(), _createBlock(_component_BasePopover, {
            key: 3,
            position: "left"
          }, {
            button: _withCtx(() => [
              _createElementVNode("button", _hoisted_8, [
                _createVNode(_component_BaseIcon, {
                  name: "more",
                  class: "text-gray-400",
                  height: "12px"
                })
              ])
            ]),
            menu: _withCtx(() => [
              _createVNode(_component_PopoverButton, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.edit')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_PopoverButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.delete')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 16))
}

import VuePdfEmbed from 'vue-pdf-embed';
import { Vue, Options } from 'vue-class-component';
import { PageLoading } from '@/lib/components/Loading';

@Options({
  props: {
    pdf: {
      type: String,
      required: true
    }
  },
  components: {
    PageLoading,
    VuePdfEmbed
  }
})
export default class PdfLetter extends Vue {
  pdf!: string;
}

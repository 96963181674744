import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row justify-between items-center"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id", "value", "placeholder", "maxlength", "rows", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label && _ctx.label.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: _normalizeClass(["inline-block text-base font-medium mb-3 whitespace-pre-line", { 'text-transparent bg-gray-200 rounded-lg h-5': _ctx.loading }])
          }, _toDisplayString(_ctx.label), 11, _hoisted_3),
          (_ctx.isOptional)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["text-xs font-medium", _ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500'])
              }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", _mergeProps({
      id: _ctx.id,
      value: _ctx.loading ? '' : _ctx.modelValue,
      placeholder: _ctx.loading ? '' : _ctx.placeholder,
      maxlength: _ctx.maxLength,
      rows: _ctx.rows,
      disabled: _ctx.disabled || _ctx.loading,
      class: ["w-full p-4 rounded border shadow", {
        'hover:border-gray-500 text-gray-600 bg-gray-50 border-transparent': _ctx.disabled,
        'text-gray-400': _ctx.modelValue === '',
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none': _ctx.error
      }],
      style: {"resize":"none"}
    }, _ctx.$attrs, _toHandlers(_ctx.inputListeners)), null, 16, _hoisted_4),
    _createVNode(_component_InputTextHelper, {
      error: _ctx.error,
      description: _ctx.instructions
    }, null, 8, ["error", "description"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row bg-gray-900 rounded items-center p-2 text-sm font-medium select-none" }
const _hoisted_2 = { class: "text-center text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "focus:outline-none px-4",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.zoomOut()))
    }, [
      _createVNode(_component_BaseIcon, {
        name: "minus",
        class: "text-white"
      })
    ]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.roundedValue) + "%", 1),
    _createElementVNode("button", {
      type: "button",
      class: "focus:outline-none px-4",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.zoomIn()))
    }, [
      _createVNode(_component_BaseIcon, {
        name: "add",
        class: "text-white"
      })
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "font-medium text-sm text-gray-900" }
const _hoisted_3 = { class: "text-sm font-regular text-gray-500" }
const _hoisted_4 = { class: "flex flex-col items-start" }
const _hoisted_5 = { class: "font-normal text-sm text-gray-900" }
const _hoisted_6 = { class: "font-normal text-sm text-gray-900" }
const _hoisted_7 = {
  key: 0,
  class: "inline-flex justify-between"
}
const _hoisted_8 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowPatientSummary = _resolveComponent("RowPatientSummary")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      loading: _ctx.loading,
      columns: _ctx.columns,
      rows: _ctx.rows,
      sort: _ctx.sort,
      class: "w-full mt-6",
      onSort: _ctx.updateSort
    }, _createSlots({
      "cell.last_sent": _withCtx(({ row }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.lastSentDate(row)), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.lastSentTime(row)), 1)
        ])
      ]),
      "cell.patient": _withCtx(({ row }) => [
        _createVNode(_component_RowPatientSummary, {
          patient: _ctx.patient(row),
          "home-monitoring": _ctx.getTagName(row, 'home-monitoring')
        }, null, 8, ["patient", "home-monitoring"])
      ]),
      "cell.status": _withCtx(({ row }) => [
        _createElementVNode("div", {
          class: _normalizeClass(["flex items-center", _ctx.statusColor(row) && 'flex-row'])
        }, [
          _createVNode(_component_MkBadge, {
            color: _ctx.statusColor(row),
            size: "xsmall"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`custom.uhb.copd.${_ctx.status(row)}`)) + " ", 1),
              (_ctx.status(row) === 'discharged')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.dischargedAt(row)) + " ", 1),
                    _createVNode(_component_BaseTooltip, null, {
                      target: _withCtx(() => [
                        _createVNode(_component_BaseIcon, {
                          class: "text-gray-400 hover:text-gray-900 focus:text-gray-900 stroke-2 ml-2",
                          height: "14px",
                          name: "info"
                        })
                      ]),
                      content: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(
                        `custom.uhb.copd.discharge-reason.${_ctx.dischargeKey(row)}`
                      )), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : (row.attributes.high_risk)
                  ? (_openBlock(), _createBlock(_component_MkBadge, {
                      key: 1,
                      color: "danger",
                      size: "2xsmall",
                      filled: true
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.high-risk')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["color"])
        ], 2)
      ]),
      "cell.actions": _withCtx(({ row }) => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_ButtonLink, {
            to: _ctx.programLink(row),
            "data-dusk": `view-copd-program-${row.id}-btn`,
            color: "ghost",
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.view')), 1)
            ]),
            _: 2
          }, 1032, ["to", "data-dusk"])
        ])
      ]),
      _: 2
    }, [
      (_ctx.tagFeatureFlagEnabled)
        ? {
            name: "cell.team",
            fn: _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getTagName(row, 'hospital-team')), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getTagName(row, 'community-team')), 1)
              ])
            ])
          }
        : {
            name: "cell.organisation",
            fn: _withCtx(({ row }) => [
              _createElementVNode("div", { class: "flex flex-col items-start" }, [
                _createElementVNode("div", { class: "font-medium text-sm text-gray-900" }, _toDisplayString(_ctx.organisationalUnitName(row)), 1)
              ])
            ])
          }
    ]), 1032, ["loading", "columns", "rows", "sort", "onSort"]),
    (_ctx.total && _ctx.total > _ctx.perPage)
      ? (_openBlock(), _createBlock(_component_BasePagination, {
          key: 0,
          class: "mt-8",
          "model-value": _ctx.page,
          "page-size": _ctx.perPage,
          length: _ctx.total,
          "items-name": _ctx.$t('custom.uhb.copd.programs').toLowerCase(),
          "onUpdate:modelValue": _ctx.changePage
        }, null, 8, ["model-value", "page-size", "length", "items-name", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
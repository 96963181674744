import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-10 max-w-xs text-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_3 = { class: "text-lg font-semibold leading-tight text-gray-900 mt-6 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconGlyph = _resolveComponent("IconGlyph")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, { "close-on-background-click": false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isGlyph)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_IconGlyph, {
                name: _ctx.icon,
                color: _ctx.iconColor,
                size: "large"
              }, null, 8, ["name", "color"])
            ]))
          : (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 1,
              name: _ctx.icon,
              class: _normalizeClass('text-' + _ctx.iconColor),
              height: "64px",
              stroke: _ctx.stroke
            }, null, 8, ["name", "class", "stroke"])),
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.heading), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.body.split('\n'), (line) => {
          return (_openBlock(), _createElementBlock("p", {
            key: line,
            class: "text-gray-600"
          }, _toDisplayString(line), 1))
        }), 128)),
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }))
}
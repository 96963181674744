import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-12 bg-gray-700 overflow-y-scroll scrollbar-dark rounded shadow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuePdfEmbed = _resolveComponent("VuePdfEmbed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VuePdfEmbed, {
      source: _ctx.pdf,
      class: "w-full space-y-12 shadow rounded mb-12"
    }, null, 8, ["source"])
  ]))
}
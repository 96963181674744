import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelectInput = _resolveComponent("BaseSelectInput")!

  return (_openBlock(), _createBlock(_component_BaseSelectInput, _mergeProps(_ctx.$attrs, {
    options: _ctx.options,
    "model-value": _ctx.modelValue,
    clear: _ctx.clear,
    loading: _ctx.loading,
    "icon-stroke": false
  }), null, 16, ["options", "model-value", "clear", "loading"]))
}

import { Vue, Options } from 'vue-class-component';
import { ColorName, IModalAction } from '@/lib';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import BaseModal from './BaseModal.vue';
import { colors } from '@/lib/constants';
import IconGlyph from '@/lib/components/Icon/IconGlyph.vue';

@Options({
  components: {
    IconGlyph,
    BaseModal,
    BaseIcon,
    BaseButton
  },
  props: {
    iconColor: {
      type: String,
      default: 'primary-500'
    },
    icon: {
      type: String
    },
    iconBgColor: {
      type: String,
      default: null,
      validator: (value: ColorName): boolean => value === null || colors.includes(value)
    },
    stroke: {
      type: Boolean,
      default: true
    },
    isGlyph: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    },
    danger: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    }
  }
})
export default class ActionModal extends Vue {
  iconColor!: ColorName;
  actions!: Array<IModalAction>;
  iconBgColor!: ColorName | null;

  get iconBgClasses() {
    return this.iconBgColor ? `bg-${this.iconBgColor} rounded-xl p-3` : '';
  }

  close() {
    this.$emit('close');
  }

  onActionClick(action: IModalAction, event: MouseEvent) {
    action.onClick(event);
    event.stopPropagation();
    this.close();
  }
}

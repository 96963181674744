import { GenericService } from './generic.service';
import apiClient from './client/apiClient';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class CopdPatientService extends GenericService<any> {
  endpoint = 'copd/patients';

  async invitePatient(id: string, params: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return await apiClient.post(`${this.endpoint}/${id}/invites`, null, params);
  }
}

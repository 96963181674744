
import { Vue, Options } from 'vue-class-component';
import BaseCheckbox from './BaseCheckbox.vue';
import { InputTextHelper } from '@/lib/components/Helper';

interface ICheckboxGroupOption {
  label: string;
  value: any;
}

@Options({
  props: {
    label: {
      type: String,
      default: null
    },

    options: {
      type: Array,
      required: true
    },

    // eslint-disable-next-line
    modelValue: {
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    },

    fullWidth: {
      type: Boolean,
      default: false
    },

    checkboxClass: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    error: {
      type: String,
      default: null
    },

    displayErrorMessage: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseCheckbox,
    InputTextHelper
  }
})
export default class CheckboxGroup extends Vue {
  options!: string[] | ICheckboxGroupOption[];

  onUpdate(value: boolean | Array<any>) {
    this.$emit('input', value);
    this.$emit('update:modelValue', value);
    this.$emit('change', value);
  }
}

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("th", {
    class: "px-5 font-medium text-left",
    style: _normalizeStyle(_ctx.customStyle)
  }, [
    (_ctx.showSortButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "font-medium group focus:outline-none",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sortClicked && _ctx.sortClicked(...args)))
        }, [
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_component_BaseIcon, {
            name: _ctx.currentDirection === 'asc' ? 'ascending' : 'descending',
            class: _normalizeClass(["ml-3 text-xs group-hover:text-primary-500 group-focus:text-primary-500 stroke-1.5", _ctx.isActive ? 'text-gray-900' : 'text-gray-300'])
          }, null, 8, ["name", "class"])
        ]))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ], 4))
}
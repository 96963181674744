import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute top-0 left-0 bg-gray-900 text-white shadow-lg m-4 h-6 text-xs font-semibold py-1 px-2 rounded-lg select-none"
}
const _hoisted_2 = {
  key: 1,
  class: "absolute bottom-0 left-0 bg-gray-900 text-white shadow-lg m-4 h-6 text-xs font-semibold py-1 px-2 rounded-lg select-none"
}
const _hoisted_3 = {
  key: 2,
  class: "absolute bottom-0 right-0 bg-gray-900 text-white shadow-lg m-4 h-6 text-xs font-semibold py-1 px-2 rounded-lg select-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.loading ? 'ImageLoading' : 'div'), {
    class: "relative shadow bg-no-repeat bg-center bg-contain w-full h-full",
    style: _normalizeStyle(_ctx.imageUrl && _ctx.imageUrl.length ? 'background-image: url(' + _ctx.imageUrl + ')' : _ctx.imageUrl)
  }, {
    default: _withCtx(() => [
      (_ctx.$slots['top-left'])
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "top-left")
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots['bottom-left'])
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "bottom-left")
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots['bottom-right'])
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "bottom-right")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["style"]))
}
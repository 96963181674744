
import { Vue, Options } from 'vue-class-component';
import { IFilterOptions, IFilterOptionsSelected } from '@/lib';
import BaseCheckbox from '@/lib/components/Checkbox/BaseCheckbox.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { initPopper, updatePopper, Instance as PopperInstance } from '@/lib/helpers/popover.helper';

@Options({
  components: { BaseCheckbox, BaseButton, BaseIcon },
  props: {
    options: {
      type: Object,
      default: null
    },
    position: {
      type: String,
      default: 'right'
    },
    width: {
      type: String,
      default: '295px'
    }
  }
})
export default class FilterPopover extends Vue {
  options!: IFilterOptions;
  style = '';
  position!: 'right' | 'left';
  isOpen = false;
  maxHeight: null | number = 280;
  width!: string;
  selectedItems: IFilterOptionsSelected = { checkboxItems: [] };
  selectedCheckboxItems: IFilterOptionsSelected['checkboxItems'] = [];
  popper!: PopperInstance;

  get checkboxModel() {
    return this.selectedCheckboxItems || this.selectedItems.checkboxItems;
  }

  set checkboxModel(value) {
    this.selectedCheckboxItems = value;
  }

  get menuStyle() {
    return `width: ${this.width};`;
  }

  get scrollableStyle() {
    return `${this.maxHeight ? `max-height:${this.maxHeight}px` : ''}`;
  }

  get selectedCount(): number {
    return this.selectedItems?.checkboxItems?.length || 0;
  }

  async mounted() {
    await this.reInitPopper();
    this.$watch('options', this.reInitPopper); // Re-initialise the popper instance when options changes
  }

  async reInitPopper() {
    if (this.$refs.main && this.$refs.menu) {
      this.popper = initPopper(this.$refs.main as HTMLElement, this.$refs.menu as HTMLElement, this.position);
      await this.updateWatchPopper();
    }
  }

  async updateWatchPopper() {
    await updatePopper(this.popper);
    this.$watch('position', async (newVal) => {
      await updatePopper(this.popper, newVal);
    });
    this.$watch('isOpen', async () => {
      await updatePopper(this.popper);
    });
  }

  confirm() {
    this.selectedItems.checkboxItems = this.selectedCheckboxItems;
    this.$emit('confirm', this.selectedItems);
    this.close();
  }

  resetSelections() {
    this.checkboxModel = [];
  }

  cancel() {
    this.checkboxModel = this.selectedItems.checkboxItems;
    this.close();
  }

  close() {
    this.isOpen = false;
  }

  open() {
    this.isOpen = true;
  }
}


import { Vue, Options } from 'vue-class-component';
import { Role } from '@/models';
import { i18n } from '@/i18n/i18n';

@Options({
  props: {
    title: {
      type: String,
      default() {
        return i18n.global.t('platform.role.select') as string;
      }
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    roles: {
      type: Array,
      default: () => []
    },
    maxHeight: {
      type: String,
      default: '172px'
    }
  }
})
export default class RolesSelector extends Vue {
  modelValue!: Array<string | number>;
  roles!: Array<Role>;

  onRoleClick(id: string | number) {
    if (this.modelValue.includes(id)) {
      this.$emit(
        'update:modelValue',
        this.modelValue.filter((role) => role !== id)
      );
    } else {
      this.$emit('update:modelValue', [...this.modelValue, id]);
    }
  }
}

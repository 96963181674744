import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex items-center relative justify-between h-16 p-3 bg-white border-t border-gray-300" }
const _hoisted_2 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_3 = { class: "text-base font-semibold text-gray-900" }
const _hoisted_4 = { class: "absolute inset-0" }
const _hoisted_5 = { class: "flex items-center justify-center h-full" }
const _hoisted_6 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_7 = {
  key: 0,
  class: "inline-block mr-4 font-semibold text-xs"
}
const _hoisted_8 = {
  key: 0,
  class: "text-gray-500"
}
const _hoisted_9 = {
  key: 1,
  class: "text-gray-500"
}
const _hoisted_10 = {
  key: 2,
  class: "text-success-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_portal_target = _resolveComponent("portal-target")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{ invisible: _ctx.loading }, "flex items-center space-x-4"])
    }, [
      _createVNode(_component_BrandLogo, { "use-default": false }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('platform.form.workflow')), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_portal_target, { name: "workflow-footer-middle" })
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({ invisible: _ctx.loading })
    }, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.status && _ctx.status !== _ctx.SavingStatus.UNSET)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.status === _ctx.SavingStatus.SAVING)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('platform.form.saving-changes')), 1))
                : (_ctx.status === _ctx.SavingStatus.UNSAVED || _ctx.status === _ctx.SavingStatus.ERROR)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.form.unsaved-changes')) + " ", 1),
                      (_ctx.status === _ctx.SavingStatus.ERROR)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "text-primary-500 underline",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('save')))
                          }, _toDisplayString(_ctx.$t('platform.error.try-again')), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('platform.form.all-changes-saved')), 1))
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "actions")
      ])
    ], 2)
  ]))
}

import { Vue, Options } from 'vue-class-component';
import { BaseCard } from '@/lib/components/Card';
import {
  changeHomeMonitoringPlanReasons,
  reviewOutcomes,
  measurementReadingsList,
  dischargeReasons
} from '@/helpers/home-monitoring.helper';
import CopdMeasurementRow from './CopdMeasurementRow.vue';
import CopdUhbModifiedAnthonisenCriteriaMeasurementReading from './CopdUhbModifiedAnthonisenCriteriaMeasurementReading.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { CopdOutcome, CopdProgramMeasurement, CopdProgramReview, CopdProgramSettingResource } from '@/models';
import { TranslateResult } from 'vue-i18n/index';
import { ITranslatedOption } from '@/lib';

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(utc);

enum ReviewedStates {
  Reviewed = 'reviewed',
  UnreviewedHigh = 'unreviewed-high',
  Unreviewed = 'unreviewed'
}

@Options({
  props: {
    measurement: {
      type: Object,
      required: true
    },
    review: {
      type: Object,
      default: null
    },
    settings: {
      type: Object,
      required: true
    }
  },
  components: { BaseCard, CopdMeasurementRow, CopdUhbModifiedAnthonisenCriteriaMeasurementReading }
})
export default class CopdMeasurement extends Vue {
  measurement!: CopdProgramMeasurement;
  review?: CopdProgramReview | null;
  settings!: CopdProgramSettingResource;

  get isReviewed(): boolean {
    return !!this.measurement.relationships.review;
  }

  get isNotReviewed(): boolean {
    return !this.isReviewed;
  }

  get isChange(): boolean {
    return this.review?.attributes.outcome === CopdOutcome.change;
  }

  get isDischarge(): boolean {
    return this.review?.attributes.outcome === CopdOutcome.discharge;
  }

  get flowRate(): TranslateResult | string {
    if (!this.measurement.attributes.pulse_oximetry.flow_rate) {
      return this.measurement.attributes.pulse_oximetry.method_of_oxygen_delivery.label;
    }

    return this.$t('custom.uhb.copd.inspired-oxygen-with-flow-rate-value', [
      this.measurement.attributes.pulse_oximetry.method_of_oxygen_delivery.label,
      this.measurement.attributes.pulse_oximetry.flow_rate
    ]);
  }

  get createdAt() {
    const date = this.formatDate(this.measurement.attributes.created_at);

    const time = dayjs.utc(this.measurement.attributes.created_at).local().format('HH:mm');

    return this.$t('custom.uhb.copd.created-at', [date, time]);
  }

  get reviewedAt(): TranslateResult | undefined {
    if (this.review === null) {
      return this.$t('custom.uhb.copd.reviewed-at-unknown');
    }

    return this.review && this.formatDate(this.review?.attributes.created_at);
  }

  get labelState(): ReviewedStates {
    if (this.isReviewed) {
      return ReviewedStates.Reviewed;
    }

    if (this.measurement.attributes.high_risk) {
      return ReviewedStates.UnreviewedHigh;
    }

    return ReviewedStates.Unreviewed;
  }

  get labelColor(): string {
    return {
      [ReviewedStates.Reviewed]: 'gray',
      [ReviewedStates.Unreviewed]: 'primary',
      [ReviewedStates.UnreviewedHigh]: 'danger'
    }[this.labelState];
  }

  get uhbModifiedAnthonisenCriteriaMeasurementReadings(): Array<ITranslatedOption> {
    return measurementReadingsList(this.measurement.attributes.uhb_modified_anthonisen_criteria);
  }

  formatDate(value: string): TranslateResult | string {
    const date = dayjs.utc(value).local();

    if (date.isToday()) {
      return this.$t('platform.common.today');
    }

    if (date.isYesterday()) {
      return this.$t('platform.common.yesterday');
    }

    return date.format('D MMM YYYY');
  }

  reasonLabel(key: string): string {
    if (this.isChange) {
      return changeHomeMonitoringPlanReasons.find((x) => x.value === key)?.label.toString() || '';
    }

    if (this.isDischarge) {
      return dischargeReasons.find((x) => x.value === key)?.label.toString() || '';
    }

    return '';
  }

  outcomeLabel(key: string): string {
    const outcome = reviewOutcomes.find((x) => x.value === key);
    return outcome ? outcome.label.toString() : '';
  }
}

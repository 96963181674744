import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createBlock(_component_InlineSvg, {
    src: require(`../../../assets/icons/${_ctx.name}.svg`),
    style: _normalizeStyle([{"vertical-align":"-0.125em"}, { height: _ctx.height }]),
    class: _normalizeClass(["inline-block w-auto", [
      _ctx.position === 'right'
        ? 'ml-3'
        : _ctx.position === 'left'
          ? 'mr-3'
          : _ctx.position === 'top'
            ? 'mb-3'
            : _ctx.position === 'bottom'
              ? 'mt-3'
              : '',
      _ctx.stroke ? 'stroke-current' : ''
    ]])
  }, null, 8, ["src", "style", "class"]))
}
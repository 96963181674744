
import { Vue, Options } from 'vue-class-component';
import debounce from 'lodash-es/debounce';
import { Address, AutocompletePrediction } from '@/models';
import { IOption } from '@/lib';
import { GoogleApiService } from '@/services/api';
import { LoadingIcon } from '@/lib/components/Loading';
import { BaseTextInput } from '@/lib/components/Input';

@Options({
  components: { LoadingIcon, BaseTextInput },
  props: {
    label: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object,
      default: () => ({
        formatted_address: ''
      })
    },
    isOptional: {
      type: Boolean,
      default: false
    }
  }
})
export default class AddressAutocomplete extends Vue {
  modelValue!: Address;
  apiService = new GoogleApiService();
  autocompleteResults: Array<IOption> = [];
  addressText = '';
  loading = false;
  lastCommittedAddressText = '';

  mounted() {
    this.addressText = this.modelValue?.formatted_address || '';
    this.lastCommittedAddressText = this.modelValue?.formatted_address || '';
  }

  clearAutocompleteResults() {
    this.autocompleteResults = [];
  }

  handleChangedTextInput(value: string) {
    if (value === '') {
      this.clearAddress();
    } else {
      this.getAutocompleteDebounced();
    }
  }

  clearAddress() {
    this.lastCommittedAddressText = '';
    const clearAddress: Address = {
      formatted_address: ''
    };
    this.$emit('change', clearAddress);
    this.$emit('update:modelValue', clearAddress);
  }

  getAutocompleteDebounced = debounce(() => this.getAutocomplete(), 500);

  async getAutocomplete() {
    const input = this.addressText ? this.addressText : '';
    this.loading = true;

    try {
      if (input.length > 4) {
        const autocompletePredictions = await this.apiService.placeAutocomplete(input);

        if (autocompletePredictions.predictions) {
          this.autocompleteResults = autocompletePredictions.predictions.map((prediction: AutocompletePrediction) => ({
            label: prediction.description,
            value: prediction.place_id
          }));
        }
      }
    } catch (e) {
      this.autocompleteResults = [];
    }

    this.loading = false;
  }

  async getPlaceDetails(place_id: string) {
    this.loading = true;

    try {
      const detailsResults = await this.apiService.placeDetails(place_id);
      const newAddress: Address = {
        formatted_address: detailsResults.result.formatted_address || '',
        place_id: detailsResults.result.place_id,
        geometry: detailsResults.result.geometry,
        address_components: detailsResults.result.address_components
      };

      this.addressText = newAddress.formatted_address;
      this.lastCommittedAddressText = newAddress.formatted_address;

      this.$emit('change', newAddress);
      this.$emit('update:modelValue', newAddress);
    } catch (e) {
      console.warn('Error fetching address details');
    }

    this.clearAutocompleteResults();
    this.loading = false;
  }

  clickAway() {
    this.addressText = this.lastCommittedAddressText;
    this.clearAutocompleteResults();
    this.$emit('blur');
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Condition = _resolveComponent("Condition")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_ctx.modelValue.length)
    ? (_openBlock(), _createBlock(_component_draggable, {
        key: 0,
        "model-value": _ctx.modelValue,
        class: "flex flex-col border border-gray-400",
        handle: ".drag-btn",
        "item-key": "id",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, {
        item: _withCtx(({ element }) => [
          _createVNode(_component_Condition, {
            condition: element.condition ? element.condition.name : '',
            stage: element.stage ? element.stage.name : '',
            location: element.anatomy ? element.anatomy.name : '',
            status: element.status,
            presence: element.is_present,
            differential: element.is_differential,
            class: "border-b border-gray-300",
            onEdit: ($event: any) => (_ctx.$emit('edit', element)),
            onDelete: ($event: any) => (_ctx.$emit('delete', element.id))
          }, null, 8, ["condition", "stage", "location", "status", "presence", "differential", "onEdit", "onDelete"])
        ]),
        _: 1
      }, 8, ["model-value"]))
    : _createCommentVNode("", true)
}
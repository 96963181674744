
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    padding: {
      type: String,
      default: '8'
    }
  }
})
export default class BaseCard extends Vue {}

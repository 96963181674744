import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full max-w-xl p-8 flex flex-col space-y-2" }
const _hoisted_2 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_3 = { class: "text-base leading-normal text-gray-600 pb-8" }
const _hoisted_4 = { class: "w-full justify-start flex flex-row items-center space-x-4" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-between p-4 mt-3 text-md font-semibold leading-none text-white bg-danger-400 border-danger-600 rounded-lg"
}
const _hoisted_6 = { class: "flex w-3/4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('custom.uhb.copd.patient-invitation-title')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.copd.patient-invitation-desc')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_BaseTextInput, {
        label: _ctx.$t('custom.uhb.copd.patient-invitation-email-label'),
        class: "flex-1",
        placeholder: _ctx.$t('platform.profile.email'),
        "model-value": _ctx.patient.email,
        disabled: true
      }, null, 8, ["label", "placeholder", "model-value"]),
      _createVNode(_component_BaseButton, {
        "data-dusk": "send-invite",
        class: "mt-8",
        size: "large",
        color: "primary",
        onClick: _withModifiers(_ctx.invitePatient, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.user.send-invite')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_ctx.inviteErrors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.inviteErrors), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
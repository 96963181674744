import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-5 mt-4 bg-white border rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryNavItem = _resolveComponent("SummaryNavItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_SummaryNavItem, {
        key: item.id,
        active: _ctx.modelValue === item.id,
        onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.id))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.name), 1)
        ]),
        _: 2
      }, 1032, ["active", "onClick"]))
    }), 128))
  ]))
}
import { GenericService } from '@/services/api/generic.service';
import { FormSubmission } from '@/models';

export class AnonymousParticipantFormSubmissionService extends GenericService<FormSubmission> {
  endpoint = '';

  constructor(participantId: string) {
    super();
    this.endpoint = `v1/anonymous-participants/${participantId}/form-submissions`;
  }
}

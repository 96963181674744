import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row items-center cursor-pointer justify-between p-4 rounded", 
      _ctx.dark
        ? 'text-gray-400 hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white'
        : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900'
    ]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["truncate", _ctx.modelValue ? (_ctx.dark ? 'text-white' : 'text-gray-900') : ''])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["rounded-full flex items-center p-2", {
        'bg-gray-800 text-white': _ctx.modelValue && _ctx.dark,
        'bg-gray-100 text-gray-900': _ctx.modelValue && !_ctx.dark
      }])
    }, [
      _createVNode(_component_BaseIcon, {
        name: _ctx.modelValue ? 'tick' : 'add'
      }, null, 8, ["name"])
    ], 2)
  ], 2))
}
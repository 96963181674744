
import { Vue, Options } from 'vue-class-component';
import { firstLetterToUpper } from '@/lib/helpers/string.helper';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: { BaseIcon },
  props: {
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    },
    showInitials: {
      type: Boolean,
      default: true
    },
    infos: {
      type: Object,
      default: () => ({})
    }
  }
})
export default class PatientDetailsCard extends Vue {
  infos!: { [key: string]: string };
  firstName!: string;
  lastName!: string;

  get name() {
    return `${this.lastName.length ? `${this.lastName}, ` : ''}${this.firstName}`;
  }

  get initials() {
    return `${this.lastName.charAt(0)}${this.firstName.charAt(0)}`.toUpperCase();
  }

  formatInfoKey(key: string) {
    return firstLetterToUpper(key);
  }
}

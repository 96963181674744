
import { Vue, Options } from 'vue-class-component';
import { v4 } from 'uuid';
import { ColorName, PositionName } from '@/lib';
import { InputTextHelper } from '@/lib/components/Helper';

@Options({
  components: { InputTextHelper },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    labelPosition: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value)
    },
    labelClass: {
      type: String,
      default: 'leading-none text-sm px-3 font-medium'
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary-500'
    }
  }
})
export default class BaseSwitch extends Vue {
  labelPosition!: PositionName;
  color!: ColorName;
  modelValue!: boolean;
  disabled!: boolean;
  id: string = v4();

  inputListeners = {};

  toggleValue() {
    if (!this.disabled) {
      this.$emit('input', !this.modelValue);
      this.$emit('update:modelValue', !this.modelValue);
      this.$emit('change', !this.modelValue);
      this.$emit('check', !this.modelValue);
    }
  }

  created() {
    this.inputListeners = {
      input: (ev: UIEvent) => this.$emit('update:modelValue', (ev.target as HTMLInputElement).checked)
    };
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "ml-2" }
const _hoisted_2 = { class: "w-full flex flex-col" }
const _hoisted_3 = { class: "text-sm font-semibold text-gray-600 ml-2 mb-1" }
const _hoisted_4 = { class: "w-full flex flex-row justify-between p-4 border-t" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "main",
    class: "relative inline-flex focus:outline-none",
    onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
  }, [
    _createElementVNode("div", {
      ref: "button",
      role: "button",
      class: "z-10 w-full focus:outline-none",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = true))
    }, [
      _createVNode(_component_BaseButton, { color: "ghost" }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseIcon, {
            name: "filter",
            height: "16px"
          }),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('platform.common.filters')), 1),
          (_ctx.selectedCount > 0)
            ? (_openBlock(), _createBlock(_component_MkBadge, {
                key: 0,
                size: "xsmall",
                color: "primary",
                class: "ml-2",
                filled: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.selectedCount), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 512),
    (_ctx.options)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute left-0 flex flex-col w-full h-full transition duration-100 ease-in-out", _ctx.isOpen ? 'z-50 opacity-100 visible' : 'z-0 opacity-0 w-0 h-0 invisible']),
          style: _normalizeStyle(_ctx.style)
        }, [
          _createElementVNode("div", {
            ref: "menu",
            style: _normalizeStyle(_ctx.menuStyle),
            class: _normalizeClass(["static absolute top-0 flex flex-col whitespace-no-wrap border rounded-lg shadow-lg bg-white border-gray-300", [
          _ctx.isOpen ? 'visible opacity-100 z-50' : 'invisible opacity-0',
          _ctx.position === 'left' ? 'right-0' : 'left-0'
        ]])
          }, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.options && _ctx.options.checkboxItems.length)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "p-4 flex flex-col overflow-auto scrollbar-dark",
                    style: _normalizeStyle(_ctx.scrollableStyle)
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.checkboxItems, (optionGroup, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: i,
                        class: "w-full flex flex-col mb-3"
                      }, [
                        _createElementVNode("div", _hoisted_3, _toDisplayString(optionGroup.label), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionGroup.options, (singleOption) => {
                          return (_openBlock(), _createBlock(_component_BaseCheckbox, {
                            key: singleOption.value,
                            modelValue: _ctx.checkboxModel,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkboxModel) = $event)),
                            label: singleOption.label,
                            value: singleOption.value,
                            border: false,
                            shadow: false,
                            "checkbox-class": "w-4 h-4 ml-2",
                            "hover-bg-class": "bg-gray-100"
                          }, null, 8, ["modelValue", "label", "value"]))
                        }), 128))
                      ]))
                    }), 128))
                  ], 4))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BaseButton, {
                color: "ghost",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", null, [
                _createVNode(_component_BaseButton, {
                  color: "ghost",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetSelections()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.reset')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseButton, {
                  class: "ml-2",
                  color: "primary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirm()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.apply')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ], 6)
        ], 6))
      : _createCommentVNode("", true)
  ], 544))
}
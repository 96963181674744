
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: {
    BaseIcon
  },

  props: {
    to: {
      type: [String, Object],
      default: null
    },

    icon: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  }
})
export default class PopoverLink extends Vue {}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b9dcd45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "flex flex-col justify-center" }
const _hoisted_3 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_4 = { class: "text-base font-medium text-gray-900" }
const _hoisted_5 = {
  key: 0,
  class: "text-xs text-gray-500 font-medium"
}
const _hoisted_6 = { class: "text-base font-normal text-gray-600" }
const _hoisted_7 = { class: "text-base font-normal text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilePond = _resolveComponent("FilePond")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilePond, {
      name: "file",
      "label-idle": _ctx.$t('platform.profile.image-upload'),
      "accepted-file-types": "image/png, image/jpeg",
      "style-panel-layout": "compact",
      "allow-multiple": false,
      "image-preview-height": 112,
      "image-crop-aspect-ratio": '1:1',
      files: _ctx.files,
      server: _ctx.server,
      onError: _ctx.handleError,
      onProcessfile: _ctx.handleProcessFile,
      onRemovefile: _ctx.handleRemoveFile,
      onAddfilestart: _ctx.handleAddFileStart
    }, null, 8, ["label-idle", "files", "server", "onError", "onProcessfile", "onRemovefile", "onAddfilestart"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('platform.profile.image')), 1),
        (_ctx.isOptional)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('platform.profile.image-format')), 1),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('platform.profile.image-message')), 1)
    ])
  ]))
}
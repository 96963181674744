import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import { i18n } from '@/i18n/i18n';
import { TokenService } from '@/services';
import { useNotificationStore } from '@/stores/notification.store';

import router from '@/router';
import { useSessionStore } from '@/stores/session.store';

export function handleErrorResponse(error: AxiosError) {
  const sessionStore = useSessionStore();
  if (error.response) {
    const { status } = error.response;

    // Server Errors
    if (status >= 500) {
      // showErrorNotificationFromResponse(error);
    }

    // Unauthorised
    if (status === 401) {
      TokenService.removeToken();
      if (error.response.data.error === 'password_reset_required') {
        throw error;
      }

      router.push({ name: 'login' }).catch((error) => {
        if (!error.message.includes('Avoided redundant navigation to current location: "/".')) {
          throw error;
        }
      });
    }

    // Forbidden
    if (status === 403) {
      Sentry.captureException(error);

      useNotificationStore().snackbar = {
        label: i18n.global.t('platform.error.403'),
        autoDismiss: true,
        dismissAfter: 6000,
        color: 'danger',
        icon: 'warning'
      };
    }
    // Maintenance
    if (status === 503) {
      sessionStore.isMaintenance = true;
    }

    // Conflict
    if (status === 409) {
      // showErrorNotificationFromResponse(error);
    }
  }
}

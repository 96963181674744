
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: {
    BaseIcon
  },

  props: {
    to: {
      type: [String, Object],
      default: null
    },

    icon: {
      type: String,
      default: null
    }
  }
})
export default class NavLink extends Vue {
  isRouteActive(path: string) {
    return this.$route?.path.includes(path);
  }
}

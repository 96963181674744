import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 z-50 flex flex-col bg-gray-900 pt-10"
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "flex flex-row items-center h-full text-gray-700 text-5xl" }
const _hoisted_4 = { class: "flex w-full items-end p-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ImageWrapper = _resolveComponent("ImageWrapper")!
  const _component_ImagesGallery = _resolveComponent("ImagesGallery")!

  return (_ctx.images && _ctx.images.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "absolute focus:outline-none top-0 left-0 text-white bg-gray-800 p-3 rounded-r-full text-sm text-center w-14 h-12 hover:bg-gray-600",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
          }, [
            _createVNode(_component_BaseIcon, {
              name: "close",
              class: "stroke-1.5"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            type: "button",
            class: "hover:text-white h-12 focus:outline-none",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.previousImage()))
          }, [
            _createVNode(_component_BaseIcon, {
              class: "ml-12 mr-16",
              name: "left-arrow"
            })
          ]),
          _createVNode(_component_ImageWrapper, {
            class: "h-auto w-auto",
            "image-url": _ctx.image,
            loading: _ctx.loading
          }, _createSlots({
            "bottom-right": _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.selectedIndex + 1) + " / " + _toDisplayString(_ctx.images.length), 1)
            ]),
            _: 2
          }, [
            (_ctx.images[_ctx.selectedIndex].date)
              ? {
                  name: "top-left",
                  fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.images[_ctx.selectedIndex].date), 1)
                  ])
                }
              : undefined,
            (_ctx.images[_ctx.selectedIndex].description)
              ? {
                  name: "bottom-left",
                  fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.images[_ctx.selectedIndex].description), 1)
                  ])
                }
              : undefined
          ]), 1032, ["image-url", "loading"]),
          _createElementVNode("button", {
            type: "button",
            class: "hover:text-white h-12 focus:outline-none",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextImage()))
          }, [
            _createVNode(_component_BaseIcon, {
              class: "hover:text-white mr-12 ml-16",
              name: "right-arrow"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ImagesGallery, {
            modelValue: _ctx.selectedIndex,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedIndex) = $event)),
            class: "justify-center",
            images: _ctx.images
          }, null, 8, ["modelValue", "images"])
        ])
      ]))
    : _createCommentVNode("", true)
}
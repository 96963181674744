
import { Vue, Options } from 'vue-class-component';
import { IPermission } from '@/lib';
import PermissionGroupItem from './PermissionGroupItem.vue';

@Options({
  name: 'PermissionGroup',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    category: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PermissionGroupItem
  }
})
export default class Permissions extends Vue {
  modelValue!: Array<IPermission>;
  category!: string;

  get isCategoryChecked() {
    return this.modelValue.filter((permission) => permission.isChecked).length === this.modelValue.length;
  }

  onCategoryChange(value: boolean) {
    // //Update parent status
    const array = this.modelValue.map((permission) => ({
      ...permission,
      isChecked: value
    }));
    this.$emit('update:modelValue', array);
  }

  onPermissionChange(permissionIndex: number, value: boolean) {
    const array = [...this.modelValue];
    // // Update children status
    if (value) {
      array[permissionIndex].isChecked = value;
      this.$emit('update:modelValue', array);
    } else {
      array[permissionIndex].isChecked = value;
      this.$emit('update:modelValue', array);
    }
  }
}

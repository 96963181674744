
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  props: {
    /**
     * A vue-Router object or string specifying the URL to navigate to
     */
    to: {
      type: [String, Object],
      default: null
    },
    icon: {
      type: String
    }
  },
  components: {
    BaseIcon
  }
})
export default class SidebarNavigationLink extends Vue {
  isRouteActive(path: string) {
    return this.$route?.path.includes(path);
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchInput, {
      ref: "searchInput",
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      label: _ctx.label,
      suggestions: _ctx.suggestionsList,
      placeholder: _ctx.placeholder,
      description: _ctx.description,
      loading: _ctx.loading,
      icon: _ctx.icon,
      "icon-position": _ctx.iconPosition,
      "icon-stroke": _ctx.iconStroke,
      clear: _ctx.clear,
      error: _ctx.error && _ctx.error.length ? _ctx.error : '',
      wrap: "",
      "is-optional": _ctx.isOptional,
      onSubmit: _ctx.validateInputOrClear,
      onValidate: _ctx.validateOrClear
    }, null, 8, ["modelValue", "label", "suggestions", "placeholder", "description", "loading", "icon", "icon-position", "icon-stroke", "clear", "error", "is-optional", "onSubmit", "onValidate"])
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white border shadow border-gray-400" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row justify-between bg-white px-4 py-6"
}
const _hoisted_3 = { class: "text-gray-900 text-base font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Condition = _resolveComponent("Condition")!
  const _component_ManagementPlanStepAdder = _resolveComponent("ManagementPlanStepAdder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.diagnoses, (diagnosis, diagnosisIndex) => {
      return (_openBlock(), _createBlock(_component_Condition, {
        key: diagnosisIndex,
        class: _normalizeClass({ 'border-t': diagnosisIndex }),
        condition: diagnosis.condition ? diagnosis.condition.name : '',
        stage: diagnosis.stage ? diagnosis.stage.name : '',
        location: diagnosis.anatomy ? diagnosis.anatomy.name : '',
        status: diagnosis.status,
        presence: diagnosis.is_present,
        differential: diagnosis.is_differential,
        "show-actions": false
      }, null, 8, ["class", "condition", "stage", "location", "status", "presence", "differential"]))
    }), 128)),
    (!_ctx.diagnoses.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.unable')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ManagementPlanStepAdder, {
      class: "p-6 border-t rounded-b-lg bg-gray-100",
      value: _ctx.stepsValue,
      fields: _ctx.defaultField,
      loading: _ctx.loading,
      destinations: _ctx.destinationOptions,
      timeframes: _ctx.timeframeOptions,
      onAddStep: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addStep', { ...$event, action_id: _ctx.actionId }))),
      onDeleteStep: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('deleteStep', { ...$event, action_id: _ctx.actionId }))),
      onUpdateStep: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('updateStep', { ...$event, action_id: _ctx.actionId })))
    }, null, 8, ["value", "fields", "loading", "destinations", "timeframes"])
  ]))
}
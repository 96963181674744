import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between leading-tight mb-3"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-gray-500 font-medium text-xs"
}
const _hoisted_6 = {
  key: 1,
  class: "text-xs font-semibold text-primary-500"
}
const _hoisted_7 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!

  return (_openBlock(), _createBlock(_component_BasePopover, {
    ref: "selectDropdownPopover",
    width: "100%",
    class: "w-full inline-flex flex-col"
  }, {
    menu: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createBlock(_component_PopoverButton, {
            key: option.value,
            class: "my-1",
            active: _ctx.selectedOption === option.value,
            onClick: ($event: any) => (_ctx.selectedOption = option.value)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.label), 1)
            ]),
            _: 2
          }, 1032, ["active", "onClick"]))
        }), 128))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ((_ctx.label && _ctx.label.length) || _ctx.$slots.action)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", {
                for: _ctx.id,
                class: "text-base font-medium leading-tight"
              }, _toDisplayString(_ctx.label), 9, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.isOptional)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
                  : _createCommentVNode("", true),
                (_ctx.$slots.action)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "action")
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          tabindex: "0",
          class: _normalizeClass(["flex cursor-pointer items-center w-full bg-white border rounded h-12", {
          'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
            _ctx.error,
          'outline-primary-500': _ctx.focused
        }]),
          onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.openPopup && _ctx.openPopup(...args)), ["enter","space"]))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-full ml-5 appearance-none bg-white focus:outline-none", {
            'text-gray-400': !_ctx.modelValue,
            'leading-none': _ctx.lineHeight === 1,
            'leading-tight': _ctx.lineHeight > 1
          }])
          }, _toDisplayString(_ctx.selectedLabel || _ctx.placeholder), 3),
          _createVNode(_component_BaseIcon, {
            height: "1.25em",
            class: "text-gray-500 rounded-full p-px stroke-1.5 ml-3 mr-3",
            name: "chevron-down"
          })
        ], 34),
        _createVNode(_component_InputTextHelper, {
          error: _ctx.error,
          description: _ctx.description
        }, null, 8, ["error", "description"])
      ])
    ]),
    _: 3
  }, 512))
}
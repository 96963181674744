import { GenericService } from './generic.service';
import { Consult, ConsultType } from '@/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class ConsultService extends GenericService<Consult> {
  endpoint = 'v1/consults';

  async getConsultTypes(params: AxiosRequestConfig = {}): Promise<AxiosResponse<ConsultType[]>> {
    return await apiClient.get('v1/consult-types', params);
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withKeys as _withKeys, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center justify-between mb-3"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs font-medium cursor-default text-gray-500 capitalize"
}
const _hoisted_4 = ["id", "placeholder", "value", "disabled"]
const _hoisted_5 = { class: "relative w-full" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ClearButton = _resolveComponent("ClearButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_InputTextHelper = _resolveComponent("InputTextHelper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'p-4 border bg-white rounded shadow ': !_ctx.absolute }, "relative"])
  }, [
    (_ctx.label && _ctx.label.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: "inline-block text-base font-medium leading-tight"
          }, _toDisplayString(_ctx.label), 9, _hoisted_2),
          (_ctx.isOptional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "search",
      class: _normalizeClass([[
        _ctx.dark ? 'bg-gray-900' : 'bg-white',
        _ctx.label && _ctx.label.length ? 'mt-3' : '',
        _ctx.disabled ? 'bg-gray-100' : '',
        _ctx.error && _ctx.error.length
          ? 'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none'
          : ''
      ], "flex flex-row items-center w-full cursor-text rounded border border-solid border-gray-300 shadow"]),
      onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.$refs.input.focus()), ["stop"]))
    }, [
      (_ctx.icon && _ctx.icon.length)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            class: _normalizeClass(["text-gray-500", [_ctx.iconPosition === 'right' ? 'order-1' : 'mr-2']]),
            position: _ctx.iconPosition === 'left' ? 'right' : 'left',
            name: _ctx.icon,
            height: _ctx.iconHeight,
            stroke: _ctx.iconStroke
          }, null, 8, ["class", "position", "name", "height", "stroke"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        id: _ctx.id,
        ref: "input",
        class: _normalizeClass([[
          _ctx.dark ? 'bg-gray-900 text-gray-100' : 'bg-white',
          _ctx.disabled ? 'bg-gray-100' : '',
          _ctx.size === 'regular' ? 'h-12' : 'h-10'
        ], "focus:outline-none text-medium rounded pl-2 min-w-0 w-full truncate"]),
        type: "text",
        autocomplete: "chrome-off",
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = true)),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput($event.target.value))),
        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('submit', _ctx.modelValue)), ["enter"]))
      }, null, 42, _hoisted_4),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 1,
            class: "mr-4",
            name: "loading-spinner-small"
          }))
        : _createCommentVNode("", true),
      (_ctx.clear && !_ctx.disabled)
        ? (_openBlock(), _createBlock(_component_ClearButton, {
            key: 2,
            class: "focus:outline-none pointer-events-auto mr-4 ml-2 justify-self-end",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInput('')))
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(["w-full", [
          _ctx.suggestions && (_ctx.suggestions.length || _ctx.addButtonLink) && (_ctx.isOpen || _ctx.alwaysShowResults) && !_ctx.disabled
            ? 'block'
            : 'hidden'
        ]]),
        "data-dusk": "search-input-list-wrapper"
      }, [
        _createElementVNode("div", {
          ref: "list",
          class: _normalizeClass(["z-40 mt-2 mr-6 overflow-hidden overflow-y-auto max-h-75", [
            _ctx.wrap ? '' : 'whitespace-no-wrap',
            _ctx.maxWidth ? 'w-auto' : 'w-full',
            _ctx.dark ? 'bg-gray-900' : 'bg-white',
            _ctx.absolute ? 'absolute p-2 border rounded-lg shadow-lg border-gray-300' : ''
          ]]),
          onScroll: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onScroll($event)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestions, (suggestion) => {
            return (_openBlock(), _createBlock(_component_PopoverButton, {
              key: suggestion.label,
              dark: _ctx.dark,
              "data-dusk": "suggestion",
              onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isOpen = false)),
              onMousedown: ($event: any) => (_ctx.onItemClick(suggestion))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _ctx.formatText(suggestion.label)
                }, null, 8, _hoisted_6)
              ]),
              _: 2
            }, 1032, ["dark", "onMousedown"]))
          }), 128)),
          (_ctx.addButtonLink)
            ? (_openBlock(), _createBlock(_component_ButtonLink, {
                key: 0,
                class: "w-full border-none text-primary-500 justify-start shadow-none",
                "left-icon": "add",
                color: "muted",
                to: _ctx.addButtonLink
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.addButtonLinkLabel) + " ", 1),
                  (_ctx.modelValue)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, " \"" + _toDisplayString(_ctx.modelValue) + "\" ", 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ], 34)
      ], 2)
    ]),
    _createVNode(_component_InputTextHelper, {
      error: _ctx.error,
      description: _ctx.description
    }, null, 8, ["error", "description"])
  ], 2))
}

import { Vue, Options } from 'vue-class-component';
import vueFilePond from 'vue-filepond';
import { FilePondErrorDescription, FilePondFile, FilePondInitialFile } from 'filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { apiClient } from '@/services/api';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop
);

@Options({
  components: {
    FilePond
  },
  props: {
    id: {
      type: String,
      default: ''
    },

    isOptional: {
      type: Boolean,
      default: false
    }
  }
})
export default class AvatarUploader extends Vue {
  id!: string;
  files: Array<FilePondInitialFile> = [];
  server: { url: string; process: string; load: string } = {
    url: `${apiClient.defaults.baseURL}/v1/images`,
    process: '/',
    load: '/'
  };

  mounted() {
    if (this.id) {
      this.files = [
        {
          source: this.id,
          options: {
            type: 'local'
          }
        }
      ];
    }
  }

  handleError() {
    setTimeout(() => {
      this.$emit('refresh-avatar-upload-component', true);
    }, 15000);
  }

  handleProcessFile(error: FilePondErrorDescription, file: FilePondFile) {
    if (!error) {
      const newId = JSON.parse(file.serverId).data.id;
      this.$emit('update:id', newId);
    }

    this.$emit('loading', false);
  }

  handleRemoveFile(error: FilePondErrorDescription) {
    if (!error) {
      this.$emit('update:id', '');
    }
  }

  handleAddFileStart() {
    if (!this.id) {
      this.$emit('loading', true);
    }
  }
}


import { Vue, Options } from 'vue-class-component';
import { TriageStatus } from '@/models';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';

@Options({
  components: { BaseIcon },
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  }
})
export default class TriageSelect extends Vue {
  options!: Array<TriageStatus>;
  modelValue!: string | null;
  showOptions = false;
  loading!: boolean;
  readOnly!: boolean;

  get selectedOption() {
    return this.computedOptions.find((option: TriageStatus): boolean => option.id === this.modelValue);
  }

  get computedOptions() {
    return this.options.map((option) => ({
      ...option,
      style: { '--hovercolor': option.color },
      circleStyle: {
        'background-color': `${option.color}`
      }
    }));
  }

  async selectTriage(triageId: string) {
    await this.$emit('update:modelValue', triageId);
    await this.$emit('change');
    this.showOptions = false;
  }

  toggleShowOptions() {
    if (!this.readOnly) {
      this.showOptions = !this.showOptions;
    }
  }

  clickAway() {
    if (!this.readOnly) {
      this.showOptions = false;
      this.$emit('blur');
    }
  }
}


import { Vue, Options } from 'vue-class-component';
import axios from 'axios';
import { v4 } from 'uuid';
import { CountryCode } from 'libphonenumber-js';
import * as helper from '@/lib/helpers/phone-number-input.helper';
import { countries, ICountry } from '@/assets/data/country_dial_info';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';

@Options({
  components: { BaseIcon, InputTextHelper },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isOptional: {
      type: Boolean,
      default: false
    }
  }
})
export default class PhoneNumberInput extends Vue {
  id = v4();
  countries: { [key: string]: Array<ICountry> } = {};
  modelValue!: string;
  countryCode: CountryCode = 'GB';
  placeholder = helper.getPlaceholder(this.countryCode);
  selectedCountry?: ICountry = helper.getCountry(this.countryCode);
  formattedNumber = '';
  countriesOpened = false;
  isValid = true;
  focused = false;
  disabled!: boolean;

  async created() {
    this.countries = countries.reduce((acc, curr) => {
      const name = this.$t(`platform.countries.${curr.code}`) as string;
      const firstLetter = name.charAt(0).toLowerCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push({
        ...curr,
        name
      });
      return acc;
    }, {} as { [key: string]: Array<ICountry> });
    window.addEventListener('click', () => (this.countriesOpened = false));
    if (this.modelValue?.length) {
      const countryCode = helper.getCountryCodeFromInternationalPhoneNumber(this.modelValue);
      if (countryCode) {
        this.countryCode = countryCode;
      }
    } else {
      try {
        const countryCodeFromApi = (await axios.get(this.ipApiUrlPath)).data.countryCode;
        if (countryCodeFromApi && countryCodeFromApi.length) {
          this.countryCode = countryCodeFromApi;
        } else {
          console.error('We could not get a valid country code.The phone number country has been set to default (GB).');
        }
      } catch (e) {
        console.error('We could not get the user country. The phone number country has been set to default (GB).');
      }
    }
    this.onSelectedCountryChange(this.countryCode);

    window.addEventListener('keydown', (ev) => this.onKeyDown(ev));
    this.$watch('modelValue', (value) => this.onValueChanges(value));
  }

  unmounted() {
    window.removeEventListener('click', () => (this.countriesOpened = false));
    window.removeEventListener('keydown', (ev) => this.onKeyDown(ev));
  }

  onKeyDown(ev: KeyboardEvent) {
    if (this.countriesOpened) {
      let countryDiv = this.$refs[`countries-${ev.key.toLowerCase()}`];
      const countriesDiv = this.$refs.countriesList as HTMLElement;
      if (Array.isArray(countryDiv)) {
        countryDiv = countryDiv[0];
      }
      if (countryDiv && countriesDiv) {
        countriesDiv.scrollTop = (countryDiv as HTMLElement).offsetTop;
      }
    }
  }

  toggle() {
    if (this.disabled) {
      return;
    }
    this.countriesOpened = !this.countriesOpened;
    if (this.countriesOpened) {
      const el = this.$el.querySelector(`#${this.countryCode}`);
      const container = this.$refs.countriesList;
      if (el && container) {
        (container as HTMLElement).scrollTop = (el as HTMLElement).offsetTop;
      }
    }
  }

  updateCountry(countryCode: CountryCode) {
    this.countryCode = countryCode;
    this.isValid = false;
    this.onSelectedCountryChange(countryCode);
    this.updateValue(this.formattedNumber);
  }

  get ipApiUrlPath() {
    const apiUrl = 'https://pro.ip-api.com/json';
    const apiKey = process.env.VUE_APP_IP_API_KEY || null;
    return apiKey ? `${apiUrl}?key=${apiKey}` : apiUrl;
  }

  updateValue(value: string) {
    this.countriesOpened = false;
    const number = helper.getNumber(value, this.countryCode);
    this.$emit('update:modelValue', number ? number.formatInternational() : value);
  }

  onValueChanges(newValue: string) {
    if (this.countryCode?.length) {
      this.formatValue(newValue, this.countryCode);
    }
  }

  onSelectedCountryChange(countryCode: CountryCode) {
    this.selectedCountry = helper.getCountry(countryCode);
    this.placeholder = helper.getPlaceholder(countryCode);
    if (this.modelValue && this.modelValue.length) {
      this.formatValue(this.modelValue, countryCode);
    }
  }

  formatValue(value: string, countryCode: CountryCode) {
    const number = helper.getNumber(value, countryCode);
    this.formattedNumber = number ? number.formatNational() : value;
    this.isValid = number ? number.isValid() : !value.length;
  }
}


import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    modelValue: {
      type: Boolean,
      default: null
    }
  }
})
export default class YesNoAnswer extends Vue {
  modelValue!: boolean | null;

  setValue(value: boolean) {
    if (this.modelValue !== value) {
      this.$emit('update:modelValue', value);
      this.$emit('change', value);
    }
  }
}

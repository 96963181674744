
import { Vue, Options } from 'vue-class-component';
import { FormItem } from '@/models';
import { IOption } from '@/lib';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import ChecklistItem from '@/lib/components/Checkbox/CheckListItem.vue';
import SmartFormRepeater from './SmartFormRepeater.vue';
// @ts-ignore
import { defineAsyncComponent } from 'vue';
import dayjs from 'dayjs';
import { localisedMask } from '@/helpers/date.helper';
import { i18n } from '@/i18n/i18n';
import { getAnswer } from '@/helpers/smart-form.helper';
import { useSmartFormStore } from '@/stores/smartForm.store';

@Options({
  name: 'SmartFormSummaryItem', // Required for recursive components
  props: {
    item: {
      type: Object,
      required: true
    },
    patientId: {
      type: String,
      default: null
    },
    participantId: {
      type: String,
      default: null
    },
    formSubmissionId: {
      type: String,
      required: true
    },
    level: {
      type: Number,
      default: 0
    },
    locale: {
      type: String,
      default: i18n.global.locale.value
    }
  },
  components: {
    BaseIcon,
    SmartFormRepeater,
    ChecklistItem
  }
})
export default class SmartFormSummaryItem extends Vue {
  formSubmissionId!: string;
  item!: FormItem;
  locale!: string;
  smartForm = useSmartFormStore();

  get conditionsMet() {
    return this.smartForm.getItemConditionsMet(this.formSubmissionId, this.item.conditions);
  }

  get itemTypeHasValue() {
    return !['group', 'component', 'repeater', 'spacer', 'list-item', 'label'].includes(this.item.type);
  }

  get answer() {
    return getAnswer(this.smartForm, this.formSubmissionId, this.item, true);
  }

  get formattedAnswer() {
    if (this.item.type === 'slider' && this.item.props) {
      return this.item.props.options[this.answer];
    }

    if (Array.isArray(this.answer)) {
      if (this.item.type === 'checkbox' && this.item.props) {
        return this.answer
          .map((answer) => {
            const options = (this.item.props as { [key: string]: any }).options;
            const option = options && options.find((opt: IOption) => opt.value === answer);
            return option ? option.label : null;
          })
          .filter(Boolean)
          .join(', ');
      } else if (this.item.type === 'file') {
        return this.$t('platform.common.file-upload.count-message', [this.answer.length], { locale: this.locale });
      }
      return this.answer.join(', ');
    }

    if (typeof this.answer === 'boolean') {
      return this.answer ? this.$t('platform.common.yes', [], { locale: this.locale }) : this.$t('platform.common.no', [], { locale: this.locale });
    }

    if (this.item.props?.options && Array.isArray(this.item.props.options) &&
        !this.item.props.options.find((option) => !option.label || !option.value)) {
      const option = this.item.props.options.find((opt: IOption) => opt.value === this.answer);
      if (option) {
        return option.label;
      }
    }

    if (this.item.type === 'date' && dayjs(this.answer).isValid()) {
      return dayjs(this.answer).format(localisedMask(this.locale, this.item.props?.mode));
    }

    return this.answer;
  }

  get componentType() {
    if (this.item.type === 'repeater') {
      return 'SmartFormRepeater';
    }

    if (this.item.type === 'list-item') {
      return 'ChecklistItem';
    }

    if (this.item.type !== 'component') {
      return null;
    }

    return defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "component-[request]" */
          `@/${this.item.component}.vue`
        )
    );
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'a'), { to: _ctx.to }, {
    default: _withCtx(({ route }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center px-4 h-12 rounded-md focus:outline-none", 
        _ctx.isRouteActive(route.path)
          ? 'text-gray-900 bg-gray-100'
          : 'text-gray-500 border-transparent hover:text-gray-900 hover:border-gray-400 focus:text-gray-900 focus:border-gray-400'
      ])
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              name: _ctx.icon,
              class: "text-gray-900 mr-4"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 8, ["to"]))
}
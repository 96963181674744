import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-center w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartLegendItem = _resolveComponent("ChartLegendItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.label
      }, [
        (!item.hide)
          ? (_openBlock(), _createBlock(_component_ChartLegendItem, {
              key: 0,
              class: "mr-6",
              label: item.label,
              color: item.color
            }, null, 8, ["label", "color"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
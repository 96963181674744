
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  ManagementPlan,
  ManagementPlanStep,
  Patient,
  Review,
  FormSection,
  FormItem,
  FormConfig
} from '@/models';
import {
  apiClient,
  ManagementPlanService,
  OrganisationPatientService,
  PatientReviewService
} from '@/services/api';
import { IOption } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import CopyableTextSummary from '@/lib/components/CopyableTextSummary.vue';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { useSessionStore } from '@/stores/session.store';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(utc);

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    formSubmissionId: {
      type: String,
      required: true
    },
    // Not sure if a formSubmission can have multiple review
    reviewId: {
      type: String,
      required: true
    },
    clinicName: {
      type: String,
      default: ''
    },
    clinicAddress: {
      type: String,
      default: ''
    },
    consultCompletedAt: {
      type: String,
      default: ''
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    clinician: {
      type: String,
      default: ''
    }
  },
  components: { BaseIcon, CopyableTextSummary }
})
export default class ReviewSummary extends Vue {
  patient!: Patient;
  sessionStore = useSessionStore();

  review: Review | null = null;
  consultCompletedAt!: string;
  clinician!: string;
  clinicAddress!: string;
  clinicName!: string;
  reviewId!: string;
  formSubmissionId!: string;
  documentId!: string;
  managementPlanId!: string;
  managementPlan: ManagementPlan | null = null;
  managementPlanNote = '';
  correspondence: Array<{ heading: string; content: string }> = [];
  loading = false;

  schema: FormConfig = {
    id: '',
    name: '',
    sections: [],
    completion: []
  };

  successMsg = '';
  errorMsg = '';
  success = false;
  error = false;
  smartForm = useSmartFormStore();


  notificationStore = useNotificationStore();

  get patientService() {
    return new OrganisationPatientService(this.organisationId);
  }

  get patientReviewService() {
    return new PatientReviewService(this.patient.id);
  }

  get managementPlanService() {
    return new ManagementPlanService(this.patient.id);
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get patientInfos() {
    return this.patient
      ? {
        [this.$t(
          'platform.common.name'
        ) as string]: `${this.patient.first_name} ${this.patient.last_name}`,
        [this.$t('platform.patient.dob') as string]: this.formatDob(
          this.patient.date_of_birth
        ),
        MRN: this.patient.mrn
      }
      : {};
  }

  get clinicSummary() {
    return {
      [this.$t('custom.uhb.consult.clinic') as string]: this.clinicName,
      [this.$t('custom.uhb.review.date-completed') as string]: this.formatDate(
        this.consultCompletedAt
      ),
      [this.$t('custom.uhb.consult.clinician') as string]: this.clinician, // TODO:  Not sure where to get this?
      ...(this.clinicAddress
        ? {
          [this.$t('platform.patient.location') as string]: this.clinicAddress
        }
        : {})
    };
  }

  get reviewSummary() {
    const completeAt =
      this.review && this.review.completed_at
        ? this.formatDate(this.review.completed_at)
        : this.$t('custom.uhb.review.not-complete-date');
    return this.review
      ? {
        [this.$t('custom.uhb.review.date-completed') as string]: completeAt
      }
      : {};
  }

  get formatContext() {
    let result = '';
    this.loading = true;
    // Patient Info
    result += this.formatSummary(
      this.$t('custom.uhb.review.summary.patient-summary') as string,
      this.patientInfos
    );
    // Clinic Summary
    result += this.formatSummary(
      this.$t('custom.uhb.review.summary.clinic-summary') as string,
      this.clinicSummary
    );
    // Review Summary
    result += this.formatSummary(
      this.$t('custom.uhb.review.summary.summary') as string,
      this.reviewSummary
    );

    // Form
    if (this.schema) {
      result += this.formatForm();
    }
    // Management Plan
    if (this.managementPlan) {
      result += this.formatManagementPlan();
    }
    // Correspondence
    if (this.correspondence) {
      result += this.formatCorrespondence();
    }
    // Export date
    result += `<br><br>Export generated on : ${this.today} via Big Picture Platform`;
    this.loading = false;
    return result;
  }

  get today() {
    return `${dayjs.utc().format('DD MMM YYYY, HH:mm:ss')}(UTC)`;
  }

  beforeCreate() {
    this.successMsg = this.$t('custom.uhb.review.summary.success-message');
    this.errorMsg = this.$t('custom.uhb.review.summary.error-message');
  }

  async mounted() {
    try {
      await this.fetchPatientReview();
      await this.fetchManagementPlan();
      await this.loadFormSubmission();
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async fetchPatientReview() {
    const response = await this.patientReviewService.fetch(this.reviewId, {
      params: {
        include: 'plan'
      }
    });
    this.review = response.data;

    // TODO: what if a review has multiple documents?
    if (this.review?.documents?.length > 0) {
      this.documentId = this.review.documents[0].id;
      this.fetchCorrespondence();
    }

    if (response.data.plan) {
      this.managementPlanId = response.data.plan.id;
    }
  }

  async fetchManagementPlan() {
    if (this.managementPlanId) {
      this.managementPlan = (
        await this.managementPlanService.getManagementPlan(
          this.managementPlanId
        )
      ).data;
      if (this.managementPlan) {
        this.managementPlanNote = this.managementPlan.review_summary_markdown;
      }
    }
  }

  async fetchCorrespondence() {
    const document = (
      await apiClient.get<{
        content: Record<string, string>;
        document_template_id: string;
      }>(`v1/patients/${this.patient.id}/documents/${this.documentId}`)
    ).data;

    const documentTemplate = (
      await apiClient.get(
        `v1/document-templates/${document.document_template_id}`
      )
    ).data;

    // We only need to display editable sections.
    const editableSections: { heading: string; id: string }[] =
      documentTemplate.schema.items.filter(
        (item: { editable: boolean }) => item.editable
      );

    const correspondence: { heading: string; content: string }[] = [];
    const firstEditableSection = editableSections[0];
    for (let i = 0; i < editableSections.length; i++) {
      correspondence.push({
        heading: editableSections[i].heading,
        content: document.content
          ? document.content[firstEditableSection.id]
          : ''
      });
    }
    this.correspondence = correspondence;
  }

  async loadFormSubmission() {
    const response = await apiClient.get(
      `v1/patients/${this.patient.id}/form-submissions/${this.formSubmissionId}`
    );
    this.schema = response.data.form.schema;
    this.smartForm.answers = {
      ...this.smartForm.answers,
      [this.formSubmissionId]: response.data.answers
    };
  }

  conditionsMet(section: FormSection | FormItem) {
    return this.smartForm.getItemConditionsMet(
      this.formSubmissionId,
      section.conditions
    );
  }

  formatDob(isoDate: string) {
    return this.$d(getDobFromISOString(isoDate), 'short');
  }

  formatSummary(title: string, params: { [key: string]: any }) {
    let result = '';
    result += this.formatTitle(title);
    if (title && params) {
      for (const [key, value] of Object.entries(params)) {
        result += `<br>${key}: ${value}`;
      }
    }
    return result;
  }

  formatForm() {
    let result = '';
    if (this.schema.sections.length > 0) {
      result += this.formatTitle(
        `${this.schema.name} (${this.$t('platform.form.type')})`
      );
      for (let i = 0; i < this.schema.sections.length; i++) {
        if (
          this.conditionsMet(this.schema.sections[i]) &&
          this.schema.sections[i].heading &&
          !this.schema.sections[i].hide_when_readonly
        ) {
          result += this.formatSection(this.schema.sections[i]);
        }
      }
    }
    return result;
  }

  formatSection(section: FormSection) {
    let result = '';
    if (section.heading) {
      result += this.formatTitle(section.heading);
    }
    if (section.items) {
      result += this.formatItems(section.items);
    }
    return result;
  }

  formatItems(items: Array<FormItem>) {
    let result = '';
    for (let i = 0; i < items.length; i++) {
      result += this.formatItem(items[i]);
    }
    return result;
  }

  formatItem(item: FormItem) {
    let result = '';
    if (this.conditionsMet(item) && item.type !== 'hidden' && item.label) {
      if (
        item.type === 'select' ||
        item.type === 'radio' ||
        item.type === 'checkbox' ||
        item.type === 'slider'
      ) {
        if (item.type === 'checkbox' || item.type === 'radio') {
          // Multiselect use array for answer.
          result += `${this.formatItemLabel(item.label)}: ${this.answer(
            item.id
          )}`;
        } else if (item.type === 'slider' && item.props) {
          // BaseSlider use array index for answer.
          result += `${this.formatItemLabel(item.label)}: ${
            item.props.options[this.answer(item.id)]
          }`;
        } else {
          // BaseSelect use option id for answer.
          const optionId = this.answer(item.id);
          const option =
            item.props &&
            item.props.options.find(
              (option: IOption) => option.value === optionId
            );
          result += `${this.formatItemLabel(item.label)}: ${
            option ? option.label : this.$t('platform.common.not-answered')
          }`;
        }
      } else if (item.type === 'repeater') {
        // Loop the repeater group and display all the answers
        result += this.formatRepeaterQuestions(item);
      } else if (item.type === 'group') {
        // If type is group, just display label.
        result += this.formatItemLabel(item.label);
      } else if (item.type === 'component') {
        result += this.formatMedication(item);
      } else {
        result += `${this.formatItemLabel(item.label)}: ${this.answer(
          item.id
        )}`;
      }
    }

    if (item.type === 'list-item') {
      result += this.formatListItem(item);
    }

    if (item.items && item.type !== 'repeater') {
      result += this.formatItems(item.items);
    }
    return result;
  }

  formatManagementPlan() {
    let result = '';
    if (this.managementPlan && this.managementPlan.actions.length > 0) {
      for (let i = 0; i < this.managementPlan.actions.length; i++) {
        if (this.review && this.review.unable_to_diagnose) {
          result += this.formatTitle(
            this.$t('custom.uhb.diagnosis.unable') as string
          );
          if (this.review.technical_issues.length) {
            result += '<br>Technical issues: ';
            for (let j = 0; j < this.review.technical_issues.length; j++) {
              result += `<br>${this.review.technical_issues[j].name}`;
            }
          }
        } else {
          result += this.formatTitle(
            this.$t('custom.uhb.diagnosis.diagnosis') as string
          );
        }
        const diagnoses = this.managementPlan.actions[i].diagnoses;
        const steps = this.managementPlan.actions[i].steps;
        if (diagnoses) {
          for (let j = 0; j < diagnoses.length; j++) {
            result += `<br>${diagnoses[j].anatomy.name}`;
            result += `<br> ${
              diagnoses[j].is_present
                ? diagnoses[j].condition.name
                : this.$t('custom.uhb.diagnosis.no-evidence-of', [
                  diagnoses[j].condition.name
                ])
            }`;
            result += diagnoses[j].stage
              ? ` (${diagnoses[j].stage?.name || ''}) `
              : '';
            result += diagnoses[j].status
              ? `[${diagnoses[j].status?.name || ''}]`
              : '';
          }
        }
        result +=
          steps && steps.length ? this.formatManagementPlanSteps(steps) : '';
      }
    }

    if (this.managementPlanNote) {
      result += this.formatTitle(
        this.$t('custom.uhb.management.notes') as string
      );
      result += `<br>${this.managementPlanNote}<br>`;
    }
    return result;
  }

  formatTitle(title: string) {
    return `<br><br><b>${title}</b>`;
  }

  formatItemLabel(label: string) {
    return `<br><br>${label}`;
  }

  formatManagementPlanSteps(steps: Array<ManagementPlanStep>) {
    let result = this.formatTitle(
      this.$t('custom.uhb.management.plan') as string
    );
    for (let i = 0; i < steps.length; i++) {
      result += `<br>${this.formatManagementPlanStep(steps[i])}`;
    }
    return result;
  }

  formatManagementPlanStep(step: ManagementPlanStep) {
    const timeframe =
      step.timeframe.name === 'None'
        ? `[${this.$t('platform.common.none')}] `
        : `[${step.timeframe.name}] `;
    const notes = step.notes
      ? ` (${this.$t('platform.common.notes')}:${step.notes})`
      : '';
    return timeframe + step.destination.name + notes;
  }

  formatCorrespondence() {
    let correspondenceSection = '';
    for (let i = 0; i < this.correspondence.length; i++) {
      if (this.correspondence[i].content) {
        correspondenceSection += `<br>${this.correspondence[i].heading}: <br>${this.correspondence[i].content}<br>`;
      }
    }
    return correspondenceSection.length
      ? this.formatTitle(
          this.$t('custom.uhb.correspondence.correspondence') as string
      ) + correspondenceSection
      : '';
  }

  formatRepeaterQuestions(item: FormItem) {
    let result = '';
    const repeaterAnswers = this.answer(item.id);
    if (typeof repeaterAnswers === 'string' && item.items) {
      for (let i = 0; i < item.items.length; i++) {
        if (item.items[i].label) {
          result += `<br><br>${item.items[i].label}: ${repeaterAnswers}`;
        } else if (
          repeaterAnswers !== this.$t('platform.common.not-answered')
        ) {
          result += `<br><br>${repeaterAnswers}`;
        }
      }
    } else {
      for (let i = 0; i < Object.keys(repeaterAnswers).length; i++) {
        const repeaterAnswer = repeaterAnswers[i];
        const itemIdsOfAnswer = Object.keys(repeaterAnswer);
        for (let j = 0; j < itemIdsOfAnswer.length; j++) {
          const currentAnswerItemId = itemIdsOfAnswer[j];
          const itemOfRepeaterAnswer =
            item.items &&
            item.items.find(
              (item: FormItem) => item.id === currentAnswerItemId
            );
          if (itemOfRepeaterAnswer) {
            let label = itemOfRepeaterAnswer.label;
            if (itemOfRepeaterAnswer.type === 'date') {
              label = this.$t('platform.common.date');
            }
            if (itemOfRepeaterAnswer.type === 'text') {
              label = this.$t('custom.uhb.diagnosis.condition');
            }
            result += `<br><br>${label}: ${
              repeaterAnswers[i][currentAnswerItemId]?.value
            }`;
          }
        }
      }
    }
    return result.length ? `<br><br>${item.label}${result}` : '';
  }

  formatMedication(item: FormItem) {
    let result = '';
    const answers = this.smartForm.getAnswer(
      this.formSubmissionId,
      item.id
    );
    if (!answers) {
      return this.$t('platform.common.not-answered');
    }
    result += `<br><br>${item.label}`;
    for (let i = 0; i < Object.keys(answers).length; i++) {
      for (let j = 0; j < Object.keys(answers[i]).length; j++) {
        const answerKey = Object.keys(answers[i])[j];
        const answerValue = answers[i][Object.keys(answers[i])[j]];
        result += `<br>`;
        if (answerKey === 'medication') {
          result += `${this.$t('platform.common.name')}: ${answerValue?.name?.value ?? this.$t('platform.common.not-answered')}`;
        } else {
          result += `${answerKey}: ${answerValue?.value ?? this.$t('platform.common.not-answered')}`;
        }
      }
    }
    return result;
  }

  formatListItem(item: FormItem) {
    let result = '';

    const answers = this.smartForm.getAnswer(
      this.formSubmissionId,
      item.id
    );

    result += `<br>${item.props?.label}: ${answers[0]}`;

    if (!answers[0]) {
      result += ` - ${answers[1]}`;
    }

    return result;
  }

  answer(itemId: string) {
    const answer = this.smartForm.getAnswer(
      this.formSubmissionId,
      itemId
    );

    if (typeof answer === 'undefined') {
      return this.$t('platform.common.not-answered');
    } else if (answer === true) {
      return this.$t('platform.common.yes') as string;
    } else if (answer === false) {
      return this.$t('platform.common.no') as string;
    }

    return answer;
  }

  showSuccess() {
    this.success = true;
    this.error = false;
    this.$emit('copy', true);
  }

  showError() {
    this.success = false;
    this.error = true;
    this.$emit('copy', false);
  }

  formatDate(date: string) {
    return dayjs(date).format('DD MMM YYYY, [at] hh:MM:ss a');
  }

  close() {
    this.$emit('close');
  }
}


import { Vue, Options } from 'vue-class-component';
import QRCodeVue from 'qrcode.vue';

@Options({
  props: {
    value: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 200
    }
  },
  components: {
    QRCodeVue
  }
})
export default class BaseQRCodeViewer extends Vue {}

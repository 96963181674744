
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    buttonClass: {
      type: String,
      default: 'px-8'
    },
    menuClass: {
      type: String,
      default: 'mr-6'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseIcon
  }
})
export default class NavDropdown extends Vue {
  isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }

  close() {
    this.isOpen = false;
  }
}

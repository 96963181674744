
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

@Options({
  props: {
    sent: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: () => new Date().toISOString()
    },
    sender: {
      type: Object,
      required: true,
      validator: (sender) => sender.name && ['user', 'caregiver', 'staff', 'patient'].includes(sender.type)
    },
    patient: {
      type: Object,
      default: null
    }
  }
})
export default class ThreadMessage extends Vue {
  date!: string;

  get dateFromNow() {
    return dayjs(this.date).fromNow();
  }
}

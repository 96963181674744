
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class SpecialityButton extends Vue {}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-white flex flex-col" }
const _hoisted_2 = { class: "bg-gray-900 h-10 flex items-center justify-center text-white text-sm font-semibold" }
const _hoisted_3 = { class: "text-sm" }
const _hoisted_4 = {
  class: "bg-gray-900 p-4",
  style: {"width":"max-content"}
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "text-sm text-white font-medium flex flex-shrink-0" }
const _hoisted_7 = { class: "text-sm text-white font-medium flex flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_NavDropdown = _resolveComponent("NavDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedItem)
      ? (_openBlock(), _createBlock(_component_NavDropdown, {
          key: 0,
          dark: "",
          "button-class": "px-4",
          "menu-class": "",
          scrollable: false
        }, {
          button: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.selectedItem.icon && _ctx.selectedItem.icon.length)
                ? (_openBlock(), _createBlock(_component_BaseIcon, {
                    key: 0,
                    class: "mr-3 text-white",
                    name: _ctx.selectedItem.icon
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedItem.name), 1)
            ])
          ]),
          menu: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: item.value.join('x'),
                  type: "button",
                  class: _normalizeClass([{ 'bg-primary-500': _ctx.selectedItem.name === item.name }, "flex w-46 items-center w-full px-4 py-3 font-semibold text-left rounded outline-none text-white hover:bg-gray-500 focus:bg-gray-500"]),
                  onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.value))
                }, [
                  _createVNode(_component_BaseIcon, {
                    name: item.icon,
                    position: "left"
                  }, null, 8, ["name"]),
                  _createTextVNode(" " + _toDisplayString(item.name), 1)
                ], 10, _hoisted_5))
              }), 128)),
              _createElementVNode("div", {
                class: "border-t border-gray-800 flex flex-row items-center py-4 justify-between space-x-4",
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('platform.scans.lock-views')), 1),
                _createVNode(_component_BaseSwitch, {
                  "model-value": _ctx.lock,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('lock', $event)))
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", {
                class: "border-t border-gray-800 flex flex-row items-center pt-4 justify-between space-x-4",
                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('custom.uhb.scans.annotation.annotations')), 1),
                _createVNode(_component_BaseSwitch, {
                  "model-value": _ctx.showAnnotations,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('showAnnotations', $event)))
                }, null, 8, ["model-value"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
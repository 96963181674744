
import { Vue, Options } from 'vue-class-component';
import { IOption, ISuggestion } from '@/lib';
import { getSuggestions } from '@/helpers/search.helper';
import { BaseIcon } from '@/lib/components/Icon';
import SearchInput from './BaseSearchInput.vue';

@Options({
  props: {
    options: {
      type: Array,
      required: true,
      validator: (value: Array<IOption>) => !value.find((option) => !option.value || !option.label)
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    error: {
      type: String,
      default: null
    }
  },
  components: {
    BaseIcon,
    SearchInput
  }
})
export default class MedicationSearch extends Vue {
  options!: Array<IOption>;
  search = '';
  modelValue!: Array<string>;

  get computedModel() {
    return this.modelValue ? this.modelValue : [];
  }

  get filteredList(): Array<ISuggestion> {
    return this.options
      .filter((option: IOption) => !this.computedModel.includes(option.label))
      .map((option: IOption) => ({ id: option.value, label: option.label }));
  }

  get suggestions() {
    return getSuggestions(this.filteredList, this.search);
  }

  add(value: ISuggestion) {
    this.search = '';
    if (!this.computedModel.includes(value.label)) {
      this.$emit('update:modelValue', [...this.computedModel, value.label]);
      this.$emit('change', [...this.computedModel, value.label]);
      this.$refs.searchInput.close();
    }
  }

  remove(value: string) {
    if (this.computedModel.includes(value)) {
      const newItems = this.computedModel.filter((item) => item !== value);

      this.$emit('update:modelValue', newItems);
      this.$emit('change', newItems);
    }
  }
}

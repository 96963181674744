
import { Vue, Options } from 'vue-class-component';
import { IGalleryImage } from '@/lib';
import ImagePreview from './BaseImagePreview.vue';

@Options({
  props: {
    images: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Number,
      default: 0
    }
  },
  components: {
    ImagePreview
  }
})
export default class ImagesGallery extends Vue {
  images!: Array<IGalleryImage>;
}

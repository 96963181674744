import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ec0c1a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "nav",
  class: "flex flex-row justify-between items-start w-full p-6 absolute top-0 z-10 top-nav",
  style: {"background":"linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%)"}
}
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "mx-4 flex-grow signs-dropdown" }
const _hoisted_4 = { class: "flex flex-row items-center" }
const _hoisted_5 = { class: "h-10 flex items-center justify-center text-white text-sm font-semibold colour-filter-button" }
const _hoisted_6 = { class: "text-sm" }
const _hoisted_7 = {
  class: "bg-gray-900 p-4 rounded-lg",
  style: {"width":"max-content"}
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  class: "bg-gray-900 h-10 flex items-center justify-center text-white text-sm font-semibold",
  "data-dusk": "patient-name"
}
const _hoisted_10 = {
  key: 0,
  class: "flex flex-col py-3 border-t border-gray-800"
}
const _hoisted_11 = { class: "break-words text-gray-500" }
const _hoisted_12 = {
  "data-dusk": "patient-details-address",
  class: "break-words text-white mt-2"
}
const _hoisted_13 = {
  key: 0,
  class: "flex flex-col top-0 right-0 absolute items-center justify-center px-4 h-full"
}
const _hoisted_14 = { class: "opacity-75 text-xs font-medium text-white mt-3" }
const _hoisted_15 = {
  key: 1,
  class: "flex w-full h-full items-center justify-center"
}
const _hoisted_16 = { class: "absolute bottom-0 right-0 left-0" }
const _hoisted_17 = { class: "flex justify-between items-end mb-1" }
const _hoisted_18 = { class: "flex flex-col bottom-0 mb-6 ml-6 space-y-5" }
const _hoisted_19 = {
  key: 0,
  class: "flex flex-col text-white space-y-2"
}
const _hoisted_20 = {
  key: 0,
  class: "text-sm font-regular leading-normal opacity-75"
}
const _hoisted_21 = {
  key: 1,
  class: "text-sm font-regular leading-normal opacity-75"
}
const _hoisted_22 = {
  key: 2,
  class: "text-sm font-regular leading-normal opacity-75"
}
const _hoisted_23 = {
  key: 0,
  class: "bg-gray-900 rounded outline-none mb-2 p-1.5"
}
const _hoisted_24 = ["onClick"]
const _hoisted_25 = {
  class: "text-alert-500 text-xs leading-tight h-4 font-semibold rounded px-1",
  style: {"background":"rgba(0, 0, 0, 0.7)"}
}
const _hoisted_26 = {
  key: 1,
  class: "mb-6 mr-4 flex items-center"
}
const _hoisted_27 = ["onClick"]
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "bg-gray-900 p-10 space-y-8" }
const _hoisted_30 = { class: "flex justify-between flex-row space-x-4 items-start" }
const _hoisted_31 = { class: "text-white font-bold text-xl" }
const _hoisted_32 = {
  key: 1,
  class: "flex flex-row flex-wrap -mb-2"
}
const _hoisted_33 = {
  key: 2,
  class: "space-x-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ViewerNavigationSwitch = _resolveComponent("ViewerNavigationSwitch")!
  const _component_ScanSigns = _resolveComponent("ScanSigns")!
  const _component_NavDropdown = _resolveComponent("NavDropdown")!
  const _component_ViewerSelect = _resolveComponent("ViewerSelect")!
  const _component_PatientDetailsCard = _resolveComponent("PatientDetailsCard")!
  const _component_ViewerItem = _resolveComponent("ViewerItem")!
  const _component_SlicesSlider = _resolveComponent("SlicesSlider")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_AnnotationToolbar = _resolveComponent("AnnotationToolbar")!
  const _component_ViewerZoomInput = _resolveComponent("ViewerZoomInput")!
  const _component_ViewerThumbnailGallery = _resolveComponent("ViewerThumbnailGallery")!
  const _component_GridLayout = _resolveComponent("GridLayout")!
  const _component_SeriesSelector = _resolveComponent("SeriesSelector")!
  const _component_CheckboxGroup = _resolveComponent("CheckboxGroup")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-col max-h-screen h-full w-full overflow-hidden",
    style: {"background-color":"#000"},
    onKeydown: _cache[19] || (_cache[19] = _withKeys(($event: any) => (_ctx.selectedTool = null), ["esc"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          "data-dusk": "viewer-close-button",
          class: "flex items-center justify-center bg-gray-900 text-white text-sm font-medium focus:outline-none rounded h-10 w-10 mr-2 hover:bg-gray-800",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_BaseIcon, { name: "close" })
        ]),
        _createVNode(_component_ViewerNavigationSwitch, {
          modelValue: _ctx.mode,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mode) = $event)),
            _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeMode($event)))
          ],
          items: _ctx.modes
        }, null, 8, ["modelValue", "items"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.readonly && _ctx.mode === _ctx.ViewerMode.SINGLE_MODE)
          ? (_openBlock(), _createBlock(_component_ScanSigns, {
              key: 0,
              "model-value": _ctx.currentSigns,
              list: _ctx.signsList,
              onSearch: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('searchSigns', $event))),
              onAdd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addSign($event))),
              onRemove: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeSign($event))),
              onLoadNext: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('loadNextSigns')))
            }, null, 8, ["model-value", "list"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isColourFiltersEnabled)
          ? (_openBlock(), _createBlock(_component_NavDropdown, {
              key: 0,
              highlight: _ctx.colourFilter['value'] !== '',
              dark: "",
              "button-class": "px-4",
              "menu-class": "",
              scrollable: false
            }, {
              button: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.colourFilter['label']), 1)
                ])
              ]),
              menu: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableColourFilters, (availableColourFilter) => {
                    return (_openBlock(), _createElementBlock("button", {
                      key: availableColourFilter['value'],
                      class: _normalizeClass([{ 'bg-primary-500': _ctx.colourFilter === availableColourFilter }, "flex w-46 items-center w-full px-4 py-3 font-semibold text-left rounded outline-none text-white hover:bg-gray-500 focus:bg-gray-500"]),
                      onClick: ($event: any) => (_ctx.colourFilter = availableColourFilter)
                    }, _toDisplayString(availableColourFilter['label']), 11, _hoisted_8))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["highlight"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ViewerSelect, {
          class: _normalizeClass(_ctx.mode === _ctx.ViewerMode.COMPARE_MODE ? 'visible ml-1' : 'invisible'),
          "model-value": _ctx.layout,
          items: _ctx.layoutOptions,
          lock: _ctx.lock,
          "show-annotations": _ctx.showAnnotations,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.setLayout($event))),
          onLock: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setLock($event))),
          onShowAnnotations: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showAnnotations = $event))
        }, null, 8, ["class", "model-value", "items", "lock", "show-annotations"]),
        (_ctx.patient)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "flex flex-col ml-2",
              "data-dusk": "patient-details",
              style: _normalizeStyle({ 'visibility: collapse': _ctx.patientName?.length })
            }, [
              _createVNode(_component_NavDropdown, {
                dark: "",
                "button-class": "px-4",
                "menu-class": "",
                scrollable: false
              }, {
                button: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.patientName), 1)
                ]),
                menu: _withCtx(() => [
                  _createVNode(_component_PatientDetailsCard, {
                    id: _ctx.patient.mrn,
                    class: "w-72 -m-2 z-20",
                    "first-name": _ctx.$t('platform.patient.details'),
                    dark: "",
                    "show-initials": false,
                    infos: _ctx.patientInfos
                  }, {
                    default: _withCtx(() => [
                      (_ctx.patient.address)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('platform.common.address')), 1),
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.patient.address.formatted_address), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["id", "first-name", "infos"])
                ]),
                _: 1
              })
            ], 4))
          : _createCommentVNode("", true)
      ])
    ], 512),
    _createVNode(_component_GridLayout, {
      cols: _ctx.layout.length,
      rows: _ctx.rows
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array(_ctx.viewerItemsLength), (_, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["relative", `row-span-${_ctx.rows / _ctx.getLayout(index)}`]),
            style: {"height":"inherit"}
          }, [
            (_ctx.currentImages[index] && _ctx.currentImages[index].referenceImageId && _ctx.referenceLines[index])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "absolute left-0 w-46 h-32 z-30 ml-6",
                  style: _normalizeStyle(index < _ctx.layout.length ? `top: 80px` : 'top: 4px')
                }, [
                  _createVNode(_component_ViewerItem, {
                    class: "rounded border border-gray-800",
                    "image-ids": [`${_ctx.imagesRoute}/${_ctx.currentImages[index].referenceImageId}`],
                    "reference-line": _ctx.referenceLines[index],
                    "enable-reference-line": ""
                  }, null, 8, ["image-ids", "reference-line"])
                ], 4))
              : _createCommentVNode("", true),
            _createVNode(_component_ViewerItem, {
              class: _normalizeClass(["border-gray-500", {
            'border-r': _ctx.mode === _ctx.ViewerMode.COMPARE_MODE,
            'border-t': index >= _ctx.layout.length
          }]),
              state: _ctx.currentAnnotations[index],
              zoom: _ctx.zooms[index] / 100,
              "zoom-min": _ctx.zoomMin,
              "enable-tools": "",
              "enable-pan": "",
              "enable-zoom": "",
              tool: _ctx.selectedTool,
              tools: _ctx.tools,
              translation: _ctx.translations[index],
              "image-ids": _ctx.imageIds[index],
              "current-image-index": _ctx.imageIndexes[index],
              "colour-filter": _ctx.colourFilter,
              dimensions: _ctx.dimensions[index],
              readonly: _ctx.readonly || _ctx.mode === _ctx.ViewerMode.COMPARE_MODE,
              onSetZoom: ($event: any) => (_ctx.setZoom(index, $event)),
              onTranslate: ($event: any) => (_ctx.translate(index, $event)),
              onMouseover: ($event: any) => (_ctx.focused = index),
              onCreateAnnotation: ($event: any) => (_ctx.createAnnotation(index, $event)),
              onRemoveAnnotation: ($event: any) => (_ctx.removeAnnotation(index, $event)),
              onUpdateAnnotation: ($event: any) => (_ctx.updateAnnotation(index, $event))
            }, {
              default: _withCtx(() => [
                (_ctx.imageIds[index].length > 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_SlicesSlider, {
                        modelValue: _ctx.imageIndexes[index],
                        "onUpdate:modelValue": [($event: any) => ((_ctx.imageIndexes[index]) = $event), ($event: any) => (_ctx.updateImageIndex(index, $event))],
                        style: {"height":"60%"},
                        max: _ctx.imageIds[index].length - 1,
                        size: "small",
                        color: "white",
                        class: "w-px -mt-8",
                        "enable-shortcuts": false,
                        annotated: _ctx.annotatedSlicesIndexes[index]
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "max", "annotated"]),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.imageIndexes[index] + 1) + "/" + _toDisplayString(_ctx.imageIds[index].length), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.mode === _ctx.ViewerMode.COMPARE_MODE && index > 0 && !_ctx.imageIds[index].length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_BaseButton, {
                        size: "small",
                        "left-icon": "add",
                        onClick: ($event: any) => (_ctx.openImagesSelector = index)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.scans.add-image')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      (_ctx.imageData[index])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            (_ctx.imageData[index].description && _ctx.imageData[index].description.length)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.imageData[index].description), 1))
                              : _createCommentVNode("", true),
                            (_ctx.imageData[index].dimensions && _ctx.imageData[index].dimensions.length)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.imageData[index].dimensions), 1))
                              : _createCommentVNode("", true),
                            (_ctx.imageData[index].acquisitionDateTime && _ctx.imageData[index].acquisitionDateTime.length)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.imageData[index].acquisitionDateTime), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.showInadequateModal)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (!_ctx.inadequateReasons.length && _ctx.mode === _ctx.ViewerMode.SINGLE_MODE && !_ctx.readonly)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                  _createVNode(_component_BaseSwitch, {
                                    "label-class": "text-xs font-semibold text-gray-700 pr-3",
                                    label: _ctx.$t('custom.uhb.scans.mark-as-inadequate'),
                                    dark: "",
                                    "onUpdate:modelValue": ($event: any) => (_ctx.showInadequateModal = index)
                                  }, null, 8, ["label", "onUpdate:modelValue"])
                                ]))
                              : ((_ctx.inadequateReasons.length && !index) || _ctx.inadequacies[index].length)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: "flex items-center py-1.5 cursor-pointer px-2 space-x-2 text-black bg-alert-500 font-semibold rounded mb-2 text-xs font-semibold",
                                    onClick: ($event: any) => (_ctx.showInadequateModal = index)
                                  }, [
                                    _createVNode(_component_BaseIcon, { name: "inadequate" }),
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('custom.uhb.scans.inadequate')), 1),
                                    _createElementVNode("span", _hoisted_25, _toDisplayString(index === 0 ? _ctx.inadequateReasons.length : _ctx.inadequacies[index].length), 1)
                                  ], 8, _hoisted_24))
                                : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.mode === _ctx.ViewerMode.SINGLE_MODE && _ctx.tools && _ctx.tools.length && !_ctx.readonly)
                      ? (_openBlock(), _createBlock(_component_AnnotationToolbar, {
                          key: 0,
                          modelValue: _ctx.selectedTool,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedTool) = $event)),
                          class: "flex self-end mb-6",
                          tools: _ctx.tools
                        }, null, 8, ["modelValue", "tools"]))
                      : _createCommentVNode("", true),
                    (_ctx.imageIds[index].length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                          (index > 0)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                type: "button",
                                class: "flex items-center bg-gray-900 text-white text-sm font-medium focus:outline-none rounded mr-2 px-4 h-10",
                                onClick: ($event: any) => (_ctx.openImagesSelector = index)
                              }, [
                                _createVNode(_component_BaseIcon, {
                                  name: "section",
                                  position: "left"
                                }),
                                _createTextVNode(_toDisplayString(_ctx.$t('platform.scans.change-image')), 1)
                              ], 8, _hoisted_27))
                            : _createCommentVNode("", true),
                          (
                    _ctx.translations[index].x !== 0 || _ctx.translations[index].y !== 0 ||
                      _ctx.zooms[index] !== _ctx.defaultZooms[index] ||
                      _ctx.colourFilter !== _ctx.availableColourFilters[0]
                  )
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                type: "button",
                                class: "flex items-center bg-gray-900 text-gray-500 hover:text-white focus:text-white focus:outline-none p-3 rounded mr-2",
                                onClick: ($event: any) => (_ctx.resetViewport(index))
                              }, [
                                _createVNode(_component_BaseIcon, { name: "undo" })
                              ], 8, _hoisted_28))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ViewerZoomInput, {
                            "model-value": _ctx.zooms[index] - _ctx.defaultZooms[index] + 100,
                            class: "z-10 h-10",
                            min: _ctx.zoomMin,
                            step: 10,
                            "onUpdate:modelValue": ($event: any) => (
                    _ctx.setZoom(index, {
                      value: $event - 100 + _ctx.defaultZooms[index],
                      default: false
                    })
                  )
                          }, null, 8, ["model-value", "min", "onUpdate:modelValue"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_ViewerThumbnailGallery, {
                    ref_for: true,
                    ref: "gallery",
                    modelValue: _ctx.seriesIndex,
                    "onUpdate:modelValue": [
                      _cache[11] || (_cache[11] = ($event: any) => ((_ctx.seriesIndex) = $event)),
                      _cache[12] || (_cache[12] = ($event: any) => (_ctx.onSeriesChange($event)))
                    ],
                    style: {"background":"rgba(28, 28, 28, 0.9)"},
                    class: _normalizeClass(_ctx.mode === _ctx.ViewerMode.SINGLE_MODE && _ctx.galleryImages.length ? 'visible' : 'hidden'),
                    images: _ctx.galleryImages
                  }, null, 8, ["modelValue", "class", "images"])
                ])
              ]),
              _: 2
            }, 1032, ["class", "state", "zoom", "zoom-min", "tool", "tools", "translation", "image-ids", "current-image-index", "colour-filter", "dimensions", "readonly", "onSetZoom", "onTranslate", "onMouseover", "onCreateAnnotation", "onRemoveAnnotation", "onUpdateAnnotation"])
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 8, ["cols", "rows"]),
    (_ctx.openImagesSelector)
      ? (_openBlock(), _createBlock(_component_SeriesSelector, {
          key: 0,
          class: "fixed inset-0 z-50",
          studies: _ctx.studies,
          signs: _ctx.signs,
          "current-study-id": _ctx.studyId,
          "exclude-series": _ctx.excludedSeries,
          onClose: _cache[13] || (_cache[13] = ($event: any) => (_ctx.openImagesSelector = null)),
          onSelect: _cache[14] || (_cache[14] = ($event: any) => (_ctx.selectSeries($event)))
        }, null, 8, ["studies", "signs", "current-study-id", "exclude-series"]))
      : _createCommentVNode("", true),
    (_ctx.showInadequateModal !== null)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 1,
          class: "bg-gray-900",
          "dialog-class": "m-auto max-w-xl",
          "bg-color": "gray-900",
          dark: "",
          onClose: _ctx.closeInadequateModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t('custom.uhb.scans.inadequate')), 1),
                (_ctx.mode === _ctx.ViewerMode.COMPARE_MODE || _ctx.readonly)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "flex items-center justify-center w-6 h-6 bg-black hover:bg-gray-800 focus:bg-gray-800 focus:outline-none text-white rounded-full",
                      onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.closeInadequateModal && _ctx.closeInadequateModal(...args)))
                    }, [
                      _createVNode(_component_BaseIcon, {
                        class: "stroke-1.5 text-xl p-1",
                        name: "close"
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.mode === _ctx.ViewerMode.SINGLE_MODE && !_ctx.readonly)
                ? (_openBlock(), _createBlock(_component_CheckboxGroup, {
                    key: 0,
                    modelValue: _ctx.inadequateReasons,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.inadequateReasons) = $event)),
                    options: _ctx.inadequateReasonsOptions,
                    dark: "",
                    "checkbox-class": "w-4 h-4"
                  }, null, 8, ["modelValue", "options"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inadequacies[_ctx.showInadequateModal], (inadequacy) => {
                      return (_openBlock(), _createBlock(_component_MkBadge, {
                        key: inadequacy.id,
                        class: "mb-3 mr-2",
                        color: "danger",
                        size: "large",
                        filled: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(inadequacy.name), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ])),
              (_ctx.mode === _ctx.ViewerMode.SINGLE_MODE && !_ctx.readonly)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createVNode(_component_BaseButton, {
                      color: "primary",
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.markScanInadequate()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_BaseButton, {
                      color: "dark",
                      class: "border border-gray-800",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.closeInadequateModal()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 32))
}

import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (value: string) => ['primary', 'secondary', 'success', 'alert', 'danger', 'gray'].includes(value)
    },

    size: {
      type: String,
      default: 'medium',
      validator: (value: string) => ['xsmall', 'small', 'medium', 'large'].includes(value)
    },

    label: {
      type: String,
      default: null
    }
  }
})
export default class BaseBanner extends Vue {}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["data-dusk"]
const _hoisted_2 = ["data-dusk"]
const _hoisted_3 = { class: "flex items-center space-x-3" }
const _hoisted_4 = {
  key: 1,
  class: "text-sm font-medium text-gray-600 capitalize"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_SmartFormItem = _resolveComponent("SmartFormItem", true)!

  return (_ctx.conditionsMet && _ctx.item.type !== 'hidden')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["space-y-12", {
      'bg-white border rounded-lg shadow': _ctx.item.container === 'card',
      'p-8': _ctx.item.container === 'card' && (_ctx.item.type !== 'yes-no' || !_ctx.answer),
      'px-8 pt-8': _ctx.item.container === 'card' && _ctx.item.type === 'yes-no' && _ctx.answer
    }]),
        "data-dusk": _ctx.hyphenatedLabel
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({
        'flex items-center justify-between': _ctx.item.type === 'yes-no'
      }),
          "data-dusk": _ctx.hyphenatedLabel
        }, [
          (_ctx.item.label || _ctx.item.icon)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent('h' + (_ctx.level + 2)), {
                key: 0,
                class: _normalizeClass([{
          'font-bold': _ctx.item.bold,
        }, "mb-6 text-lg font-semibold text-gray-900"]),
                "data-dusk": "heading"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.item.icon)
                      ? (_openBlock(), _createBlock(_component_BaseIcon, {
                          key: 0,
                          name: _ctx.item.icon,
                          height: "30px",
                          color: "white"
                        }, null, 8, ["name"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(_ctx.item.label), 1),
                    (_ctx.item.label && _ctx.item.optional)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.item.type !== 'group' && _ctx.conditionsMet)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), _mergeProps({
                key: 1,
                id: _ctx.item.id,
                modelValue: _ctx.answer,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answer) = $event)),
                "data-dusk": _ctx.item.id,
                error: _ctx.error,
                item: _ctx.item
              }, _ctx.item.props, {
                description: _ctx.isPrefilled ? _ctx.item.prefillDescription : _ctx.item.props?.description,
                "patient-id": _ctx.patientId,
                "participant-id": _ctx.participantId,
                "form-submission-id": _ctx.formSubmissionId,
                validating: _ctx.validating,
                next: _ctx.next,
                back: _ctx.back,
                loading: _ctx.loading,
                onChange: _ctx.saveAnswers,
                onLoading: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('loading', $event)))
              }), {
                default: _withCtx(() => [
                  (_ctx.item.slot)
                    ? (_openBlock(), _createBlock(_component_SmartFormItem, {
                        key: _ctx.item.slot.id,
                        item: _ctx.item.slot,
                        "patient-id": _ctx.patientId,
                        "participant-id": _ctx.participantId,
                        "form-submission-id": _ctx.formSubmissionId,
                        next: _ctx.next,
                        back: _ctx.back,
                        level: _ctx.level + 1,
                        validating: _ctx.validating,
                        class: _normalizeClass({
            'border-t border-gray-300': _ctx.item.layout === 'list' && _ctx.index,
            'py-6': _ctx.item.layout === 'list'
          })
                      }, null, 8, ["item", "patient-id", "participant-id", "form-submission-id", "next", "back", "level", "validating", "class"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 16, ["id", "modelValue", "data-dusk", "error", "item", "description", "patient-id", "participant-id", "form-submission-id", "validating", "next", "back", "loading", "onChange"]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2),
        (_ctx.item.items && _ctx.item.type !== 'repeater' && _ctx.item.type !== 'yes-no')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.gridClasses(_ctx.item))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.items, (subitem, index) => {
                return (_openBlock(), _createBlock(_component_SmartFormItem, {
                  key: subitem.id,
                  item: subitem,
                  "patient-id": _ctx.patientId,
                  "participant-id": _ctx.participantId,
                  "form-submission-id": _ctx.formSubmissionId,
                  next: _ctx.next,
                  back: _ctx.back,
                  level: _ctx.level + 1,
                  validating: _ctx.validating,
                  class: _normalizeClass({
          'border-t border-gray-300': _ctx.item.layout === 'list' && index,
          'py-6': _ctx.item.layout === 'list'
        }),
                  "data-dusk": "item"
                }, null, 8, ["item", "patient-id", "participant-id", "form-submission-id", "next", "back", "level", "validating", "class"]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.item.items && _ctx.item.type === 'yes-no' && _ctx.answer)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass([
        _ctx.item.layout === 'grid' ? 'mt-6 grid gap-4 grid-cols-' + _ctx.item.cols || 1 : '',
        _ctx.item.container === 'card' ? 'border-t bg-gray-100 p-4 -m-8' : ''
      ])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.items, (subitem) => {
                return (_openBlock(), _createBlock(_component_SmartFormItem, {
                  key: subitem.id,
                  item: subitem,
                  "patient-id": _ctx.patientId,
                  "participant-id": _ctx.participantId,
                  "form-submission-id": _ctx.formSubmissionId,
                  next: _ctx.next,
                  back: _ctx.back,
                  level: _ctx.level + 1,
                  "data-dusk": "item"
                }, null, 8, ["item", "patient-id", "participant-id", "form-submission-id", "next", "back", "level"]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
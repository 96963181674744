
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    to: {
      type: [Object, String],
      default: null
    }
  }
})
export default class FooterLink extends Vue {}

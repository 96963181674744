
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';
import FooterLink from './FooterLink.vue';
import { i18n } from '@/i18n/i18n';
import { useSessionStore } from '@/stores/session.store';
import { CustomerBranding } from '@/models';

interface ILink {
  label: string;
  to?: string;
  name?: string;
}

@Options({
  props: {
    links: {
      type: Array,
      default: () => [
        {
          label: i18n.global.t('platform.navigation.legal') as string,
          name: 'legal'
        }
      ]
    }
  },
  components: {
    BaseIcon,
    FooterLink
  }
})
export default class BaseFooter extends Vue {
  links!: Array<ILink>;
  sessionStore = useSessionStore();
  bpmBranding = CustomerBranding.BPM;

  get branding() {
    return this.sessionStore.customerBranding;
  }
}


import { Options, Vue } from 'vue-class-component';
import { v4 } from 'uuid';
import { InputTextHelper } from '@/lib/components/Helper';

@Options({
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: String,
      default: null
    },
    rows: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    instructions: {
      type: String,
      default: null
    }
  },
  components: { InputTextHelper }
})
export default class BaseTextarea extends Vue {
  id: string = v4();

  get inputListeners() {
    return {
      input: (event: UIEvent) => this.$emit('update:modelValue', (event.target as HTMLInputElement).value),
      change: (event: UIEvent) => this.$emit('change', (event.target as HTMLInputElement).value)
    };
  }
}

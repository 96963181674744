
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';
import { BasePopover, PopoverButton } from '@/lib/components/Popover';

@Options({
  components: { PopoverButton, BasePopover, BaseIcon },
  props: {
    condition: {
      type: String,
      default: ''
    },
    stage: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    status: {
      type: Object,
      default: null,
      validator: (status) => !status || status.name
    },
    differential: {
      type: Boolean,
      default: false
    },
    presence: {
      type: Boolean,
      default: true
    },
    sortable: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    }
  }
})
export default class Condition extends Vue {
  getStatusColor(status: string) {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus === 'new') {
      return 'alert';
    } else if (lowerCaseStatus === 'deteriorating') {
      return 'danger';
    }
    return 'primary';
  }
}


import { Vue, Options } from 'vue-class-component';
import { ImageLoading } from '@/lib/components/Loading';

@Options({
  components: { ImageLoading },
  props: {
    imageUrl: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class ImageWrapper extends Vue {}

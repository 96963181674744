import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "flex flex-row items-center justify-between py-3 cursor-pointer" }, _ctx.$attrs), [
    _createElementVNode("span", {
      class: _normalizeClass(["text-base font-medium", _ctx.active ? 'text-gray-900' : 'text-gray-400'])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _createVNode(_component_BaseIcon, {
      class: _normalizeClass([_ctx.active ? 'text-primary' : 'text-gray-400', "stroke-1.5"]),
      name: "chevron-right"
    }, null, 8, ["class"])
  ], 16))
}

import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    columns: {
      type: Array,
      required: true
    },

    rows: {
      type: Array,
      required: true
    }
  }
})
export default class Table extends Vue {}

import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white border rounded-lg p-4 shadow inline-block" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "bg-center bg-no-repeat bg-cover",
      style: _normalizeStyle(`width: ${_ctx.width}px; height: ${_ctx.height}px;`)
    }, [
      _createElementVNode("img", { src: _ctx.getImageUrl }, null, 8, _hoisted_2)
    ], 4),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "mt-4 text-center leading-tight font-medium text-sm",
          style: _normalizeStyle(`max-width: ${_ctx.width}px`)
        }, _toDisplayString(_ctx.description), 5))
      : _createCommentVNode("", true)
  ]))
}
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "h-full focus:outline-none group cursor-pointer"
}
const _hoisted_2 = { class: "flex items-center h-full border-b-2 border-transparent group-hover:border-gray-400 group-focus:border-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_BaseIcon, {
        name: "chevron-down",
        class: "stroke-1.5",
        position: "right"
      })
    ])
  ]))
}